import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';
import { IconInfoFilledSmall, typography } from '@izettle/otto';
import { Button } from '@izettle/react';
const StyledButton = styled(Button) `
  text-transform: uppercase;
  align-self: start;
  span span {
    ${typography.regular}
  }
`;
export const LanguageSwitchButton = memo(forwardRef((props, ref) => {
    return (React.createElement(StyledButton, Object.assign({ iconPosition: "left", variant: "inline", size: "small", noPadding: true, svgIcon: IconInfoFilledSmall }, props, { ref: ref })));
}));
