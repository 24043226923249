import React from 'react';
import { iZettleMarket } from '@izettle/constants';
import { useLocalization } from '@izettle/lingo-client';
import { getCountryUtils } from 'src/utils/country-helpers';
import { AddressValue } from '../../../AddressValue';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
export const BusinessDetailsSection = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const { getLocalizedBusinessTypeValue } = getCountryUtils(iZettleMarket.SE);
    return (React.createElement(Section, { name: "businessDetails", title: text('OVERVIEW_SECTION_BUSINESS_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: ['businessType'], title: text('OVERVIEW_FIELD_BUSINESS_TYPE_TITLE') },
            React.createElement(Value, null, getLocalizedBusinessTypeValue(stepData, text))),
        React.createElement(Field, { fieldNames: ['businessLegalEntityNumber'], title: text('OVERVIEW_FIELD_BUSINESS_LEGAL_ENTITY_NUMBER_SE_TITLE'), editable: true },
            React.createElement(Value, null, stepData.businessLegalEntityNumber)),
        React.createElement(Field, { fieldNames: ['businessName'], title: text('OVERVIEW_FIELD_BUSINESS_NAME_TITLE'), editable: true },
            React.createElement(Value, null, stepData.businessName)),
        React.createElement(Field, { fieldNames: ['businessStreetAddress', 'businessPostalCode', 'businessCity'], title: text('OVERVIEW_FIELD_BUSINESS_ADDRESS_TITLE'), editable: true },
            React.createElement(AddressValue, { addressData: {
                    streetAddress: stepData.businessStreetAddress,
                    postalCode: stepData.businessPostalCode,
                    city: stepData.businessCity,
                } }))));
};
