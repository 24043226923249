var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useRef, useCallback } from 'react';
import { makeOAuthRequest } from 'src/utils';
import { config } from '../config';
import { useAuthStateValue } from '../providers/auth';
export var RegistrationStatus;
(function (RegistrationStatus) {
    RegistrationStatus["SUCCESS"] = "SUCCESS";
    RegistrationStatus["ACCEPTED_WITH_ID_VERIFICATION"] = "ACCEPTED_WITH_ID_VERIFICATION";
    RegistrationStatus["DENIED"] = "DENIED";
    RegistrationStatus["DENIED_RETRY"] = "DENIED_RETRY";
    RegistrationStatus["VALIDATION_ERROR"] = "VALIDATION_ERROR";
    RegistrationStatus["ACCEPTED_FOR_INTERNAL_TESTING"] = "ACCEPTED_FOR_INTERNAL_TESTING";
    RegistrationStatus["PROCESSING"] = "PROCESSING";
    RegistrationStatus["GAP_WITH_DOCUMENT_VERIFICATION"] = "GAP_WITH_DOCUMENT_VERIFICATION";
})(RegistrationStatus || (RegistrationStatus = {}));
export const usePollingQuestionnaire = () => {
    const [pollingResponse, setPollingResponse] = useState({});
    const [isPollingError, setIsPollingError] = useState(false);
    const { authClient } = useAuthStateValue();
    const pollingInterval = 2000;
    const pollingIntervalLimit = 300;
    let intervalCount = 0;
    // TODO: Ask or understand useRef return type
    const pollingIntervalID = useRef(null);
    const stopPolling = useCallback(() => {
        if (pollingIntervalID.current) {
            clearTimeout(pollingIntervalID.current);
            pollingIntervalID.current = null;
        }
    }, []);
    const sendPollingRequest = (requestId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield makeOAuthRequest({
                method: 'GET',
                url: `${config.api.registration}/upgrade/company/result/${requestId}`,
            }, authClient, 'getUpgradeCompanyResult');
            const responseData = response.data;
            setPollingResponse(responseData);
        }
        catch (error) {
            setIsPollingError(true);
            throw error;
        }
    });
    const doPolling = (requestId) => __awaiter(void 0, void 0, void 0, function* () {
        yield sendPollingRequest(requestId);
        intervalCount += 1;
        pollingIntervalID.current = window.setTimeout(() => {
            if (intervalCount >= pollingIntervalLimit) {
                setIsPollingError(true);
            }
            else {
                void doPolling(requestId);
            }
        }, pollingInterval);
    });
    const startPolling = (requestId) => {
        setIsPollingError(false);
        setPollingResponse({});
        void doPolling(requestId);
    };
    return [{ pollingResponse, isPollingError }, startPolling, stopPolling];
};
