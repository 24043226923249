import React, { useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useMarkdown } from '@izettle/lingo-client';
import { colors, IconSimpleArrowDownMedium, spacing, typography } from '@izettle/otto';
import { Text, Icon, MouseClickFocusRemover, actionFocusMixin } from '@izettle/react';
import { useMeasure } from '@softbind/hook-use-measure';
import { StylelessButton } from 'src/components/StylelessButton';
import { flexItemTextWrapFix } from 'src/styles/mixins';
import { breakpoints } from 'src/theme';
const Container = styled.div `
  border: 1px solid ${colors.border.divider};
  border-bottom: 0px;
  background: ${colors.background.groupedPrimary};
  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
    border: 1px solid ${colors.border.divider};
  }
  &:only-child {
    border-radius: 8px;
  }
`;
const HeaderContainer = styled.div `
  padding: ${spacing.xSmall};
  cursor: pointer;

  @media (max-width: ${breakpoints.tiny}) {
    padding: ${spacing.xxSmall};
  }
`;
const AccordionTrigger = styled(StylelessButton) `
  padding: ${spacing.tiny};
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    ${actionFocusMixin}
  }
`;
const OpenButton = styled(Icon) `
  margin-left: 16px;
  font-size: 26px;
  height: 26px;
  transform-origin: 50% 50%;
  transform: rotate(${(p) => (p.isOpen ? 180 : 0)}deg);
  transition: transform 100ms;
`;
const Title = styled(Text.Regular) `
  ${flexItemTextWrapFix}
  flex-grow: 1;
`;
const Body = styled.div `
  padding: 0 ${spacing.small} ${spacing.small} ${spacing.small};
  visibility: hidden;

  p:first-child,
  ul:first-child {
    margin-top: 0;
  }

  p:last-child,
  ul:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.tiny}) {
    padding: 0 ${spacing.xSmall} ${spacing.xSmall} ${spacing.xSmall};
  }
`;
const StyledHelperText = styled(Text.Small) `
  p,
  ul > li {
    ${typography.small}
  }
  ,
  p a {
    ${typography.regular}
    color : ${colors.action.inline.text.default}
  }
`;
export const HelpSectionItem = ({ item, isOpen = false, toggleOpen, triggerRef, handleTriggerKeyDown, }) => {
    const id = useMemo(() => uuid().slice(-8), []);
    const bodyRef = React.useRef(null);
    const { bounds: bodyBounds } = useMeasure(bodyRef, 'bounds');
    const { mdElement } = useMarkdown();
    // bodyBounds can be zero if the whole element is hidden.
    // By setting value to 'undefined' in this case, we prevent unexpected animation from happening.
    const bodyOpenHeight = (bodyBounds === null || bodyBounds === void 0 ? void 0 : bodyBounds.height) || undefined;
    const bodyHeightStyle = useSpring({
        height: isOpen ? bodyOpenHeight : 0,
        onStart: () => {
            if (bodyRef === null || bodyRef === void 0 ? void 0 : bodyRef.current) {
                bodyRef.current.style.visibility = 'visible';
            }
        },
        onRest: () => {
            if ((bodyRef === null || bodyRef === void 0 ? void 0 : bodyRef.current) && !isOpen) {
                bodyRef.current.style.visibility = 'hidden';
            }
        },
    });
    const triggerId = `${id}__trigger`;
    const bodyId = `${id}__body`;
    return (React.createElement(Container, null,
        React.createElement(HeaderContainer, { onClick: toggleOpen },
            React.createElement(AccordionTrigger, { ref: triggerRef, id: triggerId, onKeyDown: handleTriggerKeyDown, "aria-expanded": isOpen, "aria-controls": bodyId },
                React.createElement(MouseClickFocusRemover, null,
                    React.createElement(Title, { className: "override-text" }, item.title),
                    React.createElement(OpenButton, { svgIcon: IconSimpleArrowDownMedium, isOpen: isOpen })))),
        React.createElement(animated.div, { style: Object.assign({ overflow: 'hidden' }, bodyHeightStyle) },
            React.createElement(Body, { id: bodyId, ref: bodyRef, role: "region", "aria-labelledby": triggerId },
                React.createElement(StyledHelperText, { className: "override-text", as: "span" }, mdElement(item.body, { textKey: false }))))));
};
