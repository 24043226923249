import React from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { Text } from '@izettle/react';
import { isRunningInGoAppWebView } from 'src/util';
import { LogoutButton } from './button';
const LogoutQuestionContainer = styled.div `
  text-align: center;
`;
export const LogoutQuestion = () => {
    const { text } = useLocalization();
    if (isRunningInGoAppWebView()) {
        return null;
    }
    return (React.createElement(LogoutQuestionContainer, null,
        React.createElement(Text.Regular, { className: "override-text" },
            text('LOGOUT_QUESTION_BODY'),
            " ",
            React.createElement(LogoutButton, { variant: "inlineDestructive" }))));
};
