import { BusinessType, NativeBusinessType } from 'src/utils/country/DK/data';
import { getNativeBusinessTypeInfo } from 'src/utils/country/DK/data-helpers';
import { generateIntFromInterval } from '../../helpers';
const calculateCheckDigit = (baseNumber, multipliers) => {
    if (baseNumber.length !== multipliers.length) {
        throw new Error('Multipliers length does not match base number length when calculating check digit');
    }
    const sum = multipliers.reduce((acc, multiplier, index) => acc + multiplier * Number(baseNumber[index]), 0);
    const remainder = sum % 11;
    // This case is not expected. According to the references, remainder can never be 1.
    if (remainder === 1) {
        return null;
    }
    if (remainder === 0) {
        return 0;
    }
    return 11 - remainder;
};
export const generateOrganizationNumber = () => {
    const baseNumber = generateIntFromInterval(1, 9999999, 7);
    const multipliers = [2, 7, 6, 5, 4, 3, 2];
    const checkDigit = calculateCheckDigit(baseNumber, multipliers);
    if (checkDigit === null) {
        return generateOrganizationNumber();
    }
    return `${baseNumber}${checkDigit}`;
};
export const generateSocialSecurityNumber = () => {
    const dateOfBirth = [
        generateIntFromInterval(1, 28, 2),
        generateIntFromInterval(1, 12, 2),
        generateIntFromInterval(60, 99, 2), // year
    ].join('');
    const centuryDigit = '0'; // 1900 based on https://da.wikipedia.org/wiki/CPR-nummer
    const individualNumber = generateIntFromInterval(0, 99, 2);
    const baseNumber = `${dateOfBirth}${centuryDigit}${individualNumber}`;
    const multipliers = [4, 3, 2, 7, 6, 5, 4, 3, 2];
    const checkDigit = calculateCheckDigit(baseNumber, multipliers);
    if (checkDigit === null) {
        return generateSocialSecurityNumber();
    }
    return `${dateOfBirth}-${centuryDigit}${individualNumber}${checkDigit}`;
};
export const generateBusinessCategoryFields = (formContext) => {
    var _a;
    const businessCategories = (_a = formContext.additionalData) === null || _a === void 0 ? void 0 : _a.businessCategories;
    if (!businessCategories) {
        throw new Error('Categories not found in form context');
    }
    return {
        businessCategoryId: businessCategories[0].code.toString(),
        businessSubCategoryId: businessCategories[0].children[0].code.toString(),
    };
};
const generatePersonFields = () => ({
    firstName: 'Signe',
    lastName: 'Toft',
    legalEntityNumber: '170953-1308',
    nationality: 'DK',
    streetAddress: 'Munkemøllestræde 3',
    postalCode: '5000',
    city: 'Odense',
});
const generateOwnerStructureFieldsLimitedCompany = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateOwnerStructureFieldsPartnership = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    rolePartners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateOwnerStructureFieldsNonProfit = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    roleAlternativeOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
export const ownerStructureFieldsGenerators = {
    [BusinessType.LimitedCompany]: generateOwnerStructureFieldsLimitedCompany,
    [BusinessType.Partnership]: generateOwnerStructureFieldsPartnership,
    [BusinessType.NonProfit]: generateOwnerStructureFieldsNonProfit,
    [BusinessType.SelfEmployed]: () => null,
};
const createGenerateFunc = (nativeBusinessType) => {
    const { businessType } = getNativeBusinessTypeInfo(nativeBusinessType);
    return (formContext) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ businessType,
        nativeBusinessType, businessLegalEntityNumber: generateOrganizationNumber(), businessName: 'Idea Infinity', businessStreetAddress: 'Lykkesholmvej 99', businessPostalCode: '5591', businessCity: 'Gelsted', signatoryFirstName: 'Alma', signatoryLastName: 'Jespersen', signatoryLegalEntityNumber: '300455-1280', signatoryNationality: 'US' }, (businessType === 'SelfEmployed' && formContext.form.endsWith('1099K')
        ? { signatoryUnderUsaTaxLaw: false }
        : false)), { isSignatoryShareAddress: true, signatoryPhoneNumber: '+4587785000' }), ownerStructureFieldsGenerators[businessType]()), generateBusinessCategoryFields(formContext)), { businessLocationTypes: ['PHYSICAL_STORE', 'TEMPORARY_STORE'], businessFrequency: 'FEW_TIMES_A_MONTH', businessAverageTransaction: '0-500', businessIzettleShareOfTurnover: '20-80%', businessCurrentAnnualTurnover: '200000-2000000' }));
};
export const DK = Object.values(NativeBusinessType).map((nativeBusinessType) => ({
    title: `${nativeBusinessType} (${getNativeBusinessTypeInfo(nativeBusinessType).businessType})`,
    generateFunc: createGenerateFunc(nativeBusinessType),
}));
