import Ajv from 'ajv';
import { all, intersection, pickBy } from 'ramda';
import { config } from 'src/config';
import { makeOAuthRequest } from 'src/utils';
import { Action } from '../use-tracker';
import { ValidationResponseSuccessValue, } from './types';
export const createDependenciesPayload = (stepObject, questionnaire) => {
    var _a;
    const result = {};
    // Go through step dependencies and fetch fields defined in them
    (_a = stepObject.dependentOn) === null || _a === void 0 ? void 0 : _a.forEach((dependencyStepId) => {
        const dependencyStep = questionnaire.stepList.find((step) => step.ID === dependencyStepId);
        if (!dependencyStep) {
            throw new Error(`Dependency step '${dependencyStepId}' not found when preparing step validation payload`);
        }
        if (!dependencyStep.fields) {
            throw new Error(`Dependency step '${dependencyStepId}' does not have 'fields' defined`);
        }
        dependencyStep.fields.forEach((dependencyField) => {
            result[dependencyField] = questionnaire.stepData[dependencyField];
        });
    });
    return result;
};
const validateMappedStepData = (mappedStepData, dependenciesPayload) => {
    if (intersection(Object.keys(dependenciesPayload), Object.keys(mappedStepData)).length > 0) {
        throw new Error(`Current step data (${Object.keys(mappedStepData).join()}) has colliding fields with its dependencies (${Object.keys(dependenciesPayload).join()})`);
    }
};
export const prepareRequestData = (stepData, stepObject, questionnaire, mapDataForRequest) => {
    const dependenciesPayload = createDependenciesPayload(stepObject, questionnaire);
    // Pick only fields declared in stepObject
    const filteredStepData = pickBy((_, key) => stepObject.fields.includes(key), stepData);
    // Map step payload using provided mapDataForRequest function
    const mappedStepData = mapDataForRequest ? mapDataForRequest(filteredStepData, stepObject) : filteredStepData;
    validateMappedStepData(mappedStepData, dependenciesPayload);
    return {
        requestPayload: Object.assign(Object.assign({}, dependenciesPayload), mappedStepData),
        allowedResponseFieldNames: Object.keys(mappedStepData),
    };
};
export const sendRequest = (requestPayload, authClient, formContext) => {
    var _a;
    const stepValidationPath = (_a = formContext.stepValidationPath) !== null && _a !== void 0 ? _a : '/registration/validate-upgrade/step';
    return makeOAuthRequest({
        method: 'POST',
        url: `${config.api.registration}${stepValidationPath}`,
        data: requestPayload,
        headers: {
            'Content-Type': 'application/json',
        },
        validateStatus(status) {
            return (status >= 200 && status < 300) || status === 422;
        },
    }, authClient, 'stepValidation');
};
export const trackValidationError = (trackEvent, stepObject, questionnaire) => {
    trackEvent(Action.ReceivedStepValidationError, {
        stepName: stepObject.applicationVariables.stepName,
        businessType: questionnaire.stepData.businessType,
        nativeBusinessType: questionnaire.stepData.nativeBusinessType,
        categoryId: questionnaire.stepData.businessCategoryId,
        subCategoryId: questionnaire.stepData.businessSubCategoryId,
        segment: stepObject.applicationVariables.segment,
    });
};
export const isValidationResponseDataValid = new Ajv().compile({
    type: 'object',
    properties: {
        success: {
            type: 'string',
            enum: ['true', 'false'],
        },
        errors: {
            type: 'object',
        },
    },
    required: ['success'],
    if: {
        properties: {
            success: { const: 'false' },
        },
    },
    then: {
        required: ['errors'],
    },
});
export const validateResponseDataFieldNames = (response, allowedResponseFieldNames) => {
    if (response.success === ValidationResponseSuccessValue.FALSE) {
        const onlyAllowedFieldNames = all((fieldName) => allowedResponseFieldNames.includes(fieldName), Object.keys(response.errors));
        if (!onlyAllowedFieldNames) {
            throw new Error('Errors object contained fields that do not belong to current step');
        }
    }
};
const validateStepErrors = (errors, stepObject) => {
    if (!Object.keys(errors).every((fieldName) => stepObject.fields.includes(fieldName))) {
        throw new Error(`Some fields in errors object are not declared in step's 'fields'`);
    }
};
export const prepareValidationErrors = (data, stepObject, mapErrorsFromResponse) => {
    const responseErrors = data.errors;
    const stepErrors = mapErrorsFromResponse ? mapErrorsFromResponse(responseErrors, stepObject) : responseErrors;
    validateStepErrors(stepErrors, stepObject);
    return stepErrors;
};
