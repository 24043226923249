import React, { useEffect, useRef } from 'react';
import { LUX_NEW_LEGAL_PEOPLE_FIELD_NAME } from 'src/schema/common/owner-structure';
import { GENERAL_ERROR_FIELD_NAME } from '../../constants';
import { useOverviewPageContextValue } from '../Context';
import { ErrorItem } from './components/ErrorItem';
const OVERVIEW_GENERAL_ERROR_FIELD_NAMES = [GENERAL_ERROR_FIELD_NAME, LUX_NEW_LEGAL_PEOPLE_FIELD_NAME];
export const GeneralErrors = () => {
    const { errors } = useOverviewPageContextValue();
    const containerRef = useRef(null);
    // Scroll container into view if any of the fields become visible
    useEffect(() => {
        var _a;
        if (!errors || Object.keys(errors).length === 0) {
            return;
        }
        if (OVERVIEW_GENERAL_ERROR_FIELD_NAMES.some((fieldName) => fieldName in errors)) {
            (_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
        }
    }, [errors]);
    return (React.createElement("div", { ref: containerRef }, OVERVIEW_GENERAL_ERROR_FIELD_NAMES.map((errorFieldName) => (React.createElement(ErrorItem, { key: errorFieldName, errorFieldName: errorFieldName })))));
};
