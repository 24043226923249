import React from 'react';
import { iZettleMarket } from '@izettle/constants';
import { useLocalization } from '@izettle/lingo-client';
import { getCountryUtils } from 'src/utils/country-helpers';
import { BusinessType, statesData } from 'src/utils/country/MX/data';
import { getStateLabel } from 'src/utils/country/MX/data-helpers';
import { AddressValue } from '../../../AddressValue';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
export const BusinessDetailsSection = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const { getLocalizedBusinessTypeValue } = getCountryUtils(iZettleMarket.MX);
    return (React.createElement(Section, { name: "businessDetails", title: text('OVERVIEW_SECTION_BUSINESS_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: ['businessType'], title: text('OVERVIEW_FIELD_BUSINESS_TYPE_TITLE') },
            React.createElement(Value, null, getLocalizedBusinessTypeValue(stepData, text))),
        stepData.businessType !== BusinessType.SelfEmployed && (React.createElement(Field, { fieldNames: ['businessLegalEntityNumber'], title: "RFC" /* CopyMX.OVERVIEW_FIELD_BUSINESS_LEGAL_ENTITY_NUMBER_MX_RFC */, editable: true },
            React.createElement(Value, null, stepData.businessLegalEntityNumber))),
        stepData.businessType !== BusinessType.SelfEmployed && (React.createElement(Field, { fieldNames: ['businessName'], title: text('OVERVIEW_FIELD_BUSINESS_NAME_TITLE'), editable: true },
            React.createElement(Value, null, stepData.businessName))),
        stepData.businessType === BusinessType.SelfEmployed && (React.createElement(Field, { fieldNames: ['tradingName'], title: text('OVERVIEW_FIELD_TRADING_NAME_TITLE'), editable: true },
            React.createElement(Value, null, stepData.tradingName ? stepData.tradingName : '-'))),
        React.createElement(Field, { fieldNames: [
                'businessStreetAddress',
                'businessNeighborhood',
                'businessPostalCode',
                'businessCity',
                'businessState',
            ], title: text('OVERVIEW_FIELD_BUSINESS_ADDRESS_TITLE'), editable: true },
            React.createElement(AddressValue, { addressData: {
                    streetAddress: stepData.businessStreetAddress,
                    neighborhood: stepData.businessNeighborhood,
                    postalCode: stepData.businessPostalCode,
                    city: stepData.businessCity,
                    state: getStateLabel(statesData, stepData.businessState),
                } }))));
};
