export var SIGNATORY_ACTION;
(function (SIGNATORY_ACTION) {
    SIGNATORY_ACTION["SET_NATIONALITY"] = "SET_NATIONALITY";
    SIGNATORY_ACTION["CHANGE_SIGNATORY"] = "CHANGE_SIGNATORY";
})(SIGNATORY_ACTION || (SIGNATORY_ACTION = {}));
export var COLLECT_KEYS;
(function (COLLECT_KEYS) {
    COLLECT_KEYS["BIRTHDATE"] = "collectBirthdate";
    COLLECT_KEYS["ADDRESS"] = "collectAddress";
    COLLECT_KEYS["NATIONALITY"] = "collectNationality";
})(COLLECT_KEYS || (COLLECT_KEYS = {}));
export var GAPS;
(function (GAPS) {
    GAPS["LUX_SIGNATORY_NATIONALITY"] = "LUX_SIGNATORY_NATIONALITY";
    GAPS["LUX_LOA"] = "LUX_LOA";
    GAPS["LUX_UBOS"] = "LUX_UBOS";
    GAPS["LUX_DIRECTORS"] = "LUX_DIRECTORS";
})(GAPS || (GAPS = {}));
