import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { CROSSED_OUT_CERTIFICATION_KEY } from '../../../../constants';
import { getTermsTextKeys } from '../../../../helpers';
import { BackupWithholdingItem } from '../../../BackupWithholdingItem';
import { LanguageSwitchButton } from '../LanguageSwitchButton';
import { isUsaPerson } from './helpers';
import { BodyContainer, HeaderContainer, HeaderText, BackgroundContainer, TaxPayerBody } from './styles';
const TaxpayerCertificationBody = ({ termsTextKeys }) => {
    const { text } = useLocalization();
    const textElements = termsTextKeys.map((termsKey) => {
        if (termsKey === 'OVERVIEW_USA_TAXPAYER_CERTIFICATION_ITEM_2') {
            const isCrossedOut = termsTextKeys.includes(CROSSED_OUT_CERTIFICATION_KEY);
            return React.createElement(BackupWithholdingItem, { key: termsKey, termContent: text(termsKey), isCrossedOut: isCrossedOut });
        }
        if (termsKey === CROSSED_OUT_CERTIFICATION_KEY) {
            return null;
        }
        return React.createElement(TaxPayerBody, { key: termsKey, dangerouslySetInnerHTML: { __html: text(termsKey) } });
    });
    return React.createElement(React.Fragment, null, textElements);
};
export const TaxpayerCertificationContent = ({ languageSwitchText, onLanguageSwitchClick }) => {
    const { text } = useLocalization();
    const { state: { stepData }, } = useQuestionnaireState();
    const termsTextKeys = getTermsTextKeys(stepData);
    return (React.createElement(BackgroundContainer, null,
        React.createElement(HeaderContainer, null,
            React.createElement(HeaderText, { marginBottom: "none", marginTop: "none" }, text(isUsaPerson(stepData)
                ? 'OVERVIEW_USA_TAXPAYER_CERTIFICATION_TITLE'
                : 'OVERVIEW_NON_USA_TAXPAYER_CERTIFICATION_TITLE')),
            languageSwitchText && (React.createElement(LanguageSwitchButton, { onClick: onLanguageSwitchClick }, languageSwitchText))),
        React.createElement(BodyContainer, null,
            React.createElement(TaxpayerCertificationBody, { termsTextKeys: termsTextKeys }))));
};
