import { groupBy } from 'ramda';
import { RoleFieldName } from '../../../schema/common/owner-structure';
import { BusinessType, nativeBusinessTypeInfoList, CompanyRegister, } from './data';
export const nativeBusinessTypesGroupedByBusinessType = groupBy((businessTypeInfo) => businessTypeInfo.businessType, nativeBusinessTypeInfoList);
export const nativeBusinessTypesFromBusinessType = (businessType) => nativeBusinessTypeInfoList
    .filter((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.businessType === businessType)
    .map((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.value);
export const nativeBusinessTypesFromCompanyRegister = (companyRegister) => nativeBusinessTypeInfoList
    .filter((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.companyRegister === companyRegister)
    .map((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.value);
export const isNativeBusinessTypeRegistered = (nativeBusinessType) => {
    const result = nativeBusinessTypeInfoList.find((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.value === nativeBusinessType);
    if (!result) {
        throw new Error(`Not possible to check if the native business type is question is registered. No info object was found with the value ${nativeBusinessType}`);
    }
    return result.companyRegister !== CompanyRegister.NonRegistered;
};
export const getBusinessType = (nativeBusinessType) => {
    const result = nativeBusinessTypeInfoList.find((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.value === nativeBusinessType);
    if (!result) {
        throw new Error(`Not possible to fetch business type with the value ${nativeBusinessType}`);
    }
    return result.businessType;
};
export const getNativeBusinessTypeInfo = (nativeBusinessType) => {
    const result = nativeBusinessTypeInfoList.find((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.value === nativeBusinessType);
    if (!result) {
        throw new Error(`Not possible to fetch native business type info with the value ${nativeBusinessType}`);
    }
    return result;
};
export const getLocalizedBusinessTypeValue = (stepData) => {
    if (!stepData.nativeBusinessType) {
        return '';
    }
    const nativeBusinessTypeInfo = getNativeBusinessTypeInfo(stepData.nativeBusinessType);
    return nativeBusinessTypeInfo ? nativeBusinessTypeInfo.title : '';
};
export const getDistrictCourts = (districtCourtsData) => districtCourtsData.reduce((acc, group) => {
    return [...acc, ...group.districtCourts];
}, []);
export const getDistrictCourtsWithGroupLabel = (districtCourtsData) => districtCourtsData.reduce((acc, group) => {
    return [...acc, ...group.districtCourts.map((city) => (Object.assign(Object.assign({}, city), { groupLabel: group.label })))];
}, []);
export const getDistrictCourtLabel = (districtCourtsData, districtCourtName) => {
    const result = getDistrictCourts(districtCourtsData).find((element) => element.name === districtCourtName);
    if (!result) {
        throw new Error(`No districut court label was found with the given name ${districtCourtName}`);
    }
    return result.label;
};
export const getLimitedPartnershipRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_LIMITED_COMPANY_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: text('OWNER_STRUCTURE_ROLE_VALUE_PARTNER'),
        [RoleFieldName.roleBeneficialOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_BENEFICIAL_OWNER'),
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const getNonProfitRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: null,
        [RoleFieldName.roleBeneficialOwners]: null,
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const createRoleFieldsTitles = (businessType, text) => {
    return {
        roleDirectors: businessType === BusinessType.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL')
            : text('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
        roleAlternativeOwners: businessType === BusinessType.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_LABEL')
            : text('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
        rolePartners: text('OWNER_STRUCTURE_PARTNERS_LABEL'),
        roleBeneficialOwners: text('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
    };
};
