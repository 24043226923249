import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { uniq } from 'ramda';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useLocalization } from '@izettle/lingo-client';
import { IconCirclePlusMedium, typography } from '@izettle/otto';
import { Form } from 'src/components/Form';
import { InlineButtonWithIcon } from 'src/components/InlineButtonWithIcon';
import { usePopupsState } from 'src/components/Popups/state';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { useBackendStepValidation } from 'src/hooks/use-backend-step-validation';
import { buildOwnerListStepObject } from './helpers';
const AddPersonButton = styled(InlineButtonWithIcon) `
  span {
    ${typography.regular}
    color : inherit;
  }
`;
const reducer = (state, action) => {
    switch (action.type) {
        case 'selectPerson':
            return Object.assign(Object.assign({}, state), { selectedIds: uniq([...state.selectedIds, action.payload.personId]) });
        case 'updateSelectedIds':
            return Object.assign(Object.assign({}, state), { selectedIds: action.payload.newValue });
        default:
            throw new Error(`Action type not supported`);
    }
};
const createInitialState = (rolePeopleList) => ({
    // TODO: make TypeScript resolve the role list type automatically
    selectedIds: rolePeopleList !== null && rolePeopleList !== void 0 ? rolePeopleList : [],
});
const getPersonData = (state, personId) => {
    var _a;
    const personData = (_a = state.stepData.legalPersons) === null || _a === void 0 ? void 0 : _a[personId];
    if (!personData) {
        throw new Error('Person not found in StepData');
    }
    return personData;
};
export const OwnerList = ({ stepObject: originalStepObject }) => {
    var _a;
    const { roleFieldName } = originalStepObject.applicationVariables;
    const { text } = useLocalization();
    const { state: { popups }, dispatch: popupsDispatch, } = usePopupsState();
    const { state: questionnaireState, dispatch: questionnaireDispatch } = useQuestionnaireState();
    const [internalState, internalDispatch] = useReducer(reducer, createInitialState(questionnaireState.stepData[roleFieldName]));
    const { selectedIds } = internalState;
    const handleAddPersonButtonClick = useCallback(() => {
        const personId = uuidv4();
        popupsDispatch({
            type: 'displayPopup',
            payload: {
                popupName: 'AddEditPerson',
                params: {
                    questionnaireState,
                    mode: 'add',
                    personStepObject: originalStepObject.personStep,
                    onSave: (personData) => {
                        questionnaireDispatch({ type: 'savePerson', payload: { personId, data: personData } });
                        internalDispatch({
                            type: 'selectPerson',
                            payload: {
                                personId,
                            },
                        });
                    },
                },
            },
        });
    }, [popupsDispatch, questionnaireDispatch, questionnaireState]);
    const handleFormDataChange = useCallback((formData) => {
        var _a;
        internalDispatch({
            type: 'updateSelectedIds',
            payload: {
                newValue: (_a = formData[roleFieldName]) !== null && _a !== void 0 ? _a : [],
            },
        });
    }, [internalDispatch]);
    const handleEvent = useCallback((event) => {
        if (event.type === 'checkBoxEditClick') {
            const personId = event.checkBoxValue;
            return popupsDispatch({
                type: 'displayPopup',
                payload: {
                    popupName: 'AddEditPerson',
                    params: {
                        mode: 'edit',
                        questionnaireState,
                        personStepObject: originalStepObject.personStep,
                        personData: getPersonData(questionnaireState, personId),
                        onSave: (updatedPersonData) => questionnaireDispatch({ type: 'savePerson', payload: { personId, data: updatedPersonData } }),
                    },
                },
            });
        }
        if (event.type === 'checkBoxRemoveClick') {
            const personId = event.checkBoxValue;
            return questionnaireDispatch({ type: 'removePerson', payload: { personId } });
        }
    }, [popupsDispatch, questionnaireDispatch, questionnaireState]);
    const handleComplete = useCallback((payload) => {
        questionnaireDispatch({ type: 'completeCurrentStep', payload });
    }, [questionnaireDispatch]);
    const currentValue = useMemo(() => ({ [roleFieldName]: selectedIds }), [selectedIds, roleFieldName]);
    const ownerListStepObject = useMemo(() => buildOwnerListStepObject(originalStepObject, questionnaireState.stepData, currentValue, text), [originalStepObject, questionnaireState.stepData, currentValue, text]);
    const backendValidation = useBackendStepValidation(ownerListStepObject);
    useEffect(() => {
        backendValidation.reset();
    }, [popups.length]);
    return (React.createElement(Form, { JSONSchema: ownerListStepObject.JSONSchema, UISchema: ownerListStepObject.UISchema, extraTexts: ownerListStepObject.applicationVariables.content, helpTexts: (_a = ownerListStepObject.applicationVariables.content) === null || _a === void 0 ? void 0 : _a.helpTexts, onFormDataChange: handleFormDataChange, formData: currentValue, onComplete: handleComplete, onEvent: handleEvent, backendValidation: backendValidation, innerContent: React.createElement(AddPersonButton, { id: "addPersonBtn", variant: "inline", size: "regular", svgIcon: IconCirclePlusMedium, onClick: handleAddPersonButtonClick }, text('OWNER_STRUCTURE_ADD_PERSON_BUTTON')) }));
};
