import { createContext } from 'react';
const noop = () => {
    // noop
};
export const OverviewPageContext = createContext({
    stepData: {},
    errors: {},
    stepDataPath: '',
    updateFieldOptions: noop,
    triggerTechnicalError: noop,
    isSubmissionInProgress: false,
});
