import React from 'react';
import { useQuestionnaireState } from '../Questionnaire/state';
import { InfoBoxScreen } from './components/InfoBoxScreen';
import { OverviewPage } from './components/OverviewPage';
import { OwnerList } from './components/OwnerList';
import { PreparationScreen } from './components/PreparationScreen';
import { SectionIntro } from './components/SectionIntro';
const componentOf = {
    PreparationScreen,
    InfoBoxScreen,
    SectionIntro,
    OwnerList,
    OverviewPage,
};
export const CustomStep = ({ stepObject }) => {
    const { state, dispatch } = useQuestionnaireState();
    const Component = componentOf[stepObject.componentName];
    const handleNextStep = () => {
        const payload = Object.assign(Object.assign({}, state.stepData), { [stepObject.ID]: true });
        dispatch({ type: 'completeCurrentStep', payload });
    };
    return React.createElement(Component, { stepObject: stepObject, nextStep: handleNextStep });
};
