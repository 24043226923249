import React, { useCallback, useContext } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { ComboBox } from '../../../ComboBox';
import { FieldContext } from '../FieldTemplate/context';
import { useEnumOptions } from '../hooks/use-enum-options';
export const CustomComboBox = ({ id, value, options, onChange, placeholder }) => {
    const { text } = useLocalization();
    const { hasError, errorElementId, descriptionElementId } = useContext(FieldContext);
    const handleOnChange = useCallback((newValue) => {
        // Optional enum-based field values have to be undefined when no options are selected.
        // Otherwise the client validation fails, as all values are expected to be explicitly defined in the the enum property.
        // More details at: https://github.com/iZettle/kyc-client/pull/785
        const finalValue = newValue === '' ? undefined : newValue;
        // Avoid calling onChange unnecessarily when value does not change
        if (finalValue !== value) {
            onChange(finalValue);
        }
    }, [value]);
    const enumOptions = useEnumOptions(options);
    return (React.createElement(ComboBox, { id: id, options: enumOptions, onChange: handleOnChange, placeholder: placeholder, label: options.label ? options.label.toString() : undefined, value: value, expandButtonTitle: text('COMBOBOX_EXPAND_HINT'), clearButtonTitle: text('COMBOBOX_CLEAR_HINT'), hasError: hasError, errorElementId: errorElementId, descriptionElementId: descriptionElementId }));
};
