export var BusinessType;
(function (BusinessType) {
    BusinessType["SelfEmployed"] = "SelfEmployed";
    BusinessType["LimitedCompany"] = "LimitedCompany";
    BusinessType["Partnership"] = "Partnership";
    BusinessType["NonProfit"] = "NonProfit";
})(BusinessType || (BusinessType = {}));
export var NativeBusinessType;
(function (NativeBusinessType) {
    NativeBusinessType["AutonomoEmpresarioIndividual"] = "AutonomoEmpresarioIndividual";
    NativeBusinessType["SociedadLimitada"] = "SociedadLimitada";
    NativeBusinessType["SociedadAnonima"] = "SociedadAnonima";
    NativeBusinessType["SociedadCooperativa"] = "SociedadCooperativa";
    NativeBusinessType["AsociacionSinAnimoDeLucro"] = "AsociacionSinAnimoDeLucro";
    NativeBusinessType["AgenciaEstatal"] = "AgenciaEstatal";
    NativeBusinessType["ComunidadDeBienes"] = "ComunidadDeBienes";
    NativeBusinessType["SociedadCivilPrivada"] = "SociedadCivilPrivada";
    NativeBusinessType["SociedadColectiva"] = "SociedadColectiva";
    NativeBusinessType["SociedadComanditaria"] = "SociedadComanditaria";
})(NativeBusinessType || (NativeBusinessType = {}));
export const nativeBusinessTypeInfoList = [
    {
        value: NativeBusinessType.AutonomoEmpresarioIndividual,
        businessType: BusinessType.SelfEmployed,
        title: "Aut\u00F3nomo / Empresario individual" /* CopyES.BUSINESS_TYPE_AUTONOMO_EMPRESARIO_INDIVIDUAL_TITLE */,
    },
    {
        value: NativeBusinessType.SociedadLimitada,
        businessType: BusinessType.LimitedCompany,
        title: "Sociedad limitada (SL)" /* CopyES.BUSINESS_TYPE_SOCIEDAD_LIMITADA_TITLE */,
    },
    {
        value: NativeBusinessType.SociedadAnonima,
        businessType: BusinessType.LimitedCompany,
        title: "Sociedad an\u00F3nima (SA)" /* CopyES.BUSINESS_TYPE_SOCIEDAD_ANONIMA_TITLE */,
    },
    {
        value: NativeBusinessType.SociedadCooperativa,
        businessType: BusinessType.NonProfit,
        title: "Sociedad cooperativa" /* CopyES.BUSINESS_TYPE_SOCIEDAD_COOPERATIVA_TITLE */,
    },
    {
        value: NativeBusinessType.AsociacionSinAnimoDeLucro,
        businessType: BusinessType.NonProfit,
        title: "Asociaci\u00F3n sin \u00E1nimo de lucro" /* CopyES.BUSINESS_TYPE_ASOCIACION_SIN_ANIMO_DE_LUCRO_TITLE */,
    },
    {
        value: NativeBusinessType.AgenciaEstatal,
        businessType: BusinessType.NonProfit,
        title: "Agencia estatal" /* CopyES.BUSINESS_TYPE_AGENCIA_ESTATAL_TITLE */,
    },
    {
        value: NativeBusinessType.ComunidadDeBienes,
        businessType: BusinessType.Partnership,
        title: "Comunidad de bienes" /* CopyES.BUSINESS_TYPE_COMUNIDAD_DE_BIENES_TITLE */,
    },
    {
        value: NativeBusinessType.SociedadCivilPrivada,
        businessType: BusinessType.Partnership,
        title: "Sociedad civil privada" /* CopyES.BUSINESS_TYPE_SOCIEDAD_CIVIL_PRIVADA_TITLE */,
    },
    {
        value: NativeBusinessType.SociedadColectiva,
        businessType: BusinessType.Partnership,
        title: "Sociedad colectiva" /* CopyES.BUSINESS_TYPE_SOCIEDAD_COLECTIVA_TITLE */,
    },
    {
        value: NativeBusinessType.SociedadComanditaria,
        businessType: BusinessType.Partnership,
        title: "Sociedad comanditaria" /* CopyES.BUSINESS_TYPE_SOCIEDAD_COMANDITARIA_TITLE */,
    },
];
