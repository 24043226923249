import React, { useEffect, useState } from 'react';
import { Environment, config } from 'src/config';
import { initializeUjet, loadUjetScript } from './helpers';
import { UjetLauncherStyle } from './launcher-style';
import { updateUJETIcons } from './loading-icon-overlay';
const excludedLocales = [];
export const Ujet = (props) => {
    const { scriptUrl, onError } = props;
    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
        // eslint-disable-next-line sonarjs/no-empty-collection
        if (excludedLocales.includes(props.locale)) {
            return;
        }
        loadUjetScript(scriptUrl)
            .then(() => setScriptLoaded(true))
            .catch(onError);
    }, [scriptUrl, props.locale]);
    const destroyExistingUjetInstance = () => {
        var _a, _b;
        (_b = (_a = window.ujetInstance) === null || _a === void 0 ? void 0 : _a.destroy) === null || _b === void 0 ? void 0 : _b.call(_a);
        delete window.ujetInstance;
    };
    useEffect(() => {
        if (!scriptLoaded) {
            return;
        }
        try {
            destroyExistingUjetInstance();
            window.ujetInstance = initializeUjet(props);
            if (config.appEnv === Environment.Production) {
                updateUJETIcons(window.ujetInstance, true);
            }
        }
        catch (error) {
            onError(error);
        }
        return destroyExistingUjetInstance;
    }, [scriptLoaded, ...Object.values(props)]);
    return (React.createElement(React.Fragment, null,
        React.createElement(UjetLauncherStyle, null)));
};
