import React, { useMemo } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { BusinessType } from 'src/utils/country/MX/data';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
export const PersonalDetailsSection = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const isSelfEmployed = useMemo(() => stepData.businessType === BusinessType.SelfEmployed, [stepData.businessType]);
    return (React.createElement(Section, { name: "personalDetails", title: text('OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: ['signatoryFirstName', 'signatoryLastName', 'signatoryMaidenName'], title: text('OVERVIEW_FIELD_SIGNATORY_NAME_TITLE'), editable: true },
            React.createElement(Value, null, `${stepData.signatoryFirstName} ${stepData.signatoryLastName} ${stepData.signatoryMaidenName && ` ${stepData.signatoryMaidenName}`}`)),
        React.createElement(Field, { fieldNames: isSelfEmployed ? ['businessLegalEntityNumber'] : ['signatoryLegalEntityNumber'], title: text('CURP_MX_LABEL'), editable: true, conditionalRendering: true },
            React.createElement(Value, null, isSelfEmployed ? stepData.businessLegalEntityNumber : stepData.signatoryLegalEntityNumber)),
        React.createElement(Field, { fieldNames: ['signatoryPhoneNumber'], title: text('OVERVIEW_FIELD_SIGNATORY_PHONE_NUMBER_TITLE'), editable: true },
            React.createElement(Value, null, stepData.signatoryPhoneNumber))));
};
