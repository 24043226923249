var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';
export const makeTracedRequest = (axiosConfig, transactionContext) => __awaiter(void 0, void 0, void 0, function* () {
    datadogRum.addAction('[Traced request] Start', { context: transactionContext });
    return axios(axiosConfig)
        .then((response) => {
        datadogRum.addAction('[Traced request] Response', { status: response.status });
        return response;
    })
        .catch((error) => {
        var _a, _b;
        if (axios.isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status)) {
            datadogRum.addAction('[Traced request] Error', { status: (_b = error.response) === null || _b === void 0 ? void 0 : _b.status });
        }
        else {
            datadogRum.addAction('[Traced request] Unknown error');
        }
        throw error;
    })
        .finally(() => {
        datadogRum.addAction('[Traced request] End', { context: transactionContext });
    });
});
