import React, { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useLocalization } from '@izettle/lingo-client';
import { redirectToBackOffice } from 'src/helpers';
import { useThemeInfo } from 'src/hooks/use-theme-info';
import { useFormContextValue, FormStatus, } from 'src/providers/form';
import { Environment, config } from '../../config';
import { PPUI_THEME_CLASSNAME } from '../../theme';
import { closeWebview, openDocifyOnNativeApps } from '../../util';
import { ConfirmationPage } from '../ConfirmationPage';
import { EligibleContent } from './components/EligibleContent';
import { useAppSideEffects } from './hooks';
const IneligibleContent = ({ formContext }) => {
    const { text } = useLocalization();
    const closeOrRedirect = useCallback(() => {
        closeWebview(formContext.user.emailAddress);
        redirectToBackOffice();
    }, [formContext]);
    return (React.createElement(ConfirmationPage, { title: text('NOT_ELIGIBLE_FOR_ANY_FORMS_TITLE'), image: "loyalCustomer", subtitle: text('NOT_ELIGIBLE_FOR_ANY_FORMS_SUB_TITLE'), button: text('NOT_ELIGIBLE_FOR_ANY_FORMS_BUTTON'), onConfirm: closeOrRedirect }));
};
const ProcessingContent = ({ formContext }) => {
    const { text } = useLocalization();
    const closeOrRedirect = useCallback(() => {
        closeWebview(formContext.user.emailAddress);
        redirectToBackOffice();
    }, [formContext]);
    return (React.createElement(ConfirmationPage, { title: text('PROCESSING_PAGE_TITLE'), image: "stopWatch", subtitle: text('PROCESSING_PAGE_SUBTITLE'), button: text('PROCESSING_PAGE_CLOSE_BUTTON'), onConfirm: closeOrRedirect }));
};
const DocumentUploadContent = ({ formContext }) => {
    useEffect(() => {
        openDocifyOnNativeApps(formContext.user.emailAddress);
        window.location.replace(`${config.portalUrl}/seller-verification`);
    }, []);
    return null;
};
export const AppContent = () => {
    const { formContext } = useFormContextValue();
    const { haveAllEffectsRun } = useAppSideEffects();
    const [enabled] = useLocalStorage('ppui-theme', document.body.classList.contains(PPUI_THEME_CLASSNAME) ? 'true' : 'false') || '';
    const { paypalTheme, enablePaypalTheme } = useThemeInfo();
    // Updating favicon based on theme
    useEffect(() => {
        const faviconIcon = document.querySelector("link[rel~='icon']");
        const faviconShortIcon = document.getElementById('favicon');
        faviconIcon.href = paypalTheme ? '/favicon/ujet-paypal-logo-blue_transparent.ico' : '/favicon/favicon.ico';
        faviconShortIcon.href = paypalTheme ? '/favicon/ujet-paypal-logo-blue_transparent.ico' : '/favicon/favicon.ico';
    }, [paypalTheme]);
    // Enabling theme while loading the page
    useEffect(() => {
        if (enabled === 'true') {
            document.body.classList.add(PPUI_THEME_CLASSNAME);
        }
        else {
            document.body.classList.remove(PPUI_THEME_CLASSNAME);
        }
    }, [enabled]);
    // Enabling paypal theme for production
    useEffect(() => {
        if (config.appEnv === Environment.Production) {
            enablePaypalTheme();
        }
    }, []);
    // Allow critical side effects to be performed at least once before rendering content.
    // Ensures that all tracking and monitoring systems are properly initialized.
    if (!haveAllEffectsRun) {
        return null;
    }
    switch (formContext.status) {
        case FormStatus.DOCUMENT_UPLOAD:
            return React.createElement(DocumentUploadContent, { formContext: formContext });
        case FormStatus.ONBOARDING:
        case FormStatus.GAPS:
            return React.createElement(EligibleContent, { formContext: formContext });
        case FormStatus.PROCESSING:
            return React.createElement(ProcessingContent, { formContext: formContext });
        case FormStatus.INELIGIBLE:
            return React.createElement(IneligibleContent, { formContext: formContext });
        default:
            throw new Error(`Unsupported formStatus: "${formContext.status}".`);
    }
};
