export var FunctionStepFuncKey;
(function (FunctionStepFuncKey) {
    FunctionStepFuncKey["initLegalPersons"] = "initLegalPersons";
    FunctionStepFuncKey["FR_businessType"] = "FR_businessType";
    FunctionStepFuncKey["NO_businessType"] = "NO_businessType";
    FunctionStepFuncKey["FI_businessType"] = "FI_businessType";
    FunctionStepFuncKey["DE_businessType"] = "DE_businessType";
    FunctionStepFuncKey["DK_businessType"] = "DK_businessType";
    FunctionStepFuncKey["ES_businessType"] = "ES_businessType";
    FunctionStepFuncKey["IT_businessType"] = "IT_businessType";
    FunctionStepFuncKey["MX_businessType"] = "MX_businessType";
    FunctionStepFuncKey["GAP_newLegalPeopleDefaultValue"] = "GAP_newLegalPeopleDefaultValue";
    FunctionStepFuncKey["GAP_omitLegalPeopleProps"] = "GAP_omitLegalPeopleProps";
    FunctionStepFuncKey["GAP_readonlyLegalPeopleProps"] = "GAP_readonlyLegalPeopleProps";
    FunctionStepFuncKey["GAP_normalizeSignatoryFields"] = "GAP_normalizeSignatoryFields";
})(FunctionStepFuncKey || (FunctionStepFuncKey = {}));
export const isFormStepObject = (stepObject) => {
    return stepObject.JSONSchema !== undefined;
};
export const isFunctionStepObject = (stepObject) => {
    return stepObject.functions !== undefined;
};
export const isCustomStepObject = (stepObject) => {
    return stepObject.componentName !== undefined;
};
export var SupportedImages;
(function (SupportedImages) {
    SupportedImages["highFive"] = "highFive";
    SupportedImages["fistBump"] = "fistBump";
    SupportedImages["loyalCustomer"] = "loyalCustomer";
    SupportedImages["stopWatch"] = "stopWatch";
})(SupportedImages || (SupportedImages = {}));
