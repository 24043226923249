import { getPersonRoles } from 'src/components/Questionnaire/state/helpers';
import { ROLE_FIELD_NAMES, SIGNATORY_PERSON_ID } from 'src/schema/common/owner-structure';
/**
 * Returns the list of personIds belonging to a role in the order as the persons were added
 */
const getPersonIdsByRoleOrdered = (roleIds, legalPersons) => {
    const result = [];
    if (roleIds.includes(SIGNATORY_PERSON_ID)) {
        result.push(SIGNATORY_PERSON_ID);
    }
    Object.keys(legalPersons).forEach((personId) => {
        if (roleIds.includes(personId)) {
            result.push(personId);
        }
    });
    return result;
};
export const getPeopleByRole = (roleFieldName, stepData) => {
    const { signatoryFirstName, signatoryLastName, signatoryMaidenName, legalPersons } = stepData;
    if (!legalPersons) {
        throw new Error('Legal persons cannot be undefined');
    }
    return getPersonIdsByRoleOrdered(stepData[roleFieldName], legalPersons).map((personId) => {
        if (personId === SIGNATORY_PERSON_ID) {
            return {
                id: SIGNATORY_PERSON_ID,
                firstName: signatoryFirstName,
                lastName: signatoryLastName,
                maidenName: signatoryMaidenName,
            };
        }
        return {
            id: personId,
            firstName: legalPersons[personId].firstName,
            lastName: legalPersons[personId].lastName,
            maidenName: legalPersons[personId].maidenName,
        };
    });
};
export const getPeopleWithNoRoles = (stepData) => {
    const { legalPersons } = stepData;
    if (!legalPersons) {
        throw new Error('Legal persons cannot be undefined');
    }
    return Object.keys(legalPersons)
        .filter((personId) => {
        const roles = getPersonRoles(personId, stepData, ROLE_FIELD_NAMES);
        return roles.length === 0;
    })
        .map((personId) => {
        return {
            id: personId,
            firstName: legalPersons[personId].firstName,
            lastName: legalPersons[personId].lastName,
            maidenName: legalPersons[personId].maidenName,
        };
    });
};
