import { iZettleMarket } from '@izettle/constants';
import { isKyc2Country } from 'src/providers/form/helpers';
import * as CountryUtils from './country/index';
const countryUtilsMap = {
    [iZettleMarket.DE]: CountryUtils.DE,
    [iZettleMarket.DK]: CountryUtils.DK,
    [iZettleMarket.ES]: CountryUtils.ES,
    [iZettleMarket.FI]: CountryUtils.FI,
    [iZettleMarket.FR]: CountryUtils.FR,
    [iZettleMarket.GB]: CountryUtils.GB,
    [iZettleMarket.IT]: CountryUtils.IT,
    [iZettleMarket.MX]: CountryUtils.MX,
    [iZettleMarket.NL]: CountryUtils.NL,
    [iZettleMarket.NO]: CountryUtils.NO,
    [iZettleMarket.SE]: CountryUtils.SE,
};
export const getCountryUtils = (countryId) => {
    if (!isKyc2Country(countryId)) {
        throw new Error(`No market-specific dependencies defined for ${countryId}`);
    }
    return countryUtilsMap[countryId];
};
