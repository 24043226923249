import React from 'react';
import { iZettleMarket } from '@izettle/constants';
import { useEligibleFormContextValue } from 'src/providers/form';
import { isKyc2Country } from 'src/providers/form/helpers';
import { Value } from '../Value';
const renderDefaultEuropeanAddress = ({ streetAddress, postalCode, city }) => (React.createElement(React.Fragment, null,
    React.createElement(Value, null, streetAddress),
    React.createElement(Value, null, `${postalCode} ${city}`)));
const renderAddressWithCo = ({ addressCo, streetAddress, postalCode, city }) => (React.createElement(React.Fragment, null,
    addressCo && React.createElement(Value, null, addressCo),
    React.createElement(Value, null, streetAddress),
    React.createElement(Value, null, `${postalCode} ${city}`)));
const renderAddressFR = ({ streetAddress, streetAddressLine2, city, postalCode }) => (React.createElement(React.Fragment, null,
    React.createElement(Value, null, `${streetAddress}`),
    streetAddressLine2 ? React.createElement(Value, null, streetAddressLine2) : null,
    React.createElement(Value, null, `${postalCode === null || postalCode === void 0 ? void 0 : postalCode.toString().toUpperCase()} ${city}`)));
const renderAddressMX = ({ streetAddress, neighborhood, postalCode, city, state }) => (React.createElement(React.Fragment, null,
    React.createElement(Value, null, streetAddress),
    React.createElement(Value, null, neighborhood),
    React.createElement(Value, null, `${postalCode} ${city}, ${state}`)));
const renderAddressNL = ({ streetName, streetNumber, streetAddressLine2, city, postalCode }) => {
    const complementText = streetAddressLine2 ? `, ${streetAddressLine2}` : '';
    return (React.createElement(React.Fragment, null,
        React.createElement(Value, null, `${streetName} ${streetNumber}${complementText}`),
        React.createElement(Value, null, `${postalCode === null || postalCode === void 0 ? void 0 : postalCode.toString().toUpperCase()} ${city}`)));
};
const renderAddressGB = ({ building, streetAddress, city, county, postalCode }) => (React.createElement(React.Fragment, null,
    React.createElement(Value, null, `${building} ${streetAddress}`),
    React.createElement(Value, null, city),
    React.createElement(Value, null, county),
    postalCode && React.createElement(Value, null, postalCode.toString().toUpperCase())));
const countryAddressRenderers = {
    [iZettleMarket.DE]: renderDefaultEuropeanAddress,
    [iZettleMarket.DK]: renderDefaultEuropeanAddress,
    [iZettleMarket.ES]: renderDefaultEuropeanAddress,
    [iZettleMarket.FI]: renderAddressWithCo,
    [iZettleMarket.FR]: renderAddressFR,
    [iZettleMarket.GB]: renderAddressGB,
    [iZettleMarket.IT]: renderDefaultEuropeanAddress,
    [iZettleMarket.MX]: renderAddressMX,
    [iZettleMarket.NL]: renderAddressNL,
    [iZettleMarket.NO]: renderAddressWithCo,
    [iZettleMarket.SE]: renderDefaultEuropeanAddress,
};
export const AddressValue = ({ addressData }) => {
    const { formContext: { user: { countryId }, }, } = useEligibleFormContextValue();
    if (!isKyc2Country(countryId)) {
        throw new Error(`No address definitions are expected for unreleased markets such as '${countryId}'`);
    }
    const countryAddressRenderer = countryAddressRenderers[countryId];
    return React.createElement(React.Fragment, null, countryAddressRenderer(addressData));
};
