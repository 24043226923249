import { buildSelfEmployedUsaTaxpayerSequence } from 'src/schema/common/usaTaxpayerDetails/schema';
import { BusinessType } from 'src/utils/country/GB/data';
import { buildCategorySteps } from '../../common/categories';
import { sequence, decision, step, render, conditional, customStepBuilder } from '../../steps-builder';
import * as stepTemplates from './flow-step-templates';
export const ownerStructure = [
    conditional('businessType', [
        {
            when: [BusinessType.LimitedCompany],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.limitedPartnershipOwnerStructureIntro),
                step(stepTemplates.limitedDirectorsList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.beneficialOwners) },
                    { when: false, branch: step(stepTemplates.limitedPartnershipAlternativeOwnersList) },
                ]),
            ]),
        },
        {
            when: [BusinessType.Partnership],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.limitedPartnershipOwnerStructureIntro),
                step(stepTemplates.partnersList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.beneficialOwners) },
                    { when: false, branch: step(stepTemplates.limitedPartnershipAlternativeOwnersList) },
                ]),
            ]),
        },
        {
            when: [BusinessType.NonProfit],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.nonProfitOwnerStructureIntro),
                step(stepTemplates.nonProfitDirectorsList),
                step(stepTemplates.nonProfitAlternativeOwnersList),
            ]),
        },
    ]),
];
const sharedPersonalDetailsQuestions = [
    step(stepTemplates.personalDetailsQuestionsIntro),
    step(stepTemplates.signatoryName),
    step(stepTemplates.signatoryDOB),
    step(stepTemplates.signatoryNationality),
    customStepBuilder(buildSelfEmployedUsaTaxpayerSequence('businessType', 'SelfEmployed')),
    decision('isSignatoryShareAddress', stepTemplates.isSignatoryShareAddress, [
        { when: false, branch: step(stepTemplates.signatoryAddress) },
    ]),
    step(stepTemplates.signatoryPhoneNumber),
];
export const sharedFSAQuestions = [
    customStepBuilder(buildCategorySteps),
    step(stepTemplates.businessLocationsTypes),
    step(stepTemplates.businessFrequency),
    step(stepTemplates.businessAverageTransaction),
    step(stepTemplates.businessIzettleShareOfTurnover),
    decision('businessCurrentAnnualTurnover', stepTemplates.businessCurrentAnnualTurnover, [
        { when: 'NEW_BUSINESS', branch: step(stepTemplates.businessExpectedAnnualTurnover) },
    ]),
];
const partnership = [
    decision('isRegisteredAtCompaniesHouse', stepTemplates.isPartnershipRegistered, [
        {
            when: true,
            branch: sequence([
                step(stepTemplates.registeredPartnershipPrepScreen),
                step(stepTemplates.limitedAndPartnershipBusinessName),
                step(stepTemplates.limitedBusinessEntityNumber),
            ]),
        },
        {
            when: false,
            branch: sequence([
                step(stepTemplates.unregisteredPartnershipPrepScreen),
                step(stepTemplates.limitedAndPartnershipBusinessName),
            ]),
        },
    ]),
    step(stepTemplates.limitedAndPartnershipAddress),
    sequence(sharedPersonalDetailsQuestions),
    sequence(ownerStructure),
    step(stepTemplates.partnershipFSAIntro),
    sequence(sharedFSAQuestions),
];
const nonProfit = [
    decision('isRegisteredAtCompaniesHouse', stepTemplates.isNonProfitRegistered, [
        {
            when: true,
            branch: sequence([
                step(stepTemplates.registeredNonProfitPrepScreen),
                step(stepTemplates.nonProfitBusinessName),
                step(stepTemplates.limitedBusinessEntityNumber),
            ]),
        },
        {
            when: false,
            branch: sequence([
                step(stepTemplates.unregisteredNonProfitPrepScreen),
                step(stepTemplates.nonProfitBusinessName),
                step(stepTemplates.unregisteredNonProfitBusinessEntityNumber),
            ]),
        },
    ]),
    step(stepTemplates.nonProfitAddress),
    sequence(sharedPersonalDetailsQuestions),
    sequence(ownerStructure),
    step(stepTemplates.nonProfitFSAIntro),
    sequence(sharedFSAQuestions),
];
const selfEmployed = [
    step(stepTemplates.selfEmployedPrepScreen),
    step(stepTemplates.selfEmployedBusinessName),
    step(stepTemplates.selfEmployedAddress),
    sequence(sharedPersonalDetailsQuestions),
    step(stepTemplates.selfEmployedFSAQuestionsIntro),
    sequence(sharedFSAQuestions),
];
const limited = [
    step(stepTemplates.limitedPrepScreen),
    step(stepTemplates.limitedAndPartnershipBusinessName),
    step(stepTemplates.limitedBusinessEntityNumber),
    step(stepTemplates.limitedAndPartnershipAddress),
    sequence(sharedPersonalDetailsQuestions),
    sequence(ownerStructure),
    step(stepTemplates.limitedFSAIntro),
    sequence(sharedFSAQuestions),
];
const root = sequence([
    decision('businessType', stepTemplates.businessType, [
        { when: 'SelfEmployed', branch: sequence(selfEmployed) },
        { when: 'LimitedCompany', branch: sequence(limited) },
        { when: 'Partnership', branch: sequence(partnership) },
        { when: 'NonProfit', branch: sequence(nonProfit) },
    ]),
    step(stepTemplates.overviewPage),
]);
export const GB = (context) => render(root, context);
