import React from 'react';
import styled from 'styled-components';
import { spacing, typography } from '@izettle/otto';
import { InlineButtonWithIcon } from 'src/components/InlineButtonWithIcon';
import { useOverviewPageContextValue } from '../Context';
const StyledButton = styled(InlineButtonWithIcon) `
  margin-left: -${spacing.xSmall}; // reduces the inset but still leaves some to provide visual hierarchy

  &:not(:last-child) {
    margin-bottom: ${spacing.xxSmall};
  }
  span {
    ${typography.regular}
    color : inherit;
  }
`;
export const FieldInlineButton = ({ svgIcon, onClick, children }) => {
    const { isSubmissionInProgress } = useOverviewPageContextValue();
    return (React.createElement(StyledButton, { size: "small", variant: "inline", svgIcon: svgIcon, onClick: onClick, disabled: isSubmissionInProgress }, children));
};
