var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import createAuthorizationClient from '@izettle/authorization-sdk-js';
import { config } from 'src/config';
import { updateBrowserUrl } from 'src/helpers';
import { createAppState, handleAppStateAfterRedirect } from './app-state';
import { handleAndRethrowAuthError } from './helpers';
const handleRedirectFromLoginPage = (client) => __awaiter(void 0, void 0, void 0, function* () {
    const isRedirect = new URLSearchParams(window.location.search).get('code');
    if (!isRedirect) {
        return false;
    }
    return client
        .handleRedirectCallback()
        .then(({ appState }) => {
        handleAppStateAfterRedirect(appState);
        return true;
    })
        .catch((err) => {
        // happens when login page session has expired
        if (err.message === 'Invalid state') {
            return false;
        }
        return handleAndRethrowAuthError('handleRedirectCallback')(err);
    })
        .finally(() => updateBrowserUrl(window.location.pathname));
});
export const startAuthFlow = () => __awaiter(void 0, void 0, void 0, function* () {
    const client = yield createAuthorizationClient({
        domain: config.oauth.domain,
        client_id: config.oauth.clientId,
        redirect_uri: config.applicationUrl,
        scope: 'ALL:INTERNAL',
    }).catch(handleAndRethrowAuthError('createAuthorizationClient'));
    if (yield handleRedirectFromLoginPage(client)) {
        return client;
    }
    if (yield client.isAuthenticated()) {
        return client;
    }
    else {
        yield client.loginWithRedirect({
            appState: createAppState(),
        });
        return null;
    }
});
