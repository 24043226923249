import React, { forwardRef } from 'react';
import RjsfForm from '@rjsf/core';
import { ArrayFieldTemplate } from './components/ArrayFieldTemplate';
import { CustomCheckboxGroup } from './components/CustomCheckboxGroup';
import { CustomComboBox } from './components/CustomComboBox';
import { CustomRadioGroup } from './components/CustomRadioGroup';
import { CustomSelect } from './components/CustomSelect';
import { DescriptionField } from './components/DescriptionField';
import { FieldTemplate } from './components/FieldTemplate';
import { ObjectFieldTemplate } from './components/ObjectFieldTemplate';
import { TextWidget } from './components/TextWidget';
import { TitleField } from './components/TitleField';
const formFields = {
    DescriptionField,
    TitleField,
};
const formWidgets = {
    TextWidget,
    CustomRadioGroupWidget: CustomRadioGroup,
    CustomCheckboxGroupWidget: CustomCheckboxGroup,
    CustomComboBoxWidget: CustomComboBox,
    CustomSelectWidget: CustomSelect,
};
/**
 * This React.memo prevents unnecessary rerenders of rjsf.Form component,
 * when it's props do not change.
 * rjsf.Form doesn't handle rerenders well and is prone to race-conditions
 * which end up in corrupted state and weird side-effects.
 * Related issues/PRs:
 * https://github.com/rjsf-team/react-jsonschema-form/pull/1634
 * https://github.com/rjsf-team/react-jsonschema-form/pull/1990
 */
export const RjsfFormWrapper = React.memo(forwardRef((props, ref) => {
    return (React.createElement(RjsfForm, Object.assign({ fields: formFields, widgets: formWidgets, ArrayFieldTemplate: ArrayFieldTemplate, FieldTemplate: FieldTemplate, ObjectFieldTemplate: ObjectFieldTemplate }, props, { ref: ref }), props.children));
}));
