export const generateDigitSequence = (numberOfDigits) => {
    const baseNumber = Math.pow(10, (numberOfDigits - 1));
    const complement = baseNumber * 9;
    return Math.floor(baseNumber + Math.random() * complement);
};
export const generateIntFromInterval = (min, max, minLength) => {
    const value = Math.floor(Math.random() * (max - min + 1) + min).toString();
    return value.padStart(minLength, '0');
};
/**
 * Returns a random letter of a given list
 * {string} @param [allowedLetters=abcdefghijklmnopqrstuvwxyz] - letters that should be considered when generating random letter
 */
export const generateRandomLetter = (allowedLetters = 'abcdefghijklmnopqrstuvwxyz') => {
    return allowedLetters[Math.floor(Math.random() * allowedLetters.length)];
};
export const generateRandomDateOfBirth = ({ separator }) => [
    generateIntFromInterval(1, 28, 2),
    generateIntFromInterval(1, 12, 2),
    generateIntFromInterval(1930, 2000, 4), // year
].join(separator);
export const addLuhnCheckDigitToNumber = (baseNumber) => {
    const digits = baseNumber.toString().split('').map(Number);
    let sum = 0;
    digits.reverse().forEach((digit, index) => {
        const multiplier = index % 2 === 0 ? 2 : 1;
        const product = digit * multiplier;
        sum += product > 9 ? product - 9 : product;
    });
    const checkDigit = (10 - (sum % 10)) % 10;
    return Number(`${baseNumber}${checkDigit}`);
};
/**
 * Returns the sum of all digits of a given number
 * {number} @param number
 */
export const sumNumberDigits = (number) => number
    .toString()
    .split('')
    .map(Number)
    .reduce((a, b) => {
    return a + b;
}, 0);
