import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { errorContentMap } from './content';
export const useCaptureUnknownErrors = (errorKeys) => {
    const unknownErrorKeys = Array.isArray(errorKeys)
        ? errorKeys.filter((key) => !(key in errorContentMap)).join(', ')
        : null;
    // Track error keys that we miss definition for
    useEffect(() => {
        if (unknownErrorKeys) {
            datadogRum.addError(`Missing content for error keys '${unknownErrorKeys}'`);
        }
    }, [unknownErrorKeys]);
};
