export const getBusinessTypeValue = (businessType, text) => {
    switch (businessType) {
        case 'SelfEmployed':
            return text('OVERVIEW_FIELD_BUSINESS_TYPE_VALUE_SELF_EMPLOYED');
        case 'LimitedCompany':
            return text('OVERVIEW_FIELD_BUSINESS_TYPE_VALUE_LIMITED_COMPANY');
        case 'Partnership':
            return text('OVERVIEW_FIELD_BUSINESS_TYPE_VALUE_PARTNERSHIP');
        case 'NonProfit':
            return text('OVERVIEW_FIELD_BUSINESS_TYPE_VALUE_NON_PROFIT');
        default:
            throw new Error(`Not possible to display business type in the summary. Unexpected value '${businessType}'`);
    }
};
