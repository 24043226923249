import styled from 'styled-components';
import { colors } from '@izettle/otto';
export const ALERT_CONTAINER_ID = 'alert-container';
export const Alert = styled.div.attrs(() => ({
    id: ALERT_CONTAINER_ID,
    'aria-live': 'assertive',
})) `
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: ${colors.input.text.warning};
  background-color: ${colors.message.background.error};
  border-color: #f5c6cb;
`;
export const Warning = styled.div.attrs(() => ({
    id: ALERT_CONTAINER_ID,
    'aria-live': 'assertive',
})) `
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: ${colors.background.warning};
  border-color: ${colors.border.warning};
  p {
    color: ${colors.text.warning};
  }
`;
