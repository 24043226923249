import ownerStructureHelpSvg from 'src/assets/owner-structure-help.svg';
import { FunctionStepFuncKey } from 'src/components/Questionnaire/types';
import { getLimitedPartnershipRoleLabels, getNonProfitRoleLabels } from 'src/utils/country/GB/data-helpers';
import { formatCurrencyRange, formatPercentRange, locale } from 'src/utils/country/GB/format';
import { nationalityOptions } from '../../common/nationality-options';
import { RoleFieldName } from '../../common/owner-structure';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { template } from '../../template';
export const businessType = ({ translate }) => ({
    fields: ['businessType'],
    JSONSchema: {
        title: translate('BUSINESS_TYPE_HEADER'),
        description: translate('BUSINESS_TYPE_INSTRUCTION'),
        required: ['businessType'],
        type: 'object',
        properties: {
            businessType: {
                title: translate('BUSINESS_TYPE_LABEL'),
                type: 'string',
                oneOf: [
                    { enum: ['SelfEmployed'], title: translate('BUSINESS_TYPE_VALUE_SELFEMPLOYED') },
                    { enum: ['LimitedCompany'], title: translate('BUSINESS_TYPE_VALUE_LIMITEDCOMPANY') },
                    {
                        enum: ['Partnership'],
                        title: translate('BUSINESS_TYPE_VALUE_PARTNERSHIP'),
                    },
                    {
                        enum: ['NonProfit'],
                        title: translate('BUSINESS_TYPE_VALUE_NONPROFIT'),
                    },
                ],
            },
        },
    },
    UISchema: {
        businessType: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessType',
        segment: 'businessDetails',
    },
});
export const isPartnershipRegistered = ({ translate }) => ({
    fields: ['isRegisteredAtCompaniesHouse'],
    JSONSchema: {
        required: ['isRegisteredAtCompaniesHouse'],
        type: 'object',
        properties: {
            isRegisteredAtCompaniesHouse: {
                title: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_PARTNERSHIP_LABEL'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_PARTNERSHIP_TRUE_RADIO_BUTTON') },
                    {
                        enum: [false],
                        title: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_PARTNERSHIP_FALSE_RADIO_BUTTON'),
                    },
                ],
            },
        },
    },
    UISchema: {
        isRegisteredAtCompaniesHouse: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'isRegisteredAtCompaniesHouse',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const isNonProfitRegistered = ({ translate }) => ({
    fields: ['isRegisteredAtCompaniesHouse'],
    JSONSchema: {
        required: ['isRegisteredAtCompaniesHouse'],
        type: 'object',
        properties: {
            isRegisteredAtCompaniesHouse: {
                title: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_NON_PROFIT_LABEL'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_NON_PROFIT_TRUE_RADIO_BUTTON') },
                    {
                        enum: [false],
                        title: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_NON_PROFIT_FALSE_RADIO_BUTTON'),
                    },
                ],
            },
        },
    },
    UISchema: {
        isRegisteredAtCompaniesHouse: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'isRegisteredAtCompaniesHouse',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_NON_PROFIT_HELP_TEXT_TITLE'),
                    body: translate('IS_REGISTERED_AT_COMPANIES_HOUSE_NON_PROFIT_HELP_TEXT_BODY'),
                },
            ],
        },
    },
});
export const selfEmployedPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_SOLE_TRADER_ITEM_1_HEADER'),
                    description: translate('PREPARATION_SCREEN_SOLE_TRADER_ITEM_1_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_SOLE_TRADER_ITEM_2_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_SOLE_TRADER_ITEM_3_HEADER'),
                },
            ],
        },
    },
});
export const selfEmployedBusinessName = ({ translate }) => ({
    fields: ['businessName'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            businessName: {
                title: translate('BUSINESS_NAME_SOLE_TRADER_LABEL'),
                description: translate('BUSINESS_NAME_SOLE_TRADER_INSTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessName: {
            'ui:autofocus': true,
        },
    },
    applicationVariables: {
        stepName: 'businessName',
        segment: 'businessDetails',
    },
});
export const limitedPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_LIMITED_COMPANY_ITEM_1_HEADER'),
                    description: translate('PREPARATION_SCREEN_LIMITED_COMPANY_ITEM_1_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_LIMITED_COMPANY_ITEM_2_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_LIMITED_COMPANY_ITEM_3_HEADER'),
                    description: translate('PREPARATION_SCREEN_LIMITED_COMPANY_ITEM_3_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_LIMITED_COMPANY_ITEM_4_HEADER'),
                },
            ],
        },
    },
});
export const registeredPartnershipPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_PARTNERSHIP_REGISTERED_ITEM_1_HEADER'),
                    description: translate('PREPARATION_SCREEN_PARTNERSHIP_REGISTERED_ITEM_1_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PARTNERSHIP_REGISTERED_ITEM_2_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_PARTNERSHIP_REGISTERED_ITEM_3_HEADER'),
                    description: translate('PREPARATION_SCREEN_PARTNERSHIP_REGISTERED_ITEM_3_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_PARTNERSHIP_REGISTERED_ITEM_4_HEADER'),
                },
            ],
        },
    },
});
export const unregisteredPartnershipPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_PARTNERSHIP_NOT_REGISTERED_ITEM_1_HEADER'),
                    description: translate('PREPARATION_SCREEN_PARTNERSHIP_NOT_REGISTERED_ITEM_1_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PARTNERSHIP_NOT_REGISTERED_ITEM_2_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_PARTNERSHIP_NOT_REGISTERED_ITEM_3_HEADER'),
                    description: translate('PREPARATION_SCREEN_PARTNERSHIP_NOT_REGISTERED_ITEM_3_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_PARTNERSHIP_NOT_REGISTERED_ITEM_4_HEADER'),
                },
            ],
        },
    },
});
export const registeredNonProfitPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_NON_PROFIT_COMPANIES_HOUSE_ITEM_1_HEADER'),
                    description: translate('PREPARATION_SCREEN_NON_PROFIT_COMPANIES_HOUSE_ITEM_1_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_NON_PROFIT_COMPANIES_HOUSE_ITEM_2_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_NON_PROFIT_COMPANIES_HOUSE_ITEM_3_HEADER'),
                    description: translate('PREPARATION_SCREEN_NON_PROFIT_COMPANIES_HOUSE_ITEM_3_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_NON_PROFIT_COMPANIES_HOUSE_ITEM_4_HEADER'),
                },
            ],
        },
    },
});
export const unregisteredNonProfitPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_NON_PROFIT_CHARITY_COMISSION_ITEM_1_HEADER'),
                    description: translate('PREPARATION_SCREEN_NON_PROFIT_CHARITY_COMISSION_ITEM_1_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_NON_PROFIT_CHARITY_COMISSION_ITEM_2_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_NON_PROFIT_CHARITY_COMISSION_ITEM_3_HEADER'),
                    description: translate('PREPARATION_SCREEN_NON_PROFIT_CHARITY_COMISSION_ITEM_3_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_NON_PROFIT_CHARITY_COMISSION_ITEM_4_HEADER'),
                },
            ],
        },
    },
});
export const limitedAndPartnershipBusinessName = ({ translate }) => ({
    fields: ['businessName'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            businessName: {
                title: translate('BUSINESS_NAME_LABEL'),
                description: translate('BUSINESS_NAME_INSTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessName: {
            'ui:autofocus': true,
        },
    },
    applicationVariables: {
        stepName: 'businessName',
        segment: 'businessDetails',
    },
});
export const nonProfitBusinessName = ({ translate }) => ({
    fields: ['businessName'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            businessName: {
                title: translate('BUSINESS_NAME_NON_PROFIT_LABEL'),
                description: translate('BUSINESS_NAME_NON_PROFIT_INSTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessName: {
            'ui:autofocus': true,
        },
    },
    applicationVariables: {
        stepName: 'businessName',
        segment: 'businessDetails',
    },
});
export const limitedBusinessEntityNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessLegalEntityNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLegalEntityNumber: {
                title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_LIMITED_COMPANY_LABEL'),
                description: translate('BUSINESS_LEGAL_ENTITY_NUMBER_LIMITED_COMPANY_INTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessLegalEntityNumber: {
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': translate('BUSINESS_LEGAL_ENTITY_NUMBER_LIMITED_COMPANY_PLACEHOLDER'),
        },
    },
    applicationVariables: {
        stepName: 'businessLegalEntityNumber',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_LIMITED_COMPANY_HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_LEGAL_ENTITY_NUMBER_LIMITED_COMPANY_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const unregisteredNonProfitBusinessEntityNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessLegalEntityNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLegalEntityNumber: {
                title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_NON_PROFIT_CHARITY_COMISSION_LABEL'),
                description: translate('BUSINESS_LEGAL_ENTITY_NUMBER_NON_PROFIT_CHARITY_COMISSION_INTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessLegalEntityNumber: {
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': translate('BUSINESS_LEGAL_ENTITY_NUMBER_NON_PROFIT_CHARITY_COMISSION_PLACEHOLDER'),
        },
    },
    applicationVariables: {
        stepName: 'businessLegalEntityNumber',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_NON_PROFIT_CHARITY_COMISSION_HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_LEGAL_ENTITY_NUMBER_NON_PROFIT_CHARITY_COMISSION_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const selfEmployedAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessBuilding', 'businessStreetAddress', 'businessCity', 'businessPostalCode', 'businessCounty'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_SELF_EMPLOYED_LABEL'),
                description: translate('BUSINESS_ADDRESS_SOLE_TRADER_INSTRUCTION'),
                type: 'null',
            },
            businessBuilding: {
                type: 'string',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
            businessCounty: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessBuilding: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        businessStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        businessCounty: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_ADDRESS_SOLE_TRADER_HELP_TEXT_WHY_ADDRESS_ON_RECEIPTS_TITLE'),
                    body: translate('BUSINESS_ADDRESS_SOLE_TRADER_HELP_TEXT_WHY_ADDRESS_ON_RECEIPTS_BODY'),
                },
            ],
        },
    },
});
export const limitedAndPartnershipAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessBuilding', 'businessStreetAddress', 'businessCity', 'businessPostalCode', 'businessCounty'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_LABEL'),
                description: translate('BUSINESS_ADDRESS_LIMITED_COMPANY_INSTRUCTION'),
                type: 'null',
            },
            businessBuilding: {
                type: 'string',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
            businessCounty: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessBuilding: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        businessStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        businessCounty: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
    },
});
export const nonProfitAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessBuilding', 'businessStreetAddress', 'businessCity', 'businessPostalCode', 'businessCounty'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_NON_PROFIT_LABEL'),
                type: 'null',
            },
            businessBuilding: {
                type: 'string',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
            businessCounty: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessBuilding: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        businessStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        businessCounty: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
    },
});
export const personalDetailsQuestionsIntro = ({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'personalDetails',
        content: {
            icon: 'Person',
            image: 'highFive',
            header: translate('SECTION_INTRO_PERSONAL_DETAILS_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_PERSONAL_DETAILS_TITLE'),
            description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
            button: translate('FORM_BUTTON'),
        },
    },
});
export const signatoryName = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryFirstName', 'signatoryLastName'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryQuestion: {
                title: translate('SIGNATORY_NAME_LABEL'),
                type: 'null',
            },
            signatoryFirstName: {
                type: 'string',
            },
            signatoryLastName: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryFirstName: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_LABEL'),
            },
        },
        signatoryLastName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('LAST_NAME_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryName',
        segment: 'personalDetails',
    },
});
export const signatoryDOB = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryDateOfBirth'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryDateOfBirth: {
                title: translate('SIGNATORY_DATE_OF_BIRTH_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryDateOfBirth: {
            'ui:options': {
                mask: "99/99/9999" /* CopyGB.DATE_OF_BIRTH_MASK */,
            },
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': "DD/MM/YYYY" /* CopyGB.DATE_OF_BIRTH_PLACEHOLDER */,
        },
    },
    applicationVariables: {
        stepName: 'signatoryDateOfBirth',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_DATE_OF_BIRTH_HELP_TEXT_WHY_DATE_OF_BIRTH_TITLE'),
                    body: translate('SIGNATORY_DATE_OF_BIRTH_HELP_TEXT_WHY_DATE_OF_BIRTH_BODY'),
                },
            ],
        },
    },
});
export const signatoryNationality = ({ translate }) => ({
    fields: ['signatoryNationality'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryNationality: {
                title: translate('SIGNATORY_NATIONALITY_LABEL'),
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
        },
    },
    UISchema: {
        signatoryNationality: {
            'ui:widget': 'CustomComboBoxWidget',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
            'ui:options': {
                sortByLabels: 'asc',
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryNationality',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_NATIONALITY_HELP_TEXT_WHY_NATIONALITY_TITLE'),
                    body: translate('SIGNATORY_NATIONALITY_HELP_TEXT_WHY_NATIONALITY_BODY'),
                },
            ],
        },
    },
});
export const isSignatoryShareAddress = ({ translate }) => ({
    fields: ['isSignatoryShareAddress'],
    JSONSchema: {
        required: ['isSignatoryShareAddress'],
        type: 'object',
        properties: {
            isSignatoryShareAddress: {
                title: translate('IS_SIGNATORY_SHARE_ADDRESS_LABEL'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('IS_SIGNATORY_SHARE_ADDRESS_VALUE_YES') },
                    { enum: [false], title: translate('IS_SIGNATORY_SHARE_ADDRESS_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        isSignatoryShareAddress: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'isSignatoryShareAddress',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('IS_SIGNATORY_SHARE_ADDRESS_HELP_TEXT_WHY_ADDRESS_TITLE'),
                    body: translate('IS_SIGNATORY_SHARE_ADDRESS_HELP_TEXT_WHY_ADDRESS_BODY'),
                },
            ],
        },
    },
});
export const signatoryAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryBuilding', 'signatoryStreetAddress', 'signatoryCity', 'signatoryCounty', 'signatoryPostalCode'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryBuilding: {
                type: 'string',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
            signatoryCounty: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryBuilding: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        signatoryCounty: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
    },
});
export const signatoryPhoneNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryPhoneNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryPhoneNumber: {
                title: translate('SIGNATORY_PHONE_NUMBER_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryPhoneNumber: {
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:placeholder': translate('SIGNATORY_PHONE_NUMBER_PLACEHOLDER'),
        },
    },
    applicationVariables: {
        stepName: 'signatoryPhoneNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_PHONE_NUMBER_TITLE'),
                    body: translate('SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_PHONE_NUMBER_BODY'),
                },
            ],
        },
    },
});
export const selfEmployedFSAQuestionsIntro = ({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'usageOfIzettle',
        content: {
            icon: 'CardReaderOne',
            image: 'fistBump',
            header: translate('SECTION_INTRO_USAGE_OF_IZETTLE_SELF_EMPLOYED_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_USAGE_OF_IZETTLE_TITLE'),
            button: translate('FORM_BUTTON'),
        },
    },
});
const genericFSAQuestionsIntro = ({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'usageOfIzettle',
        content: {
            icon: 'CardReaderOne',
            image: 'highFive',
            header: translate('SECTION_INTRO_USAGE_OF_IZETTLE_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_USAGE_OF_IZETTLE_TITLE'),
            button: translate('FORM_BUTTON'),
        },
    },
});
export const partnershipFSAIntro = genericFSAQuestionsIntro;
export const limitedFSAIntro = genericFSAQuestionsIntro;
export const nonProfitFSAIntro = genericFSAQuestionsIntro;
export const businessLocationsTypes = ({ translate }) => ({
    fields: ['businessLocationTypes'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLocationTypes: {
                title: translate('BUSINESS_LOCATION_TYPES_LABEL'),
                description: translate('BUSINESS_LOCATION_TYPES_INSTRUCTION'),
                type: 'array',
                minItems: 1,
                items: {
                    type: 'string',
                    anyOf: [
                        { enum: ['PHYSICAL_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_PHYSICAL_STORE') },
                        { enum: ['TEMPORARY_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_TEMPORARY_STORE') },
                        { enum: ['EVENT_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_EVENT_STORE') },
                        { enum: ['ONLINE_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_ONLINE_STORE') },
                    ],
                },
                uniqueItems: true,
            },
        },
    },
    UISchema: {
        businessLocationTypes: {
            'ui:widget': 'CustomCheckboxGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessLocationTypes',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessFrequency = ({ translate }) => ({
    fields: ['businessFrequency'],
    JSONSchema: {
        required: ['businessFrequency'],
        type: 'object',
        properties: {
            businessFrequency: {
                title: translate('BUSINESS_FREQUENCY_LABEL'),
                type: 'string',
                oneOf: [
                    { enum: ['EVERY_DAY'], title: translate('BUSINESS_FREQUENCY_VALUE_EVERY_DAY') },
                    {
                        enum: ['FEW_TIMES_A_MONTH'],
                        title: translate('BUSINESS_FREQUENCY_VALUE_FEW_TIMES_A_MONTH'),
                    },
                    {
                        enum: ['FEW_TIMES_A_YEAR'],
                        title: translate('BUSINESS_FREQUENCY_VALUE_FEW_TIMES_A_YEAR'),
                    },
                ],
            },
        },
    },
    UISchema: {
        businessFrequency: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessFrequency',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessAverageTransaction = ({ translate }) => ({
    fields: ['businessAverageTransaction'],
    JSONSchema: {
        required: ['businessAverageTransaction'],
        type: 'object',
        properties: {
            businessAverageTransaction: {
                title: translate('BUSINESS_AVERAGE_TRANSACTION_LABEL'),
                type: 'string',
                oneOf: ['0-50', '50-500', '500-X'].map((range) => ({
                    enum: [range],
                    title: formatCurrencyRange(range, translate),
                })),
            },
        },
    },
    UISchema: {
        businessAverageTransaction: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessAverageTransaction',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessIzettleShareOfTurnover = ({ translate }) => ({
    fields: ['businessIzettleShareOfTurnover'],
    JSONSchema: {
        required: ['businessIzettleShareOfTurnover'],
        type: 'object',
        properties: {
            businessIzettleShareOfTurnover: {
                title: translate('BUSINESS_IZETTLE_SHARE_OF_TURNOVER_LABEL'),
                type: 'string',
                oneOf: ['0-20%', '20-80%', '80-X%'].map((rangeValue) => ({
                    enum: [rangeValue],
                    title: formatPercentRange(rangeValue, translate),
                })),
            },
        },
    },
    UISchema: {
        businessIzettleShareOfTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessIzettleShareOfTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessCurrentAnnualTurnover = ({ translate }) => ({
    fields: ['businessCurrentAnnualTurnover'],
    JSONSchema: {
        required: ['businessCurrentAnnualTurnover'],
        type: 'object',
        properties: {
            businessCurrentAnnualTurnover: {
                title: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_LABEL'),
                description: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_INSTRUCTION'),
                type: 'string',
                oneOf: [
                    {
                        enum: ['NEW_BUSINESS'],
                        title: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_VALUE_NEW_BUSINESS'),
                    },
                    ...['0-20000', '20000-200000', '200000-1000000', '1000000-X'].map((rangeValue) => ({
                        enum: [rangeValue],
                        title: formatCurrencyRange(rangeValue, translate),
                    })),
                ],
            },
        },
    },
    UISchema: {
        businessCurrentAnnualTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessCurrentAnnualTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessExpectedAnnualTurnover = ({ translate }) => ({
    fields: ['businessExpectedAnnualTurnover'],
    JSONSchema: {
        required: ['businessExpectedAnnualTurnover'],
        type: 'object',
        properties: {
            businessExpectedAnnualTurnover: {
                title: translate('BUSINESS_EXPECTED_ANNUAL_TURNOVER_LABEL'),
                type: 'string',
                oneOf: ['0-20000', '20000-200000', '200000-1000000', '1000000-X'].map((rangeValue) => ({
                    enum: [rangeValue],
                    title: formatCurrencyRange(rangeValue, translate),
                })),
            },
        },
    },
    UISchema: {
        businessExpectedAnnualTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessExpectedAnnualTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
const personStep = (translate) => {
    return {
        ID: 'personStepId',
        backendValidation: true,
        fields: [
            'firstName',
            'lastName',
            'dateOfBirth',
            'nationality',
            'building',
            'streetAddress',
            'city',
            'county',
            'postalCode',
        ],
        JSONSchema: {
            type: 'object',
            properties: {
                nameTitle: {
                    title: translate('PERSON_NAME_LABEL'),
                    type: 'null',
                },
                firstName: {
                    type: 'string',
                },
                lastName: {
                    type: 'string',
                },
                dateOfBirthTitle: {
                    title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                    type: 'null',
                },
                dateOfBirth: {
                    type: 'string',
                },
                nationalityTitle: {
                    title: translate('PERSON_NATIONALITY_LABEL'),
                    type: 'null',
                },
                nationality: {
                    type: 'string',
                    enum: nationalityOptions.map((option) => option.value),
                    enumNames: nationalityOptions.map((option) => translate(option.textKey)),
                },
                addressTitle: {
                    title: translate('PERSON_HOME_ADDRESS_LABEL'),
                    type: 'null',
                },
                building: {
                    type: 'string',
                },
                streetAddress: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
                county: {
                    type: 'string',
                },
                postalCode: {
                    type: 'string',
                },
            },
        },
        UISchema: {
            firstName: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('FIRST_NAME_LABEL'),
                },
            },
            lastName: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('LAST_NAME_LABEL'),
                },
            },
            dateOfBirth: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: "DD/MM/YYYY" /* CopyGB.DATE_OF_BIRTH_PLACEHOLDER */,
                    mask: "99/99/9999" /* CopyGB.DATE_OF_BIRTH_MASK */,
                },
            },
            nationality: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:widget': 'CustomComboBoxWidget',
                'ui:emptyValue': '',
                'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
                'ui:options': {
                    sortByLabels: 'asc',
                },
            },
            building: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_BUILDING_LABEL'),
                },
            },
            streetAddress: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
                },
            },
            city: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_CITY_LABEL'),
                },
            },
            county: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_COUNTY_LABEL'),
                },
            },
            postalCode: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                },
            },
        },
        applicationVariables: {
            stepName: 'newPerson',
        },
    };
};
export const initLegalPersonsFunction = () => ({
    functions: [FunctionStepFuncKey.initLegalPersons],
    fields: ['legalPersons'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'legalPersonsFunc',
        segment: 'ownerStructure',
    },
});
const ownerStructureIntroTemplate = template(({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'ownerStructure',
        content: {
            icon: 'IdentityCard',
            image: 'fistBump',
            header: translate('SECTION_INTRO_OWNER_STRUCTURE_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            description: translate('SECTION_INTRO_OWNER_STRUCTURE_NAME_DOB_NATIONALITY_ADDRESS_DESCRIPTION'),
            button: translate('FORM_BUTTON'),
        },
    },
}));
export const limitedPartnershipOwnerStructureIntro = ownerStructureIntroTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            title: translate('SECTION_INTRO_OWNER_STRUCTURE_TITLE'),
        },
    },
}));
export const nonProfitOwnerStructureIntro = ownerStructureIntroTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            title: translate('SECTION_INTRO_OWNER_STRUCTURE_NON_PROFIT_TITLE'),
        },
    },
}));
export const limitedDirectorsList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleDirectors'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'directorsList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleDirectors,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
            description: translate('OWNER_STRUCTURE_DIRECTORS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_GB_COMPANIES_HOUSE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const partnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['rolePartners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'partnersList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.rolePartners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_PARTNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_PARTNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_GB_COMPANIES_HOUSE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const nonProfitDirectorsList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleDirectors'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'directorsList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleDirectors,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getNonProfitRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL'),
            description: translate('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_GB_CHARITY_COMISSION_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const hasUBO = ({ translate }) => ({
    fields: ['hasUBO'],
    JSONSchema: {
        required: ['hasUBO'],
        type: 'object',
        title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
        properties: {
            hasUBO: {
                title: translate('OWNER_STRUCTURE_HAS_UBO_DESCRIPTION'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_YES') },
                    { enum: [false], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        hasUBO: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
        'ui:options': {
            titleStyle: 'LargeBold',
        },
    },
    applicationVariables: {
        stepName: 'hasUBO',
        segment: 'ownerStructure',
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_GB_COMPANIES_HOUSE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
            ],
        },
    },
});
export const beneficialOwners = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleBeneficialOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'beneficialOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleBeneficialOwners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_GB_COMPANIES_HOUSE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
            ],
        },
    },
});
export const alternativeOwnersListTemplate = template(({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleAlternativeOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'alternativeOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleAlternativeOwners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        content: {
            title: translate('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
        },
    },
}));
export const limitedPartnershipAlternativeOwnersList = alternativeOwnersListTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_GB_COMPANIES_HOUSE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
                {
                    title: translate('HELP_TEXT_WHAT_TOP_LEVEL_MANAGEMENT_MEAN_TITLE'),
                    body: translate('HELP_TEXT_WHAT_TOP_LEVEL_MANAGEMENT_MEAN_BODY'),
                },
            ],
        },
    },
}));
export const overviewPage = ({ translate, formContext }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: 'GB',
        displayUsaTaxpayerTerms: formContext.form.endsWith('1099K'),
        content: {
            title: translate('OVERVIEW_TITLE'),
            subtitle: translate('OVERVIEW_SUB_TITLE'),
            submitButton: translate('OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: translate('OVERVIEW_SUBMIT_INSTRUCTION', [getPrivacyPolicyLink(locale)]),
            helpTexts: [
                {
                    title: translate('OVERVIEW_HELP_TEXT_PEP_INTRUCTIONS_TITLE'),
                    body: translate('OVERVIEW_HELP_TEXT_PEP_INTRUCTIONS_BODY'),
                },
            ],
        },
    },
});
export const nonProfitAlternativeOwnersList = alternativeOwnersListTemplate
    .extendDeep(({ translate }) => ({
    applicationVariables: {
        personRoleLabels: getNonProfitRoleLabels(translate),
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_GB_CHARITY_COMISSION_HELP_TEXT_INFO_LOCATION_BODY'),
                },
                {
                    title: translate('HELP_TEXT_WHAT_TOP_LEVEL_MANAGEMENT_MEAN_TITLE'),
                    body: translate('HELP_TEXT_WHAT_TOP_LEVEL_MANAGEMENT_MEAN_BODY'),
                },
            ],
        },
    },
}))
    .overrideDeep(({ translate }) => ({
    applicationVariables: {
        personRoleLabels: getNonProfitRoleLabels(translate),
    },
}));
