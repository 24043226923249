import styled from 'styled-components';
import { typography, colors } from '@izettle/otto';
import { flexItemTextWrapFix } from 'src/styles/mixins';
export const Title = styled.div `
  ${typography.regular};
  user-select: none;
  ${({ disabled }) => disabled && `color: ${colors.text.disabled}; `};
  span {
    ${typography.regular}
  }
`;
export const Description = styled.div `
  ${typography.mini};
  user-select: none;
  color: ${colors.text.secondary};
  ${({ disabled }) => disabled && `color: ${colors.text.disabled}; `};
`;
export const ContentContainer = styled.div `
  ${flexItemTextWrapFix}
  flex-grow: 1;
`;
