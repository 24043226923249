import { COLLECT_KEYS, SIGNATORY_ACTION } from './data';
export const GAP_omitLegalPeoplePropsFunction = (data) => {
    const { legalPeople } = data;
    if (!legalPeople) {
        return data;
    }
    legalPeople.forEach((personObject) => {
        if (!personObject[COLLECT_KEYS.ADDRESS]) {
            personObject.omit_addressTitle = true;
            personObject.omit_building = true;
            personObject.omit_streetAddress = true;
            personObject.omit_city = true;
            personObject.omit_county = true;
            personObject.omit_postalCode = true;
        }
        if (!personObject[COLLECT_KEYS.BIRTHDATE]) {
            personObject.omit_dateOfBirthTitle = true;
            personObject.omit_dateOfBirth = true;
        }
        if (!personObject[COLLECT_KEYS.NATIONALITY]) {
            personObject.omit_nationalityTitle = true;
            personObject.omit_nationality = true;
        }
    });
    return Object.assign(Object.assign({}, data), { legalPeople });
};
export const GAP_readonlyLegalPeoplePropsFunction = (data) => {
    const { legalPeople } = data;
    if (!legalPeople) {
        return data;
    }
    legalPeople.forEach((personObject) => {
        const { role, readonly_values_role } = personObject;
        if (!readonly_values_role) {
            personObject.readonly_values_role = role;
        }
    });
    return Object.assign(Object.assign({}, data), { legalPeople });
};
export const GAP_newLegalPeopleDefaultValueFunction = (data) => (Object.assign({ newLegalPeople: [] }, data));
export const GAP_normalizeSignatoryFieldsFunction = (data) => {
    const { signatoryAction, existingSignatoryFirstName, existingSignatoryLastName } = data;
    if (signatoryAction === SIGNATORY_ACTION.SET_NATIONALITY) {
        return Object.assign(Object.assign({}, data), { signatoryFirstName: existingSignatoryFirstName, signatoryLastName: existingSignatoryLastName });
    }
    return data;
};
