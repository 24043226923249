// These are helper functions for the effect-handlers to store/restore Questionnaires
// in browser local storage
import isEqual from 'lodash.isequal';
import { datadogRum } from '@datadog/browser-rum';
import { createEmptyQuestionnaireState } from 'src/components/Questionnaire/state/create-empty-state';
const QUESTIONNAIRE_KEY_PREFIX = 'questionnaire';
// Bumping QUESTIONNAIRE_VERSION is not necessary when the steps schema is changed.
// But may be needed along a code change that can make older version of schema not possible to be rendered.
const QUESTIONNAIRE_VERSION = 11;
const getCacheKeyForOrganization = (organizationUuid) => `${QUESTIONNAIRE_KEY_PREFIX}-v${QUESTIONNAIRE_VERSION}-${organizationUuid}`;
const removeOtherSavedQuestionnaires = (preserveKey) => {
    if (!localStorage) {
        return;
    }
    Object.keys(localStorage).forEach((key) => {
        if (key === preserveKey) {
            return;
        }
        if (!key.startsWith(QUESTIONNAIRE_KEY_PREFIX)) {
            return;
        }
        localStorage === null || localStorage === void 0 ? void 0 : localStorage.removeItem(key);
    });
};
export const storeQuestionnaireInLocalStorage = (questionnaire, organizationUuid) => {
    if (isEqual(questionnaire, createEmptyQuestionnaireState(questionnaire))) {
        return;
    }
    const serializedData = JSON.stringify(questionnaire);
    try {
        const key = getCacheKeyForOrganization(organizationUuid);
        removeOtherSavedQuestionnaires(key);
        localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem(key, serializedData);
    }
    catch (e) {
        datadogRum.addError(e);
    }
};
export const restoreQuestionnaireFromLocalStorage = (orgUuid) => {
    const savedSerializedData = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(getCacheKeyForOrganization(orgUuid));
    if (!savedSerializedData) {
        return null;
    }
    return JSON.parse(savedSerializedData);
};
export const removeQuestionnaireFromLocalStorage = (organizationUuid) => {
    localStorage === null || localStorage === void 0 ? void 0 : localStorage.removeItem(getCacheKeyForOrganization(organizationUuid));
};
