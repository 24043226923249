import React, { useState } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { Form } from 'src/components/Form';
import { Header, HeaderButton } from 'src/components/Header';
import { useBackendStepValidation } from 'src/hooks/use-backend-step-validation';
import { mapDataForRequest, mapErrorsFromResponse } from './helpers';
export const AddEditPerson = ({ personStepObject, personData, onClickBack, onValidationSuccess: handleComplete }) => {
    var _a;
    const { text } = useLocalization();
    const [formData, setFormData] = useState(personData !== null && personData !== void 0 ? personData : {});
    const isEdit = personData !== undefined;
    const backendValidation = useBackendStepValidation(personStepObject, mapDataForRequest, mapErrorsFromResponse);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { leftButton: React.createElement(HeaderButton, { displayStyle: "cancel", onClick: onClickBack }) }, isEdit ? text('OWNER_STRUCTURE_PERSON_FORM_EDIT_TITLE') : text('OWNER_STRUCTURE_PERSON_FORM_ADD_TITLE')),
        React.createElement(Form, { JSONSchema: personStepObject.JSONSchema, UISchema: personStepObject.UISchema, helpTexts: (_a = personStepObject.applicationVariables.content) === null || _a === void 0 ? void 0 : _a.helpTexts, extraTexts: personStepObject.applicationVariables.content, onComplete: handleComplete, onFormDataChange: setFormData, backendValidation: backendValidation, formData: formData, completeButtonText: isEdit ? text('FORM_BUTTON_SAVE') : text('OWNER_STRUCTURE_FORM_BUTTON_ADD_PERSON') })));
};
