import React from 'react';
import styled from 'styled-components';
import { spacing, typography } from '@izettle/otto';
const Title = styled.div `
  ${typography.regularBold};
  padding-bottom: ${spacing.xxSmall};
`;
export const TitleField = ({ title, id }) => {
    return React.createElement(Title, { id: id }, title);
};
