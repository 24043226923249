import { decision, step } from '../steps-builder';
const businessCategoryId = (categories) => ({ translate }) => ({
    fields: ['businessCategoryId'],
    JSONSchema: {
        required: ['businessCategoryId'],
        type: 'object',
        properties: {
            businessCategoryId: {
                title: translate('BUSINESS_CATEGORY_ID_LABEL'),
                type: 'string',
                oneOf: categories.map((category) => ({
                    enum: [category.code.toString()],
                    title: category.name,
                })),
            },
        },
    },
    UISchema: {
        businessCategoryId: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessCategoryId',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_CATEGORY_ID_HELP_TEXT_SEVERAL_CATEGORIES_TITLE'),
                    body: translate('BUSINESS_CATEGORY_ID_HELP_TEXT_SEVERAL_CATEGORIES_BODY'),
                },
            ],
        },
    },
});
const businessSubCategoryId = (subCategories) => ({ translate }) => ({
    fields: ['businessSubCategoryId'],
    JSONSchema: {
        required: ['businessSubCategoryId'],
        type: 'object',
        properties: {
            businessSubCategoryId: {
                title: translate('BUSINESS_SUB_CATEGORY_ID_LABEL'),
                type: 'string',
                oneOf: subCategories.map((subCategory) => ({
                    enum: [subCategory.code.toString()],
                    title: subCategory.name,
                })),
            },
        },
    },
    UISchema: {
        businessSubCategoryId: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessSubCategoryId',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_CATEGORY_ID_HELP_TEXT_SEVERAL_CATEGORIES_TITLE'),
                    body: translate('BUSINESS_CATEGORY_ID_HELP_TEXT_SEVERAL_CATEGORIES_BODY'),
                },
            ],
        },
    },
});
export const buildCategorySteps = ({ formContext, }) => {
    var _a;
    const categories = (_a = formContext === null || formContext === void 0 ? void 0 : formContext.additionalData) === null || _a === void 0 ? void 0 : _a.businessCategories;
    if (!categories) {
        throw new Error('Not possible to build schema. Categories not found in form context data');
    }
    return decision('businessCategoryId', businessCategoryId(categories), categories.map((category) => ({
        when: category.code.toString(),
        branch: step(businessSubCategoryId(category.children)),
    })));
};
