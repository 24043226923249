import React, { createContext, useEffect, useState, useContext } from 'react';
import { redirectToBackOffice } from 'src/helpers';
import { useAsyncError } from 'src/hooks/use-async-error';
import { useAuthStateValue } from '../auth';
import { fetchBusinessCategories, fetchFormContext } from './api';
import { FormStatus } from './types';
const FormContext = createContext({});
const isEligibleFormContext = (formContext) => formContext.status === FormStatus.ONBOARDING || formContext.status === FormStatus.GAPS;
export const FormContextProvider = ({ children }) => {
    const [formContext, setFormContext] = useState(null);
    const { authClient } = useAuthStateValue();
    const throwError = useAsyncError();
    useEffect(() => {
        void fetchFormContext(authClient)
            .then(fetchBusinessCategories)
            .then(setFormContext)
            .catch((error) => {
            if (error.message === 'Forbidden status received.') {
                redirectToBackOffice();
            }
            else {
                throwError(error);
            }
        });
    }, []);
    if (!formContext) {
        return null;
    }
    return React.createElement(FormContext.Provider, { value: { formContext } }, children);
};
export const useFormContextValue = () => useContext(FormContext);
export const useEligibleFormContextValue = () => {
    const { formContext } = useContext(FormContext);
    if (!isEligibleFormContext(formContext)) {
        throw new Error('Trying to fetch an eligible form context where it is not applicable');
    }
    return { formContext };
};
