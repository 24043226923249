import { useTracker } from 'src/hooks/use-tracker';
import { addBreadcrumb as addDatadogRumBreadcrumb } from 'src/tools/datadog-rum';
const scrollToTop = () => {
    const main = window.document.querySelector('main');
    // ensure content not hidden behind address bar
    // case for Samsung S9, Chrome
    main === null || main === void 0 ? void 0 : main.scrollIntoView();
    (main !== null && main !== void 0 ? main : window).scrollTo(0, 0);
};
const triggerEvent = (_state, effect, dispatch) => {
    dispatch(effect.data);
};
// Since there will be several effects that would require data from contexts, make this a hook
export const useQuestionnaireEffectsMap = () => {
    const { trackEvent } = useTracker();
    return {
        triggerEvent,
        scrollToTop,
        addDatadogRumBreadcrumb: (_state, { data }) => addDatadogRumBreadcrumb(data),
        trackEvent: (_state, effect) => trackEvent(effect.data.action, effect.data.payload),
    };
};
