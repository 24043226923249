import { updateBrowserUrl } from 'src/helpers';
export const createAppState = () => {
    return {
        queryParameters: window.location.search,
    };
};
export const handleAppStateAfterRedirect = (appState) => {
    if (appState === null || appState === void 0 ? void 0 : appState.queryParameters) {
        updateBrowserUrl(appState.queryParameters);
    }
};
