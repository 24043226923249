import { IsoLocale } from '@izettle/constants';
import { Kyc2Countries } from './types';
export const isKyc2Country = (countryId) => Object.keys(Kyc2Countries).includes(countryId);
export const isValidIsoLocaleEnumKey = (isoLocaleEnumKey) => Object.keys(IsoLocale).includes(isoLocaleEnumKey);
export const getUserLocale = ({ user: { countryId, languageId } }) => {
    const isoLocaleEnumKey = `${languageId}${countryId}`;
    // TODO: move this validation to the form context so this is checked only once before the flow is rendered
    if (!isValidIsoLocaleEnumKey(isoLocaleEnumKey)) {
        throw new Error(`Not possible to render the intro screen. IsoLocale key '${isoLocaleEnumKey}' not supported`);
    }
    return IsoLocale[isoLocaleEnumKey];
};
