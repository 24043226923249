import React from 'react';
import styled, { css } from 'styled-components';
import { elevation, colors, borderRadius, spacing, size, typography } from '@izettle/otto';
import { actionFocusMixin } from '@izettle/react';
import { useComboBox } from './hook';
const List = styled.ul `
  background: ${colors.background.default};
  border-radius: ${borderRadius.large};
  box-shadow: ${elevation.level3};
  padding: ${spacing.xxSmall} 0;
  position: absolute;
  overflow: auto;
  margin-top: ${spacing.xxSmall};
  top: ${size.medium};
  width: 100%;
  z-index: 1000;
  display: flex;
  max-height: 40vh;
  flex-direction: column;
  list-style: none;

  ${({ isOpen }) => !isOpen &&
    css `
      display: none;
    `};
`;
const Item = styled.li `
  position: relative;
  text-align: left;
  background: none;
  border: 0;
  cursor: default;
  align-items: center;
  outline: 0;
  padding: ${spacing.xSmall};
  ${typography.regular};
  :active,
  :disabled {
    background: none;
    cursor: default;
    color: ${colors.text.disabled};
  }

  ${({ isHighlighted }) => isHighlighted &&
    css `
      ${actionFocusMixin}

      :before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    `};

  :hover {
    background-color: ${colors.background.groupedSecondary};
  }
`;
const ItemDescription = styled.div `
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: left;
  pointer-events: none;
  ${typography.smallSecondary}
`;
export const Popup = () => {
    const { id, listRef, selectedValue, highlightedIndex, filteredOptions, handleOptionClick, isOpen } = useComboBox();
    if (filteredOptions.length <= 0) {
        return null;
    }
    return (React.createElement(List, { ref: listRef, id: `${id}__listbox`, role: "listbox", "data-element-name": "list", isOpen: isOpen, tabIndex: -1 }, isOpen &&
        filteredOptions.map((option, index) => (React.createElement(Item, { role: "option", id: `${id}__listbox__item__${index}`, isHighlighted: index === highlightedIndex, "aria-selected": option.value === selectedValue, key: option.value, "data-element-name": "list-option", "data-option-index": index, "data-title": option.label, "data-value": option.value, onClick: handleOptionClick },
            option.label,
            option.description && React.createElement(ItemDescription, null, option.description))))));
};
