import React, { useEffect } from 'react';
import { uniqBy } from 'ramda';
import { useOverviewPageContextValue } from '../../Context';
import { errorContentMap } from '../content';
import { useCaptureUnknownErrors } from '../hooks';
import { ErrorContainer } from '../styles';
import { ErrorContent } from './ErrorContent';
const createItemKey = (content) => JSON.stringify(content);
export const ErrorItem = ({ errorFieldName }) => {
    const { errors, updateFieldOptions } = useOverviewPageContextValue();
    const fieldErrors = errors[errorFieldName];
    useCaptureUnknownErrors(fieldErrors);
    useEffect(() => {
        updateFieldOptions(errorFieldName, { canHandleErrors: true });
        return () => updateFieldOptions(errorFieldName, { canHandleErrors: false });
    }, [errorFieldName]);
    if (!Array.isArray(fieldErrors) || fieldErrors.length === 0) {
        return null;
    }
    const errorContentItems = fieldErrors
        // if the key is not in content map, we just attempt to display it as it is
        .map((errorKey) => { var _a; return (_a = errorContentMap[errorKey]) !== null && _a !== void 0 ? _a : { bodyKey: errorKey }; })
        // assign a key to each item for uniqueness check and to be used in React list
        .map((item) => (Object.assign({ key: errorFieldName + createItemKey(item) }, item)));
    const uniqueErrorContentItems = uniqBy((item) => item.key, errorContentItems);
    return (React.createElement(React.Fragment, null, uniqueErrorContentItems.map((item) => (React.createElement(ErrorContainer, { key: item.key },
        React.createElement(ErrorContent, { content: item }))))));
};
