import styled from 'styled-components';
import { borderRadius, colors, spacing, typography } from '@izettle/otto';
import { Text } from '@izettle/react';
export const BackgroundContainer = styled.div `
  background-color: ${colors.message.background.info};
  border-radius: ${borderRadius.medium};
  padding: ${spacing.small};
`;
export const BodyContainer = styled.div `
  &&& {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
`;
export const HeaderContainer = styled.div `
  display: flex;
  margin-bottom: ${spacing.small};
`;
export const HeaderText = styled(Text.Regular) `
  color: ${colors.text.secondary};
  flex: 1;
`;
export const TaxPayerBody = styled(Text.Regular) `
  > p,
  ul > li {
    ${typography.regular}
  }
`;
