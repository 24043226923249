import React from 'react';
import { iZettleMarket } from '@izettle/constants';
import { useEligibleFormContextValue } from 'src/providers/form';
import { useOverviewPageContextValue } from '../Context';
import { Value } from '../Value';
export const PrefilledAddressValue = () => {
    const { formContext: { user: { countryId }, }, } = useEligibleFormContextValue();
    const { stepData: { businessLegalAddress, businessLegalAddressLine2, businessLegalZipCode, businessLegalCity, businessLegalState, }, } = useOverviewPageContextValue();
    const line1 = businessLegalAddress;
    const line2 = businessLegalAddressLine2;
    let line3;
    let line4 = `${businessLegalZipCode} ${businessLegalCity}`;
    if (countryId === iZettleMarket.GB && businessLegalState) {
        line3 = businessLegalState;
    }
    else if (countryId === iZettleMarket.MX && businessLegalState) {
        line4 = `${line4}, ${businessLegalState}`;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Value, null, line1),
        line2 && React.createElement(Value, null, line2),
        line3 && React.createElement(Value, null, line3),
        React.createElement(Value, null, line4)));
};
