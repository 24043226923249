import { useEffect } from 'react';
import { functionsMap } from 'src/schema/functions';
import { useQuestionnaireState } from '../Questionnaire/state';
const invokeFunctionStepFuncs = (stepObject, formData) => {
    return stepObject.functions.reduce((data, functionKey) => {
        const func = functionsMap[functionKey];
        if (!func) {
            throw new Error(`FunctionStep function '${functionKey}' not found`);
        }
        return func(data);
    }, formData);
};
export const FunctionStep = ({ stepObject }) => {
    const { state, dispatch } = useQuestionnaireState();
    useEffect(() => {
        const payload = invokeFunctionStepFuncs(stepObject, state.stepData);
        dispatch({ type: 'completeCurrentStep', payload });
    });
    return null;
};
