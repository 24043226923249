import { addLuhnCheckDigitToNumber, generateIntFromInterval } from '../../helpers';
export const generateOrganisationsnummer = () => {
    const baseNumber = Number([
        generateIntFromInterval(1, 9, 1),
        generateIntFromInterval(1, 9, 1),
        generateIntFromInterval(2000, 9999, 4),
        generateIntFromInterval(1, 999, 3), // control digits
    ].join(''));
    return addLuhnCheckDigitToNumber(baseNumber).toString();
};
export const generatePersonnummer = () => {
    const baseNumber = Number([
        generateIntFromInterval(60, 99, 2),
        generateIntFromInterval(1, 12, 2),
        generateIntFromInterval(1, 28, 2),
        generateIntFromInterval(1, 99, 2),
        generateIntFromInterval(1, 9, 1), // gender identifier
    ].join(''));
    return addLuhnCheckDigitToNumber(baseNumber).toString();
};
// partially based on https://github.com/iZettle/kyc/wiki/KYC-static-test-reports#for-se-use
export const commonData = {
    businessName: 'AB Skuldman & Son',
    businessStreetAddress: 'Box 1396',
    businessPostalCode: '17127',
    businessCity: 'Solna',
    signatoryFirstName: 'Eric',
    signatoryLastName: 'Synman',
    signatoryPhoneNumber: '46771793333',
    signatoryNationality: 'US',
    isSignatoryShareAddress: true,
    businessCategoryId: '244',
    businessSubCategoryId: '250',
    businessLocationTypes: ['PHYSICAL_STORE', 'TEMPORARY_STORE'],
    businessFrequency: 'FEW_TIMES_A_MONTH',
    businessAverageTransaction: '0-500',
    businessIzettleShareOfTurnover: '20-80%',
    businessCurrentAnnualTurnover: '200000-2000000',
};
const signatoryLegalEntityNumber = '0005155676';
const personData = {
    firstName: 'Lars',
    lastName: 'Johansen',
    legalEntityNumber: '4006283974',
    nationality: 'SE',
    streetAddress: 'Drottninggatan 12',
    postalCode: '10001',
    city: 'Stockholm',
};
// Complete generators for each company type
const generateSelfEmployedData = (formContext) => (Object.assign(Object.assign(Object.assign({}, commonData), { businessType: 'SelfEmployed', businessLegalEntityNumber: generatePersonnummer() }), (formContext.form.endsWith('1099K') ? { signatoryUnderUsaTaxLaw: false } : false)));
export const generateLimitedCompanyData = () => (Object.assign(Object.assign({}, commonData), { businessType: 'LimitedCompany', businessLegalEntityNumber: generateOrganisationsnummer(), signatoryLegalEntityNumber, legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': personData,
    }, roleDirectors: ['495953a4-6706-4604-aa87-5373bb9b7bda', 'SIGNATORY'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
export const generatePartnershipData = () => (Object.assign(Object.assign({}, commonData), { businessType: 'Partnership', businessLegalEntityNumber: generateOrganisationsnummer(), signatoryLegalEntityNumber, legalPersons: {
        'a023b5da-0dd9-4f82-95e4-d2983ba03d85': personData,
    }, rolePartners: ['SIGNATORY', 'a023b5da-0dd9-4f82-95e4-d2983ba03d85'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', 'a023b5da-0dd9-4f82-95e4-d2983ba03d85'] }));
export const generateNonProfitData = () => (Object.assign(Object.assign({}, commonData), { businessType: 'NonProfit', businessLegalEntityNumber: generateOrganisationsnummer(), signatoryLegalEntityNumber, legalPersons: {
        'a251e1e4-cfd8-41db-aa7b-60166375f746': personData,
    }, roleDirectors: ['a251e1e4-cfd8-41db-aa7b-60166375f746', 'SIGNATORY'], roleAlternativeOwners: ['SIGNATORY', 'a251e1e4-cfd8-41db-aa7b-60166375f746'] }));
export const SE = [
    {
        title: 'Self Employed',
        generateFunc: generateSelfEmployedData,
    },
    {
        title: 'Limited Company',
        generateFunc: generateLimitedCompanyData,
    },
    {
        title: 'Partnership',
        generateFunc: generatePartnershipData,
    },
    {
        title: 'NonProfit',
        generateFunc: generateNonProfitData,
    },
];
