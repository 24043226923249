import React, { useContext, useRef, useState } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { IconTrashMedium, IconEditMedium } from '@izettle/otto';
import { CheckedInputContainer, ContentContainer, Description, HiddenInput, Title, useCheckedInputKeyboardListener, } from '../CheckedInputBase';
import { useCheckedInputAriaProps } from '../CheckedInputBase/use-aria-props';
import { ActionIconButton } from './action-icon-button';
import { ActionsContainer } from './actions-container';
import { CheckboxGroupContext } from './context';
import { Tick } from './tick';
const noop = () => {
    // noop
};
export const Checkbox = ({ value, title, description, editable, removable, disabled, onClickEdit, onClickRemove, }) => {
    const [hasKeyboardFocus, setHasKeyboardFocus] = useState(false);
    const { values: selectedValues, name, onChange, errorElementId } = useContext(CheckboxGroupContext);
    const { onKeyDown } = useCheckedInputKeyboardListener();
    const inputRef = useRef(null);
    const { text } = useLocalization();
    const checked = selectedValues.includes(value);
    const aria = useCheckedInputAriaProps({ checked, description, errorElementId });
    return (React.createElement(CheckedInputContainer
    // tabIndex=-1 is only needed to capture focus with clicks
    // when keyboard focus already present, it should not be set,
    // so that screen readers reads <legend> tag for RadioGroup fieldset
    , { 
        // tabIndex=-1 is only needed to capture focus with clicks
        // when keyboard focus already present, it should not be set,
        // so that screen readers reads <legend> tag for RadioGroup fieldset
        tabIndex: hasKeyboardFocus ? undefined : -1, checked: checked, disabled: disabled, "data-value": value, hasKeyboardFocus: hasKeyboardFocus, onClick: () => {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
        }, onKeyDown: onKeyDown },
        React.createElement(HiddenInput, { ref: inputRef, checked: checked, disabled: disabled, id: name, name: name, type: "checkbox", value: value, onChange: disabled ? noop : onChange, "aria-labelledby": aria.titleId }),
        React.createElement(Tick, Object.assign({ className: "focusTarget", role: "checkbox", tabIndex: 0, checked: checked, disabled: disabled, "data-value": value, onFocus: (e) => {
                if (e.currentTarget === e.target) {
                    setHasKeyboardFocus(true);
                }
            }, onBlur: (e) => {
                if (e.currentTarget === e.target) {
                    setHasKeyboardFocus(false);
                }
            } }, aria.attrs)),
        React.createElement(ContentContainer, null,
            React.createElement(Title, { id: aria.titleId, disabled: disabled, checked: checked }, title),
            description && (React.createElement(Description, { id: aria.descriptionId, disabled: disabled, checked: checked }, description))),
        React.createElement(ActionsContainer, null,
            editable && (React.createElement(ActionIconButton, { disabled: disabled, title: text('EDIT_BUTTON_TITLE'), "data-action": "edit", svgIcon: IconEditMedium, variant: "primary", onKeyDown: (e) => e.stopPropagation(), onClick: () => onClickEdit === null || onClickEdit === void 0 ? void 0 : onClickEdit(value) })),
            removable && (React.createElement(ActionIconButton, { disabled: disabled, title: text('REMOVE_BUTTON_TITLE'), "data-action": "remove", svgIcon: IconTrashMedium, variant: "destructive", onKeyDown: (e) => e.stopPropagation(), onClick: () => onClickRemove === null || onClickRemove === void 0 ? void 0 : onClickRemove(value) })))));
};
