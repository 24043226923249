import { IsoLocale } from '@izettle/constants';
import ownerStructureHelpSvg from 'src/assets/owner-structure-help.svg';
import { nationalityOptions } from '../../common/nationality-options';
import { RoleFieldName } from '../../common/owner-structure';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { step, sequence, render, decision } from '../../steps-builder';
import { template } from '../../template';
import { introScreenTemplate, legalPeoplePreparationScreenTemplate, alternativeOwnersListTemplate, directorsListTemplate, normalizeSignatoryFieldsFunction, initLegalPersonsFunction, overviewPageTemplate, hasUBOTemplate, beneficialOwnersListTemplate, } from './common/steps/limited-company';
import { signatoryNameTemplate, signatoryPhoneNumberTemplate, signatoryNationalityTemplate, signatoryNamePrefilledTemplate, } from './common/steps/signatory';
import { SIGNATORY_ACTION } from './data';
const introScreenSE = introScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            items: [
                {
                    icon: 'Person',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                },
                {
                    icon: 'People',
                    title: translate('GAP_PARTNERSHIP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION_PRN'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('GAP_AUTO_VERIFICATION_PREPARATION_SCREEN_ITEM_VERIFICATION_TITLE'),
                    description: translate('GAP_PARTNERSHIP_PREPARATION_SCREEN_ITEM_VERIFICATION_DESCRIPTION'),
                },
            ],
        },
    },
}));
const legalPeoplePreparationScreenSE = legalPeoplePreparationScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_SE_BOLAGSVERKET_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
            items: [
                {
                    icon: 'People',
                    description: translate('GAP_PARTNERSHIP_LEGAL_PEOPLE_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
            ],
        },
    },
}));
const personStep = (translate) => ({
    ID: 'personStepId',
    backendValidation: true,
    fields: ['firstName', 'lastName', 'legalEntityNumber', 'nationality', 'streetAddress', 'postalCode', 'city'],
    JSONSchema: {
        type: 'object',
        properties: {
            nameTitle: {
                title: translate('PERSON_NAME_LABEL'),
                type: 'null',
            },
            firstName: {
                type: 'string',
            },
            lastName: {
                type: 'string',
            },
            legalEntityNumberQuestion: {
                title: translate('PERSON_LEGAL_ENTITY_NUMBER_SSN_LABEL'),
                type: 'null',
            },
            legalEntityNumber: {
                type: 'string',
            },
            nationalityTitle: {
                title: translate('PERSON_NATIONALITY_LABEL'),
                type: 'null',
            },
            nationality: {
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
            addressTitle: {
                title: translate('PERSON_HOME_ADDRESS_LABEL'),
                type: 'null',
            },
            streetAddress: {
                type: 'string',
            },
            postalCode: {
                type: 'string',
            },
            city: {
                type: 'string',
            },
        },
    },
    UISchema: {
        firstName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_LABEL'),
            },
        },
        lastName: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('LAST_NAME_LABEL'),
            },
        },
        legalEntityNumber: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:placeholder': "\u00C5\u00C5MMDD-NNNN" /* CopySE.SOCIAL_SECURITY_NUMBER_PLACEHOLDER */,
        },
        nationality: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:widget': 'CustomComboBoxWidget',
            'ui:emptyValue': '',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
            'ui:options': {
                sortByLabels: 'asc',
            },
        },
        streetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        postalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        city: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'newPerson',
    },
});
const alternativeOwnersListSE = alternativeOwnersListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_SE_BOLAGSVERKET_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const beneficialOwnersListSE = beneficialOwnersListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_SE_BOLAGSVERKET_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
/* eslint-disable sonarjs/no-identical-functions */
const directorsListSE = directorsListTemplate(personStep).overrideDeep(({ translate }) => ({
    fields: ['rolePartners'],
    applicationVariables: {
        stepName: 'partnersList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.rolePartners,
        signatoryRoleLabel: translate('GAP_OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        content: {
            title: translate('OWNER_STRUCTURE_PARTNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_PARTNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_SE_BOLAGSVERKET_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const overviewPageSE = overviewPageTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        version: 'GAP_SE_PARTNERSHIP',
        content: {
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(IsoLocale.svSE)]),
        },
    },
}));
const signatoryLegalEntityNumber = template(({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryLegalEntityNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryLegalEntityNumber: {
                title: translate('GAP_SIGNATORY_LEGAL_ENTITY_NUMBER_SSN_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryLegalEntityNumber: {
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': "\u00C5\u00C5MMDD-NNNN" /* CopySE.SOCIAL_SECURITY_NUMBER_PLACEHOLDER */,
        },
    },
    applicationVariables: {
        stepName: 'signatoryLegalEntityNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_SSN_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_SSN_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
}));
const signatoryAddressSE = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('GAP_SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
});
const signatoryPhoneNumberSE = signatoryPhoneNumberTemplate.extendDeep(({ translate }) => ({
    UISchema: {
        signatoryPhoneNumber: {
            'ui:placeholder': translate('FORM_EXAMPLE', ["070-12 34 567" /* CopySE.SIGNATORY_PHONE_NUMBER_EXAMPLE */]),
        },
    },
}));
/* eslint-disable sonarjs/no-identical-functions */
const hasUBOSE = hasUBOTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_SE_BOLAGSVERKET_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const newSignatorySequence = [
    step(signatoryNameTemplate),
    step(signatoryLegalEntityNumber),
    step(signatoryPhoneNumberSE),
    step(signatoryAddressSE),
    step(signatoryNationalityTemplate),
];
const existingSignatorySequence = [
    step(signatoryAddressSE),
    step(signatoryNationalityTemplate),
];
const rootSequence = sequence([
    step(introScreenSE),
    decision('signatoryAction', signatoryNamePrefilledTemplate, [
        { when: SIGNATORY_ACTION.CHANGE_SIGNATORY, branch: sequence(newSignatorySequence) },
        { when: SIGNATORY_ACTION.SET_NATIONALITY, branch: sequence(existingSignatorySequence) },
    ]),
    step(normalizeSignatoryFieldsFunction),
    step(legalPeoplePreparationScreenSE),
    step(initLegalPersonsFunction),
    step(directorsListSE),
    decision('hasUBO', hasUBOSE, [
        { when: true, branch: step(beneficialOwnersListSE) },
        { when: false, branch: step(alternativeOwnersListSE) },
    ]),
    step(overviewPageSE),
]);
export const GAP_SE_PARTNERSHIP = (context) => render(rootSequence, context);
