import { useCallback } from 'react';
import { usePopupsState } from 'src/components/Popups/state';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { addBreadcrumb, DatadogRumBreadcrumbCategory } from 'src/tools/datadog-rum';
export const useQuestionnaireEditMode = () => {
    const popups = usePopupsState();
    const questionnaire = useQuestionnaireState();
    const startEditMode = useCallback((fieldName, options) => {
        popups.dispatch({
            type: 'displayPopup',
            payload: {
                popupName: 'QuestionnaireEditMode',
                params: {
                    fieldName,
                    options,
                    questionnaireState: questionnaire.state,
                    /*
                      Be careful while accessing variables from the outside scope within this function.
                      The values might get stale as this callback is stored in the state alongside the other popup properties.
                    */
                    onEditComplete: (updatedStepData) => {
                        popups.dispatch({ type: 'hidePopup' });
                        // Once edit is complete, the modified version of StepData is applied to the main Questionnaire state.
                        questionnaire.dispatch({ type: 'applyEdit', payload: { stepData: updatedStepData } });
                        addBreadcrumb({ category: DatadogRumBreadcrumbCategory.EditModeOff });
                    },
                },
            },
        });
        addBreadcrumb({ category: DatadogRumBreadcrumbCategory.EditModeOn, fieldName });
    }, [questionnaire.state, questionnaire.dispatch, popups.dispatch]);
    const cancelEditMode = useCallback(() => {
        if (!questionnaire.state.editMode) {
            throw new Error('Cannot cancel edit mode when not in edit mode');
        }
        popups.dispatch({ type: 'hidePopup' });
        addBreadcrumb({ category: DatadogRumBreadcrumbCategory.EditModeOff });
    }, [questionnaire.state.editMode]);
    return {
        startEditMode,
        cancelEditMode,
    };
};
