import React from 'react';
import styled from 'styled-components';
import { withHidden } from 'src/hoc/with-hidden';
import { PopupsStateConsumer, PopupsStateProvider } from '../state';
import { PopupsStack } from './Stack';
const HidableContainer = withHidden(styled.div ``);
export const PopupsContainer = ({ children }) => {
    return (React.createElement(PopupsStateProvider, null,
        React.createElement(PopupsStateConsumer, null, ({ state }) => (React.createElement(React.Fragment, null,
            React.createElement(PopupsStack, { popups: state.popups }),
            React.createElement(HidableContainer, { hidden: state.popups.length > 0 }, children))))));
};
