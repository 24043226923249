var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Ajv from 'ajv';
import { config } from 'src/config';
import { makeOAuthRequest, makeTracedRequest } from 'src/utils';
import { FormStatus } from './types';
// TODO: Also cover localisationEndpoint once backend is done with the changes
const validateFormContext = (data) => {
    const user = {
        type: 'object',
        properties: {
            emailAddress: {
                type: 'string',
            },
            countryId: {
                type: 'string',
            },
            organizationUuid: {
                type: 'string',
            },
            languageId: {
                type: 'string',
            },
            timeZoneId: {
                type: 'string',
            },
            betaFeatures: {
                type: 'array',
                items: {
                    type: 'string',
                },
            },
        },
        required: ['emailAddress', 'countryId', 'organizationUuid', 'languageId', 'timeZoneId', 'betaFeatures'],
    };
    const tracking = {
        type: 'object',
        properties: {
            enabled: {
                type: 'boolean',
            },
        },
        required: ['enabled'],
    };
    const ineligibleSchema = {
        type: 'object',
        properties: {
            status: {
                type: 'string',
                enum: ['INELIGIBLE'],
            },
            user,
        },
        required: ['status', 'user'],
    };
    const documentUploadSchema = {
        type: 'object',
        properties: {
            status: {
                type: 'string',
                enum: ['DOCUMENT_UPLOAD', 'PROCESSING'],
            },
            user,
            tracking,
        },
        required: ['status', 'user', 'tracking'],
    };
    const eligibleSchema = {
        type: 'object',
        properties: {
            form: {
                type: 'string',
            },
            status: {
                type: 'string',
                enum: ['ONBOARDING', 'GAPS'],
            },
            submitPath: {
                type: 'string',
            },
            stepValidationPath: {
                type: 'string',
            },
            user,
            tracking,
        },
        required: ['form', 'status', 'submitPath', 'stepValidationPath', 'user', 'tracking'],
    };
    const validateEligible = new Ajv().compile(eligibleSchema);
    const validateIneligible = new Ajv().compile(ineligibleSchema);
    const validateDocumentUpload = new Ajv().compile(documentUploadSchema);
    if (!validateEligible(data) && !validateIneligible(data) && !validateDocumentUpload(data)) {
        throw new Error('Invalid FormContext response');
    }
    return data;
};
const validateBusinessCategoriesResponseData = (data) => {
    const categoriesListSchema = {
        definitions: {
            category: {
                type: 'object',
                required: ['name', 'code', 'children'],
                properties: {
                    name: {
                        type: 'string',
                    },
                    code: {
                        type: 'integer',
                    },
                    children: {
                        type: 'array',
                        items: { $ref: '#/definitions/category' },
                    },
                },
            },
        },
        type: 'array',
        items: { $ref: '#/definitions/category' },
    };
    const validate = new Ajv().compile(categoriesListSchema);
    if (!validate(data)) {
        throw new Error('Invalid business categories data');
    }
    return data;
};
export const fetchBusinessCategories = (formContext) => __awaiter(void 0, void 0, void 0, function* () {
    if (formContext.status !== FormStatus.ONBOARDING) {
        return formContext;
    }
    return makeTracedRequest({
        method: 'GET',
        url: `${config.api.sun}/resources/business_types?country_id=${formContext.user.countryId}&language_id=${formContext.user.languageId}`,
        timeout: config.axios.timeout,
    }, 'fetchBusinessCategories')
        .then((response) => response.data)
        .then((responseData) => responseData.data)
        .then(validateBusinessCategoriesResponseData)
        .then((businessCategories) => {
        formContext.additionalData = {
            businessCategories,
        };
        return formContext;
    })
        .catch((error) => {
        throw new Error(`Failed to fetch categories for form context. ${error.message}`);
    });
});
export const fetchFormContext = (authClient) => {
    const axiosConfig = {
        method: 'GET',
        url: `${config.api.registration}/kyc-form/context`,
    };
    return makeOAuthRequest(axiosConfig, authClient, 'fetchFormContext')
        .then((response) => response.data)
        .then(validateFormContext)
        .catch((error) => {
        if (error.response && error.response.status === 403) {
            throw new Error('Forbidden status received.');
        }
        error.message = `Failed to fetch form context. ${error.message}`;
        throw error;
    });
};
