const STORAGE_KEY = 'redirect-to';
const getStorageKey = (organizationUuid) => `${STORAGE_KEY}:${organizationUuid}`;
export const useSaveRedirectTo = () => {
    const loadRedirectTo = (organizationUuid) => {
        var _a;
        const storageKey = getStorageKey(organizationUuid);
        return (_a = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(storageKey)) !== null && _a !== void 0 ? _a : null;
    };
    const trySaveRedirectTo = (organizationUuid) => {
        const redirectTo = new URLSearchParams(window.location.search).get('redirectTo');
        if (!redirectTo) {
            return;
        }
        const storageKey = getStorageKey(organizationUuid);
        localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem(storageKey, redirectTo);
    };
    return { loadRedirectTo, trySaveRedirectTo };
};
