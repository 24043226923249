import React from 'react';
import { useMarkdown, useLocalization } from '@izettle/lingo-client';
import { LogoutButton } from 'src/components/Logout';
import { useRenderFieldError } from 'src/hooks/use-render-field-error';
import { useSubstitute } from 'src/hooks/use-substitute';
import { ErrorContentContainer, ErrorContentTitle } from '../styles';
export const ErrorContent = ({ content }) => {
    const { text } = useLocalization();
    const { substitute } = useSubstitute();
    const { hasLogoutButton } = useRenderFieldError();
    const { mdElement } = useMarkdown();
    const { titleKey, bodyKey, substitutions } = content;
    return (React.createElement(ErrorContentContainer, null,
        titleKey ? React.createElement(ErrorContentTitle, null, text(titleKey)) : null,
        mdElement(bodyKey, {
            replacements: substitutions === null || substitutions === void 0 ? void 0 : substitutions.map(substitute),
        }),
        hasLogoutButton(bodyKey) ? React.createElement(LogoutButton, { variant: "secondary" }) : null));
};
