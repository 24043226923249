import { datadogRum } from '@datadog/browser-rum';
export var DatadogRumBreadcrumbCategory;
(function (DatadogRumBreadcrumbCategory) {
    DatadogRumBreadcrumbCategory["StepDisplayed"] = "kyc.stepDisplayed";
    DatadogRumBreadcrumbCategory["EditModeOn"] = "kyc.editModeOn";
    DatadogRumBreadcrumbCategory["EditModeOff"] = "kyc.editModeOff";
    DatadogRumBreadcrumbCategory["PopupDisplayed"] = "kyc.popupDisplayed";
    DatadogRumBreadcrumbCategory["PopupHidden"] = "kyc.popupHidden";
})(DatadogRumBreadcrumbCategory || (DatadogRumBreadcrumbCategory = {}));
export const addBreadcrumb = (data) => {
    const { category } = data;
    switch (data.category) {
        case DatadogRumBreadcrumbCategory.StepDisplayed: {
            datadogRum.addAction(`Step ${data.stepObject.ID} displayed`, {
                category,
            });
            break;
        }
        case DatadogRumBreadcrumbCategory.EditModeOn: {
            datadogRum.addAction('Edit mode on', {
                category,
                data: { fieldName: data.fieldName },
            });
            break;
        }
        case DatadogRumBreadcrumbCategory.EditModeOff: {
            datadogRum.addAction('Edit mode off', {
                category,
            });
            break;
        }
        case DatadogRumBreadcrumbCategory.PopupDisplayed: {
            datadogRum.addAction(`Popup '${data.popupName}' displayed`, {
                category,
            });
            break;
        }
        case DatadogRumBreadcrumbCategory.PopupHidden: {
            const currentStateMessage = data.nextPopup
                ? `Displaying '${data.nextPopup.popupName}' popup.`
                : 'No more popups to display.';
            datadogRum.addAction(`Top popup '${data.hiddenPopup.popupName}' hidden. ${currentStateMessage}`, {
                category,
            });
            break;
        }
        default:
            throw new Error(`Unhandled breadcrumb category: ${category}`);
    }
};
