import { useEffect, useState } from 'react';
import { useEvent, useLocalStorage } from 'react-use';
import { PPUI_THEME_CLASSNAME } from 'src/theme';
export const useThemeInfo = () => {
    const [paypalTheme, setPaypalTheme] = useState(false);
    const [enabled] = useLocalStorage('ppui-theme', document.body.classList.contains('theme-ppui')) || '';
    const triggerEvent = (key) => {
        var _a;
        const triggerEventElement = (_a = key === null || key === void 0 ? void 0 : key.target) === null || _a === void 0 ? void 0 : _a.className;
        if (triggerEventElement !== undefined &&
            triggerEventElement !== '' &&
            triggerEventElement === 'theme-enable-option') {
            const theme = localStorage.getItem('ppui-theme');
            setPaypalTheme(theme === '"true"');
        }
    };
    const enablePaypalTheme = () => {
        localStorage.setItem('ppui-theme', '"true"');
        document.body.classList.add(PPUI_THEME_CLASSNAME);
        setPaypalTheme(true);
    };
    useEvent('click', triggerEvent);
    useEffect(() => {
        setPaypalTheme(localStorage.getItem('ppui-theme') === '"true"');
    }, [enabled]);
    return { paypalTheme, enabled, enablePaypalTheme };
};
