import { useMemo } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useEligibleFormContextValue } from 'src/providers/form';
import { createSubstitute } from './substitute';
export const useSubstitute = () => {
    const { isoLocale, text } = useLocalization();
    const { formContext: { user: { countryId }, }, } = useEligibleFormContextValue();
    const substitute = useMemo(() => createSubstitute({ locale: isoLocale, countryId, text }), [isoLocale, countryId, text]);
    return { substitute };
};
