import styled, { css } from 'styled-components';
import { spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
export const Value = styled(Text.Regular).attrs({ marginBottom: 'none', marginTop: 'none', 'data-hj-suppress': '' }) `
  color: inherit;

  ${(props) => props.spaceBetween &&
    css `
      margin-bottom: ${spacing.xxSmall};

      &:last-child {
        margin-bottom: 0;
      }
    `}
`;
