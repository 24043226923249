import React, { useMemo } from 'react';
import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { SelectOption, Select, InputSize } from '@izettle/react';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { useEligibleFormContextValue } from 'src/providers/form';
const fetchPresets = (formKey) => {
    if (!formKey) {
        return undefined;
    }
    // TODO: remove _1099K replacement once all the temporary flows have been removed
    const directoryName = formKey.replace('_1099K', '');
    const presetPath = `./${directoryName}/index.ts`;
    const requireContext = require.context('./presets', true, /[a-zA-Z_0-9].\/index\.ts$/);
    if (!requireContext.keys().includes(presetPath)) {
        // no presets file found
        return undefined;
    }
    return requireContext(presetPath)[formKey];
};
const SelectContainer = styled.div `
  margin-bottom: ${spacing.xxSmall};
`;
export const Autofill = ({ closeDebugger }) => {
    var _a;
    const { formContext } = useEligibleFormContextValue();
    const { dispatch } = useQuestionnaireState();
    const autofillPresets = useMemo(() => fetchPresets(formContext.form), [formContext.form]);
    if (!autofillPresets) {
        return (React.createElement(React.Fragment, null,
            "There are no presets available for the ",
            React.createElement("strong", null,
                "\"", (_a = formContext.form) !== null && _a !== void 0 ? _a : '<empty>',
                "\""),
            " form key"));
    }
    const handleOnChange = (value) => {
        const autofillPreset = autofillPresets.find((preset) => preset.title === value);
        if (autofillPreset) {
            dispatch({
                type: 'prefillForm',
                payload: {
                    data: autofillPreset.generateFunc(formContext),
                },
            });
            closeDebugger();
        }
    };
    return (React.createElement(SelectContainer, null,
        React.createElement("div", null,
            "Presets for ",
            React.createElement("strong", null,
                "\"",
                formContext.form,
                "\""),
            ":"),
        React.createElement(Select, { id: "autofill-select", textSubmit: "Done", textInputButton: "Toggle", size: InputSize.Small, onChange: handleOnChange }, autofillPresets.map((preset) => (React.createElement(SelectOption, { key: preset.title, value: preset.title, text: preset.title }))))));
};
