import { buildSelfEmployedUsaTaxpayerSequence } from 'src/schema/common/usaTaxpayerDetails/schema';
import { BusinessType } from 'src/utils/country/IT/data';
import { buildCategorySteps } from '../../common/categories';
import { sequence, step, render, conditional, decision, customStepBuilder } from '../../steps-builder';
import * as stepTemplates from './flow-step-templates';
const root = sequence([
    // BUSINESS TYPE
    step(stepTemplates.businessType),
    step(stepTemplates.mapBusinessTypeFunction),
    // intro prep screen
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(stepTemplates.prepScreenSelfEmployed),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.Partnership, BusinessType.NonProfit],
            branch: step(stepTemplates.prepScreenGeneral),
        },
    ]),
    // businessLegalEntityNumber
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed, BusinessType.NonProfit],
            branch: step(stepTemplates.businessLegalEntityNumberSelfEmployedNonProfit),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.Partnership],
            branch: step(stepTemplates.businessLegalEntityNumberLimitedPartnership),
        },
    ]),
    // businessName
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(stepTemplates.businessNameSelfEmployed),
        },
        {
            when: [BusinessType.NonProfit],
            branch: step(stepTemplates.businessNameNonProfit),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.Partnership],
            branch: step(stepTemplates.businessNameLimitedPartnership),
        },
    ]),
    // businessAddress
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed, BusinessType.NonProfit],
            branch: step(stepTemplates.businessAddressSelfEmployedNonProfit),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.Partnership],
            branch: step(stepTemplates.businessAddressLimitedPartnership),
        },
    ]),
    // PERSONAL DETAILS
    step(stepTemplates.personalDetailsIntro),
    step(stepTemplates.signatoryName),
    step(stepTemplates.signatoryDOB),
    step(stepTemplates.signatoryNationality),
    customStepBuilder(buildSelfEmployedUsaTaxpayerSequence('businessType', 'SelfEmployed')),
    decision('isSignatoryShareAddress', stepTemplates.isSignatoryShareAddress, [
        { when: false, branch: step(stepTemplates.signatoryAddress) },
    ]),
    step(stepTemplates.signatoryPhoneNumber),
    // OWNER STRUCTURE
    conditional('businessType', [
        {
            when: [BusinessType.LimitedCompany],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.ownerStructureIntro),
                step(stepTemplates.directorsListLimited),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.beneficialOwners) },
                    { when: false, branch: step(stepTemplates.alternativeOwnersListLimitedPartnership) },
                ]),
            ]),
        },
        {
            when: [BusinessType.Partnership],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.ownerStructureIntro),
                step(stepTemplates.partnersList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.beneficialOwners) },
                    { when: false, branch: step(stepTemplates.alternativeOwnersListLimitedPartnership) },
                ]),
            ]),
        },
        {
            when: [BusinessType.NonProfit],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.ownerStructureIntro),
                step(stepTemplates.directorsListNonProfit),
                step(stepTemplates.alternativeOwnersListNonProfit),
            ]),
        },
    ]),
    // USAGE OF ZETTLE
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(stepTemplates.usageOfIZettleIntroSelfEmployed),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit, BusinessType.Partnership],
            branch: step(stepTemplates.usageOfIZettleIntroGeneral),
        },
    ]),
    customStepBuilder(buildCategorySteps),
    step(stepTemplates.businessLocationsTypes),
    step(stepTemplates.businessFrequency),
    step(stepTemplates.businessAverageTransaction),
    step(stepTemplates.businessIzettleShareOfTurnover),
    decision('businessCurrentAnnualTurnover', stepTemplates.businessCurrentAnnualTurnover, [
        { when: 'NEW_BUSINESS', branch: step(stepTemplates.businessExpectedAnnualTurnover) },
    ]),
    // OVERVIEW
    step(stepTemplates.overviewPage),
]);
export const IT = (context) => render(root, context);
