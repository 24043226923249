import React from 'react';
import styled from 'styled-components';
import { colors, spacing, borderRadius } from '@izettle/otto';
import { Text } from '@izettle/react';
const SectionContainer = styled.div `
  margin-bottom: ${spacing.large};

  &:last-child {
    margin-bottom: 0;
  }
`;
const BoxContainer = styled.div `
  background: ${colors.background.groupedPrimary};
  border-radius: ${borderRadius.medium};
  padding: ${spacing.small};
`;
export const Section = ({ name, title, children }) => {
    return (React.createElement(SectionContainer, { "data-section-name": name },
        React.createElement(Text.LargeBold, { marginTop: "none", marginBottom: "xxSmall" }, title),
        React.createElement(BoxContainer, null, children)));
};
