export const signatoryUnderUsaTaxLaw = ({ translate }) => ({
    fields: ['signatoryUnderUsaTaxLaw'],
    JSONSchema: {
        required: ['signatoryUnderUsaTaxLaw'],
        type: 'object',
        properties: {
            signatoryUnderUsaTaxLaw: {
                title: translate('SIGNATORY_UNDER_USA_TAX_LAWS_LABEL'),
                description: translate('SIGNATORY_UNDER_USA_TAX_LAWS_DESCRIPTION'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('SIGNATORY_UNDER_USA_TAX_LAWS_VALUE_YES') },
                    { enum: [false], title: translate('COMMON_NO_ANSWER') },
                ],
            },
        },
    },
    UISchema: {
        signatoryUnderUsaTaxLaw: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'signatoryUnderUsaTaxLaw',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_UNDER_USA_TAX_LAWS_HELP_TEXT_WHY_ASKING_THIS_TITLE'),
                    body: translate('SIGNATORY_UNDER_USA_TAX_LAWS_HELP_TEXT_WHY_ASKING_THIS_BODY'),
                },
                {
                    title: translate('SIGNATORY_UNDER_USA_TAX_LAWS_HELP_TEXT_WHO_IS_CONSIDERED_USA_PERSON_TITLE'),
                    body: translate('SIGNATORY_UNDER_USA_TAX_LAWS_HELP_TEXT_WHO_IS_CONSIDERED_USA_PERSON_BODY'),
                },
            ],
        },
    },
});
export const signatoryUsaTaxNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryUsaTaxNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryUsaTaxNumber: {
                title: translate('SIGNATORY_USA_TAX_NUMBER_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryUsaTaxNumber: {
            'ui:options': {
                mask: '999-99-9999',
            },
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:placeholder': 'XXX-XX-XXXX',
        },
    },
    applicationVariables: {
        stepName: 'signatoryUsaTaxNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_USA_TAX_NUMBER_HELP_TEXT_WHY_TAX_NUMBER_TITLE'),
                    body: translate('SIGNATORY_USA_TAX_NUMBER_HELP_TEXT_WHY_TAX_NUMBER_BODY'),
                },
            ],
        },
    },
});
export const signatorySubjectToUsaBackupWithholding = ({ translate }) => ({
    fields: ['signatorySubjectToUsaBackupWithholding'],
    JSONSchema: {
        required: ['signatorySubjectToUsaBackupWithholding'],
        type: 'object',
        properties: {
            signatorySubjectToUsaBackupWithholding: {
                title: translate('SIGNATORY_SUBJECT_TO_USA_BACKUP_WITHHOLDING_LABEL'),
                description: translate('SIGNATORY_SUBJECT_TO_USA_BACKUP_WITHHOLDING_DESCRIPTION'),
                type: 'boolean',
                oneOf: [
                    { enum: [false], title: translate('SIGNATORY_SUBJECT_TO_USA_BACKUP_WITHHOLDING_VALUE_NO') },
                    { enum: [true], title: translate('COMMON_YES_ANSWER') },
                ],
            },
        },
    },
    UISchema: {
        signatorySubjectToUsaBackupWithholding: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'signatorySubjectToUsaBackupWithholding',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SUBJECT_TO_USA_BACKUP_WITHHOLDING_HELP_TEXT_WHAT_BACKUP_WITHHOLDING_MEAN_TITLE'),
                    body: translate('SUBJECT_TO_USA_BACKUP_WITHHOLDING_HELP_TEXT_WHAT_BACKUP_WITHHOLDING_MEAN_BODY'),
                },
            ],
        },
    },
});
