import { createGlobalStyle } from 'styled-components';
import { spacing } from '@izettle/otto';
import { breakpoints } from 'src/theme';
export const UjetLauncherStyle = createGlobalStyle `
#ujet-launcher {
  z-index: 100 !important;
  overflow: hidden;

  transform: scale(0.75);
  transform-origin: right bottom;

  right: ${spacing.xSmall} !important;
  bottom: ${spacing.xSmall} !important;

  &>iframe {
    // ensure icon doesn't get too big
    max-height: min(25vh, 25vw);
    max-width: min(25vh, 25vw);
  }

  @media (min-width: ${breakpoints.tablet}) {
    transform: unset;
    right: ${spacing.medium} !important;
    bottom: ${spacing.medium} !important;
  }
}
`;
