import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { IconEditMedium } from '@izettle/otto';
import { Button, Text } from '@izettle/react';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { useQuestionnaireEditMode } from 'src/hooks/use-questionnaire-edit-mode';
import { SmallMarginBottom } from '../../../styles';
export const BackupWithholdingItem = ({ termContent, isCrossedOut, }) => {
    const { startEditMode } = useQuestionnaireEditMode();
    const { text } = useLocalization();
    const { state: { stepData }, } = useQuestionnaireState();
    const finalTermContent = isCrossedOut ? `<s>${termContent}</s>` : termContent;
    return (React.createElement(React.Fragment, null,
        React.createElement(Text.Regular, { dangerouslySetInnerHTML: { __html: finalTermContent } }),
        React.createElement(SmallMarginBottom, null,
            React.createElement(Button, { svgIcon: IconEditMedium, iconPosition: "left", variant: "inline", noPadding: true, onClick: () => startEditMode(stepData.businessType === 'SelfEmployed'
                    ? 'signatorySubjectToUsaBackupWithholding'
                    : 'businessSubjectToUsaBackupWithholding') }, text('OVERVIEW_USA_TAXPAYER_CERTIFICATION_CHANGE_BACKUP_WITHHOLDING_STATUS_BUTTON')))));
};
