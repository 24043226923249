export const getOptionTitleByValue = (options, value) => {
    const foundOption = options.find((option) => option.value === value);
    if (!foundOption) {
        return '';
    }
    return foundOption.label;
};
export const getOptionValueByIndex = (options, index) => {
    const option = options[index];
    if (!option) {
        return undefined;
    }
    return option.value;
};
export const filterOptionsByLabel = (options, query, locale) => {
    if (!query) {
        return options;
    }
    // sensitivity=base: Only strings that differ in base letters compare as unequal. Examples: a ≠ b, a = á, a = A.
    // good for this kind of filters
    // more on MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator
    const collator = new Intl.Collator(locale, { sensitivity: 'base' });
    // truncating labels to length of query string before comparison
    return options.filter(({ label, description }) => collator.compare(label.substr(0, query.length), query) === 0 ||
        (description && collator.compare(description.substr(0, query.length), query) === 0));
};
/*
 * Returns a ComboBoxOption when the given value matches the label of any of the options from the list
 * @param options - List of options to be checked
 * @param label - Value to be compared while checking the labels of the options
 * @param locale - Locale used as reference when comparing the strings
 */
export const findMatchingOptionByLabel = (options, label, locale) => {
    const collator = new Intl.Collator(locale, { sensitivity: 'base' });
    return options.find((option) => collator.compare(label, option.label) === 0);
};
export const scrollToOptionIfNeeded = (listRef, index) => {
    if (!listRef.current) {
        return;
    }
    const currentListRef = listRef.current;
    if (currentListRef.scrollHeight > currentListRef.clientHeight) {
        const scrollBottom = Number(currentListRef.clientHeight) + Number(currentListRef.scrollTop);
        const option = listRef.current.querySelector(`[data-option-index="${index}"]`);
        const optionBottom = option.offsetTop + option.offsetHeight;
        if (optionBottom > scrollBottom) {
            currentListRef.scrollTop = optionBottom - currentListRef.clientHeight;
        }
        else if (option.offsetTop < currentListRef.scrollTop) {
            currentListRef.scrollTop = option.offsetTop;
        }
    }
};
export const scrollTop = (listRef) => {
    if (!listRef.current) {
        return;
    }
    const currentListRef = listRef.current;
    currentListRef.scrollTop = 0;
};
