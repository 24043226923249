import React, { useEffect, useState, useMemo } from 'react';
import { isEmpty } from 'ramda';
import { useLocalization } from '@izettle/lingo-client';
import { HelpSection } from 'src/components/Form/components/HelpSection';
import { usePopupsState } from 'src/components/Popups/state';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { ScreenReaderOnly } from 'src/components/ScreenReaderOnly';
import { config, Environment } from 'src/config';
import { redirectToUrl } from 'src/helpers';
import { RegistrationStatus } from 'src/hooks/use-polling-questionnaire';
import { useQuestionnaireLocalStorage } from 'src/hooks/use-questionnaire-local-storage';
import { useSaveRedirectTo } from 'src/hooks/use-save-redirect-to';
import { useSubmitQuestionnaire } from 'src/hooks/use-submit-questionnaire';
import { Action, useTracker } from 'src/hooks/use-tracker';
import { FormStatus, useFormContextValue } from 'src/providers/form';
import { closeWebview, openDocifyOnNativeApps } from 'src/util';
import { OverviewPageContextProvider } from './components/Context';
import { Debugger } from './components/Debugger';
import { GeneralErrors } from './components/GeneralErrors';
import { UsaTaxpayerSection } from './components/UsaTaxpayerSection';
import * as Version from './components/Version';
import { getFinalizeKYCWithSuccess, getRedirectOnSuccess } from './helpers';
import { SmallMarginBottom, PageSubTitle, PageTitle, StyledSubmitText, LargeMarginBottom, MediumMarginBottom, StyledSubmitButton, } from './styles';
// eslint-disable-next-line max-statements, complexity
export const OverviewPage = ({ stepObject }) => {
    const { formContext } = useFormContextValue();
    if (formContext.status !== FormStatus.ONBOARDING && formContext.status !== FormStatus.GAPS) {
        throw new Error('OverviewPage cannot be rendered for ineligible user ');
    }
    const { trackEvent } = useTracker();
    const { text } = useLocalization();
    const { state, dispatch } = useQuestionnaireState();
    const questionnaireLocalStorage = useQuestionnaireLocalStorage();
    const [{ result, isLoading, errors: submitErrors, triggerGenericError }, submitQuestionnaire] = useSubmitQuestionnaire(formContext);
    const [errors, setErrors] = useState({});
    const { loadRedirectTo } = useSaveRedirectTo();
    const [usaTermsPayload, setUsaTermsPayload] = useState(null);
    const [debuggerValues, setDebuggerValues] = useState({
        registrationStatus: '',
        detailedStatus: '',
        triggerDocify: false,
    });
    const { dispatch: dispatchPopup } = usePopupsState();
    const { version, content, confirmationScreen, processingScreen } = stepObject.applicationVariables;
    const handleRegistrationStatusOnChange = (value) => {
        setDebuggerValues(Object.assign(Object.assign({}, debuggerValues), { registrationStatus: value }));
    };
    const handleDetailedStatusOnChange = (value) => {
        setDebuggerValues(Object.assign(Object.assign({}, debuggerValues), { detailedStatus: value }));
    };
    const handleTriggerDocifyOnChange = (value) => {
        setDebuggerValues(Object.assign(Object.assign({}, debuggerValues), { triggerDocify: value }));
    };
    const redirectOnSuccess = getRedirectOnSuccess(loadRedirectTo, formContext);
    const finalizeKYCWithSuccess = getFinalizeKYCWithSuccess(formContext, state, redirectOnSuccess);
    useEffect(() => {
        setErrors(submitErrors);
    }, [submitErrors]);
    // eslint-disable-next-line complexity, sonarjs/cognitive-complexity
    useEffect(() => {
        if (!result || isEmpty(result)) {
            return;
        }
        trackEvent(Action.ReceivedSubmitResponseKYC, {
            businessType: state.stepData.businessType,
            nativeBusinessType: state.stepData.nativeBusinessType,
            submitStatus: result.status,
            categoryId: state.stepData.businessCategoryId,
            subCategoryId: state.stepData.businessSubCategoryId,
        });
        if (result.status === 'VALIDATION_ERROR') {
            trackEvent(Action.ReceivedSubmitValidationError, {
                businessType: state.stepData.businessType,
                nativeBusinessType: state.stepData.nativeBusinessType,
                categoryId: state.stepData.businessCategoryId,
                subCategoryId: state.stepData.businessSubCategoryId,
            });
        }
        switch (result.status) {
            case RegistrationStatus.SUCCESS:
            case RegistrationStatus.ACCEPTED_FOR_INTERNAL_TESTING:
                questionnaireLocalStorage.removeSaved();
                if (confirmationScreen) {
                    // If we require confirmation, at this point we transition to a final state then show the confirmation screen
                    dispatchPopup({
                        type: 'displayPopup',
                        payload: {
                            popupName: 'ConfirmationPage',
                            params: Object.assign(Object.assign({ image: 'highFive' }, confirmationScreen), { onConfirm: finalizeKYCWithSuccess }),
                        },
                    });
                }
                else {
                    finalizeKYCWithSuccess();
                }
                break;
            case RegistrationStatus.ACCEPTED_WITH_ID_VERIFICATION:
                questionnaireLocalStorage.removeSaved();
                closeWebview(formContext.user.emailAddress); // TODO: maybe we should use openDocifyOnNativeApps?, needs app support
                redirectToUrl(`${config.portalUrl}/seller-verification`);
                break;
            case RegistrationStatus.GAP_WITH_DOCUMENT_VERIFICATION:
                questionnaireLocalStorage.removeSaved();
                openDocifyOnNativeApps(formContext.user.emailAddress);
                redirectToUrl(`${config.portalUrl}/seller-verification`);
                break;
            case RegistrationStatus.PROCESSING:
                questionnaireLocalStorage.removeSaved();
                if (processingScreen) {
                    dispatchPopup({
                        type: 'displayPopup',
                        payload: {
                            popupName: 'ConfirmationPage',
                            params: Object.assign(Object.assign({ image: 'stopWatch' }, processingScreen), { onConfirm: finalizeKYCWithSuccess }),
                        },
                    });
                }
                else {
                    finalizeKYCWithSuccess();
                }
                break;
            default:
                if (result.errors) {
                    setErrors(result.errors);
                }
                else {
                    triggerGenericError();
                }
        }
    }, [result]);
    const onClickSubmit = () => {
        const payload = Object.assign(Object.assign(Object.assign({}, state.stepData), (usaTermsPayload && Object.assign({}, usaTermsPayload))), { kycStatusOverride: debuggerValues });
        void submitQuestionnaire(payload);
        trackEvent(Action.ClickedSubmitButton, {
            businessType: state.stepData.businessType,
            nativeBusinessType: state.stepData.nativeBusinessType,
            categoryId: state.stepData.businessCategoryId,
            subCategoryId: state.stepData.businessSubCategoryId,
        });
    };
    const showButtonLoading = useMemo(() => isLoading || (result && (result.status === 'SUCCESS' || result.status === 'ACCEPTED_FOR_INTERNAL_TESTING')), [isLoading, result.status]);
    useEffect(() => {
        if (showButtonLoading) {
            dispatch({ type: 'submissionStarted' });
        }
        else {
            dispatch({ type: 'submissionEnded' });
        }
    }, [showButtonLoading]);
    if (!Version[version]) {
        throw new Error(`Version "${version}" of the Overview Page was not found.`);
    }
    const VersionComponent = Version[version];
    return (React.createElement(OverviewPageContextProvider, { stepData: state.stepData, errors: errors, triggerTechnicalError: triggerGenericError, isSubmissionInProgress: showButtonLoading },
        React.createElement(GeneralErrors, null),
        React.createElement(SmallMarginBottom, null,
            React.createElement(PageTitle, null, content.title),
            React.createElement(PageSubTitle, null, content.subtitle)),
        React.createElement(LargeMarginBottom, null,
            React.createElement(VersionComponent, null)),
        React.createElement(LargeMarginBottom, null,
            React.createElement(MediumMarginBottom, null,
                content.submitInstructions && (React.createElement(SmallMarginBottom, { id: "overview_submit_instruction" },
                    React.createElement(StyledSubmitText, { dangerouslySetInnerHTML: { __html: content.submitInstructions } }))),
                content.helpTexts && React.createElement(HelpSection, { items: content.helpTexts })),
            stepObject.applicationVariables.displayUsaTaxpayerTerms && (React.createElement(UsaTaxpayerSection, { onTermsPayloadChange: (termsKeys) => {
                    setUsaTermsPayload(termsKeys);
                } })),
            React.createElement(StyledSubmitButton, { id: "submitBtn", type: "submit", variant: "primary", fullWidth: true, onClick: onClickSubmit, loading: showButtonLoading }, content.submitButton),
            React.createElement(ScreenReaderOnly, { "aria-live": "assertive" }, showButtonLoading ? text('COMMON_PROCESSING_REQUEST_LABEL') : '')),
        config.appEnv !== Environment.Production && (React.createElement(Debugger, { debuggerValues: debuggerValues, handleRegistrationStatusOnChange: handleRegistrationStatusOnChange, handleDetailedStatusOnChange: handleDetailedStatusOnChange, handleTriggerDocifyOnChange: handleTriggerDocifyOnChange }))));
};
