import { NativeBusinessType } from 'src/utils/country/DE/data';
import { getBusinessType } from 'src/utils/country/DE/data-helpers';
import { generateDigitSequence } from '../../helpers';
export const generateLegalEntityNumber = () => `HRB ${generateDigitSequence(6)}`;
export const generateSteuernummer = () => `${generateDigitSequence(10)}`;
export const generatePartnershipRegisterNumber = () => `PR ${generateDigitSequence(8)}`;
export const generateVereinRegisterNumber = () => `VR ${generateDigitSequence(8)}`;
export const generateGenossenschaftRegisterNumber = () => `GnR ${generateDigitSequence(8)}`;
// Implementation based on: http://www.pruefziffernberechnung.de/U/USt-IdNr.shtml
export const generateVatNumber = () => {
    const baseNumber = generateDigitSequence(8);
    const m = 10;
    const n = 11;
    let product = m;
    let sum = 0;
    baseNumber
        .toString()
        .split('')
        .forEach((digit) => {
        sum = (product + Number(digit)) % 10;
        sum = sum === 0 ? m : sum;
        product = (2 * sum) % n;
    });
    const checkDigit = n - product === 10 ? 0 : n - product;
    return `DE${baseNumber}${checkDigit}`;
};
export const getBusinessTypeFields = (nativeBusinessType) => {
    return {
        nativeBusinessType,
        businessType: getBusinessType(nativeBusinessType),
    };
};
export const commonData = {
    businessName: 'Test AB',
    businessStreetAddress: 'Rathausstrasse 90',
    businessCity: 'Fürth',
    businessPostalCode: '90708',
    businessVatNumber: '',
    signatoryFirstName: 'John',
    signatoryLastName: 'Doe',
    signatoryDateOfBirth: '18.06.1988',
    signatoryGender: 'MALE',
    signatoryNationality: 'US',
    signatoryPhoneNumber: '07712341234',
    isSignatoryShareAddress: true,
    businessCategoryId: '504',
    businessSubCategoryId: '505',
    businessLocationTypes: ['TEMPORARY_STORE', 'EVENT_STORE'],
    businessFrequency: 'FEW_TIMES_A_MONTH',
    businessAverageTransaction: '50-500',
    businessIzettleShareOfTurnover: '20-80%',
    businessCurrentAnnualTurnover: '0-20000',
};
const generatePersonData = () => ({
    firstName: 'Mary',
    lastName: 'Jane',
    dateOfBirth: '18.06.1988',
    gender: 'FEMALE',
    nationality: 'DE',
    streetAddress: 'Scharnweberstrasse 41',
    postalCode: '68161',
    city: 'Mannheim Quadrate',
});
const generateLimitedCompanyOwnerStructureData = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generatePartnershipOwnerStructureData = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    },
    rolePartners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateNonProfitOwnerStructureData = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    roleAlternativeOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
// Complete generators for each company type
const generateEKData = (formContext) => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), getBusinessTypeFields(NativeBusinessType.EK)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }), (formContext.form.endsWith('1099K') ? { signatoryUnderUsaTaxLaw: false } : false)));
const generateSEData = () => (Object.assign(Object.assign(Object.assign({}, commonData), getBusinessTypeFields(NativeBusinessType.SonstigeEinzelunternehmen)), { businessLegalEntityNumber: generateSteuernummer() }));
export const generateGMBHData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateLimitedCompanyOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.GmbH)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateAGData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateLimitedCompanyOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.AG)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateUGData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateLimitedCompanyOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.UG)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateKGAAData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateLimitedCompanyOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.KGaA)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateOHGData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generatePartnershipOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.oHG)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateKGData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generatePartnershipOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.KG)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generatePartGData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generatePartnershipOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.PartGPartGmbB)), { businessLegalEntityNumber: generatePartnershipRegisterNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateGbRData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generatePartnershipOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.GbR)), { businessLegalEntityNumber: generateSteuernummer() }));
export const generateStiftungData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateNonProfitOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.Stiftung)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateGGMBHData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateNonProfitOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.gGmbH)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateGUGData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateNonProfitOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.gUG)), { businessLegalEntityNumber: generateLegalEntityNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateVereinData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateNonProfitOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.Verein)), { businessLegalEntityNumber: generateSteuernummer() }));
export const generateEVData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateNonProfitOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.EingetragenerVerein)), { businessLegalEntityNumber: generateVereinRegisterNumber(), businessDistrictCourt: 'Adelsheim' }));
export const generateGenossenschaftData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateNonProfitOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.Genossenschaft)), { businessLegalEntityNumber: generateSteuernummer() }));
export const generateEGData = () => (Object.assign(Object.assign(Object.assign(Object.assign({}, commonData), generateNonProfitOwnerStructureData()), getBusinessTypeFields(NativeBusinessType.EingetrageneGenossenschaft)), { businessLegalEntityNumber: generateGenossenschaftRegisterNumber(), businessDistrictCourt: 'Adelsheim' }));
export const DE = [
    { title: 'Eingetragener Kaufmann', generateFunc: generateEKData },
    { title: 'Sonstige Einzelunternehmen', generateFunc: generateSEData },
    { title: 'GmbH', generateFunc: generateGMBHData },
    { title: 'AG', generateFunc: generateAGData },
    { title: 'UG', generateFunc: generateUGData },
    { title: 'KGaA', generateFunc: generateKGAAData },
    { title: 'oHG', generateFunc: generateOHGData },
    { title: 'KG', generateFunc: generateKGData },
    { title: 'PartGPartGmbB', generateFunc: generatePartGData },
    { title: 'GbR', generateFunc: generateGbRData },
    { title: 'Stiftung', generateFunc: generateStiftungData },
    { title: 'gGmbH', generateFunc: generateGGMBHData },
    { title: 'gUG', generateFunc: generateGUGData },
    { title: 'Verein', generateFunc: generateVereinData },
    { title: 'EingetragenerVerein', generateFunc: generateEVData },
    { title: 'Genossenschaft', generateFunc: generateGenossenschaftData },
    { title: 'EingetrageneGenossenschaft', generateFunc: generateEGData },
];
