export var Action;
(function (Action) {
    Action["ViewedStep"] = "ViewedStep";
    Action["CompletedStep"] = "CompletedStep";
    Action["ClickedSubmitButton"] = "ClickedSubmitButton";
    Action["ClickedHelpText"] = "ClickedHelpText";
    Action["ReceivedSubmitResponseKYC"] = "ReceivedSubmitResponseKYC";
    Action["ReceivedSubmitValidationError"] = "ReceivedSubmitValidationError";
    Action["ReceivedStepValidationError"] = "ReceivedStepValidationError";
    Action["ClickedLogoutButton"] = "ClickedLogoutButton";
    Action["OpenedSupportChat"] = "OpenedSupportChat";
    Action["ClosedSupportChat"] = "ClosedSupportChat";
})(Action || (Action = {}));
export const trackEventKyc2 = (page, action, payload) => {
    window.izPushEvent('Onboarding', 'Kyc2Form', page, action, payload);
};
