import { IsoLocale } from '@izettle/constants';
import { nationalityOptions } from '../../common/nationality-options';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { render, sequence, step } from '../../steps-builder';
import { SIGNATORY_ACTION } from './data';
const signatoryNationality = ({ translate }) => ({
    fields: ['signatoryNationality', 'signatoryAction'],
    JSONSchema: {
        required: ['signatoryNationality'],
        type: 'object',
        properties: {
            signatoryNationality: {
                title: translate('GAP_SIGNATORY_NATIONALITY_LABEL'),
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
            signatoryAction: {
                type: 'string',
                default: SIGNATORY_ACTION.SET_NATIONALITY,
            },
        },
    },
    UISchema: {
        signatoryNationality: {
            'ui:options': {
                sortByLabels: 'asc',
            },
            'ui:widget': 'CustomComboBoxWidget',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
        },
        signatoryAction: {
            'ui:title': false,
            'ui:widget': 'hidden',
        },
    },
    applicationVariables: {
        stepName: 'signatoryNationality',
        segment: 'personalDetails',
        content: {
            button: translate('FORM_BUTTON'),
            helpTexts: [
                {
                    title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                    body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                },
            ],
        },
    },
});
const overviewPage = ({ translate, formContext }) => {
    const locale = IsoLocale[`${formContext === null || formContext === void 0 ? void 0 : formContext.user.languageId}${formContext === null || formContext === void 0 ? void 0 : formContext.user.countryId}`];
    return {
        componentName: 'OverviewPage',
        applicationVariables: {
            stepName: 'overviewPage',
            version: 'GAP_GB_NATIONALITY',
            confirmationScreen: {
                title: translate('GAP_OVERVIEW_CONFIRMATION_TITLE'),
                subtitle: translate('GAP_OVERVIEW_CONFIRMATION_SUB_TITLE'),
                button: translate('GAP_OVERVIEW_CLOSE_BUTTON'),
            },
            content: {
                title: translate('GAP_OVERVIEW_TITLE'),
                subtitle: translate('GAP_OVERVIEW_SUB_TITLE'),
                submitButton: translate('GAP_OVERVIEW_SUBMIT_BUTTON'),
                submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(locale)]),
            },
        },
    };
};
const nationalitySequence = sequence([step(signatoryNationality), step(overviewPage)]);
export const GAP_SIGNATORY_NATIONALITY = (context) => render(nationalitySequence, context);
