import { generateDigitSequence, generateRandomDateOfBirth, generateRandomLetter } from '../../helpers';
export const generateLegalEntityNumber = () => `NI${generateDigitSequence(6)}`;
export const generateCharityNumber = () => `${generateDigitSequence(6)}`;
export const generateDateOfBirth = () => generateRandomDateOfBirth({ separator: '.' });
export const generatePostalCode = () => ['GB', generateDigitSequence(3), generateRandomLetter(), generateRandomLetter()].join('');
const generateCommonData = () => ({
    businessName: "Harry's Fish",
    businessBuilding: '12345',
    businessStreetAddress: 'some street name',
    businessPostalCode: 'KA1 1BA',
    businessCounty: 'Buckinghamshire',
    businessCity: 'CITY Name',
    signatoryFirstName: 'Harry',
    signatoryLastName: 'Oliver',
    signatoryDateOfBirth: '24/07/1995',
    signatoryNationality: 'US',
    signatoryPhoneNumber: '07911123456',
    isSignatoryShareAddress: true,
    businessCategoryId: '290',
    businessSubCategoryId: '294',
    businessLocationTypes: ['PHYSICAL_STORE', 'TEMPORARY_STORE'],
    businessFrequency: 'FEW_TIMES_A_MONTH',
    businessAverageTransaction: '50-500',
    businessIzettleShareOfTurnover: '20-80%',
    businessCurrentAnnualTurnover: '0-20000',
});
const generatePersonData = () => ({
    firstName: 'John',
    lastName: 'Doe',
    building: '10',
    streetAddress: 'Downing Street',
    city: 'London',
    postalCode: 'KA1 1BA',
    nationality: 'AX',
    dateOfBirth: '24/07/1995',
    county: 'Bedfordshire',
});
export const generateSelfEmployedData = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData()), { businessType: 'SelfEmployed', 
    // Postal code and data of birth are being dynamically generated here to avoid getting a VALIDATION_DUPLICATE_BUSINESS_LEGAL_ENTITY_NUMBER error
    // The BE uses them internally to generate the Org ID
    businessPostalCode: generatePostalCode(), signatoryDateOfBirth: generateRandomDateOfBirth({ separator: '' }) }), (formContext.form.endsWith('1099K') ? { signatoryUnderUsaTaxLaw: false } : false)));
const generateLimitedCompanyData = () => (Object.assign(Object.assign({}, generateCommonData()), { businessType: 'LimitedCompany', businessLegalEntityNumber: generateLegalEntityNumber(), legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    }, roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
const generateRegisteredParnershipCompanyData = () => (Object.assign(Object.assign({}, generateCommonData()), { businessType: 'Partnership', isRegisteredAtCompaniesHouse: true, businessLegalEntityNumber: generateLegalEntityNumber(), legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    }, rolePartners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
const generateNonRegisteredParnershipCompanyData = () => (Object.assign(Object.assign({}, generateCommonData()), { businessType: 'Partnership', isRegisteredAtCompaniesHouse: false, legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    }, rolePartners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
const generateRegisteredNonProfit = () => (Object.assign(Object.assign({}, generateCommonData()), { businessType: 'NonProfit', isRegisteredAtCompaniesHouse: true, businessLegalEntityNumber: generateLegalEntityNumber(), legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    }, roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'], roleAlternativeOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
const generateNonRegisteredNonProfit = () => (Object.assign(Object.assign({}, generateCommonData()), { businessType: 'NonProfit', isRegisteredAtCompaniesHouse: false, businessLegalEntityNumber: generateCharityNumber(), legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    }, roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'], roleAlternativeOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
export const GB = [
    {
        title: 'Self Employed',
        generateFunc: generateSelfEmployedData,
    },
    {
        title: 'Limited Company',
        generateFunc: generateLimitedCompanyData,
    },
    {
        title: 'Partnership (registered)',
        generateFunc: generateRegisteredParnershipCompanyData,
    },
    {
        title: 'Partnership (not registered)',
        generateFunc: generateNonRegisteredParnershipCompanyData,
    },
    {
        title: 'NonProfit (registered)',
        generateFunc: generateRegisteredNonProfit,
    },
    {
        title: 'NonProfit (not registered)',
        generateFunc: generateNonRegisteredNonProfit,
    },
];
