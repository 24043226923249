export const defineError = (content) => content;
export const unableToVerifyYourCompany = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_YOUR_COMPANY_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_YOUR_COMPANY_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const unableToVerifyCompanyAddress = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_COMPANY_ADDRESS_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_COMPANY_ADDRESS_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const unableToVerifyYourIdentity = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_YOUR_IDENTITY_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_YOUR_IDENTITY_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const unableToVerifyLegalCompanyType = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_LEGAL_COMPANY_TYPE_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_LEGAL_COMPANY_TYPE_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const technicalError = defineError({
    titleKey: 'VERIFICATION_TECHNICAL_ERROR_TITLE',
    bodyKey: 'VERIFICATION_TECHNICAL_ERROR_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const creditFailureEtc = defineError({
    titleKey: 'VERIFICATION_CREDIT_FAILURE_ETC_TITLE',
    bodyKey: 'VERIFICATION_CREDIT_FAILURE_ETC_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const inactiveCompany = defineError({
    titleKey: 'VERIFICATION_INACTIVE_COMPANY_TITLE',
    bodyKey: 'VERIFICATION_INACTIVE_COMPANY_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const unableToVerifyOwners = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_OWNERS_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_OWNERS_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const unableToVerifySignatory = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_SIGNATORY_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_SIGNATORY_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const unableToVerifySignatoryAndOwners = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_SIGNATORY_AND_OWNERS_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_SIGNATORY_AND_OWNERS_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const unableToVerifySignatoryAndOwnersLOA = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_SIGNATORY_AND_OWNERS_LOA_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_SIGNATORY_AND_OWNERS_LOA_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const unableToVerifyOwnersLOA = defineError({
    titleKey: 'VERIFICATION_UNABLE_TO_VERIFY_OWNERS_LOA_TITLE',
    bodyKey: 'VERIFICATION_UNABLE_TO_VERIFY_OWNERS_LOA_BODY',
    substitutions: ['SUPPORT_CONTACT_HUB_URL'],
});
export const validationAlreadyUpgradedOrg = defineError({
    bodyKey: 'VALIDATION_ALREADY_UPGRADED_ORGANIZATION',
    substitutions: ['SUPPORT_EMAIL', 'SUPPORT_NUMBER'],
});
export const validationRequiredGapRoleOwner = defineError({
    titleKey: 'VALIDATION_REQUIRED_GAP_ROLE_OWNER_TITLE',
    bodyKey: 'VALIDATION_REQUIRED_GAP_ROLE_OWNER_BODY',
    substitutions: ['SUPPORT_EMAIL', 'SUPPORT_NUMBER'],
});
export const validationRequiredGapRoleDirector = defineError({
    titleKey: 'VALIDATION_REQUIRED_GAP_ROLE_DIRECTOR_TITLE',
    bodyKey: 'VALIDATION_REQUIRED_GAP_ROLE_DIRECTOR_BODY',
    substitutions: ['SUPPORT_EMAIL', 'SUPPORT_NUMBER'],
});
export const validationRequiredGapRoleNpoOwner = defineError({
    titleKey: 'VALIDATION_REQUIRED_GAP_ROLE_NPO_OWNER_TITLE',
    bodyKey: 'VALIDATION_REQUIRED_GAP_ROLE_NPO_OWNER_BODY',
    substitutions: ['SUPPORT_EMAIL', 'SUPPORT_NUMBER'],
});
export const validationRequiredGapRoleNpoDirector = defineError({
    titleKey: 'VALIDATION_REQUIRED_GAP_ROLE_NPO_DIRECTOR_TITLE',
    bodyKey: 'VALIDATION_REQUIRED_GAP_ROLE_NPO_DIRECTOR_BODY',
    substitutions: ['SUPPORT_EMAIL', 'SUPPORT_NUMBER'],
});
export const validationRequiredGapRolePartnershipOwner = defineError({
    titleKey: 'VALIDATION_REQUIRED_GAP_ROLE_PARTNERSHIP_OWNER_TITLE',
    bodyKey: 'VALIDATION_REQUIRED_GAP_ROLE_PARTNERSHIP_OWNER_BODY',
    substitutions: ['SUPPORT_EMAIL', 'SUPPORT_NUMBER'],
});
export const validationRequiredGapRolePartnershipDirector = defineError({
    titleKey: 'VALIDATION_REQUIRED_GAP_ROLE_PARTNERSHIP_DIRECTOR_TITLE',
    bodyKey: 'VALIDATION_REQUIRED_GAP_ROLE_PARTNERSHIP_DIRECTOR_BODY',
    substitutions: ['SUPPORT_EMAIL', 'SUPPORT_NUMBER'],
});
export const verificationMXTechnicalError = defineError({
    titleKey: 'VERIFICATION_MX_TECHNICAL_ERROR_TITLE',
    bodyKey: 'VERIFICATION_MX_TECHNICAL_ERROR_BODY',
    substitutions: ['SUPPORT_EMAIL'],
});
export const errorContentMap = {
    // Verification Errors: UK
    REGISTRATION_RETRY_NO_CUSTOMER_AND_ADDRESS_MATCH_INDIVIDUAL: unableToVerifyYourIdentity,
    REGISTRATION_RETRY_ADDRESS_UNKNOWN_INDIVIDUAL: unableToVerifyYourIdentity,
    REGISTRATION_UK_DENIED_CREDIT_FAILURE: creditFailureEtc,
    // Verification Errors: SE, UK, DE, FR, NL, NO, FI and DK
    REGISTRATION_RETRY_COMPANY_NAME_MISMATCH: unableToVerifyYourCompany,
    REGISTRATION_RETRY_NO_COMPANY_FOUND_WITH_LEGAL_ENTITY_NUMBER: unableToVerifyYourCompany,
    REGISTRATION_RETRY_COMPANY_ADDRESS_MISMATCH: unableToVerifyCompanyAddress,
    REGISTRATION_RETRY_UBO_NOT_FOUND: unableToVerifyOwners,
    REGISTRATION_RETRY_SIGNATORY_NOT_DIRECTOR_SIGNATORY_NOT_FOUND: unableToVerifySignatory,
    REGISTRATION_RETRY_SIGNATORY_NOT_DIRECTOR_SIGNATORY_AND_UBO_NOT_FOUND: unableToVerifySignatoryAndOwnersLOA,
    // Verification Errors: UK, DE, and FR
    REGISTRATION_DENIED_COMPANY_INACTIVE: inactiveCompany,
    // Verification Errors: SE, UK, DE, NL, NO, FI and DK
    REGISTRATION_RETRY_SIGNATORY_NOT_DIRECTOR_UBO_NOT_FOUND: unableToVerifyOwnersLOA,
    // Verification Errors: UK and DE
    REGISTRATION_RETRY_NO_IDENTITY_MATCH_INDIVIDUAL: unableToVerifyYourIdentity,
    // Verification Errors: UK, DE, DK, and ES
    REGISTRATION_RETRY_SIGNATORY_NOT_FOUND_UBO_NOT_FOUND: unableToVerifySignatoryAndOwners,
    // Verification Errors: SE, DE, FR, NL, ES and MX
    REGISTRATION_RETRY_IDENTITY_NOT_FOUND: unableToVerifyYourIdentity,
    // Verification Errors: SE, DE, NL, NO, DK, ES
    REGISTRATION_DENIED_BECAUSE_OF_CREDIT_FAILURE: creditFailureEtc,
    // Verification Errors: SE, FR, NL and DK
    REGISTRATION_RETRY_LEGAL_FORM_MATCH_FAILED: unableToVerifyLegalCompanyType,
    // Verification Errors: FR and NL:
    REGISTRATION_NOT_PHYSICAL_PERSON: unableToVerifyLegalCompanyType,
    // Verification Errors: FR
    REGISTRATION_DENIED_FR_CREDIT_FAILURE: creditFailureEtc,
    // Verification Errors: NL
    REGISTRATION_RETRY_NL_COMPANY_INACTIVE: inactiveCompany,
    // Verification Errors: SE
    REGISTRATION_RETRY_SE_COMPANY_INACTIVE: inactiveCompany,
    // Verification Errors: NO
    REGISTRATION_RETRY_NO_COMPANY_INACTIVE: inactiveCompany,
    // Verification Errors: FI
    REGISTRATION_RETRY_FI_COMPANY_INACTIVE: inactiveCompany,
    // Verification Errors: ES
    REGISTRATION_RETRY_COMPANY_INACTIVE: inactiveCompany,
    // Verification Errors: DK
    REGISTRATION_RETRY_DK_COMPANY_INACTIVE: inactiveCompany,
    REGISTRATION_RETRY_SIGNATORY_NOT_FOUND: unableToVerifySignatory,
    // Verification Errors: NO, SE, FI and DK
    REGISTRATION_RETRY_NORDIC_IDENTITY_NOT_FOUND: unableToVerifyYourIdentity,
    // Verification Errors: MX
    KYC_MX_SIGNATORY_NAME_MATCH_FAILED: unableToVerifyYourIdentity,
    REGISTRATION_RETRY_KYC_MX_SIGNATORY_NAME_MATCH_FAILED: unableToVerifyYourIdentity,
    KYC_MX_LEGAL_ENTITY_NUMBER_MATCH_FAILED: unableToVerifyYourIdentity,
    REGISTRATION_RETRY_KYC_MX_LEGAL_ENTITY_NUMBER_MATCH_FAILED: unableToVerifyYourIdentity,
    KYC_MX_DATE_OF_BIRTH_MISMATCH: unableToVerifyYourIdentity,
    KYC_MX_TECHNICAL_ERROR: verificationMXTechnicalError,
    // Validation Errors
    VALIDATION_REQUIRED_ONE_DIRECTOR: defineError({ bodyKey: 'VALIDATION_REQUIRED_ONE_DIRECTOR' }),
    VALIDATION_REQUIRED_ONE_UBO: defineError({ bodyKey: 'VALIDATION_REQUIRED_ONE_UBO' }),
    VALIDATION_REQUIRED_TWO_PARTNERS: defineError({ bodyKey: 'VALIDATION_REQUIRED_TWO_PARTNERS' }),
    VALIDATION_REQUIRED_ONE_DIRECTOR_OR_TRUSTEE: defineError({ bodyKey: 'VALIDATION_REQUIRED_ONE_DIRECTOR_OR_TRUSTEE' }),
    VALIDATION_REQUIRED_NON_PROFIT_ONE_UBO: defineError({ bodyKey: 'VALIDATION_REQUIRED_NON_PROFIT_ONE_UBO' }),
    VALIDATION_DUPLICATE_BUSINESS_LEGAL_ENTITY_NUMBER: defineError({
        bodyKey: 'VALIDATION_DUPLICATE_BUSINESS_LEGAL_ENTITY_NUMBER',
        substitutions: ['SUPPORT_EMAIL', 'SUPPORT_NUMBER'],
    }),
    VALIDATION_ALREADY_UPGRADED_ORGANIZATION: validationAlreadyUpgradedOrg,
    VALIDATION_UNKNOWN_ERROR: defineError({ bodyKey: 'VALIDATION_UNKNOWN_ERROR' }),
    VALIDATION_MISMATCH_ROLE_LEGAL_PERSONS: technicalError,
    VALIDATION_INVALID_BUSINESS_SUB_CATEGORY_ID: technicalError,
    VALIDATION_INVALID_BUSINESS_TYPE: technicalError,
    // Verification Errors: GAP
    VALIDATION_REQUIRED_GAP_ROLE_OWNER: validationRequiredGapRoleOwner,
    VALIDATION_REQUIRED_GAP_ROLE_DIRECTOR: validationRequiredGapRoleDirector,
    VALIDATION_REQUIRED_GAP_ROLE_NPO_OWNER: validationRequiredGapRoleNpoOwner,
    VALIDATION_REQUIRED_GAP_ROLE_NPO_DIRECTOR: validationRequiredGapRoleNpoDirector,
    VALIDATION_REQUIRED_GAP_ROLE_PARTNERSHIP_OWNER: validationRequiredGapRolePartnershipOwner,
    VALIDATION_REQUIRED_GAP_ROLE_PARTNERSHIP_DIRECTOR: validationRequiredGapRolePartnershipDirector,
    // Technical Errors
    REGISTRATION_SYSTEM_COMMUNICATION_ERROR: technicalError,
    REGISTRATION_RETRY_COMMUNICATION_ERROR: technicalError,
    REGISTRATION_UNKNOWN_ERROR: technicalError,
    REGISTRATION_RETRY_CREDIT_REPORT_NOT_FOUND: technicalError,
    // This error is triggered by the client if communication with registration service fails during submission
    COMMON_SOMETHING_WENT_WRONG: technicalError,
};
