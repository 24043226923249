var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import { RadioGroupContext } from './context';
// Need to wrap all options with container for correct keyboard navigation behavior
const ItemsContainer = 'div';
export const RadioGroup = (_a) => {
    var { children } = _a, contextProps = __rest(_a, ["children"]);
    const contextValue = useMemo(() => contextProps, [Object.values(contextProps)]);
    return (React.createElement(RadioGroupContext.Provider, { value: contextValue },
        React.createElement(ItemsContainer, null, children)));
};
