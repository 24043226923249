import React from 'react';
import styled from 'styled-components';
import { spacing, spacingNumeric } from '@izettle/otto';
import desktopImage from '../../assets/izettle-main-1120x946.jpg';
import desktopLargeImage from '../../assets/izettle-main-1494x946.jpg';
import { breakpoints } from '../../theme';
import { Grid, GridColumn } from '../Grid';
import { ScrollbarAwarePadding } from '../ScrollbarAwarePadding';
const RootGrid = styled(Grid) `
  height: 100vh;
  max-height: -webkit-fill-available;
`;
const DesktopFillerImageColumn = styled(GridColumn).attrs({ columnWidth: { desktop: '6 / -1' }, alt: '' }) `
  display: none;

  @media (min-width: ${breakpoints.desktop}) {
    display: block;
    background: url(${desktopImage}) no-repeat center/cover;
  }

  @media (min-width: ${breakpoints.desktopLarge}) {
    background: url(${desktopLargeImage}) no-repeat center/cover;
  }
`;
const ContentColumn = styled(GridColumn).attrs({
    as: 'main',
    columnWidth: { desktop: '1 / 6', tablet: '1 / -1', mobile: '1 / -1' },
}) `
  overflow-y: auto;
`;
const ContentPlacement = styled(ScrollbarAwarePadding).attrs({
    paddingLeft: 'var(--content-horizontal-padding)',
    paddingRight: 'var(--content-horizontal-padding)',
}) `
  --content-horizontal-padding: ${spacing.medium};

  @media (max-width: ${breakpoints.tiny}) {
    --content-horizontal-padding: ${spacing.xxSmall};
  }
`;
const ContentContainer = styled.div `
  margin: ${spacingNumeric.none} auto;

  padding: ${spacing.small} ${spacingNumeric.none};

  @media (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.xxLarge} ${spacingNumeric.none};
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: ${spacing.large} ${spacingNumeric.none};
  }

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 384px;
  }
`;
export const AppLayoutGrid = ({ children }) => (React.createElement(RootGrid, null,
    React.createElement(ContentColumn, null,
        React.createElement(ContentPlacement, null,
            React.createElement(ContentContainer, null, children))),
    React.createElement(DesktopFillerImageColumn, null)));
