import React, { createContext, useContext, useEffect } from 'react';
import { useEffectReducer } from 'use-effect-reducer';
import { useQuestionnaireEffectsMap } from './effect-handlers';
import { questionnaireEventsMap } from './event-handlers';
import { mappedReducer } from './mapped-reducer';
export const QuestionnaireStateContext = createContext(undefined);
export const QuestionnaireStateProvider = ({ children, onChange, initialState, }) => {
    const effectsMap = useQuestionnaireEffectsMap();
    const [state, dispatch] = useEffectReducer(mappedReducer(questionnaireEventsMap), initialState, effectsMap);
    useEffect(() => {
        onChange === null || onChange === void 0 ? void 0 : onChange(state);
    }, [state]);
    return (React.createElement(QuestionnaireStateContext.Provider, { value: { state, dispatch } }, children));
};
export const QuestionnaireStateConsumer = ({ children }) => {
    return (React.createElement(QuestionnaireStateContext.Consumer, null, (contextValue) => {
        if (contextValue === undefined) {
            throw new Error('useQuestionnaireState must be used within a QuestionnaireStateProvider');
        }
        return children(contextValue);
    }));
};
export const useQuestionnaireState = () => {
    const contextValue = useContext(QuestionnaireStateContext);
    if (!contextValue) {
        throw new Error('useQuestionnaireState must be used within a QuestionnaireStateProvider');
    }
    return contextValue;
};
