import React from 'react';
import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { getTitleId, isGroupedWidget, isSchemaTypeNull } from './helpers';
const GroupedWidgetFieldSet = styled.fieldset `
  border: none;
  padding: 0;
  margin: 0;
`;
const GroupedWidgetLegend = styled.legend ``;
const SchemaTypeNullLegend = styled.legend `
  margin-bottom: ${spacing.xxSmall};
`;
const DefaultFieldTemplateLabel = styled.label ``;
const GroupedWidgetFieldTemplate = ({ title, description, children }) => {
    return (React.createElement(GroupedWidgetFieldSet, null,
        (title || description) && (React.createElement(GroupedWidgetLegend, null,
            title,
            description)),
        children));
};
const SchemaTypeNullFieldTemplate = ({ title, description }) => {
    return (React.createElement(SchemaTypeNullLegend, null,
        title,
        description)
    // doesn't use children, only used as a header
    );
};
const DefaultFieldTemplate = ({ title, description, inputFieldId, children }) => {
    return (React.createElement(React.Fragment, null,
        title && React.createElement(DefaultFieldTemplateLabel, { htmlFor: inputFieldId }, title),
        description,
        children));
};
export const getFieldTemplateComponent = (props) => {
    if (isGroupedWidget(props.uiSchema)) {
        return GroupedWidgetFieldTemplate;
    }
    if (isSchemaTypeNull(props.schema)) {
        return SchemaTypeNullFieldTemplate;
    }
    return DefaultFieldTemplate;
};
export const getFieldTitleAndDescription = (props) => {
    const { uiSchema, displayLabel, rawDescription, description, label, registry, id, required } = props;
    const titleHidden = uiSchema['ui:title'] === false;
    // TODO: investigate if we can stop using `ui:title` and can switch to uiSchema['ui:options']?.label instead
    if (!displayLabel || titleHidden) {
        return { title: null, description: null };
    }
    const TitleField = registry.fields.TitleField;
    return {
        title: label && React.createElement(TitleField, { title: label, id: getTitleId(id), required: required }),
        description: rawDescription ? description : null,
    };
};
