import styled, { css } from 'styled-components';
import { borderRadius, colors, spacing } from '@izettle/otto';
const containerBorderCss = css `
  border: 0;
  border-radius: ${borderRadius.medium};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    pointer-events: none;
  }
  ${({ checked, disabled }) => {
    if (checked && !disabled) {
        return css `
        border-color: ${colors.selector.selected.active};
        &:after {
          border-width: 1.5px;
        }
      `;
    }
    if (disabled) {
        return css `
        border-color: ${colors.border.divider};
      `;
    }
    return css `
      border-color: ${colors.selector.initial.default};
    `;
}};
`;
export const CheckedInputContainer = styled.div `
  position: relative;
  display: flex;
  align-items: start;

  ${containerBorderCss}

  padding: ${spacing.small};
  background: ${({ disabled }) => (disabled ? colors.background.groupedPrimary : colors.background.default)};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    background: ${colors.background.default};
    ${({ checked, disabled }) => {
    if (checked && !disabled) {
        return css `
          border-color: ${colors.selector.initial.hover};
        `;
    }
    if (disabled) {
        return css `
          border-color: ${colors.border.divider};
        `;
    }
    return css `
        border-color: ${colors.selector.initial.hover};
      `;
}};
  }

  &:focus {
    &:after {border-width : 1.5px;}
    ${({ checked, disabled }) => {
    if (checked && !disabled) {
        return css `
          border-color: ${colors.selector.selected.active};
        `;
    }
    if (disabled) {
        return css `
          border-color: ${colors.border.divider};
        `;
    }
    return css `border-color : ${colors.selector.initial.default}}`;
}};

      
  }

  

  &:not(:last-child) {
    margin-bottom: ${spacing.xxSmall};
  }

  &:hover .focusTarget .tick_box{
    ${({ checked }) => checked &&
    `
      background: ${colors.action.primary.border.default};
      border-color: ${colors.action.primary.border.default};
  `};
  }

  &:hover svg {
    circle:nth-child(1) {
      stroke: ${({ checked }) => (checked ? colors.action.primary.border.default : colors.selector.initial.default)};
      ${({ disabled }) => disabled && `stroke: ${colors.action.primary.background.disabled}`};
    }

    circle:nth-child(2) {{
      fill: ${({ disabled }) => !disabled ? colors.action.primary.border.default : colors.action.primary.background.disabled};
    }
  }
`;
