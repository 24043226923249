import React, { useCallback, useContext } from 'react';
import { CheckboxGroup } from '../../../CheckboxGroup';
import { Checkbox } from '../../../CheckboxGroup/checkbox';
import { FormEventsContext } from '../../events-context';
import { FieldContext } from '../FieldTemplate/context';
import { ObjectPropertyContext } from '../ObjectFieldTemplate/context';
import { useEnumOptions } from '../hooks/use-enum-options';
const isValueReadonly = (objectFormData, propertyName, UIOptions, value) => {
    if (UIOptions.allowReadonlyValues && UIOptions.readonly) {
        throw new Error(`Property '${propertyName}' specifies both 'readonly' and 'allowReadonlyValues'`);
    }
    if (UIOptions.readonly) {
        return true;
    }
    const readonlyValues = objectFormData[`readonly_values_${propertyName}`];
    if (readonlyValues === null || readonlyValues === void 0 ? void 0 : readonlyValues.includes(value)) {
        if (!UIOptions.allowReadonlyValues) {
            throw new Error(`Trying to make '${propertyName}' readonly without 'allowReadonlyValues'`);
        }
        return true;
    }
    return false;
};
export const CustomCheckboxGroup = ({ value, options, onChange, id: fieldId }) => {
    const fieldContext = useContext(FieldContext);
    const { dispatchEvent } = useContext(FormEventsContext);
    const handleOnChange = useCallback((event) => {
        if (event.target.checked === true) {
            const finalValue = [...value, event.target.value];
            onChange(finalValue);
        }
        else {
            const finalValue = value.filter((element) => element !== event.target.value);
            const isFinalValueEmpty = Array.isArray(finalValue) && finalValue.length === 0;
            onChange(isFinalValueEmpty ? [] : finalValue);
        }
    }, [onChange, value]);
    // TODO: Type this properly. Consider extracting it to somewhere else?
    const editableOptions = options.editableOptions ? options.editableOptions : [];
    const removableOptions = options.removableOptions ? options.removableOptions : [];
    const enumOptions = useEnumOptions(options);
    const objectProperties = useContext(ObjectPropertyContext);
    if (!objectProperties) {
        throw new Error('ObjectPropertyContext must be set in ObjectFieldTemplate for the widgets to work correctly.');
    }
    const { objectFormData, propertyName } = objectProperties;
    return (React.createElement(CheckboxGroup, { values: value, onChange: handleOnChange, errorElementId: fieldContext.errorElementId }, enumOptions.map((option) => {
        const readonly = isValueReadonly(objectFormData, propertyName, options, option.value);
        return (React.createElement(Checkbox, { key: option.value, value: option.value, title: React.createElement("span", { className: options.hotJarSuppressTitle ? 'data-hj-suppress' : undefined }, option.label), description: option.description, 
            // TODO: Consider using renderProps for the extra buttons
            editable: editableOptions.includes(option.value), removable: removableOptions.includes(option.value), disabled: readonly, onClickEdit: () => {
                dispatchEvent({ type: 'checkBoxEditClick', checkBoxValue: option.value, fieldId });
            }, onClickRemove: () => {
                dispatchEvent({ type: 'checkBoxRemoveClick', checkBoxValue: option.value, fieldId });
            } }));
    })));
};
