import React, { createRef, useCallback, useState } from 'react';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { Action, useTracker } from 'src/hooks/use-tracker';
import { getQuestionnairePayload } from 'src/hooks/use-tracker/helpers';
import { HelpSectionItem } from './item';
export const HelpSection = ({ items, className }) => {
    const { trackEvent } = useTracker();
    const { state } = useQuestionnaireState();
    const [openIdx, setOpenIdx] = React.useState(null);
    const getIsOpen = (idx) => openIdx === idx;
    const [itemTriggerRefs] = useState(items.map(() => createRef()));
    const handleTriggerKeyDown = useCallback((event) => {
        var _a, _b, _c, _d;
        const currentRefIndex = itemTriggerRefs.findIndex((ref) => ref.current === event.target);
        switch (event.key) {
            case 'ArrowDown':
            case 'ArrowRight': {
                event.preventDefault();
                (_b = (_a = itemTriggerRefs[currentRefIndex + 1]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus();
                break;
            }
            case 'ArrowUp':
            case 'ArrowLeft': {
                event.preventDefault();
                (_d = (_c = itemTriggerRefs[currentRefIndex - 1]) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.focus();
                break;
            }
            default:
                break;
        }
    }, [itemTriggerRefs]);
    const toggleOpen = (index, item) => {
        trackEvent(Action.ClickedHelpText, Object.assign({ helpTextOrderFromTop: index, helpTextLabel: item.title, helpTextAction: getIsOpen(index) ? 'close' : 'open' }, getQuestionnairePayload(state)));
        setOpenIdx(index !== openIdx ? index : null);
    };
    return (React.createElement("div", { className: className }, items.map((item, i) => (React.createElement(HelpSectionItem, { key: item.title, item: item, isOpen: getIsOpen(i), toggleOpen: () => toggleOpen(i, item), triggerRef: itemTriggerRefs[i], handleTriggerKeyDown: handleTriggerKeyDown })))));
};
