import { identity } from 'ramda';
import { IsoLocale, Currency } from '@izettle/constants';
const defaultRangeBetweenFormatter = (a, b, { formatUnit }) => `${formatUnit(a)} – ${formatUnit(b)}`;
const formatRangeBetweenMap = {
    [IsoLocale.daDK]: defaultRangeBetweenFormatter,
    [IsoLocale.deDE]: defaultRangeBetweenFormatter,
    [IsoLocale.enGB]: (a, b, { formatUnit }) => `${formatUnit(a)}–${formatUnit(b)}`,
    [IsoLocale.enUS]: defaultRangeBetweenFormatter,
    [IsoLocale.esES]: defaultRangeBetweenFormatter,
    [IsoLocale.esMX]: defaultRangeBetweenFormatter,
    [IsoLocale.esUS]: defaultRangeBetweenFormatter,
    [IsoLocale.fiFI]: (a, b, { formatUnit, formatDecimal }) => `${formatDecimal(a)}–${formatUnit(b)}`,
    [IsoLocale.frFR]: (a, b, { formatUnit }) => `Entre ${formatUnit(a)} et ${formatUnit(b)}`,
    [IsoLocale.itIT]: defaultRangeBetweenFormatter,
    [IsoLocale.nbNO]: defaultRangeBetweenFormatter,
    [IsoLocale.nlNL]: defaultRangeBetweenFormatter,
    [IsoLocale.ptBR]: defaultRangeBetweenFormatter,
    [IsoLocale.svFI]: (a, b, { formatUnit, formatDecimal }) => `${formatDecimal(a)}–${formatUnit(b)}`,
    [IsoLocale.svSE]: defaultRangeBetweenFormatter,
};
const createRangeBetweenFormatter = (locale) => formatRangeBetweenMap[locale];
const createCurrencyFormatter = (currency, locale) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
    });
    return formatter.format.bind(formatter);
};
const createPercentFormatter = (locale) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 0,
    });
    return (value) => {
        return formatter.format(value / 100);
    };
};
const createDecimalFormatter = (locale) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 0,
    });
    return formatter.format.bind(formatter);
};
const createUnitFormatter = (currency, locale) => {
    if (locale === IsoLocale.esMX && currency === Currency.MXN) {
        return ` ${Currency.MXN.toString()}`;
    }
};
/**
 * Formats @param range value in format 0-50, 50-500, 500-x or 0-20%, 20-80%, 80-x% into presentable form
 */
export const formatTranslateRange = (range, text, rangeBetweenFormatter, unitFormatter, decimalFormatter, finalUnitFormatter) => {
    if (typeof range !== 'string') {
        throw new Error('Range value must be a string');
    }
    // check if range ends with percentage sign
    if (range.endsWith('%')) {
        // dropping % sign in the end
        range = range.slice(0, -1);
    }
    const bounds = range.split('-').filter(identity);
    if (bounds.length !== 2) {
        throw new Error(`Given range value '${range}' is not valid`);
    }
    const [a, b] = bounds;
    if (a === '0') {
        const unitFormat = `${unitFormatter(Number(b))}${finalUnitFormatter !== undefined ? finalUnitFormatter : ''}`;
        return text(`RANGE_VALUE_LESS_THAN_X`, [unitFormat]);
    }
    else if (b.toLowerCase() === 'x') {
        const unitFormat = `${unitFormatter(Number(a))}${finalUnitFormatter !== undefined ? finalUnitFormatter : ''}`;
        return text(`RANGE_VALUE_MORE_THAN_X`, [unitFormat]);
    }
    else {
        const unitFormat = rangeBetweenFormatter(Number(a), Number(b), {
            formatUnit: unitFormatter,
            formatDecimal: decimalFormatter,
        });
        return `${unitFormat}${finalUnitFormatter !== undefined ? finalUnitFormatter : ''}`;
    }
};
export const createFormatHelpers = (locale, currency) => {
    const formatCurrencyRange = (rangeValue, text) => formatTranslateRange(rangeValue, text, createRangeBetweenFormatter(locale), createCurrencyFormatter(currency, locale), createDecimalFormatter(locale), createUnitFormatter(currency, locale));
    const formatPercentRange = (rangeValue, text) => formatTranslateRange(rangeValue, text, createRangeBetweenFormatter(locale), createPercentFormatter(locale), createDecimalFormatter(locale));
    return {
        formatCurrencyRange,
        formatPercentRange,
    };
};
