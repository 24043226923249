import { IsoLocale } from '@izettle/constants';
import { FunctionStepFuncKey } from 'src/components/Questionnaire/types';
import { SIGNATORY_ACTION, GAPS } from 'src/schema/gaps/LUX/data';
import { nationalityOptions } from '../../common/nationality-options';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { render, sequence, step, decision } from '../../steps-builder';
import { signatoryNamePrefilledTemplate, signatoryNationalityTemplate, signatoryPhoneNumberTemplate, signatoryAddressTemplate, signatoryDOBTemplate, signatoryNameTemplate, } from './common/steps/signatory';
const MASK = '99.99.9999';
const preparationScreenSignatory = ({ translate, formContext }) => {
    var _a;
    const gaps = (_a = formContext === null || formContext === void 0 ? void 0 : formContext.data) === null || _a === void 0 ? void 0 : _a.gaps;
    const conditionalItems = [];
    if (gaps.includes(GAPS.LUX_LOA)) {
        const LOAInformation = {
            icon: 'IdentityCard',
            title: translate('GAP_PREPARATION_SCREEN_ITEM_2_TITLE'),
            description: translate('GAP_PREPARATION_SCREEN_ITEM_2_DESCRIPTION'),
        };
        conditionalItems.push(LOAInformation);
    }
    return {
        componentName: 'PreparationScreen',
        applicationVariables: {
            stepName: 'PreparationScreen',
            content: {
                header: translate('GAP_PREPARATION_SCREEN_HEADER'),
                button: translate('FORM_BUTTON'),
                helpTexts: [
                    {
                        title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                        body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                    },
                ],
                items: [
                    {
                        icon: 'Person',
                        title: translate('GAP_PREPARATION_SCREEN_ITEM_1_TITLE'),
                    },
                    ...conditionalItems,
                ],
            },
        },
    };
};
const preparationScreenUBO = ({ translate, formContext }) => {
    var _a;
    const gaps = (_a = formContext === null || formContext === void 0 ? void 0 : formContext.data) === null || _a === void 0 ? void 0 : _a.gaps;
    const conditionalItems = [];
    if (gaps.includes(GAPS.LUX_LOA)) {
        const LOAInformation = {
            icon: 'IdentityCard',
            title: translate('GAP_PREPARATION_SCREEN_ITEM_2_TITLE'),
            description: translate('GAP_PREPARATION_SCREEN_ITEM_2_DESCRIPTION'),
        };
        conditionalItems.push(LOAInformation);
    }
    return {
        componentName: 'PreparationScreen',
        applicationVariables: {
            stepName: 'PreparationScreen',
            content: {
                header: translate('GAP_PREPARATION_SCREEN_HEADER'),
                button: translate('FORM_BUTTON'),
                helpTexts: [
                    {
                        title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                        body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                    },
                    {
                        title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_1_ACCORDION_TITLE'),
                        body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_1_ACCORDION_CONTENT'),
                    },
                    {
                        title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                        body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                    },
                ],
                items: [
                    {
                        icon: 'People',
                        title: translate(`GAP_PREPARATION_SCREEN_ITEM_3_TITLE`),
                        description: translate('GAP_PREPARATION_SCREEN_ITEM_3_DESCRIPTION'),
                    },
                    ...conditionalItems,
                ],
            },
        },
    };
};
const preparationScreenSignatoryUBO = ({ translate, formContext }) => {
    var _a;
    const gaps = (_a = formContext === null || formContext === void 0 ? void 0 : formContext.data) === null || _a === void 0 ? void 0 : _a.gaps;
    const conditionalItems = [];
    if (gaps.includes(GAPS.LUX_LOA)) {
        const LOAInformation = {
            icon: 'IdentityCard',
            title: translate('GAP_PREPARATION_SCREEN_ITEM_2_TITLE'),
            description: translate('GAP_PREPARATION_SCREEN_ITEM_2_DESCRIPTION'),
        };
        conditionalItems.push(LOAInformation);
    }
    return {
        componentName: 'PreparationScreen',
        applicationVariables: {
            stepName: 'PreparationScreen',
            content: {
                header: translate('GAP_PREPARATION_SCREEN_HEADER'),
                button: translate('FORM_BUTTON'),
                helpTexts: [
                    {
                        title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                        body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                    },
                ],
                items: [
                    {
                        icon: 'Person',
                        title: translate('GAP_PREPARATION_SCREEN_ITEM_1_TITLE'),
                    },
                    {
                        icon: 'People',
                        title: translate(`GAP_PREPARATION_SCREEN_ITEM_3_TITLE`),
                        description: translate('GAP_PREPARATION_SCREEN_ITEM_3_DESCRIPTION'),
                    },
                    ...conditionalItems,
                ],
            },
        },
    };
};
const legalPeoplePreparation = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        segment: 'ownerStructure',
        stepName: 'sectionIntro',
        content: {
            header: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'People',
                    title: '',
                    description: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_ITEM_1_DESCRIPTION'),
                },
            ],
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_1_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_1_ACCORDION_CONTENT'),
                },
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                },
            ],
        },
    },
});
const overviewPage = ({ translate }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: 'GAP_GB_LTD_COMPANY',
        confirmationScreen: {
            title: translate('GAP_OVERVIEW_CONFIRMATION_TITLE'),
            subtitle: translate('GAP_OVERVIEW_CONFIRMATION_SUB_TITLE'),
            button: translate('GAP_OVERVIEW_CLOSE_BUTTON'),
        },
        processingScreen: {
            title: translate('PROCESSING_PAGE_TITLE'),
            subtitle: translate('PROCESSING_PAGE_SUBTITLE'),
            button: translate('PROCESSING_PAGE_CLOSE_BUTTON'),
        },
        content: {
            title: '',
            subtitle: '',
            submitButton: translate('GAP_OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(IsoLocale.enGB)]),
        },
    },
});
const legalPeopleOmitPropsFunc = () => ({
    functions: [FunctionStepFuncKey.GAP_omitLegalPeopleProps],
    fields: [],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'legalPeopleOmitPropsFunc',
    },
});
const legalPeopleReadonlyPropsFunc = () => ({
    functions: [FunctionStepFuncKey.GAP_readonlyLegalPeopleProps],
    fields: [],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'legalPeopleReadonlyPropsFunc',
    },
});
const legalPeople = ({ translate }) => ({
    backendValidation: true,
    fields: ['legalPeople'],
    JSONSchema: {
        type: 'object',
        properties: {
            legalPeople: {
                title: translate('GAP_EXISTING_LEGAL_PERSON_TITLE'),
                description: translate('GAP_EXISTING_LEGAL_PERSON_DESCRIPTION'),
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        nameTitle: {
                            title: translate('PERSON_NAME_LABEL'),
                            type: 'null',
                        },
                        firstName: {
                            type: 'string',
                        },
                        lastName: {
                            type: 'string',
                        },
                        dateOfBirthTitle: {
                            title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                            type: 'null',
                        },
                        dateOfBirth: {
                            type: 'string',
                        },
                        nationalityTitle: {
                            title: translate('PERSON_NATIONALITY_LABEL'),
                            type: 'null',
                        },
                        nationality: {
                            type: 'string',
                            enum: nationalityOptions.map((option) => option.value),
                            enumNames: nationalityOptions.map((option) => translate(option.textKey)),
                        },
                        addressTitle: {
                            title: translate('PERSON_HOME_ADDRESS_LABEL'),
                            type: 'null',
                        },
                        building: {
                            type: 'string',
                        },
                        streetAddress: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        county: {
                            type: 'string',
                        },
                        postalCode: {
                            type: 'string',
                        },
                        roleTitle: {
                            title: translate('GAP_PERSON_ROLE_LABEL'),
                            description: translate('GAP_EXISTING_LEGAL_PERSON_ROLE_DESCRIPTION'),
                            type: 'null',
                        },
                        role: {
                            type: 'array',
                            items: {
                                type: 'string',
                                oneOf: [
                                    { enum: ['UBO'], title: translate('GAP_LEGAL_PERSONS_ROLE_UBO') },
                                    {
                                        enum: ['Director'],
                                        title: translate('GAP_OTHER_LEGAL_PERSONS_ROLE_DIRECTOR'),
                                    },
                                ],
                            },
                            uniqueItems: true,
                        },
                    },
                },
            },
        },
    },
    UISchema: {
        legalPeople: {
            'ui:options': {
                label: false,
                orderable: false,
                addable: false,
                removable: false,
            },
            items: {
                firstName: {
                    'ui:disabled': true,
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('FIRST_NAME_LABEL'),
                    },
                },
                lastName: {
                    'ui:disabled': true,
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('LAST_NAME_LABEL'),
                    },
                },
                dateOfBirthTitle: {
                    'ui:options': {
                        omittable: true,
                    },
                },
                dateOfBirth: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('DATE_OF_BIRTH_PLACEHOLDER'),
                        mask: MASK,
                        omittable: true,
                    },
                },
                nationalityTitle: {
                    'ui:options': {
                        omittable: true,
                    },
                },
                nationality: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:widget': 'CustomComboBoxWidget',
                    'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
                    'ui:options': {
                        sortByLabels: 'asc',
                        omittable: true,
                    },
                },
                addressTitle: {
                    'ui:options': {
                        omittable: true,
                    },
                },
                building: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_BUILDING_LABEL'),
                        omittable: true,
                    },
                },
                streetAddress: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
                        omittable: true,
                    },
                },
                city: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_CITY_LABEL'),
                        omittable: true,
                    },
                },
                county: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_COUNTY_LABEL'),
                        omittable: true,
                    },
                },
                postalCode: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                        omittable: true,
                    },
                },
                role: {
                    'ui:options': {
                        allowReadonlyValues: true,
                    },
                    'ui:title': false,
                    'ui:widget': 'CustomCheckboxGroupWidget',
                },
            },
        },
    },
    applicationVariables: {
        stepName: 'prefilledOwners',
        segment: 'ownerStructure',
        content: {
            personLabel: translate('OTHER_LEGAL_PERSONS_PERSON_LABEL'),
            removeAdd: true,
        },
    },
});
const legalPeoplePropsSequence = [
    step(legalPeopleOmitPropsFunc),
    step(legalPeopleReadonlyPropsFunc),
];
const newLegalPeople = ({ translate }) => ({
    backendValidation: true,
    fields: ['newLegalPeople'],
    JSONSchema: {
        type: 'object',
        properties: {
            newLegalPeople: {
                title: translate('GAP_NEW_LEGAL_PERSON_TITLE'),
                type: 'array',
                minItems: 1,
                items: {
                    type: 'object',
                    properties: {
                        nameTitle: {
                            title: translate('PERSON_NAME_LABEL'),
                            type: 'null',
                        },
                        firstName: {
                            type: 'string',
                        },
                        lastName: {
                            type: 'string',
                        },
                        dateOfBirthTitle: {
                            title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                            type: 'null',
                        },
                        dateOfBirth: {
                            type: 'string',
                        },
                        nationalityTitle: {
                            title: translate('PERSON_NATIONALITY_LABEL'),
                            type: 'null',
                        },
                        nationality: {
                            type: 'string',
                            enum: nationalityOptions.map((option) => option.value),
                            enumNames: nationalityOptions.map((option) => translate(option.textKey)),
                        },
                        addressTitle: {
                            title: translate('PERSON_HOME_ADDRESS_LABEL'),
                            type: 'null',
                        },
                        building: {
                            type: 'string',
                        },
                        streetAddress: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        county: {
                            type: 'string',
                        },
                        postalCode: {
                            type: 'string',
                        },
                        roleTitle: {
                            title: translate('GAP_PERSON_ROLE_LABEL'),
                            description: translate('GAP_PERSON_ROLE_DESCRIPTION'),
                            type: 'null',
                        },
                        role: {
                            type: 'array',
                            items: {
                                type: 'string',
                                oneOf: [
                                    { enum: ['UBO'], title: translate('GAP_LEGAL_PERSONS_ROLE_UBO') },
                                    {
                                        enum: ['Director'],
                                        title: translate('GAP_OTHER_LEGAL_PERSONS_ROLE_DIRECTOR'),
                                    },
                                ],
                            },
                            uniqueItems: true,
                        },
                    },
                },
            },
        },
    },
    UISchema: {
        newLegalPeople: {
            'ui:options': {
                label: false,
                orderable: false,
            },
            items: {
                firstName: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('FIRST_NAME_LABEL'),
                    },
                },
                lastName: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('LAST_NAME_LABEL'),
                    },
                },
                dateOfBirth: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('DATE_OF_BIRTH_PLACEHOLDER'),
                        mask: MASK,
                    },
                },
                nationality: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:widget': 'CustomComboBoxWidget',
                    'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
                    'ui:options': {
                        sortByLabels: 'asc',
                    },
                },
                building: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_BUILDING_LABEL'),
                    },
                },
                streetAddress: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
                    },
                },
                city: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_CITY_LABEL'),
                    },
                },
                county: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_COUNTY_LABEL'),
                    },
                },
                postalCode: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                    },
                },
                role: {
                    'ui:title': false,
                    'ui:widget': 'CustomCheckboxGroupWidget',
                },
            },
        },
    },
    applicationVariables: {
        stepName: 'newOwners',
        segment: 'ownerStructure',
        content: {
            personLabel: translate('OTHER_LEGAL_PERSONS_PERSON_LABEL'),
            addButton: translate('GAP_OTHER_LEGAL_PERSONS_ADD_BUTTON'),
            removeButton: translate('OTHER_LEGAL_PERSONS_REMOVE_BUTTON'),
        },
    },
});
const noPrefilledNewLegalPeople = ({ translate }) => ({
    backendValidation: true,
    fields: ['newLegalPeople'],
    JSONSchema: {
        type: 'object',
        properties: {
            newLegalPeople: {
                title: translate('GAP_NO_PREFILLED_NEW_LEGAL_PERSON_TITLE'),
                type: 'array',
                minItems: 1,
                items: {
                    type: 'object',
                    properties: {
                        nameTitle: {
                            title: translate('PERSON_NAME_LABEL'),
                            type: 'null',
                        },
                        firstName: {
                            type: 'string',
                        },
                        lastName: {
                            type: 'string',
                        },
                        dateOfBirthTitle: {
                            title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                            type: 'null',
                        },
                        dateOfBirth: {
                            type: 'string',
                        },
                        nationalityTitle: {
                            title: translate('PERSON_NATIONALITY_LABEL'),
                            type: 'null',
                        },
                        nationality: {
                            type: 'string',
                            enum: nationalityOptions.map((option) => option.value),
                            enumNames: nationalityOptions.map((option) => translate(option.textKey)),
                        },
                        addressTitle: {
                            title: translate('PERSON_HOME_ADDRESS_LABEL'),
                            type: 'null',
                        },
                        building: {
                            type: 'string',
                        },
                        streetAddress: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        county: {
                            type: 'string',
                        },
                        postalCode: {
                            type: 'string',
                        },
                        roleTitle: {
                            title: translate('GAP_PERSON_ROLE_LABEL'),
                            description: translate('GAP_PERSON_ROLE_DESCRIPTION'),
                            type: 'null',
                        },
                        role: {
                            type: 'array',
                            items: {
                                type: 'string',
                                oneOf: [
                                    { enum: ['UBO'], title: translate('GAP_LEGAL_PERSONS_ROLE_UBO') },
                                    {
                                        enum: ['Director'],
                                        title: translate('GAP_OTHER_LEGAL_PERSONS_ROLE_DIRECTOR'),
                                    },
                                ],
                            },
                            uniqueItems: true,
                        },
                    },
                },
            },
        },
    },
    UISchema: {
        newLegalPeople: {
            'ui:options': {
                label: false,
                orderable: false,
            },
            items: {
                firstName: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('FIRST_NAME_LABEL'),
                    },
                },
                lastName: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('LAST_NAME_LABEL'),
                    },
                },
                dateOfBirth: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('DATE_OF_BIRTH_PLACEHOLDER'),
                        mask: MASK,
                    },
                },
                nationality: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:widget': 'CustomComboBoxWidget',
                    'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
                    'ui:options': {
                        sortByLabels: 'asc',
                    },
                },
                building: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_BUILDING_LABEL'),
                    },
                },
                streetAddress: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
                    },
                },
                city: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_CITY_LABEL'),
                    },
                },
                county: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_COUNTY_LABEL'),
                    },
                },
                postalCode: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                    },
                },
                role: {
                    'ui:title': false,
                    'ui:widget': 'CustomCheckboxGroupWidget',
                },
            },
        },
    },
    applicationVariables: {
        stepName: 'newOwners',
        segment: 'ownerStructure',
        content: {
            personLabel: translate('OTHER_LEGAL_PERSONS_PERSON_LABEL'),
            addButton: translate('GAP_OTHER_LEGAL_PERSONS_ADD_BUTTON'),
            removeButton: translate('OTHER_LEGAL_PERSONS_REMOVE_BUTTON'),
        },
    },
});
const hasNewLegalPeople = ({ translate }) => ({
    fields: ['hasNewLegalPeople'],
    JSONSchema: {
        required: ['hasNewLegalPeople'],
        type: 'object',
        properties: {
            hasNewLegalPeople: {
                title: translate('GAP_HAS_OTHER_LEGAL_PERSONS_STEP_TITLE'),
                type: 'boolean',
                oneOf: [
                    {
                        enum: [true],
                        title: translate('GAP_HAS_OTHER_LEGAL_PERSONS_STEP_VALUE_1'),
                    },
                    { enum: [false], title: translate('GAP_HAS_OTHER_LEGAL_PERSONS_STEP_VALUE_2') },
                ],
            },
        },
    },
    UISchema: {
        hasNewLegalPeople: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'hasNewLegalPeople',
        segment: 'ownerStructure',
    },
});
const newLegalPeopleDefaultValueFunc = () => ({
    functions: [FunctionStepFuncKey.GAP_newLegalPeopleDefaultValue],
    fields: ['newLegalPeople'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'newLegalPeopleDefaultValueFunc',
    },
});
const signatoryPhoneNumberGB = signatoryPhoneNumberTemplate.extendDeep(({ translate }) => ({
    UISchema: {
        signatoryPhoneNumber: {
            'ui:placeholder': translate('SIGNATORY_PHONE_NUMBER_PLACEHOLDER'),
        },
    },
}));
const newSignatorySequence = [
    step(signatoryNameTemplate),
    step(signatoryDOBTemplate),
    step(signatoryAddressTemplate),
    step(signatoryPhoneNumberGB),
    step(signatoryNationalityTemplate),
];
const signatorySequence = [
    decision('signatoryAction', signatoryNamePrefilledTemplate, [
        { when: SIGNATORY_ACTION.CHANGE_SIGNATORY, branch: sequence(newSignatorySequence) },
        { when: SIGNATORY_ACTION.SET_NATIONALITY, branch: step(signatoryNationalityTemplate) },
    ]),
];
const defaultSignatorySequence = [
    step(preparationScreenSignatory),
    sequence(signatorySequence),
];
const UBOSequence = [
    step(preparationScreenUBO),
    sequence(legalPeoplePropsSequence),
    step(legalPeople),
    decision('hasNewLegalPeople', hasNewLegalPeople, [
        { when: true, branch: step(newLegalPeople) },
        { when: false, branch: step(newLegalPeopleDefaultValueFunc) },
    ]),
];
const EmptyUBOSequence = [
    step(preparationScreenUBO),
    step(noPrefilledNewLegalPeople),
];
const signatoryUBOSequence = [
    step(preparationScreenSignatoryUBO),
    sequence(signatorySequence),
    step(legalPeoplePreparation),
    sequence(legalPeoplePropsSequence),
    step(legalPeople),
    decision('hasNewLegalPeople', hasNewLegalPeople, [
        { when: true, branch: step(newLegalPeople) },
        { when: false, branch: step(newLegalPeopleDefaultValueFunc) },
    ]),
];
const signatoryEmptyUBOSequence = [
    step(preparationScreenSignatoryUBO),
    sequence(signatorySequence),
    step(legalPeoplePreparation),
    step(noPrefilledNewLegalPeople),
];
export const GAP_GB_LTD_COMPANY = (context) => {
    var _a, _b;
    const { formContext } = context;
    const gaps = (_a = formContext === null || formContext === void 0 ? void 0 : formContext.data) === null || _a === void 0 ? void 0 : _a.gaps;
    if (!gaps) {
        throw new Error('formContext.data.gaps should always be defined');
    }
    const prefilledLegalPeople = (_b = formContext === null || formContext === void 0 ? void 0 : formContext.data) === null || _b === void 0 ? void 0 : _b.legalPeople;
    const hasPrefilledLegalPeople = prefilledLegalPeople === null || prefilledLegalPeople === void 0 ? void 0 : prefilledLegalPeople.length;
    const hasLegalPeopleGap = gaps.includes(GAPS.LUX_UBOS) || gaps.includes(GAPS.LUX_DIRECTORS);
    let sequenceToRender = defaultSignatorySequence;
    if (gaps.includes(GAPS.LUX_SIGNATORY_NATIONALITY) && hasLegalPeopleGap) {
        sequenceToRender = hasPrefilledLegalPeople ? signatoryUBOSequence : signatoryEmptyUBOSequence;
    }
    else if (hasLegalPeopleGap) {
        sequenceToRender = hasPrefilledLegalPeople ? UBOSequence : EmptyUBOSequence;
    }
    return render(sequence([sequence(sequenceToRender), step(overviewPage)]), context);
};
