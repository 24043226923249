import React from 'react';
import styled, { css } from 'styled-components';
import { colors, spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
import { flexItemTextWrapFix } from 'src/styles/mixins';
import { Buttons } from './buttons';
import { FieldContainer } from './container';
import { Errors } from './errors';
import { useField } from './hook';
const MultipleColumns = styled.div `
  display: flex;
  align-items: start;
`;
const Column = styled.div `
  &:first-child {
    ${flexItemTextWrapFix}
    flex-grow: 1;
  }
`;
const ValueContainer = styled.div `
  ${(props) => props.isError &&
    css `
      color: ${colors.text.error};
    `}
`;
const AppendContentContainer = styled.div `
  margin-top: ${spacing.xxSmall};
`;
export const Field = (props) => {
    const { title, children, removable, editable, buttonPlacement = 'value', titleStyle = 'RegularSecondary', appendContent, hotJarSuppressTitle, } = props;
    const { shouldRender, fieldReference, fieldErrorKeys, handleEditClick, handleRemoveClick } = useField(props);
    if (!shouldRender) {
        return null;
    }
    const id = `field_${fieldReference}`;
    const TitleComponent = Text[titleStyle];
    const titleId = `${id}_title`;
    const buttons = (React.createElement(Buttons, { fieldReference: fieldReference, editable: editable, removable: removable, onRemove: handleRemoveClick, onEdit: handleEditClick }));
    return (React.createElement(FieldContainer, { hasAppendedContent: Boolean(appendContent), role: "group", "aria-labelledby": titleId },
        React.createElement(MultipleColumns, null,
            React.createElement(Column, null,
                React.createElement(TitleComponent, { id: titleId, className: hotJarSuppressTitle ? 'data-hj-suppress' : undefined, marginBottom: "none", marginTop: "none" }, typeof title === 'function' ? title() : title)),
            buttonPlacement === 'title' && buttons),
        React.createElement(MultipleColumns, null,
            React.createElement(Column, null,
                React.createElement(ValueContainer, { isError: fieldErrorKeys.length > 0 },
                    typeof children === 'function' ? children() : children,
                    React.createElement(Errors, { errorKeys: fieldErrorKeys }))),
            buttonPlacement === 'value' && buttons),
        appendContent && React.createElement(AppendContentContainer, null, appendContent)));
};
