import { generateDigitSequence } from '../../helpers';
export const generateLegalEntityNumber = () => {
    return `${generateDigitSequence(9)}000${generateDigitSequence(2)}`;
};
export const commonData = {
    businessName: 'Camembert & Baguette',
    businessStreetAddress: 'Avenue Joseph Bouvard',
    businessStreetAddressLine2: '',
    businessPostalCode: '78586',
    businessCity: 'Paris',
    signatoryFirstName: 'Dane',
    signatoryLastName: 'Jones',
    signatoryDateOfBirth: '12/12/1999',
    signatoryNationality: 'US',
    isSignatoryShareAddress: true,
    signatoryPhoneNumber: '0853200985',
    businessCategoryId: '746',
    businessSubCategoryId: '749',
    businessLocationTypes: ['TEMPORARY_STORE'],
    businessFrequency: 'FEW_TIMES_A_MONTH',
    businessAverageTransaction: '0-50',
    businessIzettleShareOfTurnover: '0-20%',
    businessCurrentAnnualTurnover: '0-20000',
};
// Partial generators
const generatePersonData = () => ({
    firstName: 'Maria',
    lastName: 'Jones',
    streetAddress: 'Eiffel Tower',
    streetAddressLine2: '',
    postalCode: '98695',
    city: 'Paris',
    dateOfBirth: '12/12/1999',
    nationality: 'AF',
});
// Complete generators for each company type
const generateSelfEmployedData = (formContext) => (Object.assign(Object.assign(Object.assign({}, commonData), { businessTypePartOne: 'SelfEmployed', businessType: 'SelfEmployed', businessLegalEntityNumber: generateLegalEntityNumber() }), (formContext.form.endsWith('1099K') ? { signatoryUnderUsaTaxLaw: false } : false)));
export const generateNonProfitData = () => (Object.assign(Object.assign({}, commonData), { businessTypePartOne: 'NonProfit', businessType: 'NonProfit', businessLegalEntityNumber: generateLegalEntityNumber(), legalPersons: {
        '5f230001-f91b-4ff9-bac3-228879a20993': generatePersonData(),
    }, roleDirectors: ['SIGNATORY', '5f230001-f91b-4ff9-bac3-228879a20993'], roleAlternativeOwners: ['SIGNATORY', '5f230001-f91b-4ff9-bac3-228879a20993'] }));
export const generateCommonLimitedCompanyData = () => (Object.assign(Object.assign({}, commonData), { businessTypePartOne: 'CommonLimitedCompany', businessType: 'LimitedCompany', businessLegalEntityNumber: generateLegalEntityNumber(), legalPersons: {
        '5f230001-f91b-4ff9-bac3-228879a20993': generatePersonData(),
    }, roleDirectors: ['SIGNATORY', '5f230001-f91b-4ff9-bac3-228879a20993'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '5f230001-f91b-4ff9-bac3-228879a20993'] }));
export const generateOtherLimitedCompanyData = () => (Object.assign(Object.assign({}, commonData), { businessTypePartOne: 'Other', businessTypePartTwo: 'LimitedCompany', businessType: 'LimitedCompany', businessLegalEntityNumber: generateLegalEntityNumber(), legalPersons: {
        '5f230001-f91b-4ff9-bac3-228879a20993': generatePersonData(),
    }, roleDirectors: ['SIGNATORY', '5f230001-f91b-4ff9-bac3-228879a20993'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '5f230001-f91b-4ff9-bac3-228879a20993'] }));
export const generatePartnershipData = () => (Object.assign(Object.assign({}, commonData), { businessTypePartOne: 'Other', businessTypePartTwo: 'Partnership', businessType: 'Partnership', businessLegalEntityNumber: generateLegalEntityNumber(), legalPersons: {
        '5f230001-f91b-4ff9-bac3-228879a20993': generatePersonData(),
    }, rolePartners: ['SIGNATORY', '5f230001-f91b-4ff9-bac3-228879a20993'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '5f230001-f91b-4ff9-bac3-228879a20993'] }));
export const FR = [
    {
        title: 'Self Employed',
        generateFunc: generateSelfEmployedData,
    },
    {
        title: 'Non-profit',
        generateFunc: generateNonProfitData,
    },
    {
        title: 'SARL / SAS / SA',
        generateFunc: generateCommonLimitedCompanyData,
    },
    {
        title: 'Limited Company',
        generateFunc: generateOtherLimitedCompanyData,
    },
    {
        title: 'Partnership',
        generateFunc: generatePartnershipData,
    },
];
