import React from 'react';
import styled from 'styled-components';
import { colors, borderRadius, spacing, IconCirclePlusMedium, IconTrashMedium } from '@izettle/otto';
import { Text } from '@izettle/react';
import { InlineButtonWithIcon } from 'src/components/InlineButtonWithIcon';
const TitleContainer = styled.div `
  margin-bottom: ${spacing.small};
`;
const RemoveButton = styled(InlineButtonWithIcon) `
  margin-top: ${spacing.medium};
`;
const AddButton = styled(InlineButtonWithIcon) `
  margin-top: ${spacing.small};
`;
const ArrayListContainer = styled.div ``;
const ArrayItemContainer = styled.div `
  background: ${colors.background.groupedPrimary};
  border-radius: ${borderRadius.medium};
  padding: ${spacing.small};
  margin-top: ${spacing.small};

  &:first-child {
    margin-top: 0;
  }
`;
const ArrayItemTitleContainer = styled.div `
  margin-bottom: ${spacing.medium};
`;
export const ArrayFieldTemplate = ({ items, onAddClick, title, schema, DescriptionField, canAdd, formContext, idSchema, }) => {
    const { resetValidationErrors, extraTexts } = formContext;
    const personLabel = (extraTexts === null || extraTexts === void 0 ? void 0 : extraTexts.personLabel) || 'Person';
    const handleDelete = (element) => {
        resetValidationErrors();
        element.onDropIndexClick(element.index)();
    };
    return (React.createElement(React.Fragment, null,
        title && (React.createElement(TitleContainer, { id: "labelContainer" },
            React.createElement(Text.Regular, { className: "override-text" }, title))),
        schema.description && React.createElement(DescriptionField, { id: `${idSchema.$id}-description`, description: schema.description }),
        React.createElement(ArrayListContainer, null, items.map((element) => {
            return (React.createElement(ArrayItemContainer, { key: element.index, className: "array-item", "data-index": element.index },
                React.createElement(ArrayItemTitleContainer, null,
                    React.createElement(Text.RegularBold, { className: "override-text" }, `${personLabel} ${element.index + 1}`)),
                React.createElement(React.Fragment, null, element.children),
                element.hasRemove && (items.length > 1 || element.index > 0) && (React.createElement(RemoveButton, { id: `removeOwner_${element.index}`, className: "removeOwner", onClick: () => handleDelete(element), variant: "inlineDestructive", size: "small", svgIcon: IconTrashMedium, svgIconTransform: { y: '-2px' } }, (extraTexts === null || extraTexts === void 0 ? void 0 : extraTexts.removeButton) || 'Remove'))));
        })),
        canAdd && (React.createElement(AddButton, { id: "addOtherOwnerBtn", size: "small", onClick: onAddClick, variant: "inline", svgIcon: IconCirclePlusMedium }, (extraTexts === null || extraTexts === void 0 ? void 0 : extraTexts.addButton) || 'Add'))));
};
