import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
const LoadingIconOverlay = styled.img.attrs((props) => ({
    alt: 'loading',
    src: props.src,
    id: 'loading-icon',
})) `
  opacity: 0;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  margin: 25%;

  .ujet-loading > & {
    opacity: 1;
    transition: opacity 1s;
  }
`;
const getUJETThemeIcons = (paypalThemeEnabled) => {
    return {
        chatIcon: paypalThemeEnabled ? 'ujet-chat-start-blue-500.svg' : 'ujet-chat-start-lilac-80.svg',
        loadingIcon: paypalThemeEnabled ? 'ujet-loading-icon-blue-500.svg' : 'ujet-loading-icon-lilac-80.svg',
    };
};
export const attachLoadingIconOverlayToLauncher = (ujetInstance, onError, paypalThemeEnabled) => {
    const ujetLauncher = document.getElementById('ujet-launcher');
    if (!ujetLauncher) {
        onError(new Error('UJET launcher not found when trying to attach the loading overlay'));
        return;
    }
    const { loadingIcon } = getUJETThemeIcons(paypalThemeEnabled);
    const image = `https://cdn.izettle.com/ujet-icons/${loadingIcon}`;
    const loaderContainer = document.createElement('div');
    loaderContainer.setAttribute('id', 'loader-image');
    ReactDOM.render(React.createElement(LoadingIconOverlay, { src: image }), loaderContainer);
    ujetLauncher.ariaHidden = 'true';
    ujetLauncher.appendChild(loaderContainer);
    ujetInstance.registerHook('loading', () => {
        loaderContainer.classList.add('ujet-loading');
    });
    ujetInstance.registerHook('open', () => {
        loaderContainer.classList.remove('ujet-loading');
    });
    ujetInstance.registerHook('close', () => {
        loaderContainer.classList.remove('ujet-loading');
    });
};
export const updateUJETIcons = (ujetInstance, paypalThemeEnabled) => {
    var _a, _b;
    const ujetLauncher = document.getElementById('ujet-launcher');
    if (!ujetLauncher) {
        throw new Error('UJET launcher not found when trying to attach the loading overlay');
    }
    const findIframe = ujetLauncher.getElementsByTagName('iframe');
    const { chatIcon, loadingIcon } = getUJETThemeIcons(paypalThemeEnabled);
    const ujetLoaderElementExist = document.getElementById('loader-image');
    if (findIframe) {
        const innerDoc = findIframe[0].contentWindow ? findIframe[0].contentDocument : (_a = findIframe[0]) === null || _a === void 0 ? void 0 : _a.contentWindow;
        const chatIconElement = innerDoc === null || innerDoc === void 0 ? void 0 : innerDoc.getElementById('chat');
        const image = `https://cdn.izettle.com/ujet-icons/${chatIcon}`;
        if (chatIconElement) {
            chatIconElement.setAttribute('src', image);
        }
    }
    if (ujetLoaderElementExist) {
        const loadingIconFullURL = `https://cdn.izettle.com/ujet-icons/${loadingIcon}`;
        const loaderContainer = ujetLoaderElementExist === null || ujetLoaderElementExist === void 0 ? void 0 : ujetLoaderElementExist.getElementsByTagName('img');
        (_b = loaderContainer[0]) === null || _b === void 0 ? void 0 : _b.setAttribute('src', loadingIconFullURL);
    }
};
