import ownerStructureHelpSvg from 'src/assets/owner-structure-help.svg';
import { FunctionStepFuncKey } from 'src/components/Questionnaire/types';
import { nationalityOptions } from 'src/schema/common/nationality-options';
import { RoleFieldName } from 'src/schema/common/owner-structure';
import { getPrivacyPolicyLink } from 'src/schema/common/privacy-policy-link';
import { template } from 'src/schema/template';
import { BusinessTypePartOne, BusinessTypePartTwo, formatCurrencyRange, formatPercentRange, getLimitedPartnershipRoleLabels, getNonProfitRoleLabels, locale, } from 'src/utils/country/FR';
export const businessLocationsTypes = ({ translate }) => ({
    fields: ['businessLocationTypes'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLocationTypes: {
                title: translate('BUSINESS_LOCATION_TYPES_LABEL'),
                description: translate('BUSINESS_LOCATION_TYPES_INSTRUCTION'),
                type: 'array',
                minItems: 1,
                items: {
                    type: 'string',
                    anyOf: [
                        { enum: ['PHYSICAL_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_PHYSICAL_STORE') },
                        { enum: ['TEMPORARY_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_TEMPORARY_STORE') },
                        { enum: ['EVENT_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_EVENT_STORE') },
                        { enum: ['ONLINE_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_ONLINE_STORE') },
                    ],
                },
                uniqueItems: true,
            },
        },
    },
    UISchema: {
        businessLocationTypes: {
            'ui:widget': 'CustomCheckboxGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessLocationTypes',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessFrequency = ({ translate }) => ({
    fields: ['businessFrequency'],
    JSONSchema: {
        required: ['businessFrequency'],
        type: 'object',
        properties: {
            businessFrequency: {
                title: translate('BUSINESS_FREQUENCY_LABEL'),
                type: 'string',
                oneOf: [
                    { enum: ['EVERY_DAY'], title: translate('BUSINESS_FREQUENCY_VALUE_EVERY_DAY') },
                    {
                        enum: ['FEW_TIMES_A_MONTH'],
                        title: translate('BUSINESS_FREQUENCY_VALUE_FEW_TIMES_A_MONTH'),
                    },
                    {
                        enum: ['FEW_TIMES_A_YEAR'],
                        title: translate('BUSINESS_FREQUENCY_VALUE_FEW_TIMES_A_YEAR'),
                    },
                ],
            },
        },
    },
    UISchema: {
        businessFrequency: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessFrequency',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessAverageTransaction = ({ translate }) => ({
    fields: ['businessAverageTransaction'],
    JSONSchema: {
        required: ['businessAverageTransaction'],
        type: 'object',
        properties: {
            businessAverageTransaction: {
                title: translate('BUSINESS_AVERAGE_TRANSACTION_LABEL'),
                type: 'string',
                oneOf: ['0-50', '50-500', '500-X'].map((range) => ({
                    enum: [range],
                    title: formatCurrencyRange(range, translate),
                })),
            },
        },
    },
    UISchema: {
        businessAverageTransaction: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessAverageTransaction',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessIzettleShareOfTurnover = ({ translate }) => ({
    fields: ['businessIzettleShareOfTurnover'],
    JSONSchema: {
        required: ['businessIzettleShareOfTurnover'],
        type: 'object',
        properties: {
            businessIzettleShareOfTurnover: {
                title: translate('BUSINESS_IZETTLE_SHARE_OF_TURNOVER_LABEL'),
                type: 'string',
                oneOf: ['0-20%', '20-80%', '80-X%'].map((rangeValue) => ({
                    enum: [rangeValue],
                    title: formatPercentRange(rangeValue, translate),
                })),
            },
        },
    },
    UISchema: {
        businessIzettleShareOfTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessIzettleShareOfTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessCurrentAnnualTurnover = ({ translate }) => ({
    fields: ['businessCurrentAnnualTurnover'],
    JSONSchema: {
        required: ['businessCurrentAnnualTurnover'],
        type: 'object',
        properties: {
            businessCurrentAnnualTurnover: {
                title: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_LABEL'),
                description: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_INSTRUCTION'),
                type: 'string',
                oneOf: [
                    {
                        enum: ['NEW_BUSINESS'],
                        title: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_VALUE_NEW_BUSINESS'),
                    },
                    ...['0-20000', '20000-200000', '200000-900000', '900000-X'].map((rangeValue) => ({
                        enum: [rangeValue],
                        title: formatCurrencyRange(rangeValue, translate),
                    })),
                ],
            },
        },
    },
    UISchema: {
        businessCurrentAnnualTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessCurrentAnnualTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessExpectedAnnualTurnover = ({ translate }) => ({
    fields: ['businessExpectedAnnualTurnover'],
    JSONSchema: {
        required: ['businessExpectedAnnualTurnover'],
        type: 'object',
        properties: {
            businessExpectedAnnualTurnover: {
                title: translate('BUSINESS_EXPECTED_ANNUAL_TURNOVER_LABEL'),
                type: 'string',
                oneOf: ['0-20000', '20000-200000', '200000-900000', '900000-X'].map((rangeValue) => ({
                    enum: [rangeValue],
                    title: formatCurrencyRange(rangeValue, translate),
                })),
            },
        },
    },
    UISchema: {
        businessExpectedAnnualTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessExpectedAnnualTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const generalUsageOfIZettleIntro = template(({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'usageOfIzettle',
        content: {
            icon: 'CardReaderOne',
            image: 'highFive',
            header: translate('SECTION_INTRO_USAGE_OF_IZETTLE_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_USAGE_OF_IZETTLE_TITLE'),
            button: translate('FORM_BUTTON'),
        },
    },
}));
export const selfEmployedUsageOfIZettleIntro = generalUsageOfIZettleIntro.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            header: translate('SECTION_INTRO_USAGE_OF_IZETTLE_SELF_EMPLOYED_HEADER'),
        },
    },
}));
export const generalOwnerStructureIntro = template(({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'ownerStructure',
        content: {
            icon: 'IdentityCard',
            image: 'highFive',
            header: translate('SECTION_INTRO_OWNER_STRUCTURE_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_OWNER_STRUCTURE_TITLE'),
            description: translate('SECTION_INTRO_OWNER_STRUCTURE_DESCRIPTION'),
            button: translate('FORM_BUTTON'),
        },
    },
}));
export const nonProfitOwnerStructureIntro = generalOwnerStructureIntro.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            title: translate('SECTION_INTRO_OWNER_STRUCTURE_NON_PROFIT_TITLE'),
        },
    },
}));
export const personalDetailsQuestionsIntro = ({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'personalDetails',
        content: {
            icon: 'Person',
            image: 'highFive',
            header: translate('SECTION_INTRO_PERSONAL_DETAILS_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_PERSONAL_DETAILS_TITLE'),
            description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
            button: translate('FORM_BUTTON'),
        },
    },
});
export const signatoryName = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryFirstName', 'signatoryLastName'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryQuestion: {
                title: translate('SIGNATORY_FULL_NAME_LABEL'),
                description: translate('SIGNATORY_NAME_INSTRUCTION'),
                type: 'null',
            },
            signatoryFirstName: {
                type: 'string',
            },
            signatoryLastName: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryFirstName: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_ALL_LABEL'),
            },
        },
        signatoryLastName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('LAST_NAME_ALL_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryName',
        segment: 'personalDetails',
    },
});
export const signatoryDOB = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryDateOfBirth'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryDateOfBirth: {
                title: translate('SIGNATORY_DATE_OF_BIRTH_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryDateOfBirth: {
            'ui:options': {
                mask: "99/99/9999" /* CopyFR.DATE_OF_BIRTH_MASK */,
            },
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': "JJ/MM/AAAA" /* CopyFR.DATE_OF_BIRTH_PLACEHOLDER */,
        },
    },
    applicationVariables: {
        stepName: 'signatoryDateOfBirth',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_DATE_OF_BIRTH_HELP_TEXT_WHY_DATE_OF_BIRTH_TITLE'),
                    body: translate('SIGNATORY_DATE_OF_BIRTH_HELP_TEXT_WHY_DATE_OF_BIRTH_BODY'),
                },
            ],
        },
    },
});
export const signatoryNationality = ({ translate }) => ({
    fields: ['signatoryNationality'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryNationality: {
                title: translate('SIGNATORY_NATIONALITY_LABEL'),
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
        },
    },
    UISchema: {
        signatoryNationality: {
            'ui:widget': 'CustomComboBoxWidget',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
            'ui:options': {
                sortByLabels: 'asc',
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryNationality',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_NATIONALITY_HELP_TEXT_WHY_NATIONALITY_TITLE'),
                    body: translate('SIGNATORY_NATIONALITY_HELP_TEXT_WHY_NATIONALITY_BODY'),
                },
            ],
        },
    },
});
export const isSignatoryShareAddress = ({ translate }) => ({
    fields: ['isSignatoryShareAddress'],
    JSONSchema: {
        required: ['isSignatoryShareAddress'],
        type: 'object',
        properties: {
            isSignatoryShareAddress: {
                title: translate('IS_SIGNATORY_SHARE_ADDRESS_LABEL'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('IS_SIGNATORY_SHARE_ADDRESS_VALUE_YES') },
                    { enum: [false], title: translate('IS_SIGNATORY_SHARE_ADDRESS_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        isSignatoryShareAddress: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'isSignatoryShareAddress',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('IS_SIGNATORY_SHARE_ADDRESS_HELP_TEXT_WHY_ADDRESS_TITLE'),
                    body: translate('IS_SIGNATORY_SHARE_ADDRESS_HELP_TEXT_WHY_ADDRESS_BODY'),
                },
            ],
        },
    },
});
export const signatoryAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryStreetAddress', 'signatoryStreetAddressLine2', 'signatoryPostalCode', 'signatoryCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryStreetAddressLine2: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        signatoryStreetAddressLine2: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LINE2_FR_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                mask: "99999" /* CopyFR.ADDRESS_POSTAL_CODE_MASK */,
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
    },
});
export const signatoryPhoneNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryPhoneNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryPhoneNumber: {
                title: translate('SIGNATORY_PHONE_NUMBER_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryPhoneNumber: {
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:placeholder': translate('FORM_EXAMPLE', ["07 12 34 56 78" /* CopyFR.SIGNATORY_PHONE_NUMBER_EXAMPLE */]),
            'ui:options': {
                mask: "+99999999999999999999" /* CopyFR.SIGNATORY_PHONE_NUMBER_MASK */,
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryPhoneNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_PHONE_NUMBER_TITLE'),
                    body: translate('SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_PHONE_NUMBER_BODY'),
                },
            ],
        },
    },
});
export const generalBusinessAddress = template(({ translate }) => ({
    backendValidation: true,
    fields: ['businessStreetAddress', 'businessPostalCode', 'businessCity', 'businessStreetAddressLine2'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_LABEL'),
                description: translate('BUSINESS_ADDRESS_FR_INSTRUCTION'),
                type: 'null',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessStreetAddressLine2: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'), // @ToDo: different copy
            },
        },
        businessStreetAddressLine2: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LINE2_FR_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                mask: "99999" /* CopyFR.ADDRESS_POSTAL_CODE_MASK */,
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_ADDRESS_READER_HELP_TEXT_TITLE'),
                    body: translate('BUSINESS_ADDRESS_READER_HELP_TEXT_BODY'),
                },
            ],
        },
    },
}));
export const nonProfitBusinessAddress = generalBusinessAddress.overrideDeep(({ translate }) => ({
    JSONSchema: {
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_NON_PROFIT_LABEL'),
                description: translate('BUSINESS_ADDRESS_FR_NON_PROFIT_INSTRUCTION'),
            },
        },
    },
}));
export const generalBusinessEntityNumber = template(({ translate }) => ({
    backendValidation: true,
    fields: ['businessLegalEntityNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLegalEntityNumber: {
                title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_FR_LABEL'),
                description: translate('BUSINESS_LEGAL_ENTITY_NUMBER_FR_INSTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessLegalEntityNumber: {
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': "p. ex. 123456789 000 12" /* CopyFR.BUSINESS_LEGAL_ENTITY_NUMBER_PLACEHOLDER */,
            'ui:options': {
                mask: "999999999 999 99" /* CopyFR.BUSINESS_LEGAL_ENTITY_NUMBER_MASK */,
            },
        },
    },
    applicationVariables: {
        stepName: 'businessLegalEntityNumber',
        segment: 'businessDetails',
    },
}));
export const nonProfitBusinessEntityNumber = generalBusinessEntityNumber
    .overrideDeep(({ translate }) => ({
    JSONSchema: {
        properties: {
            businessLegalEntityNumber: {
                description: translate('BUSINESS_LEGAL_ENTITY_NUMBER_FR_NON_PROFIT_INSTRUCTION'),
            },
        },
    },
}))
    .extendDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_FR_NON_PROFIT_NO_SIRET_HELP_TEXT_TITLE'),
                    body: translate('BUSINESS_LEGAL_ENTITY_NUMBER_FR_NON_PROFIT_NO_SIRET_HELP_TEXT_BODY'),
                },
            ],
        },
    },
}));
export const generalBusinessName = template(({ translate }) => ({
    fields: ['businessName'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            businessName: {
                title: translate('BUSINESS_NAME_LABEL'),
                description: translate('BUSINESS_NAME_FR_INSTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessName: {
            'ui:autofocus': true,
        },
    },
    applicationVariables: {
        stepName: 'businessName',
        segment: 'businessDetails',
    },
}));
export const selfEmployedBusinessName = generalBusinessName
    .overrideDeep(({ translate }) => ({
    JSONSchema: {
        properties: {
            businessName: {
                description: translate('BUSINESS_NAME_FR_SELF_EMPLOYED_INSTRUCTION'),
            },
        },
    },
}))
    .extendDeep(() => ({
    UISchema: {
        businessName: {
            'ui:placeholder': "p. ex. Marion Dubois" /* CopyFR.BUSINESS_NAME_SELF_EMPLOYED_PLACEHOLDER */,
        },
    },
}));
export const nonProfitBusinessName = generalBusinessName.overrideDeep(({ translate }) => ({
    JSONSchema: {
        properties: {
            businessName: {
                title: translate('BUSINESS_NAME_NON_PROFIT_LABEL'),
                description: translate('BUSINESS_NAME_FR_NON_PROFIT_INSTRUCTION'),
            },
        },
    },
}));
export const generalPrepScreen = template(({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_FR_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_DECISION_MAKERS_HEADER'),
                    description: translate('PREPARATION_SCREEN_DECISION_MAKERS_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
}));
export const selfEmployedPrepScreen = generalPrepScreen.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_FR_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
}));
export const nonProfitPrepScreen = generalPrepScreen.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_NON_PROFIT_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_FR_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_DOB_NATIONALITY_ADDRESS_PHONE_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_DECISION_MAKERS_NON_PROFIT_HEADER'),
                    description: translate('PREPARATION_SCREEN_DECISION_MAKERS_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
}));
export const businessTypePartTwo = ({ translate }) => ({
    fields: ['businessTypePartTwo'],
    JSONSchema: {
        required: ['businessTypePartTwo'],
        type: 'object',
        properties: {
            businessTypePartTwo: {
                title: translate('BUSINESS_TYPE_FR_PART_TWO_LABEL'),
                type: 'string',
                oneOf: [
                    {
                        enum: [BusinessTypePartTwo.LimitedCompany],
                        title: translate('BUSINESS_TYPE_FR_VALUE_LIMITEDCOMPANY'),
                    },
                    {
                        enum: [BusinessTypePartTwo.Partnership],
                        title: translate('BUSINESS_TYPE_FR_VALUE_PARTNERSHIP'),
                    },
                ],
            },
        },
    },
    UISchema: {
        businessTypePartTwo: {
            'ui:widget': 'CustomRadioGroupWidget',
            'ui:options': {
                descriptions: [
                    {
                        value: BusinessTypePartTwo.LimitedCompany,
                        description: translate('BUSINESS_TYPE_FR_VALUE_DESCRIPTION_LIMITEDCOMPANY'),
                    },
                    {
                        value: BusinessTypePartTwo.Partnership,
                        description: translate('BUSINESS_TYPE_FR_VALUE_DESCRIPTION_PARTNERSHIP'),
                    },
                ],
            },
        },
    },
    applicationVariables: {
        stepName: 'businessTypeOther',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_TYPE_FR_HELP_TEXT_PART_TWO_TITLE'),
                    body: translate('BUSINESS_TYPE_FR_HELP_TEXT_PART_TWO_BODY'),
                },
            ],
        },
    },
});
export const businessTypeFunction = () => ({
    functions: [FunctionStepFuncKey.FR_businessType],
    fields: ['businessType'],
    dependsOnFields: ['businessTypePartOne', 'businessTypePartTwo'],
    applicationVariables: {
        stepName: 'businessTypeFunc',
    },
});
export const businessTypePartOne = ({ translate }) => ({
    fields: ['businessTypePartOne'],
    JSONSchema: {
        title: translate('BUSINESS_TYPE_HEADER'),
        description: translate('BUSINESS_TYPE_INSTRUCTION'),
        required: ['businessTypePartOne'],
        type: 'object',
        properties: {
            businessTypePartOne: {
                title: translate('BUSINESS_TYPE_LABEL'),
                type: 'string',
                oneOf: [
                    { enum: [BusinessTypePartOne.SelfEmployed], title: translate('BUSINESS_TYPE_FR_VALUE_SELFEMPLOYED') },
                    { enum: [BusinessTypePartOne.NonProfit], title: translate('BUSINESS_TYPE_FR_VALUE_NONPROFIT') },
                    {
                        enum: [BusinessTypePartOne.CommonLimitedCompany],
                        title: translate('BUSINESS_TYPE_FR_VALUE_COMMONLIMITEDCOMPANY'),
                    },
                    {
                        enum: [BusinessTypePartOne.Other],
                        title: translate('BUSINESS_TYPE_FR_VALUE_OTHER'),
                    },
                ],
            },
        },
    },
    UISchema: {
        businessTypePartOne: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessType',
        segment: 'businessDetails',
    },
});
export const personStep = (translate) => {
    return {
        ID: 'personStepId',
        backendValidation: true,
        fields: [
            'firstName',
            'lastName',
            'dateOfBirth',
            'nationality',
            'streetAddress',
            'streetAddressLine2',
            'postalCode',
            'city',
        ],
        JSONSchema: {
            type: 'object',
            properties: {
                nameTitle: {
                    title: translate('PERSON_NAME_LABEL'),
                    type: 'null',
                },
                firstName: {
                    type: 'string',
                },
                lastName: {
                    type: 'string',
                },
                dateOfBirthTitle: {
                    title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                    type: 'null',
                },
                dateOfBirth: {
                    type: 'string',
                },
                nationalityTitle: {
                    title: translate('PERSON_NATIONALITY_LABEL'),
                    type: 'null',
                },
                nationality: {
                    type: 'string',
                    enum: nationalityOptions.map((option) => option.value),
                    enumNames: nationalityOptions.map((option) => translate(option.textKey)),
                },
                addressTitle: {
                    title: translate('PERSON_HOME_ADDRESS_LABEL'),
                    type: 'null',
                },
                streetAddress: {
                    type: 'string',
                },
                streetAddressLine2: {
                    type: 'string',
                },
                postalCode: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
            },
        },
        UISchema: {
            firstName: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('FIRST_NAME_ALL_LABEL'),
                },
            },
            lastName: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('LAST_NAME_ALL_LABEL'),
                },
            },
            dateOfBirth: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: "JJ/MM/AAAA" /* CopyFR.DATE_OF_BIRTH_PLACEHOLDER */,
                    mask: "99/99/9999" /* CopyFR.DATE_OF_BIRTH_MASK */,
                },
            },
            nationality: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:widget': 'CustomComboBoxWidget',
                'ui:emptyValue': '',
                'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
                'ui:options': {
                    sortByLabels: 'asc',
                },
            },
            streetAddress: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
                },
            },
            streetAddressLine2: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_STREET_ADDRESS_LINE2_FR_LABEL'),
                },
            },
            postalCode: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                    mask: "99999" /* CopyFR.ADDRESS_POSTAL_CODE_MASK */,
                },
            },
            city: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_CITY_LABEL'),
                },
            },
        },
        applicationVariables: {
            stepName: 'newPerson',
        },
    };
};
export const hasUBO = ({ translate }) => ({
    fields: ['hasUBO'],
    JSONSchema: {
        required: ['hasUBO'],
        type: 'object',
        title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
        properties: {
            hasUBO: {
                title: translate('OWNER_STRUCTURE_HAS_UBO_DESCRIPTION'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_YES') },
                    { enum: [false], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        hasUBO: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
        'ui:options': {
            titleStyle: 'LargeBold',
        },
    },
    applicationVariables: {
        stepName: 'hasUBO',
        segment: 'ownerStructure',
        content: {
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const initLegalPersonsFunction = () => ({
    functions: [FunctionStepFuncKey.initLegalPersons],
    fields: ['legalPersons'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'legalPersonsFunc',
        segment: 'ownerStructure',
    },
});
export const limitedDirectorsList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleDirectors'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'directorsList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleDirectors,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
            description: translate('OWNER_STRUCTURE_DIRECTORS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const nonProfitDirectorsList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleDirectors'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'directorsList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleDirectors,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getNonProfitRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL'),
            description: translate('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_NON_PROFIT_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const partnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['rolePartners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'partnersList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.rolePartners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_PARTNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_PARTNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const limitedPartnershipBeneficialOwnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleBeneficialOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'beneficialOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleBeneficialOwners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const limitedPartnershipAlternativeOwnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleAlternativeOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'alternativeOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleAlternativeOwners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const nonProfitAlternativeOwnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleAlternativeOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'alternativeOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleAlternativeOwners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getNonProfitRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_NON_PROFIT_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const overviewPage = ({ translate, formContext }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: 'FR',
        displayUsaTaxpayerTerms: formContext.form.endsWith('1099K'),
        content: {
            title: translate('OVERVIEW_TITLE'),
            subtitle: translate('OVERVIEW_SUB_TITLE'),
            submitButton: translate('OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: translate('OVERVIEW_SUBMIT_INSTRUCTION', [getPrivacyPolicyLink(locale)]),
            helpTexts: [
                {
                    title: translate('OVERVIEW_HELP_TEXT_PEP_INTRUCTIONS_TITLE'),
                    body: translate('OVERVIEW_HELP_TEXT_PEP_INTRUCTIONS_BODY'),
                },
            ],
        },
    },
});
