import React, { useContext, useEffect, useState } from 'react';
import { ScreenReaderOnly } from 'src/components/ScreenReaderOnly';
import { PageTitleContext } from './context';
export const PageTitleForScreenReader = () => {
    const pageTitleContext = useContext(PageTitleContext);
    const pageTitle = pageTitleContext === null || pageTitleContext === void 0 ? void 0 : pageTitleContext.currentTopTitle;
    const [updating, setUpdating] = useState(false);
    // A trick to ensure aria-live is working properly every time
    useEffect(() => {
        setUpdating(true);
        setTimeout(() => setUpdating(false), 0);
    }, [pageTitle]);
    // Header element cannot be rendered with an empty title, otherwise it will trigger a WAVE accessibility error
    if (updating || !pageTitle) {
        return null;
    }
    return (React.createElement(ScreenReaderOnly, { as: "h1", "aria-live": "polite" }, pageTitle));
};
