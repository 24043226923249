import React, { useEffect, useMemo, useState } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { PopupsContainer } from 'src/components/Popups';
import { Questionnaire } from 'src/components/Questionnaire';
import { createEmptyQuestionnaireState } from 'src/components/Questionnaire/state/create-empty-state';
import { ResumeScreen } from 'src/components/ResumeScreen';
import { useQuestionnaireLocalStorage } from 'src/hooks/use-questionnaire-local-storage';
import { useSubstitute } from 'src/hooks/use-substitute';
import * as Schemas from 'src/schema';
import { Debugger } from '../Debugger';
// eslint-disable-next-line complexity
const prefillMapper = (formContext) => {
    var _a, _b, _c, _d;
    switch (formContext.form) {
        case 'GAP_NPO':
        case 'GAP_PARTNERSHIP':
        case 'GAP_GB_LTD_COMPANY':
        case 'GAP_DE_LTD_COMPANY':
        case 'GAP_FR_LTD_COMPANY':
        case 'GAP_NL_LTD_COMPANY':
        case 'GAP_NL_NPO_COMPANY':
        case 'GAP_NL_PARTNERSHIP':
        case 'GAP_SE_NPO_COMPANY':
        case 'GAP_SE_LTD_COMPANY':
        case 'GAP_NO_NPO_COMPANY':
        case 'GAP_NO_LTD_COMPANY':
        case 'GAP_SE_PARTNERSHIP':
        case 'GAP_GB_LTD_COMPANY_NEW':
        case 'GAP_NO_PARTNERSHIP':
        case 'GAP_DE_NPO_COMPANY':
        case 'GAP_FR_NPO_COMPANY':
        case 'GAP_FI_LTD_COMPANY':
        case 'GAP_FI_NPO_COMPANY':
        case 'GAP_FI_PARTNERSHIP':
        case 'GAP_DK_LTD_COMPANY':
        case 'GAP_DK_PARTNERSHIP':
        case 'GAP_DK_NPO_COMPANY':
        case 'GAP_DE_PARTNERSHIP':
        case 'GAP_ES_LTD_COMPANY':
        case 'GAP_IT_LTD_COMPANY':
            return Object.assign(Object.assign({}, formContext.data), { existingSignatoryFirstName: formContext.user.firstName, existingSignatoryLastName: formContext.user.lastName });
        case 'GAP_USA_TAX_DETAILS_SOLE_TRADER':
            return Object.assign({}, formContext.data);
        default:
            return {
                signatoryFirstName: (_b = (_a = formContext.user) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : '',
                signatoryLastName: (_d = (_c = formContext.user) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : '',
            };
    }
};
const getSchemaBuilderByFormKey = (formKey) => {
    const schemaBuilder = Schemas[formKey];
    if (!schemaBuilder) {
        throw new Error(`Schema for ${formKey} not found`);
    }
    return schemaBuilder;
};
const useNewQuestionnaireState = (formContext) => {
    const { text } = useLocalization();
    const { substitute } = useSubstitute();
    return useMemo(() => {
        const schemaBuilder = getSchemaBuilderByFormKey(formContext.form);
        const stepList = schemaBuilder({ translate: text, substitute, formContext });
        const prefilledData = prefillMapper(formContext);
        return createEmptyQuestionnaireState({
            stepList,
            prefilledData,
        });
    }, [formContext, substitute, text]);
};
export const EligibleContent = ({ formContext }) => {
    const questionnaireLocalStorage = useQuestionnaireLocalStorage();
    const newQuestionnaireState = useNewQuestionnaireState(formContext);
    const [state, setState] = useState({ display: 'empty' });
    useEffect(() => {
        const savedQuestionnaire = questionnaireLocalStorage.retrieveSavedIfMatches(newQuestionnaireState);
        if (savedQuestionnaire) {
            setState({ display: 'resumeScreen', savedQuestionnaire });
        }
        else {
            setState({ display: 'questionnaire', questionnaireInitialState: newQuestionnaireState });
            questionnaireLocalStorage.removeSaved();
        }
    }, [newQuestionnaireState]);
    if (state.display === 'resumeScreen') {
        return (React.createElement(ResumeScreen, { onResume: () => setState({ display: 'questionnaire', questionnaireInitialState: state.savedQuestionnaire }), onReset: () => {
                questionnaireLocalStorage.removeSaved();
                setState({ display: 'questionnaire', questionnaireInitialState: newQuestionnaireState });
            } }));
    }
    if (state.display === 'questionnaire') {
        return (React.createElement(PopupsContainer, null,
            React.createElement(Questionnaire, { initialState: state.questionnaireInitialState, onChange: questionnaireLocalStorage.save },
                React.createElement(Debugger, null))));
    }
    return null;
};
