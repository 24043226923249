export const stringToUpperSnakeCase = (initialValue) => {
    return initialValue.replace(/ |-/g, '_').toUpperCase();
};
export const camelCaseToUpperSnakeCase = (initialValue) => {
    return stringToUpperSnakeCase(initialValue.split(/(?=[A-Z])/).join('_'));
};
export const scrollToSelector = (selector) => {
    const element = document.querySelector(selector);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
};
export const getVerticalScrollPosition = () => {
    var _a, _b;
    return (_b = (_a = window.document.querySelector('main')) === null || _a === void 0 ? void 0 : _a.scrollTop) !== null && _b !== void 0 ? _b : 0;
};
const attemptiOSPostMessage = (handlerName, msgBody) => {
    var _a;
    if (!((_a = window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers)) {
        return;
    }
    const handler = window.webkit.messageHandlers[handlerName];
    if (typeof (handler === null || handler === void 0 ? void 0 : handler.postMessage) === 'function') {
        handler.postMessage(msgBody);
    }
};
const attemptAndroidInvoke = (handlerName) => {
    if (typeof AndroidInterface === 'undefined') {
        return;
    }
    if (typeof (AndroidInterface === null || AndroidInterface === void 0 ? void 0 : AndroidInterface[handlerName]) === 'function') {
        AndroidInterface[handlerName]();
    }
};
export const closeWebview = (email) => {
    attemptiOSPostMessage('closeWebview', { email });
    attemptAndroidInvoke('closeWebView');
};
export const openDocifyOnNativeApps = (email) => {
    attemptiOSPostMessage('openDocify', { email });
    attemptAndroidInvoke('openDocify');
};
export const isRunningInGoAppWebView = () => {
    var _a, _b;
    // if `closeWeb[V,v]iew` is available, we know it's a Zettle Go app
    return ((typeof AndroidInterface !== 'undefined' && typeof (AndroidInterface === null || AndroidInterface === void 0 ? void 0 : AndroidInterface.closeWebView) === 'function') ||
        Boolean((_b = (_a = window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers) === null || _b === void 0 ? void 0 : _b.closeWebview));
};
export const pushDataLayer = (dataObject) => {
    var _a;
    window.dataLayer = (_a = window.dataLayer) !== null && _a !== void 0 ? _a : [];
    if (Array.isArray(window.dataLayer)) {
        window.dataLayer.push(dataObject);
    }
};
export const isNodeTextElement = (node) => {
    return ['INPUT', 'TEXTAREA'].indexOf(node.nodeName) !== -1;
};
export const isNodeListItemElement = (node) => {
    return ['LI'].indexOf(node.nodeName) !== -1;
};
export const isNodeOrParentButton = (node) => {
    if (['BUTTON'].indexOf(node.nodeName) !== -1) {
        return true;
    }
    if (node.parentElement) {
        return isNodeOrParentButton(node.parentElement);
    }
    return false;
};
// taken from https://stackoverflow.com/a/9039885
export const isPlatformIOS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
export const findParentWithClassName = (element, className) => {
    if (element.classList.contains(className)) {
        return element;
    }
    if (element.parentElement) {
        return findParentWithClassName(element.parentElement, className);
    }
    return null;
};
export const isPlainObject = (obj) => {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
};
