import React from 'react';
import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
export const ObjectContainer = styled.div ``;
const ObjectTitleContainer = styled.div `
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${spacing.xxSmall};
`;
export const ObjectTitle = ({ titleStyle, children }) => {
    const ObjectTitleComponent = Text[titleStyle];
    return (React.createElement(ObjectTitleContainer, { marginTop: titleStyle === 'Title1' ? spacing.large : spacing.none },
        React.createElement(ObjectTitleComponent, { className: "override-text" }, children)));
};
export const ObjectDescription = styled(Text.RegularSecondary) `
  margin-bottom: ${spacing.large};
`;
export const ObjectPropertyFieldSet = styled.fieldset `
  border: none;
  padding: 0;
  margin: 0;

  :not(:last-child) {
    margin-bottom: ${spacing.medium};
  }
`;
export const ObjectPropertyContainer = styled.div `
  margin-bottom: ${spacing.small};

  :last-child {
    margin-bottom: 0px;
  }
`;
