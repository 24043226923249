export const nationalityOptions = [
    { value: 'AF', textKey: 'NATIONALITY_VALUE_AF' },
    { value: 'AX', textKey: 'NATIONALITY_VALUE_AX' },
    { value: 'AL', textKey: 'NATIONALITY_VALUE_AL' },
    { value: 'DZ', textKey: 'NATIONALITY_VALUE_DZ' },
    { value: 'AS', textKey: 'NATIONALITY_VALUE_AS' },
    { value: 'AD', textKey: 'NATIONALITY_VALUE_AD' },
    { value: 'AO', textKey: 'NATIONALITY_VALUE_AO' },
    { value: 'AI', textKey: 'NATIONALITY_VALUE_AI' },
    { value: 'AG', textKey: 'NATIONALITY_VALUE_AG' },
    { value: 'AR', textKey: 'NATIONALITY_VALUE_AR' },
    { value: 'AM', textKey: 'NATIONALITY_VALUE_AM' },
    { value: 'AW', textKey: 'NATIONALITY_VALUE_AW' },
    { value: 'AU', textKey: 'NATIONALITY_VALUE_AU' },
    { value: 'AT', textKey: 'NATIONALITY_VALUE_AT' },
    { value: 'AZ', textKey: 'NATIONALITY_VALUE_AZ' },
    { value: 'BS', textKey: 'NATIONALITY_VALUE_BS' },
    { value: 'BH', textKey: 'NATIONALITY_VALUE_BH' },
    { value: 'BD', textKey: 'NATIONALITY_VALUE_BD' },
    { value: 'BB', textKey: 'NATIONALITY_VALUE_BB' },
    { value: 'BY', textKey: 'NATIONALITY_VALUE_BY' },
    { value: 'BE', textKey: 'NATIONALITY_VALUE_BE' },
    { value: 'BZ', textKey: 'NATIONALITY_VALUE_BZ' },
    { value: 'BJ', textKey: 'NATIONALITY_VALUE_BJ' },
    { value: 'BM', textKey: 'NATIONALITY_VALUE_BM' },
    { value: 'BT', textKey: 'NATIONALITY_VALUE_BT' },
    { value: 'BO', textKey: 'NATIONALITY_VALUE_BO' },
    { value: 'BA', textKey: 'NATIONALITY_VALUE_BA' },
    { value: 'BW', textKey: 'NATIONALITY_VALUE_BW' },
    { value: 'BR', textKey: 'NATIONALITY_VALUE_BR' },
    { value: 'IO', textKey: 'NATIONALITY_VALUE_IO' },
    { value: 'VG', textKey: 'NATIONALITY_VALUE_VG' },
    { value: 'BN', textKey: 'NATIONALITY_VALUE_BN' },
    { value: 'BG', textKey: 'NATIONALITY_VALUE_BG' },
    { value: 'BF', textKey: 'NATIONALITY_VALUE_BF' },
    { value: 'BI', textKey: 'NATIONALITY_VALUE_BI' },
    { value: 'KH', textKey: 'NATIONALITY_VALUE_KH' },
    { value: 'CM', textKey: 'NATIONALITY_VALUE_CM' },
    { value: 'CA', textKey: 'NATIONALITY_VALUE_CA' },
    { value: 'CV', textKey: 'NATIONALITY_VALUE_CV' },
    { value: 'BQ', textKey: 'NATIONALITY_VALUE_BQ' },
    { value: 'KY', textKey: 'NATIONALITY_VALUE_KY' },
    { value: 'CF', textKey: 'NATIONALITY_VALUE_CF' },
    { value: 'TD', textKey: 'NATIONALITY_VALUE_TD' },
    { value: 'CL', textKey: 'NATIONALITY_VALUE_CL' },
    { value: 'CN', textKey: 'NATIONALITY_VALUE_CN' },
    { value: 'CC', textKey: 'NATIONALITY_VALUE_CC' },
    { value: 'CO', textKey: 'NATIONALITY_VALUE_CO' },
    { value: 'KM', textKey: 'NATIONALITY_VALUE_KM' },
    { value: 'CG', textKey: 'NATIONALITY_VALUE_CG' },
    { value: 'CD', textKey: 'NATIONALITY_VALUE_CD' },
    { value: 'CK', textKey: 'NATIONALITY_VALUE_CK' },
    { value: 'CR', textKey: 'NATIONALITY_VALUE_CR' },
    { value: 'CI', textKey: 'NATIONALITY_VALUE_CI' },
    { value: 'HR', textKey: 'NATIONALITY_VALUE_HR' },
    { value: 'CU', textKey: 'NATIONALITY_VALUE_CU' },
    { value: 'CW', textKey: 'NATIONALITY_VALUE_CW' },
    { value: 'CY', textKey: 'NATIONALITY_VALUE_CY' },
    { value: 'CZ', textKey: 'NATIONALITY_VALUE_CZ' },
    { value: 'DK', textKey: 'NATIONALITY_VALUE_DK' },
    { value: 'DJ', textKey: 'NATIONALITY_VALUE_DJ' },
    { value: 'DM', textKey: 'NATIONALITY_VALUE_DM' },
    { value: 'DO', textKey: 'NATIONALITY_VALUE_DO' },
    { value: 'EC', textKey: 'NATIONALITY_VALUE_EC' },
    { value: 'EG', textKey: 'NATIONALITY_VALUE_EG' },
    { value: 'SV', textKey: 'NATIONALITY_VALUE_SV' },
    { value: 'GQ', textKey: 'NATIONALITY_VALUE_GQ' },
    { value: 'ER', textKey: 'NATIONALITY_VALUE_ER' },
    { value: 'EE', textKey: 'NATIONALITY_VALUE_EE' },
    { value: 'ET', textKey: 'NATIONALITY_VALUE_ET' },
    { value: 'FK', textKey: 'NATIONALITY_VALUE_FK' },
    { value: 'FO', textKey: 'NATIONALITY_VALUE_FO' },
    { value: 'FJ', textKey: 'NATIONALITY_VALUE_FJ' },
    { value: 'FI', textKey: 'NATIONALITY_VALUE_FI' },
    { value: 'FR', textKey: 'NATIONALITY_VALUE_FR' },
    { value: 'GF', textKey: 'NATIONALITY_VALUE_GF' },
    { value: 'PF', textKey: 'NATIONALITY_VALUE_PF' },
    { value: 'GA', textKey: 'NATIONALITY_VALUE_GA' },
    { value: 'GM', textKey: 'NATIONALITY_VALUE_GM' },
    { value: 'GE', textKey: 'NATIONALITY_VALUE_GE' },
    { value: 'DE', textKey: 'NATIONALITY_VALUE_DE' },
    { value: 'GH', textKey: 'NATIONALITY_VALUE_GH' },
    { value: 'GI', textKey: 'NATIONALITY_VALUE_GI' },
    { value: 'GR', textKey: 'NATIONALITY_VALUE_GR' },
    { value: 'GL', textKey: 'NATIONALITY_VALUE_GL' },
    { value: 'GD', textKey: 'NATIONALITY_VALUE_GD' },
    { value: 'GP', textKey: 'NATIONALITY_VALUE_GP' },
    { value: 'GU', textKey: 'NATIONALITY_VALUE_GU' },
    { value: 'GT', textKey: 'NATIONALITY_VALUE_GT' },
    { value: 'GG', textKey: 'NATIONALITY_VALUE_GG' },
    { value: 'GN', textKey: 'NATIONALITY_VALUE_GN' },
    { value: 'GW', textKey: 'NATIONALITY_VALUE_GW' },
    { value: 'GY', textKey: 'NATIONALITY_VALUE_GY' },
    { value: 'HT', textKey: 'NATIONALITY_VALUE_HT' },
    { value: 'HN', textKey: 'NATIONALITY_VALUE_HN' },
    { value: 'HK', textKey: 'NATIONALITY_VALUE_HK' },
    { value: 'HU', textKey: 'NATIONALITY_VALUE_HU' },
    { value: 'IS', textKey: 'NATIONALITY_VALUE_IS' },
    { value: 'IN', textKey: 'NATIONALITY_VALUE_IN' },
    { value: 'ID', textKey: 'NATIONALITY_VALUE_ID' },
    { value: 'IR', textKey: 'NATIONALITY_VALUE_IR' },
    { value: 'IQ', textKey: 'NATIONALITY_VALUE_IQ' },
    { value: 'IE', textKey: 'NATIONALITY_VALUE_IE' },
    { value: 'IM', textKey: 'NATIONALITY_VALUE_IM' },
    { value: 'IL', textKey: 'NATIONALITY_VALUE_IL' },
    { value: 'IT', textKey: 'NATIONALITY_VALUE_IT' },
    { value: 'JM', textKey: 'NATIONALITY_VALUE_JM' },
    { value: 'JP', textKey: 'NATIONALITY_VALUE_JP' },
    { value: 'JE', textKey: 'NATIONALITY_VALUE_JE' },
    { value: 'JO', textKey: 'NATIONALITY_VALUE_JO' },
    { value: 'KZ', textKey: 'NATIONALITY_VALUE_KZ' },
    { value: 'KE', textKey: 'NATIONALITY_VALUE_KE' },
    { value: 'KI', textKey: 'NATIONALITY_VALUE_KI' },
    { value: 'XK', textKey: 'NATIONALITY_VALUE_XK' },
    { value: 'KW', textKey: 'NATIONALITY_VALUE_KW' },
    { value: 'KG', textKey: 'NATIONALITY_VALUE_KG' },
    { value: 'LA', textKey: 'NATIONALITY_VALUE_LA' },
    { value: 'LV', textKey: 'NATIONALITY_VALUE_LV' },
    { value: 'LB', textKey: 'NATIONALITY_VALUE_LB' },
    { value: 'LS', textKey: 'NATIONALITY_VALUE_LS' },
    { value: 'LR', textKey: 'NATIONALITY_VALUE_LR' },
    { value: 'LY', textKey: 'NATIONALITY_VALUE_LY' },
    { value: 'LI', textKey: 'NATIONALITY_VALUE_LI' },
    { value: 'LT', textKey: 'NATIONALITY_VALUE_LT' },
    { value: 'LU', textKey: 'NATIONALITY_VALUE_LU' },
    { value: 'MO', textKey: 'NATIONALITY_VALUE_MO' },
    { value: 'MK', textKey: 'NATIONALITY_VALUE_MK' },
    { value: 'MG', textKey: 'NATIONALITY_VALUE_MG' },
    { value: 'MW', textKey: 'NATIONALITY_VALUE_MW' },
    { value: 'MY', textKey: 'NATIONALITY_VALUE_MY' },
    { value: 'MV', textKey: 'NATIONALITY_VALUE_MV' },
    { value: 'ML', textKey: 'NATIONALITY_VALUE_ML' },
    { value: 'MT', textKey: 'NATIONALITY_VALUE_MT' },
    { value: 'MH', textKey: 'NATIONALITY_VALUE_MH' },
    { value: 'MQ', textKey: 'NATIONALITY_VALUE_MQ' },
    { value: 'MR', textKey: 'NATIONALITY_VALUE_MR' },
    { value: 'MU', textKey: 'NATIONALITY_VALUE_MU' },
    { value: 'YT', textKey: 'NATIONALITY_VALUE_YT' },
    { value: 'MX', textKey: 'NATIONALITY_VALUE_MX' },
    { value: 'FM', textKey: 'NATIONALITY_VALUE_FM' },
    { value: 'MD', textKey: 'NATIONALITY_VALUE_MD' },
    { value: 'MC', textKey: 'NATIONALITY_VALUE_MC' },
    { value: 'MN', textKey: 'NATIONALITY_VALUE_MN' },
    { value: 'ME', textKey: 'NATIONALITY_VALUE_ME' },
    { value: 'MS', textKey: 'NATIONALITY_VALUE_MS' },
    { value: 'MA', textKey: 'NATIONALITY_VALUE_MA' },
    { value: 'MZ', textKey: 'NATIONALITY_VALUE_MZ' },
    { value: 'MM', textKey: 'NATIONALITY_VALUE_MM' },
    { value: 'NA', textKey: 'NATIONALITY_VALUE_NA' },
    { value: 'NR', textKey: 'NATIONALITY_VALUE_NR' },
    { value: 'NP', textKey: 'NATIONALITY_VALUE_NP' },
    { value: 'NL', textKey: 'NATIONALITY_VALUE_NL' },
    { value: 'AN', textKey: 'NATIONALITY_VALUE_AN' },
    { value: 'NC', textKey: 'NATIONALITY_VALUE_NC' },
    { value: 'NZ', textKey: 'NATIONALITY_VALUE_NZ' },
    { value: 'NI', textKey: 'NATIONALITY_VALUE_NI' },
    { value: 'NE', textKey: 'NATIONALITY_VALUE_NE' },
    { value: 'NG', textKey: 'NATIONALITY_VALUE_NG' },
    { value: 'NU', textKey: 'NATIONALITY_VALUE_NU' },
    { value: 'NF', textKey: 'NATIONALITY_VALUE_NF' },
    { value: 'KP', textKey: 'NATIONALITY_VALUE_KP' },
    { value: 'MP', textKey: 'NATIONALITY_VALUE_MP' },
    { value: 'NO', textKey: 'NATIONALITY_VALUE_NO' },
    { value: 'OM', textKey: 'NATIONALITY_VALUE_OM' },
    { value: 'PK', textKey: 'NATIONALITY_VALUE_PK' },
    { value: 'PW', textKey: 'NATIONALITY_VALUE_PW' },
    { value: 'PS', textKey: 'NATIONALITY_VALUE_PS' },
    { value: 'PA', textKey: 'NATIONALITY_VALUE_PA' },
    { value: 'PG', textKey: 'NATIONALITY_VALUE_PG' },
    { value: 'PY', textKey: 'NATIONALITY_VALUE_PY' },
    { value: 'PE', textKey: 'NATIONALITY_VALUE_PE' },
    { value: 'PH', textKey: 'NATIONALITY_VALUE_PH' },
    { value: 'PN', textKey: 'NATIONALITY_VALUE_PN' },
    { value: 'PL', textKey: 'NATIONALITY_VALUE_PL' },
    { value: 'PT', textKey: 'NATIONALITY_VALUE_PT' },
    { value: 'PR', textKey: 'NATIONALITY_VALUE_PR' },
    { value: 'QA', textKey: 'NATIONALITY_VALUE_QA' },
    { value: 'RE', textKey: 'NATIONALITY_VALUE_RE' },
    { value: 'RO', textKey: 'NATIONALITY_VALUE_RO' },
    { value: 'RU', textKey: 'NATIONALITY_VALUE_RU' },
    { value: 'RW', textKey: 'NATIONALITY_VALUE_RW' },
    { value: 'WS', textKey: 'NATIONALITY_VALUE_WS' },
    { value: 'SM', textKey: 'NATIONALITY_VALUE_SM' },
    { value: 'ST', textKey: 'NATIONALITY_VALUE_ST' },
    { value: 'SA', textKey: 'NATIONALITY_VALUE_SA' },
    { value: 'SN', textKey: 'NATIONALITY_VALUE_SN' },
    { value: 'RS', textKey: 'NATIONALITY_VALUE_RS' },
    { value: 'SC', textKey: 'NATIONALITY_VALUE_SC' },
    { value: 'SL', textKey: 'NATIONALITY_VALUE_SL' },
    { value: 'SG', textKey: 'NATIONALITY_VALUE_SG' },
    { value: 'SX', textKey: 'NATIONALITY_VALUE_SX' },
    { value: 'SK', textKey: 'NATIONALITY_VALUE_SK' },
    { value: 'SI', textKey: 'NATIONALITY_VALUE_SI' },
    { value: 'SB', textKey: 'NATIONALITY_VALUE_SB' },
    { value: 'SO', textKey: 'NATIONALITY_VALUE_SO' },
    { value: 'ZA', textKey: 'NATIONALITY_VALUE_ZA' },
    { value: 'KR', textKey: 'NATIONALITY_VALUE_KR' },
    { value: 'SS', textKey: 'NATIONALITY_VALUE_SS' },
    { value: 'ES', textKey: 'NATIONALITY_VALUE_ES' },
    { value: 'LK', textKey: 'NATIONALITY_VALUE_LK' },
    { value: 'BL', textKey: 'NATIONALITY_VALUE_BL' },
    { value: 'SH', textKey: 'NATIONALITY_VALUE_SH' },
    { value: 'KN', textKey: 'NATIONALITY_VALUE_KN' },
    { value: 'LC', textKey: 'NATIONALITY_VALUE_LC' },
    { value: 'MF', textKey: 'NATIONALITY_VALUE_MF' },
    { value: 'PM', textKey: 'NATIONALITY_VALUE_PM' },
    { value: 'VC', textKey: 'NATIONALITY_VALUE_VC' },
    { value: 'SD', textKey: 'NATIONALITY_VALUE_SD' },
    { value: 'SR', textKey: 'NATIONALITY_VALUE_SR' },
    { value: 'SJ', textKey: 'NATIONALITY_VALUE_SJ' },
    { value: 'SZ', textKey: 'NATIONALITY_VALUE_SZ' },
    { value: 'SE', textKey: 'NATIONALITY_VALUE_SE' },
    { value: 'CH', textKey: 'NATIONALITY_VALUE_CH' },
    { value: 'SY', textKey: 'NATIONALITY_VALUE_SY' },
    { value: 'TW', textKey: 'NATIONALITY_VALUE_TW' },
    { value: 'TJ', textKey: 'NATIONALITY_VALUE_TJ' },
    { value: 'TZ', textKey: 'NATIONALITY_VALUE_TZ' },
    { value: 'TH', textKey: 'NATIONALITY_VALUE_TH' },
    { value: 'TL', textKey: 'NATIONALITY_VALUE_TL' },
    { value: 'TG', textKey: 'NATIONALITY_VALUE_TG' },
    { value: 'TK', textKey: 'NATIONALITY_VALUE_TK' },
    { value: 'TO', textKey: 'NATIONALITY_VALUE_TO' },
    { value: 'TT', textKey: 'NATIONALITY_VALUE_TT' },
    { value: 'TN', textKey: 'NATIONALITY_VALUE_TN' },
    { value: 'TR', textKey: 'NATIONALITY_VALUE_TR' },
    { value: 'TM', textKey: 'NATIONALITY_VALUE_TM' },
    { value: 'TC', textKey: 'NATIONALITY_VALUE_TC' },
    { value: 'TV', textKey: 'NATIONALITY_VALUE_TV' },
    { value: 'VI', textKey: 'NATIONALITY_VALUE_VI' },
    { value: 'UG', textKey: 'NATIONALITY_VALUE_UG' },
    { value: 'UA', textKey: 'NATIONALITY_VALUE_UA' },
    { value: 'AE', textKey: 'NATIONALITY_VALUE_AE' },
    { value: 'GB', textKey: 'NATIONALITY_VALUE_GB' },
    { value: 'US', textKey: 'NATIONALITY_VALUE_US' },
    { value: 'UY', textKey: 'NATIONALITY_VALUE_UY' },
    { value: 'UZ', textKey: 'NATIONALITY_VALUE_UZ' },
    { value: 'VU', textKey: 'NATIONALITY_VALUE_VU' },
    { value: 'VA', textKey: 'NATIONALITY_VALUE_VA' },
    { value: 'VE', textKey: 'NATIONALITY_VALUE_VE' },
    { value: 'VN', textKey: 'NATIONALITY_VALUE_VN' },
    { value: 'WF', textKey: 'NATIONALITY_VALUE_WF' },
    { value: 'EH', textKey: 'NATIONALITY_VALUE_EH' },
    { value: 'YE', textKey: 'NATIONALITY_VALUE_YE' },
    { value: 'ZM', textKey: 'NATIONALITY_VALUE_ZM' },
    { value: 'ZW', textKey: 'NATIONALITY_VALUE_ZW' },
];
