import { IsoLocale } from '@izettle/constants';
import ownerStructureHelpSvg from 'src/assets/owner-structure-help.svg';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { step, sequence, render, decision } from 'src/schema/steps-builder';
import { nationalityOptions } from '../../common/nationality-options';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { introScreenTemplate, legalPeoplePreparationScreenTemplate, beneficialOwnersListTemplate, alternativeOwnersListTemplate, directorsListTemplate, hasUBOTemplate, normalizeSignatoryFieldsFunction, initLegalPersonsFunction, overviewPageTemplate, } from './common/steps/limited-company';
import { signatoryNationalityTemplate, signatoryPhoneNumberTemplate, signatoryNamePrefilledTemplate, signatoryNameTemplate, signatoryDOBTemplate, } from './common/steps/signatory';
const introScreenDE = introScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            items: [
                {
                    icon: 'Person',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                },
                {
                    icon: 'Home',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_DESCRIPTION'),
                },
                {
                    icon: 'People',
                    title: translate('GAP_LTD_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('GAP_LTD_DE_PREPARATION_SCREEN_ITEM_VERIFICATION_TITLE'),
                    description: translate('GAP_LTD_PREPARATION_SCREEN_ITEM_VERIFICATION_DESCRIPTION'),
                },
            ],
        },
    },
}));
const signatoryAddressDE = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryStreetAddress', 'signatoryCity', 'signatoryPostalCode'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('GAP_SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
});
const signatoryPhoneNumberDE = signatoryPhoneNumberTemplate.extendDeep(() => ({
    UISchema: {
        signatoryPhoneNumber: {
            'ui:placeholder': "z. B. 01512 1234 567" /* CopyDE.SIGNATORY_PHONE_NUMBER_PLACEHOLDER */,
        },
    },
}));
const businessAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessStreetAddress', 'businessCity', 'businessPostalCode'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('GAP_LTD_BUSINESS_ADDRESS_TITLE'),
                description: translate('GAP_LTD_BUSINESS_ADDRESS_DESCRIPTION'),
                type: 'null',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
    },
});
const legalPeoplePreparationScreenDE = legalPeoplePreparationScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_LTD_DE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const personStep = (translate) => ({
    ID: 'personStepId',
    backendValidation: true,
    fields: ['firstName', 'lastName', 'dateOfBirth', 'nationality', 'streetAddress', 'city', 'postalCode'],
    JSONSchema: {
        type: 'object',
        properties: {
            nameTitle: {
                title: translate('PERSON_NAME_LABEL'),
                type: 'null',
            },
            firstName: {
                type: 'string',
            },
            lastName: {
                type: 'string',
            },
            dateOfBirthTitle: {
                title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                type: 'null',
            },
            dateOfBirth: {
                type: 'string',
            },
            nationalityTitle: {
                title: translate('PERSON_NATIONALITY_LABEL'),
                type: 'null',
            },
            nationality: {
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
            addressTitle: {
                title: translate('PERSON_HOME_ADDRESS_LABEL'),
                type: 'null',
            },
            streetAddress: {
                type: 'string',
            },
            postalCode: {
                type: 'string',
            },
            city: {
                type: 'string',
            },
        },
    },
    UISchema: {
        firstName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_LABEL'),
            },
        },
        lastName: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('LAST_NAME_LABEL'),
            },
        },
        dateOfBirth: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: "TT.MM.JJJJ" /* CopyDE.DATE_OF_BIRTH_PLACEHOLDER */,
                mask: "99.99.9999" /* CopyDE.DATE_OF_BIRTH_MASK */,
            },
        },
        nationality: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:widget': 'CustomComboBoxWidget',
            'ui:emptyValue': '',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
            'ui:options': {
                sortByLabels: 'asc',
            },
        },
        streetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        postalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        city: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'newPerson',
    },
});
const beneficialOwnersListDE = beneficialOwnersListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_LTD_DE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const alternativeOwnersListDE = alternativeOwnersListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_LTD_DE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
/* eslint-disable sonarjs/no-identical-functions */
const directorsListDE = directorsListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_LTD_DE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
/* eslint-disable sonarjs/no-identical-functions */
const hasUBODE = hasUBOTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_LTD_DE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const overviewPageDE = overviewPageTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        version: 'GAP_DE_LTD_COMPANY',
        content: {
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(IsoLocale.deDE)]),
        },
    },
}));
const newSignatorySequence = [
    step(signatoryNameTemplate),
    step(signatoryDOBTemplate),
    step(signatoryAddressDE),
    step(signatoryPhoneNumberDE),
    step(signatoryNationalityTemplate),
];
const rootSequence = sequence([
    step(introScreenDE),
    decision('signatoryAction', signatoryNamePrefilledTemplate, [
        { when: SIGNATORY_ACTION.CHANGE_SIGNATORY, branch: sequence(newSignatorySequence) },
        { when: SIGNATORY_ACTION.SET_NATIONALITY, branch: step(signatoryNationalityTemplate) },
    ]),
    step(normalizeSignatoryFieldsFunction),
    step(businessAddress),
    step(legalPeoplePreparationScreenDE),
    step(initLegalPersonsFunction),
    step(directorsListDE),
    decision('hasUBO', hasUBODE, [
        { when: true, branch: step(beneficialOwnersListDE) },
        { when: false, branch: step(alternativeOwnersListDE) },
    ]),
    step(overviewPageDE),
]);
export const GAP_DE_LTD_COMPANY = (context) => render(rootSequence, context);
