import { BusinessType, NativeBusinessType } from 'src/utils/country/MX/data';
import { getNativeBusinessTypeInfo } from 'src/utils/country/MX/data-helpers';
import { generateDigitSequence, generateIntFromInterval, generateRandomLetter } from '../../helpers';
export const getFirstVowel = (value) => {
    const vowels = 'aAeEiIoOuU';
    for (let i = 0; i < value.length; i++) {
        if (vowels.indexOf(value[i]) !== -1) {
            return value[i];
        }
    }
    return 'X';
};
export const getFirstNonVowel = (value) => {
    const vowels = 'aAeEiIoOuU';
    for (let i = 0; i < value.length; i++) {
        if (!(vowels.indexOf(value[i]) !== -1)) {
            return value[i];
        }
    }
    return 'X';
};
export const generateRandomDateOfBirthMX = ({ separator = '' }) => {
    return [
        generateIntFromInterval(1930, 2000, 4).substring(2, 4),
        generateIntFromInterval(1, 12, 2),
        generateIntFromInterval(1, 28, 2), // day
    ].join(separator);
};
export const generateBusinessLegalEntityNumber = () => {
    return [
        generateRandomLetter().toUpperCase(),
        generateRandomLetter().toUpperCase(),
        generateRandomLetter().toUpperCase(),
        generateRandomDateOfBirthMX({}),
        generateRandomLetter().toUpperCase(),
        generateRandomLetter().toUpperCase(),
        generateDigitSequence(1),
    ].join('');
};
export const generateLegalEntityNumber = (firstName, firstLastName, secondLastName, gender, birthDate, birthState) => {
    firstName = firstName.trim().toUpperCase();
    firstLastName = firstLastName.trim().toUpperCase();
    secondLastName = secondLastName.trim().toUpperCase();
    gender = gender.trim().toUpperCase();
    birthDate = birthDate.trim().toUpperCase();
    birthState = birthState.trim().toUpperCase();
    return [
        firstLastName.charAt(0),
        getFirstVowel(firstLastName.substring(1)),
        secondLastName.charAt(0),
        firstName.charAt(0),
        birthDate,
        gender,
        birthState,
        getFirstNonVowel(firstLastName.substring(1)),
        getFirstNonVowel(secondLastName.substring(1)),
        getFirstNonVowel(firstName.substring(1)),
        '0',
        generateDigitSequence(1).toString(),
    ].join('');
};
const generateBusinessCategoryFields = (formContext) => {
    var _a;
    const businessCategories = (_a = formContext.additionalData) === null || _a === void 0 ? void 0 : _a.businessCategories;
    if (!businessCategories) {
        throw new Error('Categories not found in form context');
    }
    return {
        businessCategoryId: businessCategories[1].code.toString(),
        businessSubCategoryId: businessCategories[1].children[0].code.toString(),
    };
};
const generatePersonFields = () => ({
    firstName: 'Gabriela',
    lastName: 'Castillo',
    maidenName: 'Bravo',
    legalEntityNumber: generateLegalEntityNumber('Gabriela', 'Castillo', 'Bravo', 'M', generateRandomDateOfBirthMX({}), 'GT'),
});
export const generateRandomLegalEntityNumber = () => {
    const person = generatePersonFields();
    return person.legalEntityNumber;
};
const generateOwnerStructureFieldsLimitedCompany = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateOwnerStructureFieldsNonProfit = () => ({
    legalPersons: {},
    hasUBO: false,
});
const ownerStructureFieldsGenerators = {
    [BusinessType.LimitedCompany]: generateOwnerStructureFieldsLimitedCompany,
    [BusinessType.NonProfit]: generateOwnerStructureFieldsNonProfit,
    [BusinessType.SelfEmployed]: () => null,
};
const createGenerateFunc = (nativeBusinessType) => {
    const { businessType } = getNativeBusinessTypeInfo(nativeBusinessType);
    const selfEmployedData = {
        businessLegalEntityNumber: generateLegalEntityNumber('Ramon', 'Gonzalez', 'Olvera', 'H', generateRandomDateOfBirthMX({}), 'GT'),
        tradingName: 'Antigüedades H2',
    };
    const notSelfEmployedData = {
        businessLegalEntityNumber: generateBusinessLegalEntityNumber(),
        businessName: 'Antigüedades H2',
        signatoryLegalEntityNumber: generateLegalEntityNumber('Ramon', 'Gonzalez', 'Olvera', 'H', generateRandomDateOfBirthMX({}), 'GT'),
    };
    const getAccountDataByBusinessType = () => businessType === BusinessType.SelfEmployed ? selfEmployedData : notSelfEmployedData;
    return (formContext) => (Object.assign(Object.assign(Object.assign(Object.assign({ businessType,
        nativeBusinessType, businessStreetAddress: 'Torre Londes 5111', businessNeighborhood: 'Rosalinda 2', businessPostalCode: '27000', businessCity: 'Torreon', businessState: 'GTO', signatoryFirstName: 'Ramon', signatoryLastName: 'Gonzalez', signatoryMaidenName: 'Olvera', signatoryPhoneNumber: '4424001858' }, getAccountDataByBusinessType()), ownerStructureFieldsGenerators[businessType]()), generateBusinessCategoryFields(formContext)), { businessLocationTypes: ['PHYSICAL_STORE', 'TEMPORARY_STORE'], businessFrequency: 'FEW_TIMES_A_MONTH', businessAverageTransaction: '1000-10000', businessIzettleShareOfTurnover: '20-80%', businessCurrentAnnualTurnover: '0-400000' }));
};
export const MX = Object.values(NativeBusinessType).map((nativeBusinessType) => {
    const info = getNativeBusinessTypeInfo(nativeBusinessType);
    return {
        title: `${info.label} (${info.businessType})`,
        generateFunc: createGenerateFunc(nativeBusinessType),
    };
});
