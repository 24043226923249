export var RoleFieldName;
(function (RoleFieldName) {
    RoleFieldName["roleDirectors"] = "roleDirectors";
    RoleFieldName["rolePartners"] = "rolePartners";
    RoleFieldName["roleBeneficialOwners"] = "roleBeneficialOwners";
    RoleFieldName["roleAlternativeOwners"] = "roleAlternativeOwners";
})(RoleFieldName || (RoleFieldName = {}));
export const ROLE_FIELD_NAMES = Object.keys(RoleFieldName);
/**
 * Field name used in LUX gaps to contain a list of owners
 */
export const LUX_NEW_LEGAL_PEOPLE_FIELD_NAME = 'newLegalPeople';
/**
 * Signatory, as a person who fills in the form, has a hardcoded person ID in
 * the list of roles. All other people added by signatory have random IDs.
 */
export const SIGNATORY_PERSON_ID = 'SIGNATORY';
