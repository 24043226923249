import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { AddressValue } from '../../../AddressValue';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
import { SelfEmployedUsaTaxpayerFields } from '../../shared/usaTaxpayerDetails/SelfEmployedUsaTaxpayerFields';
export const PersonalDetailsSection = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(Section, { name: "personalDetails", title: text('OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: ['signatoryFirstName', 'signatoryLastName'], title: text('OVERVIEW_FIELD_SIGNATORY_NAME_TITLE'), editable: true },
            React.createElement(Value, null, `${stepData.signatoryFirstName} ${stepData.signatoryLastName}`)),
        React.createElement(Field, { fieldNames: ['signatoryDateOfBirth'], title: text('OVERVIEW_FIELD_DATE_OF_BIRTH_TITLE'), editable: true, conditionalRendering: true },
            React.createElement(Value, null, stepData.signatoryDateOfBirth)),
        React.createElement(Field, { fieldNames: ['signatoryNationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE'), editable: true }, stepData.signatoryNationality && React.createElement(Value, null, text(`NATIONALITY_VALUE_${stepData.signatoryNationality}`))),
        React.createElement(SelfEmployedUsaTaxpayerFields, null),
        React.createElement(Field, { fieldNames: ['isSignatoryShareAddress'], title: text('OVERVIEW_FIELD_IS_SIGNATORY_SHARE_ADDRESS'), editable: true },
            React.createElement(Value, null, stepData.isSignatoryShareAddress ? text('OVERVIEW_PAGE_VALUE_YES') : text('OVERVIEW_PAGE_VALUE_NO'))),
        React.createElement(Field, { fieldNames: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE'), editable: true, conditionalRendering: true },
            React.createElement(AddressValue, { addressData: {
                    streetAddress: stepData.signatoryStreetAddress,
                    postalCode: stepData.signatoryPostalCode,
                    city: stepData.signatoryCity,
                } })),
        React.createElement(Field, { fieldNames: ['signatoryPhoneNumber'], title: text('OVERVIEW_FIELD_SIGNATORY_PHONE_NUMBER_TITLE'), editable: true },
            React.createElement(Value, null, stepData.signatoryPhoneNumber))));
};
