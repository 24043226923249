import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { IconCirclePlusMedium, spacing } from '@izettle/otto';
import { Divider } from '@izettle/react-shared';
import { usePopupsState } from 'src/components/Popups/state';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { getPersonRoles } from 'src/components/Questionnaire/state/helpers';
import { useQuestionnaireEditMode } from 'src/hooks/use-questionnaire-edit-mode';
import { ROLE_FIELD_NAMES } from 'src/schema/common/owner-structure';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { FieldInlineButton } from '../../../FieldInlineButton';
import { ObjectField } from '../../../ObjectField';
import { Value } from '../../../Value';
import { getPeopleByRole, getPeopleWithNoRoles } from './helpers';
const RoleFieldPerson = ({ person }) => {
    const personMaidenName = person.maidenName === undefined ? '' : ` ${person.maidenName}`;
    return React.createElement(Value, null, `${person.firstName} ${person.lastName}${personMaidenName}`);
};
export const RoleFieldIfExists = ({ roleFieldName, roleFieldsTitles }) => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const { startEditMode } = useQuestionnaireEditMode();
    if (!stepData[roleFieldName]) {
        return null;
    }
    return (React.createElement(Field, { fieldNames: [roleFieldName], title: roleFieldsTitles[roleFieldName], editable: true, appendContent: React.createElement(FieldInlineButton, { svgIcon: IconCirclePlusMedium, onClick: () => startEditMode(roleFieldName) }, text('OWNER_STRUCTURE_ADD_PERSON_BUTTON')) }, getPeopleByRole(roleFieldName, stepData).map((person) => (React.createElement(RoleFieldPerson, { key: person.id, person: person })))));
};
export const NoRoleAssignedField = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const legalPersonsWithNoRoles = getPeopleWithNoRoles(stepData);
    if (legalPersonsWithNoRoles.length === 0) {
        return null;
    }
    return (React.createElement(Field, { fieldNames: ['roleNoRoleAssigned'], title: text('OVERVIEW_FIELD_NO_ROLE_ASSIGNED_PERSONS_LIST_TITLE') }, legalPersonsWithNoRoles.map((person) => (React.createElement(RoleFieldPerson, { key: person.id, person: person })))));
};
export const HasUboFieldIfExists = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const { hasUBO } = stepData;
    if (hasUBO === undefined) {
        return null;
    }
    return (React.createElement(Field, { fieldNames: ['hasUBO'], title: text('OWNER_STRUCTURE_HAS_UBO_DESCRIPTION'), editable: true },
        React.createElement(Value, null, hasUBO ? text('OVERVIEW_PAGE_VALUE_YES') : text('OVERVIEW_PAGE_VALUE_NO'))));
};
const LegalPersonsDivider = styled(Divider).attrs({
    marginTop: spacing.large,
    marginBottom: spacing.large,
}) ``;
const LegalPersonsItem = ({ personId, contentTemplate }) => {
    const { dispatch: popupsDispatch } = usePopupsState();
    const { dispatch: questionnaireDispatch, state: questionnaireState } = useQuestionnaireState();
    const { stepData: personData } = useOverviewPageContextValue();
    const personRoles = getPersonRoles(personId, questionnaireState.stepData, ROLE_FIELD_NAMES);
    const editable = personRoles.length > 0;
    const removable = personRoles.length === 0;
    const ownerListStep = questionnaireState.stepList.find((step) => (step === null || step === void 0 ? void 0 : step.componentName) === 'OwnerList');
    if (!ownerListStep) {
        throw new Error('No OwnerList steps found when rendering LegalPersonsItem');
    }
    const personDataMaidenName = personData.maidenName === undefined ? '' : ` ${personData.maidenName}`;
    return (React.createElement(Field, { fieldNames: ['firstName', 'lastName', 'maidenName'], title: `${personData.firstName} ${personData.lastName}${personDataMaidenName}`, hotJarSuppressTitle: true, titleStyle: "RegularBold", buttonPlacement: "title", editable: editable, removable: removable, onEdit: () => {
            popupsDispatch({
                type: 'displayPopup',
                payload: {
                    popupName: 'AddEditPerson',
                    params: {
                        questionnaireState,
                        mode: 'edit',
                        personData,
                        personStepObject: ownerListStep.personStep,
                        onSave: (updatedPersonData) => questionnaireDispatch({ type: 'savePerson', payload: { personId, data: updatedPersonData } }),
                    },
                },
            });
        }, onRemove: () => {
            questionnaireDispatch({ type: 'removePerson', payload: { personId } });
        }, appendContent: contentTemplate() }));
};
export const LegalPersonsFieldIfNotEmpty = ({ legalPersonContentTemplate, isMXMarket, }) => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const { legalPersons } = stepData;
    const legalPersonsItemTemplate = useMemo(() => {
        return ({ key: personId }) => React.createElement(LegalPersonsItem, { personId: personId, contentTemplate: legalPersonContentTemplate });
    }, [legalPersonContentTemplate]);
    if (!legalPersons || Object.keys(legalPersons).length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(LegalPersonsDivider, null),
        React.createElement(ObjectField, { title: isMXMarket
                ? text('OVERVIEW_SECTION_OWNER_STRUCTURE_PERSONS_MX_LIST_TITLE')
                : text('OVERVIEW_SECTION_OWNER_STRUCTURE_PERSONS_LIST_TITLE'), stepDataFieldName: "legalPersons", itemTemplate: legalPersonsItemTemplate })));
};
