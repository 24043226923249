import React from 'react';
import { identity } from 'ramda';
import styled, { css } from 'styled-components';
import { spacing, colors, size, borderRadius, IconSimpleArrowUpMedium, IconSimpleArrowDownMedium, IconCrossMedium, typography, } from '@izettle/otto';
import { FloatingLabel, Icon } from '@izettle/react';
import { useComboBox } from './hook';
const statuses = {
    closedDefault: css `
    border-color: ${colors.selector.initial.default};
    background: ${colors.background.default};
    color: ${colors.action.minor.text.default};
  `,
    closedHover: css `
    background: ${colors.background.default};
    border-color: ${colors.selector.initial.hover};
    color: ${colors.action.minor.text.hover};
  `,
    openDefault: css `
    background: ${colors.background.default};
    border-color: ${colors.selector.selected.default};
    color: ${colors.action.inline.text.default};
  `,
    openHover: css `
    background: ${colors.background.default};
    border-color: ${colors.selector.selected.active};
    color: ${colors.action.secondary.text.hover};
  `,
};
const FloatingLabelStyled = styled(FloatingLabel) `
  ${({ isElevated, isFocused }) => isElevated && isFocused
    ? css `
          color: ${colors.action.primary.border.hover};
        `
    : css `
          color: ${colors.action.minor.text.default};
        `};
`;
const HandlerContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: ${borderRadius.medium};
  height: ${size.large};
  cursor: pointer;
  outline: none;
  user-select: none;
  transition:
    background-color 0.12s ease-in,
    border-color 0.12s ease-in,
    color 0.12s ease-in;
  ${statuses.closedDefault}
  :hover, :focus {
    ${statuses.closedHover};
    ${({ isError }) => isError
    ? css `
            border-color: ${colors.text.error};
          `
    : css ``}
  }

  ${({ isOpen }) => isOpen
    ? css `
          ${statuses.openDefault}
          border-width: 2px;

          :hover,
          :focus {
            ${statuses.openHover}
          }
        `
    : css `
          border-width: 1px;
          padding: 1px;
        `};

  ${({ isFocused }) => isFocused
    ? css `
          ${statuses.openDefault}
          border-width: 2px;
          padding: 0;
          color: ${colors.action.inline.text.default};
          }
        `
    : css `
          ${statuses.closedDefault}
          border-width: 1px;
          padding: 1px;
          color: ${colors.action.minor.text.default};
        `};

  ${({ isError, isOpen }) => isError && !isOpen
    ? css `
          border-color: ${colors.text.error};
        `
    : css ``};
`;
const Input = styled.input `
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0;
  text-overflow: ellipsis;
  margin-right: ${spacing.xxSmall};
  margin-left: ${spacing.small};
  ${typography.regular}
`;
const ControlButton = styled.button.attrs(() => ({
    tabIndex: -1,
    type: 'button',
})) `
  display: block;
  border: none;
  background-color: transparent;
  color: unset;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin-right: ${spacing.small};
`;
const ControlButtonIcon = styled(Icon).attrs(() => ({
    alt: '',
})) `
  pointer-events: none;
  color: ${colors.icon.secondary};
`;
export const Handler = () => {
    const { id, inputRef, isOpen, inputValue, placeholder, label, highlightedIndex, expandButtonTitle, errorElementId, hasError, descriptionElementId, handleInputFocus, handleInputChange, handleInputBlur, handleInputKeyDown, handleExpandClick, handleHandlerClick, handleClearClick, clearButtonTitle, isElevated, isFocused, } = useComboBox();
    const ariaOptionalProps = {};
    const ariaDescribedBy = [errorElementId, descriptionElementId].filter(identity).join(' ');
    if (ariaDescribedBy) {
        ariaOptionalProps['aria-describedby'] = ariaDescribedBy;
    }
    return (React.createElement(HandlerContainer, { id: `${id}__wrapper`, "data-element-name": "handler", isOpen: isOpen, isFocused: isFocused, onClick: handleHandlerClick, isError: hasError },
        Boolean(label) && (React.createElement(FloatingLabelStyled, { id: `${id}-label`, isElevated: isElevated, isFocused: isFocused, htmlFor: id }, label)),
        React.createElement(Input, Object.assign({ ref: inputRef, id: id, type: "text", placeholder: Boolean(label) && !(isElevated && isFocused) ? '' : placeholder, title: placeholder, value: inputValue, onFocus: handleInputFocus, onChange: handleInputChange, onBlur: handleInputBlur, onKeyDown: handleInputKeyDown, role: "combobox", "aria-autocomplete": "list", "aria-controls": `${id}__listbox`, "aria-expanded": isOpen, "aria-haspopup": "listbox", "aria-activedescendant": highlightedIndex >= 0 ? `${id}__listbox__item__${highlightedIndex}` : '', "aria-invalid": hasError }, ariaOptionalProps)),
        isOpen && inputValue ? (React.createElement(ControlButton, { onClick: handleClearClick, "data-element-name": "clear-button", title: clearButtonTitle },
            React.createElement(ControlButtonIcon, { svgIcon: IconCrossMedium }))) : (React.createElement(ControlButton, { onClick: handleExpandClick, "data-element-name": "expand-button", title: expandButtonTitle, "aria-controls": `${id}__listbox`, "aria-expanded": isOpen, "aria-haspopup": "listbox" },
            React.createElement(ControlButtonIcon, { svgIcon: isOpen ? IconSimpleArrowUpMedium : IconSimpleArrowDownMedium })))));
};
