import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
import { config, Environment } from 'src/config';
import { Autofill } from './Autofill';
import { PayPalUI } from './PaypalUI';
import { Section } from './Section';
import { Version } from './Version';
const Container = styled.div `
  position: fixed;
  z-index: 50;
  top: ${spacing.none};
  left: ${spacing.none};
  max-width: 100%;
`;
const StyledButton = styled.button `
  background-color: ${colors.background.inverted};
  border: 1px solid ${colors.background.inverted};
  cursor: pointer;
  outline: none;
  text-align: center;
  margin: ${spacing.none};
  padding: ${spacing.tiny};
`;
const StyledText = styled(Text.SmallBold) `
  color: ${colors.text.inverted};
  line-height: 15px;
`;
const ToggleContent = styled.div `
  padding: ${spacing.xSmall};
  background: ${colors.background.groupedSecondary};
  border: 1px solid ${colors.background.inverted};
  width: 240px;
  max-width: 100%;
`;
const ToggleButton = ({ onClick }) => {
    return (React.createElement(StyledButton, { type: "button", onClick: onClick, tabIndex: -1 },
        React.createElement(StyledText, { marginTop: "none", marginBottom: "none" }, "D")));
};
export const Debugger = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOnClick = useCallback(() => setIsOpen((currentValue) => !currentValue), [setIsOpen]);
    const closeDebugger = useCallback(() => setIsOpen(false), [setIsOpen]);
    if (config.appEnv === Environment.Production) {
        return null;
    }
    return (React.createElement(Container, { "aria-hidden": true },
        React.createElement(ToggleButton, { onClick: handleOnClick }),
        isOpen ? (React.createElement(ToggleContent, null,
            React.createElement(Section, { title: "PayPal UI" },
                React.createElement(PayPalUI, null)),
            React.createElement(Section, { title: "Autofill" },
                React.createElement(Autofill, { closeDebugger: closeDebugger })),
            React.createElement(Section, { title: "Version" },
                React.createElement(Version, null)))) : null));
};
