import { IsoLocale } from '@izettle/constants';
import { nationalityOptions } from '../../common/nationality-options';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { render, sequence, step } from '../../steps-builder';
import { SIGNATORY_ACTION } from './data';
const introScreen = ({ translate }) => {
    return {
        componentName: 'PreparationScreen',
        applicationVariables: {
            stepName: 'PreparationScreen',
            content: {
                header: translate('GAP_PREPARATION_SCREEN_HEADER'),
                button: translate('FORM_BUTTON'),
                helpTexts: [
                    {
                        title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                        body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                    },
                ],
                items: [
                    {
                        icon: 'Person',
                        title: translate('GAP_ST_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                        description: translate('GAP_ST_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_DESCRIPTION'),
                    },
                ],
            },
        },
    };
};
const signatoryAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
});
const signatoryNationality = ({ translate }) => ({
    fields: ['signatoryNationality', 'signatoryAction'],
    JSONSchema: {
        required: ['signatoryNationality'],
        type: 'object',
        properties: {
            signatoryNationality: {
                title: translate('SIGNATORY_NATIONALITY_LABEL'),
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
            signatoryAction: {
                type: 'string',
                default: SIGNATORY_ACTION.SET_NATIONALITY,
            },
        },
    },
    UISchema: {
        signatoryNationality: {
            'ui:options': {
                sortByLabels: 'asc',
            },
            'ui:widget': 'CustomComboBoxWidget',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
        },
        signatoryAction: {
            'ui:title': false,
            'ui:widget': 'hidden',
        },
    },
    applicationVariables: {
        stepName: 'signatoryNationality',
        segment: 'personalDetails',
        content: {
            button: translate('FORM_BUTTON'),
        },
    },
});
const overviewPage = ({ translate, formContext }) => {
    const locale = IsoLocale[`${formContext === null || formContext === void 0 ? void 0 : formContext.user.languageId}${formContext === null || formContext === void 0 ? void 0 : formContext.user.countryId}`];
    return {
        componentName: 'OverviewPage',
        applicationVariables: {
            stepName: 'overviewPage',
            version: 'GAP_FI_ST_COMPANY',
            confirmationScreen: {
                title: translate('GAP_OVERVIEW_CONFIRMATION_TITLE'),
                subtitle: translate('GAP_OVERVIEW_CONFIRMATION_SUB_TITLE'),
                button: translate('GAP_OVERVIEW_CLOSE_BUTTON'),
            },
            content: {
                title: translate('GAP_OVERVIEW_TITLE'),
                subtitle: translate('GAP_OVERVIEW_SUB_TITLE'),
                submitButton: translate('GAP_OVERVIEW_SUBMIT_BUTTON'),
                submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(locale)]),
            },
        },
    };
};
const rootSequence = sequence([
    step(introScreen),
    step(signatoryAddress),
    step(signatoryNationality),
    step(overviewPage),
]);
export const GAP_FI_ST_COMPANY = (context) => render(rootSequence, context);
