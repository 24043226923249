import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Value } from '../../../Value';
export const SelfEmployedUsaTaxpayerFields = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { fieldNames: ['signatoryUnderUsaTaxLaw'], title: text('OVERVIEW_FIELD_SIGNATORY_UNDER_TAX_LAWS_TITLE'), conditionalRendering: true, editable: true },
            React.createElement(Value, null, stepData.signatoryUnderUsaTaxLaw ? text('OVERVIEW_PAGE_VALUE_YES') : text('OVERVIEW_PAGE_VALUE_NO'))),
        React.createElement(Field, { fieldNames: ['signatoryUsaTaxNumber'], title: text('OVERVIEW_FIELD_SIGNATORY_USA_TAX_NUMBER_TITLE'), editable: true, conditionalRendering: true },
            React.createElement(Value, null, stepData.signatoryUsaTaxNumber)),
        React.createElement(Field, { fieldNames: ['signatorySubjectToUsaBackupWithholding'], title: text('OVERVIEW_FIELD_SIGNATORY_SUBJECT_TO_USA_BACKUP_WITHHOLDING_TITLE'), conditionalRendering: true, editable: true },
            React.createElement(Value, null, stepData.signatorySubjectToUsaBackupWithholding
                ? text('OVERVIEW_PAGE_VALUE_YES')
                : text('OVERVIEW_PAGE_VALUE_NO')))));
};
