import React, { useContext, useEffect, useState } from 'react';
import { identity } from 'ramda';
import styled, { css } from 'styled-components';
import { colors } from '@izettle/otto';
import { TextFieldMasked } from '@izettle/react';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { isPlatformIOS } from 'src/util';
import { FieldContext } from '../FieldTemplate/context';
const REGEX_ALL_CHARACTERS_EXCEPT_NEW_LINE = /./;
const REGEX_ONLY_ALPHABET_LETTERS = /[A-Za-z]/;
const REGEX_ONLY_NUMBERS = /[0-9]/;
const REGEX_PLUS_OR_DIGIT = /[+0-9]/;
const REGEX_EITHER_A_OR_DASH = /[Aa-]/;
const REGEX_EITHER_LETTER_OR_NUMBER = /[A-Za-z0-9]/;
const REGEX_X_Y_Z_OR_NUMBER = /[xXyYzZ0-9]/;
const StyledContainer = styled.div `
  .otto-text-field-outer-container {
    border-color: ${colors.selector.initial.default};
    &:focus {
      border-color: ${colors.selector.selected.active} !important;
    }
    ${({ error, focus }) => {
    if (error && focus) {
        return css `
          border-color: ${colors.border.error};
        `;
    }
    if (!error && focus) {
        return css `
          border-color: ${colors.selector.selected.active};
        `;
    }
    if (!error && !focus) {
        return css `
          &:hover {
            border-color: ${colors.selector.initial.hover};
          }
        `;
    }
    return css ``;
}};
  }
`;
export const TextWidget = ({ id, value, placeholder, onChange, autofocus, options, disabled }) => {
    var _a;
    const { state: { editMode }, } = useQuestionnaireState();
    const [onFocus, setOnFocus] = useState(false);
    // - autofocus is not enabled in editMode, because it may be confusing for user
    // - autofocus is not enabled for fields which already have value
    // - autofocus is not enabled on iOS, since we can't always reliably show keyboard.
    //     (keyboard is shown only on direct user interaction which is not the case when
    //     we are making step validation requests, for example)
    const autoFocusEnabled = autofocus && !editMode && !value && !isPlatformIOS;
    const { hasError, errorElementId, descriptionElementId } = useContext(FieldContext);
    const ariaOptionalProps = {};
    const ariaDescribedBy = [errorElementId, descriptionElementId].filter(identity).join(' ');
    if (ariaDescribedBy) {
        ariaOptionalProps['aria-describedby'] = ariaDescribedBy;
    }
    useEffect(() => {
        if ((hasError || autoFocusEnabled) && !onFocus) {
            setOnFocus(true);
        }
    }, [hasError, autoFocusEnabled]);
    return (React.createElement(StyledContainer, { error: hasError, focus: onFocus },
        React.createElement(TextFieldMasked, Object.assign({ id: id, value: value || '', onAccept: (event) => {
                onChange(event.value === '' ? options.emptyValue : event.value);
            }, placeholder: placeholder, disabled: disabled, autoFocus: onFocus || autoFocusEnabled, maskOptions: {
                mask: (_a = options.mask) !== null && _a !== void 0 ? _a : /.*/,
                definitions: {
                    '*': REGEX_ALL_CHARACTERS_EXCEPT_NEW_LINE,
                    '+': REGEX_PLUS_OR_DIGIT,
                    '9': REGEX_ONLY_NUMBERS,
                    a: REGEX_ONLY_ALPHABET_LETTERS,
                    Ɐ: REGEX_EITHER_A_OR_DASH,
                    Œ: REGEX_EITHER_LETTER_OR_NUMBER,
                    '¿': REGEX_X_Y_Z_OR_NUMBER,
                },
            }, label: options.label ? options.label.toString() : undefined, "aria-invalid": hasError, isErrorStateOverriden: hasError, onClick: () => {
                setOnFocus(true);
            }, onBlur: () => {
                setOnFocus(false);
            } }, ariaOptionalProps))));
};
