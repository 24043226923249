import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { isEmpty } from 'ramda';
import { datadogRum } from '@datadog/browser-rum';
import { OverviewPageContext } from './context';
import { buildErrorPaths } from './helpers/build-error-paths';
import { checkErrorsHandled } from './helpers/check-errors-handled';
const checkErrorHandledExists = (paths, fieldPath) => {
    if (paths.has(fieldPath)) {
        datadogRum.addAction(`Field is already registered as handling errors: ${fieldPath}`);
    }
};
const checkErrorHandledNotExists = (paths, fieldPath) => {
    if (!paths.has(fieldPath)) {
        datadogRum.addAction(`Field error handler wasn't registered earlier: ${fieldPath}`);
    }
};
export const OverviewPageContextProvider = ({ children, stepDataSubPath, stepData, errors, triggerTechnicalError, isSubmissionInProgress }) => {
    const parentContext = useContext(OverviewPageContext);
    const errorHandlingFieldPaths = useMemo(() => new Set(), []);
    const isRoot = parentContext.isRoot === undefined;
    const stepDataPath = isRoot ? '' : `${parentContext.stepDataPath}.${stepDataSubPath}`;
    triggerTechnicalError !== null && triggerTechnicalError !== void 0 ? triggerTechnicalError : (triggerTechnicalError = parentContext.triggerTechnicalError);
    const updateFieldOptions = useCallback((fieldPath, options) => {
        if (!isRoot) {
            return parentContext.updateFieldOptions(`${stepDataSubPath}.${fieldPath}`, options);
        }
        if (options.canHandleErrors) {
            checkErrorHandledExists(errorHandlingFieldPaths, fieldPath);
            errorHandlingFieldPaths.add(fieldPath);
        }
        else if (options.canHandleErrors === false) {
            checkErrorHandledNotExists(errorHandlingFieldPaths, fieldPath);
            errorHandlingFieldPaths.delete(fieldPath);
        }
    }, []);
    // Ensure that all fields in `errors` object have visible UI elements
    useEffect(() => {
        if (!isRoot || !errors || isEmpty(errors)) {
            return;
        }
        const errorPaths = buildErrorPaths(errors);
        if (!checkErrorsHandled(Array.from(errorHandlingFieldPaths), errorPaths)) {
            triggerTechnicalError === null || triggerTechnicalError === void 0 ? void 0 : triggerTechnicalError();
        }
    }, [errors]);
    return (React.createElement(OverviewPageContext.Provider, { value: {
            stepData,
            errors,
            updateFieldOptions,
            stepDataPath,
            triggerTechnicalError,
            isRoot,
            isSubmissionInProgress,
        } }, children));
};
