export const isGroupedWidget = (uiSchema) => {
    const uiWidget = uiSchema['ui:widget'];
    return uiWidget === 'CustomRadioGroupWidget' || uiWidget === 'CustomCheckboxGroupWidget';
};
export const isSchemaTypeNull = (schema) => {
    return (schema === null || schema === void 0 ? void 0 : schema.type) === 'null';
};
export const getTitleId = (fieldId) => `${fieldId}__title`;
export const getDescriptionId = (fieldId) => `${fieldId}__description`;
export const getErrorListId = (fieldId) => `${fieldId}__errors`;
