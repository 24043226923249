import { buildSelfEmployedUsaTaxpayerSequence } from 'src/schema/common/usaTaxpayerDetails/schema';
import { BusinessTypePartOne, BusinessType } from 'src/utils/country/FR/data';
import { buildCategorySteps } from '../../common/categories';
import { sequence, step, render, conditional, decision, customStepBuilder } from '../../steps-builder';
import * as stepTemplates from './flow-step-templates';
const root = sequence([
    // BUISINESS TYPE
    step(stepTemplates.businessTypePartOne),
    conditional('businessTypePartOne', [
        {
            when: [BusinessTypePartOne.Other],
            branch: step(stepTemplates.businessTypePartTwo),
        },
    ]),
    step(stepTemplates.businessTypeFunction),
    // BUSINESS DETAILS
    conditional('businessType', [
        {
            when: [BusinessType.LimitedCompany, BusinessType.Partnership],
            branch: sequence([
                step(stepTemplates.generalPrepScreen),
                step(stepTemplates.generalBusinessEntityNumber),
                step(stepTemplates.generalBusinessName),
                step(stepTemplates.generalBusinessAddress),
            ]),
        },
        {
            when: [BusinessType.SelfEmployed],
            branch: sequence([
                step(stepTemplates.selfEmployedPrepScreen),
                step(stepTemplates.generalBusinessEntityNumber),
                step(stepTemplates.selfEmployedBusinessName),
                step(stepTemplates.generalBusinessAddress),
            ]),
        },
        {
            when: [BusinessType.NonProfit],
            branch: sequence([
                step(stepTemplates.nonProfitPrepScreen),
                step(stepTemplates.nonProfitBusinessEntityNumber),
                step(stepTemplates.nonProfitBusinessName),
                step(stepTemplates.nonProfitBusinessAddress),
            ]),
        },
    ]),
    // PERSONAL DETAILS
    step(stepTemplates.personalDetailsQuestionsIntro),
    step(stepTemplates.signatoryName),
    step(stepTemplates.signatoryDOB),
    step(stepTemplates.signatoryNationality),
    customStepBuilder(buildSelfEmployedUsaTaxpayerSequence('businessType', 'SelfEmployed')),
    decision('isSignatoryShareAddress', stepTemplates.isSignatoryShareAddress, [
        { when: false, branch: step(stepTemplates.signatoryAddress) },
    ]),
    step(stepTemplates.signatoryPhoneNumber),
    // OWNER STRUCTURE
    conditional('businessType', [
        {
            when: [BusinessType.LimitedCompany],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.generalOwnerStructureIntro),
                step(stepTemplates.limitedDirectorsList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.limitedPartnershipBeneficialOwnersList) },
                    { when: false, branch: step(stepTemplates.limitedPartnershipAlternativeOwnersList) },
                ]),
            ]),
        },
        {
            when: [BusinessType.Partnership],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.generalOwnerStructureIntro),
                step(stepTemplates.partnersList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.limitedPartnershipBeneficialOwnersList) },
                    { when: false, branch: step(stepTemplates.limitedPartnershipAlternativeOwnersList) },
                ]),
            ]),
        },
        {
            when: [BusinessType.NonProfit],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.nonProfitOwnerStructureIntro),
                step(stepTemplates.nonProfitDirectorsList),
                step(stepTemplates.nonProfitAlternativeOwnersList),
            ]),
        },
    ]),
    // USAGE OF ZETTLE
    // Usage of Zettle intro
    conditional('businessType', [
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit, BusinessType.Partnership],
            branch: step(stepTemplates.generalUsageOfIZettleIntro),
        },
        {
            when: [BusinessType.SelfEmployed],
            branch: step(stepTemplates.selfEmployedUsageOfIZettleIntro),
        },
    ]),
    customStepBuilder(buildCategorySteps),
    step(stepTemplates.businessLocationsTypes),
    step(stepTemplates.businessFrequency),
    step(stepTemplates.businessAverageTransaction),
    step(stepTemplates.businessIzettleShareOfTurnover),
    decision('businessCurrentAnnualTurnover', stepTemplates.businessCurrentAnnualTurnover, [
        { when: 'NEW_BUSINESS', branch: step(stepTemplates.businessExpectedAnnualTurnover) },
    ]),
    // OVERVIEW
    step(stepTemplates.overviewPage),
]);
export const FR = (context) => render(root, context);
