import { datadogRum } from '@datadog/browser-rum';
import { LUXFormKeys, KycFormKeys, UsaTaxDetailsFormKeys, } from 'src/providers/form';
export const hotJarTagFormKey = (form) => {
    if (KycFormKeys.includes(form)) {
        window.hj('tagRecording', ['KYC 2.0 LUX New Users']);
    }
    else if (LUXFormKeys.includes(form)) {
        window.hj('tagRecording', ['KYC 2.0 LUX Existing Users']);
    }
    else if (UsaTaxDetailsFormKeys.includes(form)) {
        window.hj('tagRecording', ['KYC 2.0 USA Tax Details Users']);
    }
    else {
        // eslint-disable-next-line no-console
        datadogRum.addError(`Uncategorized form key: ${form}`);
    }
};
export const hotJarSetUserId = (userId) => {
    // for this to work properly, User Attributes must be enabled on HJ for all sites
    // https://insights.hotjar.com/settings/user-attributes
    window.hj('identify', userId);
};
export const hotJarSendTrigger = (triggerName) => {
    window.hj('trigger', triggerName);
};
