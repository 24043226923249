import React, { useCallback, useState } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useBackendStepValidation } from 'src/hooks/use-backend-step-validation';
import { Form } from '../Form';
import { useQuestionnaireState } from '../Questionnaire/state';
export const FormStep = ({ stepObject }) => {
    var _a;
    const { text } = useLocalization();
    const { state, dispatch } = useQuestionnaireState();
    const [formData, setFormData] = useState(state.stepData);
    const handleComplete = useCallback((submittedFormData) => {
        dispatch({ type: 'completeCurrentStep', payload: submittedFormData });
    }, [dispatch]);
    const backendValidation = useBackendStepValidation(stepObject);
    return (React.createElement(Form, { JSONSchema: stepObject.JSONSchema, UISchema: stepObject.UISchema, helpTexts: (_a = stepObject.applicationVariables.content) === null || _a === void 0 ? void 0 : _a.helpTexts, extraTexts: stepObject.applicationVariables.content, onComplete: handleComplete, onFormDataChange: setFormData, backendValidation: backendValidation, formData: formData, completeButtonText: state.editMode ? text('FORM_BUTTON_SAVE') : text('FORM_BUTTON') }));
};
