import { NativeBusinessType, BusinessTypeValue } from 'src/utils/country/NO/data';
import { generateDigitSequence, generateIntFromInterval } from '../../helpers';
const caculateChecksumDigit = (baseNumbers, weight) => {
    const additionResult = baseNumbers.reduce((total, baseNumber, currentIndex) => {
        return total + baseNumber * weight[currentIndex];
    }, 0);
    const finalResult = 11 - (additionResult % 11);
    return finalResult === 11 ? 0 : finalResult;
};
export const generateOrganizationNumber = () => {
    const baseNumbers = `${generateDigitSequence(8)}`;
    const weight = [3, 2, 7, 6, 5, 4, 3, 2];
    const checksumDigit = caculateChecksumDigit(Array.from(baseNumbers, Number), weight);
    const organizationNumber = `${baseNumbers}${checksumDigit}`;
    if (organizationNumber.length > 9) {
        return generateOrganizationNumber();
    }
    return organizationNumber;
};
export const generateSocialSecurityNumber = () => {
    const baseNumbers = [
        generateIntFromInterval(1, 28, 2),
        generateIntFromInterval(1, 12, 2),
        generateIntFromInterval(60, 99, 2),
        generateIntFromInterval(1, 999, 3), // individual digits
    ].join('');
    const baseNumbersArray = Array.from(baseNumbers, Number);
    const weight1 = [3, 7, 6, 1, 8, 9, 4, 5, 2];
    const weight2 = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    const firstChecksumDigit = caculateChecksumDigit(baseNumbersArray, weight1);
    const secondChecksumDigit = caculateChecksumDigit([...baseNumbersArray, firstChecksumDigit], weight2);
    const socialSecurityNumber = `${baseNumbers}${firstChecksumDigit}${secondChecksumDigit}`;
    if (socialSecurityNumber.length > 11) {
        return generateSocialSecurityNumber();
    }
    return `${socialSecurityNumber.substr(0, 6)} ${socialSecurityNumber.substr(6, 11)}`;
};
export const generateCommonData = () => ({
    businessName: 'Mystic Pizza',
    businessLegalEntityNumber: generateOrganizationNumber(),
    businessAddressCo: 'c/o Hivju Kristoffer',
    businessStreetAddress: 'Steintræbakken 239',
    businessPostalCode: '5238',
    businessCity: 'Rådal',
    signatoryFirstName: 'Kristoffer',
    signatoryLastName: 'Hivju',
    signatoryLegalEntityNumber: '051077 01518',
    signatoryNationality: 'US',
    isSignatoryShareAddress: true,
    signatoryPhoneNumber: '+4722171819',
    businessCategoryId: '329',
    businessSubCategoryId: '331',
    businessLocationTypes: ['PHYSICAL_STORE', 'TEMPORARY_STORE'],
    businessFrequency: 'FEW_TIMES_A_MONTH',
    businessAverageTransaction: '0-500',
    businessIzettleShareOfTurnover: '20-80%',
    businessCurrentAnnualTurnover: '200000-2000000',
});
const generatePersonData = () => ({
    firstName: 'Sofie',
    lastName: 'Simonsen',
    legalEntityNumber: '310327 15055',
    nationality: 'NO',
    streetAddress: 'Tøyengata 2',
    postalCode: '0190',
    city: 'Oslo',
});
const generateCommonLimitedCompanyData = () => (Object.assign(Object.assign({}, generateCommonData()), { businessType: BusinessTypeValue.LimitedCompany, legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    }, roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
const generateCommonPartnershipData = () => (Object.assign(Object.assign({}, generateCommonData()), { businessType: BusinessTypeValue.Partnership, legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    }, rolePartners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'], hasUBO: true, roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
const generateCommonNonProfitData = () => (Object.assign(Object.assign({}, generateCommonData()), { businessType: BusinessTypeValue.NonProfit, legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    }, roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'], roleAlternativeOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'] }));
// Complete generators for each company type
const generateENK = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData()), { nativeBusinessType: NativeBusinessType.ENK, businessType: BusinessTypeValue.SelfEmployed }), (formContext.form.endsWith('1099K') ? { signatoryUnderUsaTaxLaw: false } : false)));
export const generateAksjeselskap = () => (Object.assign(Object.assign({}, generateCommonLimitedCompanyData()), { nativeBusinessType: NativeBusinessType.Aksjeselskap }));
export const generateAnsvarligSelskap = () => (Object.assign(Object.assign({}, generateCommonPartnershipData()), { nativeBusinessType: NativeBusinessType.AnsvarligSelskap }));
export const generateKommandittselskap = () => (Object.assign(Object.assign({}, generateCommonPartnershipData()), { nativeBusinessType: NativeBusinessType.Kommandittselskap }));
export const generateSamvirkeforetag = () => (Object.assign(Object.assign({}, generateCommonLimitedCompanyData()), { nativeBusinessType: NativeBusinessType.Samvirkeforetag }));
export const generateIdeellOrganisasjon = () => (Object.assign(Object.assign({}, generateCommonNonProfitData()), { nativeBusinessType: NativeBusinessType.IdeellOrganisasjon }));
export const generateKommune = () => (Object.assign(Object.assign({}, generateCommonNonProfitData()), { nativeBusinessType: NativeBusinessType.Kommune }));
export const generateNUF = () => (Object.assign(Object.assign({}, generateCommonLimitedCompanyData()), { nativeBusinessType: NativeBusinessType.NUF }));
export const NO = [
    {
        title: "Enkeltpersonforetak (ENK)" /* CopyNO.BUSINESS_TYPE_ENK_LABEL */,
        generateFunc: generateENK,
    },
    {
        title: "Aksjeselskap (AS og ASA)" /* CopyNO.BUSINESS_TYPE_AKSJESELSKAP_LABEL */,
        generateFunc: generateAksjeselskap,
    },
    {
        title: "Ansvarlig selskap (ANS og DA)" /* CopyNO.BUSINESS_TYPE_ANSVARLIG_SELSKAP_LABEL */,
        generateFunc: generateAnsvarligSelskap,
    },
    {
        title: "Kommandittselskap (KS)" /* CopyNO.BUSINESS_TYPE_KOMMANDITTSELSKAP_LABEL */,
        generateFunc: generateKommandittselskap,
    },
    {
        title: "Samvirkeforetag (SA)" /* CopyNO.BUSINESS_TYPE_SAMVIRKEFORETAG_LABEL */,
        generateFunc: generateSamvirkeforetag,
    },
    {
        title: "Ideell organisasjon / forening" /* CopyNO.BUSINESS_TYPE_IDEELL_ORGANISASJON_LABEL */,
        generateFunc: generateIdeellOrganisasjon,
    },
    {
        title: "Kommune" /* CopyNO.BUSINESS_TYPE_KOMMUNE_LABEL */,
        generateFunc: generateKommune,
    },
    {
        title: "Norskregistrert utenlansk foretak (NUF)" /* CopyNO.BUSINESS_TYPE_NUF_LABEL */,
        generateFunc: generateNUF,
    },
];
