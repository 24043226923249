import { isEmpty } from 'ramda';
import { getPersonRoles } from 'src/components/Questionnaire/state/helpers';
import { ROLE_FIELD_NAMES, SIGNATORY_PERSON_ID } from 'src/schema/common/owner-structure';
const getSignatoryFullName = (stepData) => `${stepData.signatoryFirstName} ${stepData.signatoryLastName}${stepData.signatoryMaidenName === undefined ? '' : ` ${stepData.signatoryMaidenName}`}`;
const getPersonFullName = (person) => `${person.firstName} ${person.lastName}${person.maidenName === undefined ? '' : ` ${person.maidenName}`}`;
// TODO: Type function return properly
const getLegalPersonsEnumOptions = (legalPersons) => {
    return Object.entries(legalPersons).map((personEntries) => {
        return { enum: [personEntries[0]], title: getPersonFullName(personEntries[1]) };
    });
};
const getEditableOptions = (legalPersons, formData) => {
    const personIds = Object.keys(legalPersons);
    return personIds.filter((personId) => {
        const personRoles = getPersonRoles(personId, formData, ROLE_FIELD_NAMES);
        if (!isEmpty(personRoles)) {
            return true;
        }
        return false;
    });
};
const getRemovableOptions = (legalPersons, formData) => {
    const personIds = Object.keys(legalPersons);
    return personIds.filter((personId) => {
        const personRoles = getPersonRoles(personId, formData, ROLE_FIELD_NAMES);
        if (isEmpty(personRoles)) {
            return true;
        }
        return false;
    });
};
// TODO: Refactor this function
// It is currently doing too much and have many dependencies which makes it hard to test
export const buildOwnerListStepObject = (originalStepObject, allStepsData, roleStepData, text) => {
    var _a, _b;
    const legalPersons = (_a = allStepsData.legalPersons) !== null && _a !== void 0 ? _a : {};
    const { content: { title, description, instruction }, roleFieldName: currentRoleFieldName, signatoryRoleLabel, personRoleLabels, } = originalStepObject.applicationVariables;
    const stepData = Object.assign(Object.assign({}, allStepsData), roleStepData);
    if (!((_b = originalStepObject.fields) === null || _b === void 0 ? void 0 : _b.includes(currentRoleFieldName))) {
        throw new Error(`Field '${currentRoleFieldName}' is not included in fields of ${originalStepObject.ID}`);
    }
    return {
        ID: originalStepObject.ID,
        fields: originalStepObject.fields,
        dependentOn: originalStepObject.dependentOn,
        backendValidation: originalStepObject.backendValidation,
        JSONSchema: {
            type: 'object',
            title,
            properties: {
                [currentRoleFieldName]: {
                    title: description,
                    description: instruction,
                    type: 'array',
                    items: {
                        type: 'string',
                        anyOf: [
                            { enum: [SIGNATORY_PERSON_ID], title: getSignatoryFullName(allStepsData) },
                            ...getLegalPersonsEnumOptions(legalPersons),
                        ],
                    },
                    uniqueItems: true,
                },
            },
        },
        UISchema: {
            [currentRoleFieldName]: {
                'ui:widget': 'CustomCheckboxGroupWidget',
                'ui:options': {
                    editableOptions: getEditableOptions(legalPersons, stepData),
                    removableOptions: getRemovableOptions(legalPersons, stepData),
                    // TODO: Simplify and extract this into a function
                    descriptions: [SIGNATORY_PERSON_ID, ...Object.keys(legalPersons)].map((personId) => {
                        if (!personRoleLabels) {
                            throw new Error('The "personRoleLabels" property must be defined when using the OwnerList component');
                        }
                        const personRoles = getPersonRoles(personId, stepData, ROLE_FIELD_NAMES).map((roleFieldName) => {
                            return personRoleLabels[roleFieldName];
                        });
                        if (personId === SIGNATORY_PERSON_ID) {
                            personRoles.unshift(signatoryRoleLabel);
                        }
                        return {
                            value: personId,
                            description: !isEmpty(personRoles)
                                ? personRoles.join(', ')
                                : text('OWNER_STRUCTURE_ROLE_EMPTY_LIST_LABEL'),
                        };
                    }),
                    hotJarSuppressTitle: true,
                },
                'ui:emptyValue': [],
            },
            'ui:options': {
                titleStyle: 'LargeBold',
            },
        },
        applicationVariables: originalStepObject.applicationVariables,
    };
};
