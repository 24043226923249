import { config } from 'src/config';
import { redirectToUrl, verifyRedirectUrl } from 'src/helpers';
import { FormType, GapFormKeys } from 'src/providers/form';
import { closeWebview, pushDataLayer } from 'src/util';
export function getRedirectOnSuccess(loadRedirectTo, formContext) {
    return () => {
        // for GAP keys ignoring saved URL (why?)
        if (GapFormKeys.includes(formContext.form)) {
            return redirectToUrl(config.portalUrl);
        }
        const savedRedirectUrl = loadRedirectTo(formContext.user.organizationUuid);
        if (savedRedirectUrl && verifyRedirectUrl(savedRedirectUrl)) {
            return redirectToUrl(savedRedirectUrl);
        }
        const redirectUrl = `${config.portalUrl}`;
        redirectToUrl(redirectUrl);
    };
}
export function getFinalizeKYCWithSuccess(formContext, state, redirectOnSuccess) {
    return () => {
        // Give tracking events a chance to finish before leaving KYC
        const trackingEventsBufferTime = 1000;
        const { tracking } = formContext;
        if (tracking.enabled && tracking.formType === FormType.ONBOARDING) {
            pushDataLayer({
                event: 'GAevent',
                eventCategory: 'userCreated',
                eventAction: state.stepData.businessType,
                eventLabel: config.portalUrl,
            });
        }
        const leaveKYC = () => {
            closeWebview(formContext.user.emailAddress);
            void redirectOnSuccess();
        };
        setTimeout(leaveKYC, trackingEventsBufferTime);
    };
}
