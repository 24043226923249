import React, { useContext, useRef, useState } from 'react';
import { CheckedInputContainer, ContentContainer, Description, HiddenInput, Title, useCheckedInputKeyboardListener, } from '../CheckedInputBase';
import { useCheckedInputAriaProps } from '../CheckedInputBase/use-aria-props';
import { RadioGroupContext } from './context';
import { RadioIcon } from './radio-icon';
export const Radio = ({ value, title, description, disabled, index }) => {
    const [hasKeyboardFocus, setHasKeyboardFocus] = useState(false);
    const { value: selectedValue, name, onChange, errorElementId } = useContext(RadioGroupContext);
    const { onKeyDown } = useCheckedInputKeyboardListener({ handleArrowKeys: true });
    const inputRef = useRef(null);
    const checked = selectedValue === value;
    const aria = useCheckedInputAriaProps({ checked, description, errorElementId });
    // According to ARIA example, only checked item should be focusable
    // If there are no items selected, only first item should be focusable
    // https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/radio/radio.html
    const noValueSelected = selectedValue == null;
    const isFirstItem = index === 0;
    const tabIndex = checked || (noValueSelected && isFirstItem) ? 0 : -1;
    return (React.createElement(CheckedInputContainer
    // tabIndex=-1 is only needed to capture focus with clicks
    // when keyboard focus already present, it should not be set,
    // so that screen readers reads <legend> tag for RadioGroup fieldset
    , { 
        // tabIndex=-1 is only needed to capture focus with clicks
        // when keyboard focus already present, it should not be set,
        // so that screen readers reads <legend> tag for RadioGroup fieldset
        tabIndex: hasKeyboardFocus ? undefined : -1, checked: checked, disabled: disabled, "data-value": value, hasKeyboardFocus: hasKeyboardFocus, onClick: () => {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
        }, onKeyDown: onKeyDown },
        React.createElement(HiddenInput, { ref: inputRef, checked: checked, disabled: disabled, name: name, type: "radio", value: value, onChange: onChange, "aria-labelledby": aria.titleId }),
        React.createElement(RadioIcon, Object.assign({ className: "focusTarget", role: "radio", checked: checked, disabled: disabled, tabIndex: tabIndex, "data-value": value, onFocus: (e) => {
                if (e.currentTarget === e.target) {
                    setHasKeyboardFocus(true);
                }
            }, onBlur: (e) => {
                if (e.currentTarget === e.target) {
                    setHasKeyboardFocus(false);
                }
            } }, aria.attrs)),
        React.createElement(ContentContainer, null,
            React.createElement(Title, { id: aria.titleId, disabled: disabled, checked: checked }, title),
            description && (React.createElement(Description, { id: aria.descriptionId, disabled: disabled, checked: checked }, description)))));
};
