import React, { useEffect } from 'react';
import { getCurrentStepObject } from 'src/components/Questionnaire/state/helpers';
import { CustomStep } from '../../CustomStep';
import { FormStep } from '../../FormStep';
import { FunctionStep } from '../../FunctionStep';
import { usePageTitleForStepEffect } from '../hooks/use-page-title-for-step-effect';
import { useQuestionnaireState } from '../state';
import { isFormStepObject, isCustomStepObject, isFunctionStepObject } from '../types';
export const StepRenderer = () => {
    const { state, dispatch } = useQuestionnaireState();
    const stepObject = getCurrentStepObject(state);
    usePageTitleForStepEffect();
    useEffect(() => {
        dispatch({ type: 'stepLoaded', payload: stepObject });
    }, [stepObject]);
    if (isFormStepObject(stepObject)) {
        return React.createElement(FormStep, { stepObject: stepObject });
    }
    if (isCustomStepObject(stepObject)) {
        return React.createElement(CustomStep, { stepObject: stepObject });
    }
    if (isFunctionStepObject(stepObject)) {
        return React.createElement(FunctionStep, { stepObject: stepObject });
    }
    throw new Error('Unknown step type');
};
