import { datadogRum } from '@datadog/browser-rum';
export const checkErrorsHandled = (errorHandlingFieldPaths, errorPaths) => {
    const atLeastOneErrorFieldHandled = errorPaths.some((path) => errorHandlingFieldPaths.includes(path));
    const unhandledErrorPaths = errorPaths.filter((path) => !errorHandlingFieldPaths.includes(path));
    if (unhandledErrorPaths.length > 0) {
        // Field name not found among visible fields, nor object fields
        unhandledErrorPaths.forEach((path) => {
            datadogRum.addError(`Validation error is not handled by any visible field for '${path}'`);
        });
    }
    if (!atLeastOneErrorFieldHandled) {
        // we have validation errors but no fields are displaying it
        datadogRum.addError(`Validation errors are not handled by any visible fields for '${errorPaths.join()}'`);
        // triggering technical error to ensure that user receives some feedback
        return false;
    }
    return true;
};
