import { isPlainObject } from 'src/util';
/**
 * Builds a list of all property paths that end up with array of errors from an error object
 */
export const buildErrorPaths = (errors) => {
    const result = [];
    // recursively goes through object properties and builds paths, adds them to result
    const traverseObject = (errorsNode, parentPath = null) => {
        if (!isPlainObject(errorsNode)) {
            // we shouldn't be having anything but an object anyway here but checking just in case
            return;
        }
        Object.entries(errorsNode).forEach(([key, value]) => {
            const path = parentPath ? `${parentPath}.${key}` : key;
            if (Array.isArray(value)) {
                result.push(path);
            }
            else {
                traverseObject(value, path);
            }
        });
    };
    // start recursion with root errors object
    traverseObject(errors);
    return result;
};
