export var BusinessType;
(function (BusinessType) {
    BusinessType["SelfEmployed"] = "SelfEmployed";
    BusinessType["LimitedCompany"] = "LimitedCompany";
    BusinessType["NonProfit"] = "NonProfit";
})(BusinessType || (BusinessType = {}));
export var NativeBusinessType;
(function (NativeBusinessType) {
    NativeBusinessType["PersonaFisicaActividadEmpresarial"] = "PersonaFisicaActividadEmpresarial";
    NativeBusinessType["PersonaMoral"] = "PersonaMoral";
    NativeBusinessType["EmpresaSinFinesLucro"] = "EmpresaSinFinesLucro";
})(NativeBusinessType || (NativeBusinessType = {}));
export const nativeBusinessTypeInfoList = [
    {
        value: NativeBusinessType.PersonaFisicaActividadEmpresarial,
        businessType: BusinessType.SelfEmployed,
        label: "Persona f\u00EDsica" /* CopyMX.BUSINESS_TYPE_PERSONA_FISICA_LABEL */,
    },
    {
        value: NativeBusinessType.PersonaMoral,
        businessType: BusinessType.LimitedCompany,
        label: "Persona moral" /* CopyMX.BUSINESS_TYPE_PERSONA_MORAL_LABEL */,
    },
    {
        value: NativeBusinessType.EmpresaSinFinesLucro,
        businessType: BusinessType.NonProfit,
        label: "Empresa sin fines de lucro" /* CopyMX.BUSINESS_TYPE_EMPRESA_SIN_FINES_LUCRO_LABEL */,
    },
];
// Fetched from https://secure.izettle.com/api/resources/registration/form/states/MX
// Description has been added afterwards from https://en.wikipedia.org/wiki/Template:Mexico_State-Abbreviation_Codes
export const statesData = [
    { value: 'AGS', label: 'Ags.', description: 'Aguascalientes' },
    { value: 'BCN', label: 'B.C.', description: 'Baja California' },
    { value: 'BCS', label: 'B.C.S.', description: 'Baja California Sur' },
    { value: 'CAM', label: 'Camp.', description: 'Campeche' },
    { value: 'CHS', label: 'Chis.', description: 'Chiapas' },
    { value: 'CHI', label: 'Chih.', description: 'Chihuahua' },
    { value: 'COA', label: 'Coah.', description: 'Coahuila' },
    { value: 'COL', label: 'Col.', description: 'Colima' },
    { value: 'DF', label: 'CDMX', description: 'Mexico City' },
    { value: 'DGO', label: 'Dgo.', description: 'Durango' },
    { value: 'EM', label: 'Edomex. or Méx.', description: 'México' },
    { value: 'GTO', label: 'Gto.', description: 'Guanajuato' },
    { value: 'GRO', label: 'Gro.', description: 'Guerrero' },
    { value: 'HGO', label: 'Hgo.', description: 'Hidalgo' },
    { value: 'JAL', label: 'Jal.', description: 'Jalisco' },
    { value: 'MICH', label: 'Mich.', description: 'Michoacán' },
    { value: 'MOR', label: 'Mor.', description: 'Morelos' },
    { value: 'NAY', label: 'Nay.', description: 'Nayarit' },
    { value: 'NL', label: 'N.L.', description: 'Nuevo León' },
    { value: 'OAX', label: 'Oax.', description: 'Oaxaca' },
    { value: 'PUE', label: 'Pue.', description: 'Puebla' },
    { value: 'QRO', label: 'Qro.', description: 'Querétaro' },
    { value: 'QR', label: 'Q. Roo. or Q.R.', description: 'Quintana Roo' },
    { value: 'SLP', label: 'S.L.P.', description: 'San Luis Potosí' },
    { value: 'SIN', label: 'Sin.', description: 'Sinaloa' },
    { value: 'SON', label: 'Son.', description: 'Sonora' },
    { value: 'TAB', label: 'Tab.', description: 'Tabasco' },
    { value: 'TAM', label: 'Tamps.', description: 'Tamaulipas' },
    { value: 'TLA', label: 'Tlax.', description: 'Tlaxcala' },
    { value: 'VER', label: 'Ver.', description: 'Veracruz' },
    { value: 'YUC', label: 'Yuc.', description: 'Yucatán' },
    { value: 'ZAC', label: 'Zac.', description: 'Zacatecas' },
];
