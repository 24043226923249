import React, { useCallback, useEffect } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { AddEditPerson } from 'src/components/AddEditPerson';
import { useSetPageTitle } from 'src/components/App/components/PageTitle';
import { QuestionnaireStateProvider } from 'src/components/Questionnaire/state';
import { usePopupsState } from '../../state';
export const AddEditPersonPopupComponent = ({ popup }) => {
    const { dispatch: popupsDispatch } = usePopupsState();
    const { setPageTitle } = useSetPageTitle();
    const { text } = useLocalization();
    const onComplete = useCallback((result) => {
        popupsDispatch({ type: 'hidePopup' });
        popup.params.onSave(result);
    }, [popupsDispatch, popup.params.onSave]);
    const onClickBack = useCallback(() => {
        popupsDispatch({ type: 'hidePopup' });
    }, [popupsDispatch]);
    useEffect(() => {
        setPageTitle(popup.params.mode === 'add'
            ? text('OWNER_STRUCTURE_PERSON_FORM_ADD_TITLE')
            : text('OWNER_STRUCTURE_PERSON_FORM_EDIT_TITLE'));
    }, [popup.params.mode]);
    return (React.createElement(QuestionnaireStateProvider, { initialState: popup.params.questionnaireState },
        React.createElement(AddEditPerson, { personStepObject: popup.params.personStepObject, personData: popup.params.mode === 'edit' ? popup.params.personData : undefined, onValidationSuccess: onComplete, onClickBack: onClickBack })));
};
