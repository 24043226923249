import React, { Component } from 'react';
import styled from 'styled-components';
import { datadogRum } from '@datadog/browser-rum';
import { spacing } from '@izettle/otto';
import { Text, Button } from '@izettle/react';
import { GlobalStyle } from '../../styles/GlobalStyle';
import { breakpoints } from '../../theme';
import { getErrorContent } from './content';
const PageContainer = styled.div `
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 ${spacing.medium};

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: ${spacing.huge};
  }
`;
const ContentContainer = styled.div `
  margin-top: ${spacing.xLarge};
  text-align: center;
`;
const ButtonContainer = styled.div `
  width: 100%;
  margin: ${spacing.xLarge} auto;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 325px;
  }
`;
export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }
    componentDidCatch(error, errorInfo) {
        datadogRum.addError(error, {
            errorInfo,
            reachedErrorBoundary: true,
            fatal: true,
        });
        this.setState({ error });
    }
    render() {
        const { children } = this.props;
        const { error } = this.state;
        if (error) {
            const { imgSrc, title, body } = getErrorContent(error);
            return (React.createElement(PageContainer, null,
                React.createElement(GlobalStyle, null),
                React.createElement(ContentContainer, null,
                    React.createElement("img", { src: imgSrc, alt: "" })),
                React.createElement(ContentContainer, null,
                    React.createElement(Text.Title2, null, title),
                    React.createElement(Text.Regular, null, body)),
                React.createElement(ButtonContainer, null,
                    React.createElement(Button, { fullWidth: true, onClick: () => {
                            window.location.reload();
                        } }, "Retry"))));
        }
        return children;
    }
}
