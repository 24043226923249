import { IconCardReaderOneSmall, IconCircleTickSmall, IconHomeSmall, IconIdCardSmall, IconIdentitycardSmall, IconTickSmall, IconUserGenericSmall, IconBarDiagramSmall, } from '@izettle/otto';
// eslint-disable-next-line complexity
export const getSvgIcon = (icon) => {
    switch (icon) {
        case 'Home':
            return IconHomeSmall;
        case 'Person':
            return IconUserGenericSmall;
        case 'Tick':
            return IconTickSmall;
        case 'CircleTick':
            return IconCircleTickSmall;
        case 'People':
            return IconIdCardSmall;
        case 'CardReaderOne':
            return IconCardReaderOneSmall;
        case 'IdentityCard':
            return IconIdentitycardSmall;
        case 'BarDiagram':
            return IconBarDiagramSmall;
        default:
            throw new Error(`Icon '${icon}' has no provided SVG version`);
    }
};
