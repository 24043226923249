import React, { useCallback } from 'react';
import { map } from 'ramda';
import styled from 'styled-components';
import { spacing, borderRadius, spacingNumeric, typography } from '@izettle/otto';
import { Text, TextField, Checkbox } from '@izettle/react';
import { Dropdown } from '@izettle/react-shared';
import { RegistrationStatus } from 'src/hooks/use-polling-questionnaire';
const DebugContainer = styled.div `
    background-color: #ffffe7
    border-radius: ${borderRadius.medium};
    margin: ${spacingNumeric.none} ${spacingNumeric.none} ${spacing.large} ${spacingNumeric.none};
    padding: ${spacingNumeric.none} ${spacing.medium};
  `;
const StyledDropdown = styled(Dropdown) `
  margin-top: ${spacing.xxSmall};
  max-width: 100%;
  button div {
    ${typography.regular}
  }
`;
const InputContainer = styled.div `
  margin: ${spacing.small} ${spacingNumeric.none} ${spacingNumeric.none} ${spacingNumeric.none};
`;
const StyledInput = styled(TextField) `
  padding: ${spacing.xSmall} ${spacing.xSmall};
`;
export const Debugger = ({ debuggerValues, handleRegistrationStatusOnChange, handleDetailedStatusOnChange, handleTriggerDocifyOnChange, }) => {
    const handleOnChange = useCallback((event) => {
        handleDetailedStatusOnChange(event.target.value);
    }, [handleDetailedStatusOnChange]);
    return (React.createElement(DebugContainer, null,
        React.createElement(Text.Small, { className: "override-text" }, "Debugger:"),
        React.createElement(StyledDropdown, { "data-element": "debugger", value: debuggerValues.registrationStatus, onChange: handleRegistrationStatusOnChange, placeholder: "Identity Check Response" }, map((status) => (React.createElement(Dropdown.Option, { key: status, value: status }, status)), Object.keys(RegistrationStatus))),
        React.createElement(InputContainer, null,
            React.createElement(StyledInput, { placeholder: "KYC Detailed Status", onChange: handleOnChange, value: debuggerValues.detailedStatus })),
        React.createElement(InputContainer, null,
            React.createElement(Checkbox, { label: "Trigger Docify", checked: debuggerValues.triggerDocify, onChange: handleTriggerDocifyOnChange }))));
};
