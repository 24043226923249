import React, { createContext, useContext } from 'react';
import { useEffectReducer } from 'use-effect-reducer';
import { usePopupsEffectsMap } from './effect-handlers';
import { popupsEventsMap } from './event-handlers';
import { mappedReducer } from './mapped-reducer';
export const PopupsStateContext = createContext(undefined);
export const PopupsStateProvider = ({ children }) => {
    const popupsEffectsMap = usePopupsEffectsMap();
    const initialState = {
        popups: [],
    };
    const [state, dispatch] = useEffectReducer(mappedReducer(popupsEventsMap), initialState, popupsEffectsMap);
    return React.createElement(PopupsStateContext.Provider, { value: { state, dispatch } }, children);
};
export const PopupsStateConsumer = ({ children }) => {
    return (React.createElement(PopupsStateContext.Consumer, null, (contextValue) => {
        if (contextValue === undefined) {
            throw new Error('usePopupsState must be used within a PopupsStateProvider');
        }
        return children(contextValue);
    }));
};
export const usePopupsState = () => {
    const contextValue = useContext(PopupsStateContext);
    if (!contextValue) {
        throw new Error('usePopupsState must be used within a PopupsStateProvider');
    }
    return contextValue;
};
