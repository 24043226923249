import { extendTemplateDeep, overrideTemplateDeep } from './template-helpers';
/**
 * Defines how many chained template methods we allow to invoke not to abuse the feature.
 */
const MAX_TEMPLATE_CHAIN_LENGTH = 2;
/**
 * A function that accepts @param currentTemplate, @param previousTemplate and @param mergeTemplatesFunc and does the following:
 * 1) returns another function which is a wrapper around @param currentTemplate
 * 2) makes sure that returned function has all necessary properties of @typedef ChainableStepTemplate
 *
 * @param chainDepth allows to control how deep is the nesting of chained calls
 * @returns @typedef ChainableStepTemplate for @param currentTemplate
 */
const templateInternal = (currentTemplate, previousTemplate, mergeTemplatesFunc, chainDepth) => {
    /**
     * Wraps original template, invokes it and the previous one in the chain if it exists and merges their results.
     * It will be eventually invoked by StepBuilder and given all the necessary params to render a step.
     */
    const templateWrapper = (...templateParams) => {
        if (chainDepth > MAX_TEMPLATE_CHAIN_LENGTH) {
            throw new Error(`Override/extend chain depth cannot be greater than ${MAX_TEMPLATE_CHAIN_LENGTH}`);
        }
        const currentResult = currentTemplate(...templateParams);
        return previousTemplate && mergeTemplatesFunc
            ? mergeTemplatesFunc(previousTemplate(...templateParams), currentResult)
            : currentResult;
    };
    templateWrapper.overrideDeep = (nextTemplate) => {
        return templateInternal(nextTemplate, templateWrapper, overrideTemplateDeep, chainDepth + 1);
    };
    templateWrapper.extendDeep = (nextTemplate) => {
        return templateInternal(nextTemplate, templateWrapper, extendTemplateDeep, chainDepth + 1);
    };
    return templateWrapper;
};
/**
 * Creates a StepTemplate that can be chained with other templates using `extend` and `override`.
 *
 * @param stepTemplate Initial step template
 * @returns {ChainableStepTemplate} Chainable step template with `extend` and `override`
 */
export const template = (stepTemplate) => templateInternal(stepTemplate, undefined, undefined, 0);
