import { IsoLocale } from '@izettle/constants';
import ownerStructureHelpSvg from 'src/assets/owner-structure-help.svg';
import { FunctionStepFuncKey } from 'src/components/Questionnaire/types';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { step, sequence, render, decision } from 'src/schema/steps-builder';
import { nationalityOptions } from '../../common/nationality-options';
import { RoleFieldName } from '../../common/owner-structure';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { signatoryAddressTemplate, signatoryNamePrefilledTemplate, signatoryNationalityTemplate, signatoryPhoneNumberTemplate, signatoryNameTemplate, signatoryDOBTemplate, } from './common/steps/signatory';
import { getRoleLabels } from './data-helpers';
const MASK = '99.99.9999';
const introScreen = ({ translate }) => {
    return {
        componentName: 'PreparationScreen',
        applicationVariables: {
            stepName: 'PreparationScreen',
            content: {
                header: translate('GAP_PREPARATION_SCREEN_HEADER'),
                button: translate('FORM_BUTTON'),
                helpTexts: [
                    {
                        title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                        body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                    },
                ],
                items: [
                    {
                        icon: 'Person',
                        title: translate('GAP_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                    },
                    {
                        icon: 'Home',
                        title: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_TITLE'),
                        description: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_DESCRIPTION'),
                    },
                    {
                        icon: 'People',
                        title: translate('GAP_PARTNERSHIP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_TITLE'),
                        description: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                    },
                    {
                        icon: 'IdentityCard',
                        title: translate('GAP_PREPARATION_SCREEN_ITEM_VERIFICATION_TITLE'),
                        description: translate('GAP_PARTNERSHIP_PREPARATION_SCREEN_ITEM_VERIFICATION_DESCRIPTION'),
                    },
                ],
            },
        },
    };
};
const signatoryNamePrefilledUK = signatoryNamePrefilledTemplate.overrideDeep(({ translate }) => ({
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryQuestion: {
                description: translate('GAP_PARTNERSHIP_SIGNATORY_NAME_PREFILLED_DESCRIPTION'),
            },
        },
    },
}));
const businessAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessBuilding', 'businessStreetAddress', 'businessCity', 'businessPostalCode', 'businessCounty'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('GAP_BUSINESS_ADDRESS_PARTNERSHIP_TITLE'),
                description: translate('GAP_BUSINESS_ADDRESS_PARTNERSHIP_DESCRIPTION'),
                type: 'null',
            },
            businessBuilding: {
                type: 'string',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
            businessCounty: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessBuilding: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        businessStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        businessCounty: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
    },
});
const legalPeoplePreparationScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        segment: 'ownerStructure',
        stepName: 'sectionIntro',
        content: {
            header: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
            items: [
                {
                    icon: 'People',
                    description: translate('GAP_PARTNERSHIP_LEGAL_PEOPLE_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
            ],
        },
    },
});
const personStep = (translate) => ({
    ID: 'personStepId',
    backendValidation: true,
    fields: [
        'firstName',
        'lastName',
        'dateOfBirth',
        'nationality',
        'building',
        'streetAddress',
        'city',
        'county',
        'postalCode',
    ],
    JSONSchema: {
        type: 'object',
        properties: {
            nameTitle: {
                title: translate('PERSON_NAME_LABEL'),
                type: 'null',
            },
            firstName: {
                type: 'string',
            },
            lastName: {
                type: 'string',
            },
            dateOfBirthTitle: {
                title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                type: 'null',
            },
            dateOfBirth: {
                type: 'string',
            },
            nationalityTitle: {
                title: translate('PERSON_NATIONALITY_LABEL'),
                type: 'null',
            },
            nationality: {
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
            addressTitle: {
                title: translate('PERSON_HOME_ADDRESS_LABEL'),
                type: 'null',
            },
            building: {
                type: 'string',
            },
            streetAddress: {
                type: 'string',
            },
            city: {
                type: 'string',
            },
            county: {
                type: 'string',
            },
            postalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        firstName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_ALL_LABEL'),
            },
        },
        lastName: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('LAST_NAME_ALL_LABEL'),
            },
        },
        dateOfBirth: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('DATE_OF_BIRTH_PLACEHOLDER'),
                mask: MASK,
            },
        },
        nationality: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:widget': 'CustomComboBoxWidget',
            'ui:emptyValue': '',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
            'ui:options': {
                sortByLabels: 'asc',
            },
        },
        building: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        streetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        city: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        county: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        postalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'newPerson',
    },
});
const beneficialOwnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleBeneficialOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'beneficialOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleBeneficialOwners,
        signatoryRoleLabel: translate('GAP_OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
const alternativeOwnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleAlternativeOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'alternativeOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleAlternativeOwners,
        signatoryRoleLabel: translate('GAP_OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
const partnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['rolePartners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'partnersList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.rolePartners,
        signatoryRoleLabel: translate('GAP_OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_PARTNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_PARTNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
const hasUBO = ({ translate }) => ({
    fields: ['hasUBO'],
    JSONSchema: {
        required: ['hasUBO'],
        type: 'object',
        title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
        properties: {
            hasUBO: {
                title: translate('OWNER_STRUCTURE_HAS_UBO_DESCRIPTION'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_YES') },
                    { enum: [false], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        hasUBO: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
        'ui:options': {
            titleStyle: 'LargeBold',
        },
    },
    applicationVariables: {
        stepName: 'hasUBO',
        segment: 'ownerStructure',
        content: {
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('GAP_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
const overviewPage = ({ translate }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: 'GAP_PARTNERSHIP',
        confirmationScreen: {
            title: translate('GAP_OVERVIEW_CONFIRMATION_TITLE'),
            subtitle: translate('GAP_OVERVIEW_CONFIRMATION_SUB_TITLE'),
            button: translate('GAP_OVERVIEW_CLOSE_BUTTON'),
        },
        processingScreen: {
            title: translate('PROCESSING_PAGE_TITLE'),
            subtitle: translate('PROCESSING_PAGE_SUBTITLE'),
            button: translate('PROCESSING_PAGE_CLOSE_BUTTON'),
        },
        content: {
            title: '',
            subtitle: '',
            submitButton: translate('GAP_OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(IsoLocale.enGB)]),
        },
    },
});
const initLegalPersonsFunction = () => ({
    functions: [FunctionStepFuncKey.initLegalPersons],
    fields: ['legalPersons'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'legalPersonsFunc',
        segment: 'ownerStructure',
    },
});
const normalizeSignatoryFieldsFunction = () => ({
    functions: [FunctionStepFuncKey.GAP_normalizeSignatoryFields],
    fields: ['signatoryFirstName', 'signatoryLastName'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'normalizeSignatoryFieldsFunc',
        segment: 'personalDetails',
    },
});
const signatoryPhoneNumberGB = signatoryPhoneNumberTemplate.extendDeep(({ translate }) => ({
    UISchema: {
        signatoryPhoneNumber: {
            'ui:placeholder': translate('SIGNATORY_PHONE_NUMBER_PLACEHOLDER'),
        },
    },
}));
const newSignatorySequence = [
    step(signatoryNameTemplate),
    step(signatoryDOBTemplate),
    step(signatoryAddressTemplate),
    step(signatoryPhoneNumberGB),
    step(signatoryNationalityTemplate),
];
const rootSequence = sequence([
    step(introScreen),
    decision('signatoryAction', signatoryNamePrefilledUK, [
        { when: SIGNATORY_ACTION.CHANGE_SIGNATORY, branch: sequence(newSignatorySequence) },
        { when: SIGNATORY_ACTION.SET_NATIONALITY, branch: step(signatoryNationalityTemplate) },
    ]),
    step(normalizeSignatoryFieldsFunction),
    step(businessAddress),
    step(legalPeoplePreparationScreen),
    step(initLegalPersonsFunction),
    step(partnersList),
    decision('hasUBO', hasUBO, [
        { when: true, branch: step(beneficialOwnersList) },
        { when: false, branch: step(alternativeOwnersList) },
    ]),
    step(overviewPage),
]);
export const GAP_PARTNERSHIP = (context) => render(rootSequence, context);
