import React, { useCallback, useContext } from 'react';
import { RadioGroup } from '../../../RadioGroup';
import { Radio } from '../../../RadioGroup/radio';
import { FieldContext } from '../FieldTemplate/context';
import { useEnumOptions } from '../hooks/use-enum-options';
export const CustomRadioGroup = ({ value, options, onChange }) => {
    const fieldContext = useContext(FieldContext);
    const handleOnChange = useCallback((event) => {
        let targetValue = event.target.value;
        if (targetValue === 'true') {
            targetValue = true;
        }
        else if (targetValue === 'false') {
            targetValue = false;
        }
        onChange(targetValue);
    }, [onChange]);
    const enumOptions = useEnumOptions(options);
    return (React.createElement(RadioGroup, { value: value, onChange: handleOnChange, errorElementId: fieldContext.errorElementId }, enumOptions.map((option, index) => {
        return (React.createElement(Radio, { key: option.value, value: option.value, title: option.label, description: option.description, index: index }));
    })));
};
