import { RoleFieldName } from '../../common/owner-structure';
export const getRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_LIMITED_COMPANY_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: text('OWNER_STRUCTURE_ROLE_VALUE_PARTNER'),
        [RoleFieldName.roleBeneficialOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_BENEFICIAL_OWNER'),
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const getNonProfitRoleLabels = (text) => ({
    [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_DIRECTOR'),
    [RoleFieldName.rolePartners]: text('OWNER_STRUCTURE_ROLE_VALUE_PARTNER'),
    [RoleFieldName.roleBeneficialOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_BENEFICIAL_OWNER'),
    [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_ALTERNATIVE_OWNER'),
});
