import { RoleFieldName } from '../../../schema/common/owner-structure';
import { NativeBusinessType, BusinessTypeValue } from './data';
const nativeBusinessTypeLabel = {
    [NativeBusinessType.ENK]: "Enkeltpersonforetak (ENK)" /* CopyNO.BUSINESS_TYPE_ENK_LABEL */,
    [NativeBusinessType.Aksjeselskap]: "Aksjeselskap (AS og ASA)" /* CopyNO.BUSINESS_TYPE_AKSJESELSKAP_LABEL */,
    [NativeBusinessType.AnsvarligSelskap]: "Ansvarlig selskap (ANS og DA)" /* CopyNO.BUSINESS_TYPE_ANSVARLIG_SELSKAP_LABEL */,
    [NativeBusinessType.Kommandittselskap]: "Kommandittselskap (KS)" /* CopyNO.BUSINESS_TYPE_KOMMANDITTSELSKAP_LABEL */,
    [NativeBusinessType.Samvirkeforetag]: "Samvirkeforetag (SA)" /* CopyNO.BUSINESS_TYPE_SAMVIRKEFORETAG_LABEL */,
    [NativeBusinessType.IdeellOrganisasjon]: "Ideell organisasjon / forening" /* CopyNO.BUSINESS_TYPE_IDEELL_ORGANISASJON_LABEL */,
    [NativeBusinessType.Kommune]: "Kommune" /* CopyNO.BUSINESS_TYPE_KOMMUNE_LABEL */,
    [NativeBusinessType.NUF]: "Norskregistrert utenlansk foretak (NUF)" /* CopyNO.BUSINESS_TYPE_NUF_LABEL */,
};
const nativeBusinessTypeValues = {
    [NativeBusinessType.ENK]: BusinessTypeValue.SelfEmployed,
    [NativeBusinessType.Aksjeselskap]: BusinessTypeValue.LimitedCompany,
    [NativeBusinessType.AnsvarligSelskap]: BusinessTypeValue.Partnership,
    [NativeBusinessType.Kommandittselskap]: BusinessTypeValue.Partnership,
    [NativeBusinessType.Samvirkeforetag]: BusinessTypeValue.LimitedCompany,
    [NativeBusinessType.IdeellOrganisasjon]: BusinessTypeValue.NonProfit,
    [NativeBusinessType.Kommune]: BusinessTypeValue.NonProfit,
    [NativeBusinessType.NUF]: BusinessTypeValue.LimitedCompany,
};
export const getNativeBusinessTypeLabel = (nativeBusinessType) => {
    if (nativeBusinessType in nativeBusinessTypeLabel) {
        return nativeBusinessTypeLabel[nativeBusinessType];
    }
    throw new Error(`Unhandled business type: '${nativeBusinessType}'`);
};
export const getLocalizedBusinessTypeValue = (stepData) => {
    if (!stepData.nativeBusinessType) {
        return '';
    }
    return getNativeBusinessTypeLabel(stepData.nativeBusinessType);
};
export const mapGenericBusinessTypeFromNativeBusinessType = (nativeBusinessType) => {
    if (nativeBusinessType in nativeBusinessTypeValues) {
        return nativeBusinessTypeValues[nativeBusinessType];
    }
    throw new Error(`Unhandled business type option: '${nativeBusinessType}'`);
};
export const createRoleFieldsTitles = (businessType, text) => {
    return {
        roleDirectors: businessType === BusinessTypeValue.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL')
            : text('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
        roleAlternativeOwners: businessType === BusinessTypeValue.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_LABEL')
            : text('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
        rolePartners: text('OWNER_STRUCTURE_PARTNERS_LABEL'),
        roleBeneficialOwners: text('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
    };
};
export const getLimitedPartnershipRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_LIMITED_COMPANY_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: text('OWNER_STRUCTURE_ROLE_VALUE_PARTNER'),
        [RoleFieldName.roleBeneficialOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_BENEFICIAL_OWNER'),
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const getNonProfitRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: null,
        [RoleFieldName.roleBeneficialOwners]: null,
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
