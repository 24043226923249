import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { IconArrowLeftSmall, IconCrossSmall, colors, spacing } from '@izettle/otto';
import { IconButton } from '@izettle/react';
const StyledIconButton = styled(IconButton) `
  // compensation for extra padding to ensure
  // that icon is aligned on the left with the edge of container
  margin-left: -${spacing.xxSmall};
  display: block;
  &:focus {
    background: ${colors.background.groupedSecondary};
  }
  &:hover {
    background: ${colors.background.groupedPrimary};
  }
`;
const componentResourcesMap = {
    back: {
        titleKey: 'HEADER_BUTTON_BACK_TITLE',
        icon: IconArrowLeftSmall,
        iconTransform: { y: '-1px' },
        defaultId: 'backButton',
    },
    cancel: {
        titleKey: 'HEADER_BUTTON_CANCEL',
        icon: IconCrossSmall,
        defaultId: 'cancelButton',
    },
};
export const HeaderButton = forwardRef(({ displayStyle, onClick, id, disabled }, ref) => {
    const { text } = useLocalization();
    const { titleKey, icon, iconTransform, defaultId } = componentResourcesMap[displayStyle];
    return (React.createElement(StyledIconButton, { ref: ref, title: text(titleKey), id: id !== null && id !== void 0 ? id : defaultId, "data-display-style": displayStyle, variant: "minor", size: "small", onClick: onClick, svgIcon: icon, transform: iconTransform, disabled: disabled }));
});
