import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useEligibleFormContextValue } from 'src/providers/form';
import { BulletList } from '../../BulletList';
import { useOverviewPageContextValue } from '../../Context';
import { Field } from '../../Field';
import { Section } from '../../Section';
import { Value } from '../../Value';
const getCategoryDataFromFormContext = (stepData, formContext) => {
    var _a;
    const formContextCategories = (_a = formContext.additionalData) === null || _a === void 0 ? void 0 : _a.businessCategories;
    if (!formContextCategories) {
        throw new Error('Categories not found in form context');
    }
    const formContextCategory = formContextCategories.find((category) => category.code.toString() === stepData.businessCategoryId);
    if (!formContextCategory) {
        throw new Error('Current category not found in form context');
    }
    const formContextSubCategory = formContextCategory.children.find((subCategory) => subCategory.code.toString() === stepData.businessSubCategoryId);
    if (!formContextSubCategory) {
        throw new Error('Current subcategory not found in category list from form context');
    }
    return {
        formContextCategory,
        formContextSubCategory,
    };
};
export const UsageOfIZettleSection = ({ formatCurrencyRange, formatPercentRange, }) => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const { formContext } = useEligibleFormContextValue();
    const { formContextCategory, formContextSubCategory } = getCategoryDataFromFormContext(stepData, formContext);
    return (React.createElement(Section, { name: "usageOfIzettle", title: text('OVERVIEW_SECTION_USAGE_OF_IZETTLE_TITLE') },
        React.createElement(Field, { fieldNames: ['businessCategoryId'], title: text('OVERVIEW_FIELD_BUSINESS_CATEGORY_ID_TITLE'), editable: true },
            React.createElement(Value, null, formContextCategory.name)),
        React.createElement(Field, { fieldNames: ['businessSubCategoryId'], title: text('OVERVIEW_FIELD_BUSINESS_SUB_CATEGORY_ID_TITLE'), editable: true },
            React.createElement(Value, null, formContextSubCategory.name)),
        React.createElement(Field, { fieldNames: ['businessLocationTypes'], title: text('OVERVIEW_FIELD_BUSINESS_LOCATION_TYPES_TITLE'), editable: true }, Array.isArray(stepData.businessLocationTypes) && (React.createElement(BulletList, { values: stepData.businessLocationTypes.map((value) => text(`BUSINESS_LOCATION_TYPES_VALUE_${value}`)) }))),
        React.createElement(Field, { fieldNames: ['businessFrequency'], title: text('OVERVIEW_FIELD_BUSINESS_FREQUENCY_TITLE'), editable: true },
            React.createElement(Value, null, text(`BUSINESS_FREQUENCY_VALUE_${stepData.businessFrequency}`))),
        React.createElement(Field, { fieldNames: ['businessAverageTransaction'], title: text('OVERVIEW_FIELD_BUSINESS_AVERAGE_TRANSACTION_TITLE'), editable: true },
            React.createElement(Value, null, formatCurrencyRange
                ? formatCurrencyRange(stepData.businessAverageTransaction, text)
                : text(`BUSINESS_AVERAGE_TRANSACTION_VALUE_${stepData.businessAverageTransaction}`))),
        React.createElement(Field, { fieldNames: ['businessIzettleShareOfTurnover'], title: text('OVERVIEW_FIELD_BUSINESS_IZETTLE_SHARE_OF_TURNOVER_TITLE'), editable: true },
            React.createElement(Value, null, formatPercentRange(stepData.businessIzettleShareOfTurnover, text))),
        React.createElement(Field, { fieldNames: ['businessCurrentAnnualTurnover'], title: text('OVERVIEW_FIELD_BUSINESS_CURRENT_ANNUAL_TURNOVER_TITLE'), editable: true },
            React.createElement(Value, null, stepData.businessCurrentAnnualTurnover === 'NEW_BUSINESS'
                ? text(`BUSINESS_CURRENT_ANNUAL_TURNOVER_VALUE_NEW_BUSINESS`)
                : formatCurrencyRange(stepData.businessCurrentAnnualTurnover, text))),
        React.createElement(Field, { fieldNames: ['businessExpectedAnnualTurnover'], title: () => text('OVERVIEW_FIELD_BUSINESS_EXPECTED_ANNUAL_TURNOVER_TITLE'), conditionalRendering: true, editable: true }, () => (React.createElement(Value, null, formatCurrencyRange(stepData.businessExpectedAnnualTurnover, text))))));
};
