import styled from 'styled-components';
import { borderRadius, colors, spacing } from '@izettle/otto';
import { breakpoints } from 'src/theme';
export const InfoBox = styled.div `
  background-color: ${colors.message.background.info};
  border-radius: ${borderRadius.medium};
  padding: ${spacing.medium};

  @media (max-width: ${breakpoints.tiny}) {
    padding: ${spacing.xxSmall};
  }
`;
