import { RoleFieldName } from 'src/schema/common/owner-structure';
import { BusinessType, BusinessTypePartOne, BusinessTypePartTwo } from './data';
const businessTypePartOneMap = {
    [BusinessTypePartOne.SelfEmployed]: BusinessType.SelfEmployed,
    [BusinessTypePartOne.NonProfit]: BusinessType.NonProfit,
    [BusinessTypePartOne.CommonLimitedCompany]: BusinessType.LimitedCompany,
};
const businessTypePartTwoMap = {
    [BusinessTypePartTwo.LimitedCompany]: BusinessType.LimitedCompany,
    [BusinessTypePartTwo.Partnership]: BusinessType.Partnership,
};
export const mapPartialFieldsToBusinessType = (data) => {
    if (data.businessTypePartOne in businessTypePartOneMap) {
        return businessTypePartOneMap[data.businessTypePartOne];
    }
    // must be in BusinessTypePartOne.Other case
    if (data.businessTypePartTwo in businessTypePartTwoMap) {
        return businessTypePartTwoMap[data.businessTypePartTwo];
    }
    throw new Error('Unhandled combination for FR businessType partial fields');
};
export const getLimitedPartnershipRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_LIMITED_COMPANY_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: text('OWNER_STRUCTURE_ROLE_VALUE_PARTNER'),
        [RoleFieldName.roleBeneficialOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_BENEFICIAL_OWNER'),
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const getNonProfitRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: null,
        [RoleFieldName.roleBeneficialOwners]: null,
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const createRoleFieldsTitles = (businessType, text) => {
    return {
        roleDirectors: businessType === BusinessType.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL')
            : text('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
        roleAlternativeOwners: businessType === BusinessType.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_LABEL')
            : text('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
        rolePartners: text('OWNER_STRUCTURE_PARTNERS_LABEL'),
        roleBeneficialOwners: text('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
    };
};
export const getLocalizedBusinessTypeValue = (stepData, text) => stepData.businessTypePartOne !== BusinessTypePartOne.Other
    ? text(`BUSINESS_TYPE_FR_VALUE_${stepData.businessTypePartOne}`)
    : text(`BUSINESS_TYPE_FR_VALUE_${stepData.businessTypePartTwo}`);
