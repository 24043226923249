var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { Alert } from '../Alert';
import { ScreenReaderOnly } from '../ScreenReaderOnly';
import { HelpSection } from './components/HelpSection';
import { FormEventsContext } from './events-context';
import { addValuesForMissingOptionalFields, focusFirstFieldWithError, formTransformErrors, prepareErrorsForLib, } from './helpers';
import { RjsfFormWrapper } from './rjsf-wrapper';
import { FormContainer, HelpSectionContainer, InnerContentContainer, SubmitButton, SubmitButtonContainer, } from './styles';
export const Form = ({ JSONSchema, UISchema, formData, backendValidation, helpTexts, extraTexts, innerContent, completeButtonText, onFormDataChange, onComplete, onEvent, }) => {
    const { text } = useLocalization();
    const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
    // Reference to the RjsfForm component
    const formRef = useRef(null);
    // Update error message when BE validation fails
    useEffect(() => {
        if ((backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.state.current) === 'GenericError') {
            setGeneralErrorMessage(text('COMMON_SOMETHING_WENT_WRONG'));
        }
    }, [backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.state, setGeneralErrorMessage]);
    // Resets all errors
    const resetErrors = useCallback(() => {
        // TODO: find out how to reset client validation errors, formRef doesn't have such API
        setGeneralErrorMessage(null);
        backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.reset();
    }, [setGeneralErrorMessage, backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.reset]);
    // Invoked when `Continue` button is clicked
    const handleContinueClick = useCallback(() => {
        resetErrors();
    }, [resetErrors, formRef]);
    // Invoked when user inputs any data
    const handleChange = useCallback((e) => onFormDataChange(e.formData), [onFormDataChange]);
    // Invoked when client validation error happens
    const handleClientValidationError = useCallback(() => {
        if (!formRef.current || !formRef.current.state)
            return;
        focusFirstFieldWithError(formRef.current.state);
    }, [formRef === null || formRef === void 0 ? void 0 : formRef.current]);
    // Invoked when client validation passes.
    // Even though the prop in which we pass on this callback is called `onSubmit`, this function is not called upon form submission.
    // The library react-jsonschema-form only calls it when the client validation goes through.
    // If the client validation fails, this function is not called.
    const handleClientValidationSuccess = useCallback((e) => __awaiter(void 0, void 0, void 0, function* () {
        const completeFormData = addValuesForMissingOptionalFields(e.formData, JSONSchema, UISchema);
        if (!backendValidation) {
            return onComplete(completeFormData);
        }
        if (yield backendValidation.validate(completeFormData)) {
            return onComplete(completeFormData);
        }
    }), [backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.validate, JSONSchema, UISchema, onComplete]);
    // Transforms errors in some cases to have localized display strings
    const transformErrors = useCallback((errors) => {
        try {
            return formTransformErrors(errors);
        }
        catch (err) {
            setGeneralErrorMessage(text('COMMON_SOMETHING_WENT_WRONG'));
            throw err; // ensure that error is reported
        }
    }, [text, setGeneralErrorMessage]);
    // An object passed down to widgets rendered by the form
    const formContext = useMemo(() => {
        return {
            resetValidationErrors: resetErrors,
            extraTexts,
        };
    }, [resetErrors, extraTexts]);
    // An object with BE validation errors
    const extraErrors = useMemo(() => {
        if ((backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.state.current) === 'ValidationError') {
            return prepareErrorsForLib(backendValidation.state.errors);
        }
        return undefined;
    }, [backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.state]);
    useEffect(() => {
        if (!formRef.current || !formRef.current.state || !extraErrors)
            return;
        focusFirstFieldWithError(formRef.current.state);
    }, [extraErrors]);
    return (React.createElement(FormContainer, null,
        generalErrorMessage && React.createElement(Alert, null, generalErrorMessage),
        React.createElement(FormEventsContext.Provider, { value: { dispatchEvent: onEvent !== null && onEvent !== void 0 ? onEvent : (() => null) } },
            React.createElement(RjsfFormWrapper, { ref: formRef, transformErrors: transformErrors, schema: JSONSchema, uiSchema: UISchema, showErrorList: false, formData: formData, extraErrors: extraErrors, formContext: formContext, onError: handleClientValidationError, onChange: handleChange, onSubmit: handleClientValidationSuccess },
                innerContent && React.createElement(InnerContentContainer, null, innerContent),
                React.createElement(SubmitButtonContainer, null,
                    helpTexts && (React.createElement(HelpSectionContainer, null,
                        React.createElement(HelpSection, { items: helpTexts }))),
                    React.createElement(SubmitButton, { onClick: handleContinueClick, loading: (backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.state.current) === 'Validating' }, completeButtonText !== null && completeButtonText !== void 0 ? completeButtonText : text('FORM_BUTTON')),
                    React.createElement(ScreenReaderOnly, { "aria-live": "assertive" }, (backendValidation === null || backendValidation === void 0 ? void 0 : backendValidation.state.current) === 'Validating' ? text('COMMON_PROCESSING_REQUEST_LABEL') : ''))))));
};
