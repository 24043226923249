import { useCallback } from 'react';
import { equals, pick } from 'ramda';
import { useFormContextValue } from 'src/providers/form';
import { removeQuestionnaireFromLocalStorage, restoreQuestionnaireFromLocalStorage, storeQuestionnaireInLocalStorage, } from './save-resume';
export const useQuestionnaireLocalStorage = () => {
    const { formContext } = useFormContextValue();
    const save = useCallback((questionnaire) => {
        storeQuestionnaireInLocalStorage(questionnaire, formContext.user.organizationUuid);
    }, [formContext.user.organizationUuid]);
    const retrieveSavedIfMatches = useCallback((questionnaireToMatch) => {
        const saved = restoreQuestionnaireFromLocalStorage(formContext.user.organizationUuid);
        if (!saved) {
            return null;
        }
        // If steps or prefilledData don't match, we can't use stored questionnaire safely
        const propsToMatch = ['stepList', 'prefilledData'];
        return equals(pick(propsToMatch, saved), pick(propsToMatch, questionnaireToMatch)) ? saved : null;
    }, [formContext.user.organizationUuid]);
    const removeSaved = useCallback(() => {
        removeQuestionnaireFromLocalStorage(formContext.user.organizationUuid);
    }, [formContext.user.organizationUuid]);
    return {
        save,
        retrieveSavedIfMatches,
        removeSaved,
    };
};
