import { IsoLocale } from '@izettle/constants';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { decision, step, sequence, render } from 'src/schema/steps-builder';
import { nationalityOptions } from '../../common/nationality-options';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { signatoryNameTemplate, signatoryDOBTemplate, signatoryAddressTemplate, signatoryPhoneNumberTemplate, signatoryNationalityTemplate, signatoryNamePrefilledTemplate, } from './common/steps/signatory';
const MASK = '99.99.9999';
const introScreen = ({ translate }) => {
    return {
        componentName: 'PreparationScreen',
        applicationVariables: {
            stepName: 'PreparationScreen',
            content: {
                header: translate('GAP_PREPARATION_SCREEN_HEADER'),
                button: translate('FORM_BUTTON'),
                helpTexts: [
                    {
                        title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                        body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                    },
                ],
                items: [
                    {
                        icon: 'Person',
                        title: translate('GAP_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                    },
                    {
                        icon: 'People',
                        title: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_TITLE'),
                        description: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                    },
                    {
                        icon: 'IdentityCard',
                        title: translate('GAP_PREPARATION_SCREEN_ITEM_VERIFICATION_TITLE'),
                        description: translate('GAP_PREPARATION_SCREEN_ITEM_VERIFICATION_DESCRIPTION'),
                    },
                ],
            },
        },
    };
};
const legalPeoplePreparationScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        segment: 'ownerStructure',
        stepName: 'sectionIntro',
        content: {
            header: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                },
                {
                    title: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_HELP_UBO_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_HELP_UBO_ACCORDION_CONTENT'),
                },
            ],
            items: [
                {
                    icon: 'People',
                    description: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
            ],
        },
    },
});
const newLegalPeople = ({ translate }) => ({
    backendValidation: true,
    fields: ['newLegalPeople'],
    JSONSchema: {
        type: 'object',
        properties: {
            newLegalPeople: {
                title: translate('GAP_NPO_NEW_LEGAL_PERSON_TITLE'),
                description: translate('GAP_NPO_NEW_LEGAL_PERSON_DESCRIPTION'),
                type: 'array',
                minItems: 1,
                items: {
                    type: 'object',
                    properties: {
                        nameTitle: {
                            title: translate('PERSON_NAME_LABEL'),
                            type: 'null',
                        },
                        firstName: {
                            type: 'string',
                        },
                        lastName: {
                            type: 'string',
                        },
                        dateOfBirthTitle: {
                            title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                            type: 'null',
                        },
                        dateOfBirth: {
                            type: 'string',
                        },
                        nationalityTitle: {
                            title: translate('PERSON_NATIONALITY_LABEL'),
                            type: 'null',
                        },
                        nationality: {
                            type: 'string',
                            enum: nationalityOptions.map((option) => option.value),
                            enumNames: nationalityOptions.map((option) => translate(option.textKey)),
                        },
                        addressTitle: {
                            title: translate('PERSON_HOME_ADDRESS_LABEL'),
                            type: 'null',
                        },
                        building: {
                            type: 'string',
                        },
                        streetAddress: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        county: {
                            type: 'string',
                        },
                        postalCode: {
                            type: 'string',
                        },
                        roleTitle: {
                            title: translate('GAP_PERSON_ROLE_LABEL'),
                            description: translate('GAP_PERSON_ROLE_DESCRIPTION'),
                            type: 'null',
                        },
                        role: {
                            type: 'array',
                            items: {
                                type: 'string',
                                oneOf: [
                                    {
                                        enum: ['Director'],
                                        title: translate('GAP_LEGAL_PERSONS_NONPROFIT_ROLE_DIRECTOR'),
                                    },
                                    { enum: ['UBO'], title: translate('GAP_LEGAL_PERSONS_NONPROFIT_ROLE_UBO') },
                                ],
                            },
                            uniqueItems: true,
                        },
                    },
                },
            },
        },
    },
    UISchema: {
        newLegalPeople: {
            'ui:options': {
                label: false,
                orderable: false,
            },
            items: {
                firstName: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('FIRST_NAME_LABEL'),
                    },
                },
                lastName: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('LAST_NAME_LABEL'),
                    },
                },
                dateOfBirth: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('DATE_OF_BIRTH_PLACEHOLDER'),
                        mask: MASK,
                    },
                },
                nationality: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:widget': 'CustomComboBoxWidget',
                    'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
                    'ui:options': {
                        sortByLabels: 'asc',
                    },
                },
                building: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_BUILDING_LABEL'),
                    },
                },
                streetAddress: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
                    },
                },
                city: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_CITY_LABEL'),
                    },
                },
                county: {
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_COUNTY_LABEL'),
                    },
                },
                postalCode: {
                    'ui:marginBottom': 'medium',
                    'ui:title': false,
                    'ui:emptyValue': '',
                    'ui:options': {
                        label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                    },
                },
                role: {
                    'ui:title': false,
                    'ui:widget': 'CustomCheckboxGroupWidget',
                },
            },
        },
    },
    applicationVariables: {
        stepName: 'newOwners',
        segment: 'ownerStructure',
        content: {
            personLabel: translate('OTHER_LEGAL_PERSONS_PERSON_LABEL'),
            addButton: translate('GAP_OTHER_LEGAL_PERSONS_ADD_BUTTON_NONPROFIT'),
            removeButton: translate('OTHER_LEGAL_PERSONS_REMOVE_BUTTON'),
        },
    },
});
const overviewPage = ({ translate }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: 'GAP_NPO',
        confirmationScreen: {
            title: translate('GAP_OVERVIEW_CONFIRMATION_TITLE'),
            subtitle: translate('GAP_OVERVIEW_CONFIRMATION_SUB_TITLE'),
            button: translate('GAP_OVERVIEW_CLOSE_BUTTON'),
        },
        processingScreen: {
            title: translate('PROCESSING_PAGE_TITLE'),
            subtitle: translate('PROCESSING_PAGE_SUBTITLE'),
            button: translate('PROCESSING_PAGE_CLOSE_BUTTON'),
        },
        content: {
            title: '',
            subtitle: '',
            submitButton: translate('GAP_OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(IsoLocale.enGB)]),
        },
    },
});
const signatoryPhoneNumberGB = signatoryPhoneNumberTemplate.extendDeep(({ translate }) => ({
    UISchema: {
        signatoryPhoneNumber: {
            'ui:placeholder': translate('SIGNATORY_PHONE_NUMBER_PLACEHOLDER'),
        },
    },
}));
const newSignatorySequence = [
    step(signatoryNameTemplate),
    step(signatoryDOBTemplate),
    step(signatoryAddressTemplate),
    step(signatoryPhoneNumberGB),
    step(signatoryNationalityTemplate),
];
const rootSequence = sequence([
    step(introScreen),
    decision('signatoryAction', signatoryNamePrefilledTemplate, [
        { when: SIGNATORY_ACTION.CHANGE_SIGNATORY, branch: sequence(newSignatorySequence) },
        { when: SIGNATORY_ACTION.SET_NATIONALITY, branch: step(signatoryNationalityTemplate) },
    ]),
    step(legalPeoplePreparationScreen),
    step(newLegalPeople),
    step(overviewPage),
]);
export const GAP_NPO = (context) => render(rootSequence, context);
