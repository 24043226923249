import React from 'react';
import styled from 'styled-components';
import { Handler } from './handler';
import { useComboBox } from './hook';
import { Popup } from './popup';
const OutsideContainer = styled.div `
  display: inline-block;
  position: relative;
  width: 100%;
`;
export const ComboBoxComponent = () => {
    const { handleOutsideContainerMouseDown } = useComboBox();
    return (React.createElement(OutsideContainer, { onMouseDown: handleOutsideContainerMouseDown },
        React.createElement(Handler, null),
        React.createElement(Popup, null)));
};
