import React, { createContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { getOptionTitleByValue, filterOptionsByLabel, getOptionValueByIndex, scrollToOptionIfNeeded, scrollTop, findMatchingOptionByLabel, } from './util';
const noop = () => {
    // noop
};
export const ComboBoxContext = createContext({
    id: undefined,
    inputRef: null,
    listRef: null,
    isOpen: false,
    selectedValue: undefined,
    highlightedIndex: -1,
    inputValue: '',
    placeholder: undefined,
    label: undefined,
    filteredOptions: [],
    expandButtonTitle: 'Expand',
    clearButtonTitle: 'Clear',
    descriptionElementId: undefined,
    errorElementId: undefined,
    hasError: false,
    isElevated: false,
    isFocused: false,
    handleInputFocus: noop,
    handleInputChange: noop,
    handleInputBlur: noop,
    handleInputKeyDown: noop,
    handleOptionClick: noop,
    handleExpandClick: noop,
    handleHandlerClick: noop,
    handleOutsideContainerMouseDown: noop,
    handleClearClick: noop,
});
/* eslint-disable max-statements */
export const Provider = ({ comboBoxProps, children }) => {
    const { id, value, options, onChange, placeholder, label, expandButtonTitle, clearButtonTitle, descriptionElementId, errorElementId, hasError, } = comboBoxProps;
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [inputValue, setInputValue] = useState('');
    const [isElevated, setIsElevated] = useState(value && label ? true : Boolean(value));
    const localization = useLocalization();
    const filteredOptions = useMemo(() => {
        return filterOptionsByLabel(options, inputValue, localization.isoLocale);
    }, [inputValue, options, localization]);
    const inputRef = useRef(null);
    const listRef = useRef(null);
    useEffect(() => {
        if (value) {
            setSelectedValue(value);
        }
    }, [value]);
    useEffect(() => {
        onChange(selectedValue);
        if (!selectedValue) {
            return;
        }
        if (inputRef.current) {
            const optionTitle = getOptionTitleByValue(options, selectedValue);
            setInputValue(optionTitle);
            setIsElevated(Boolean(selectedValue));
        }
    }, [selectedValue]);
    useEffect(() => {
        if (inputRef.current === null) {
            return;
        }
        if (isOpen) {
            inputRef.current.focus();
        }
        else {
            setHighlightedIndex(-1);
            inputRef.current.blur();
        }
    }, [isOpen]);
    const handleInputChange = useCallback((event) => {
        setIsOpen(true);
        if (event === null || event.target === null) {
            return;
        }
        const newValue = event.target.value;
        if (newValue !== inputValue) {
            setInputValue(newValue);
            setSelectedValue(undefined);
            setHighlightedIndex(-1);
            scrollTop(listRef);
        }
    }, [inputValue]);
    const selectOption = useCallback((optionValue) => {
        setSelectedValue(optionValue);
        setHighlightedIndex(-1);
        setIsOpen(false);
    }, []);
    const handleKeyboardNavigation = useCallback((event, difference) => {
        let newIndex = highlightedIndex + difference;
        newIndex = Math.max(newIndex, 0);
        newIndex = Math.min(newIndex, filteredOptions.length - 1);
        scrollToOptionIfNeeded(listRef, newIndex);
        setHighlightedIndex(newIndex);
        event.preventDefault();
    }, [highlightedIndex, listRef, filteredOptions]);
    const handleKeyboardOnEscape = useCallback(() => {
        if (!selectedValue && inputValue) {
            setInputValue('');
        }
        else {
            setIsOpen(false);
        }
    }, [selectedValue, inputValue]);
    const handleKeyboardOnEnter = useCallback((event) => {
        if (highlightedIndex >= 0) {
            const highlightedValue = getOptionValueByIndex(filteredOptions, highlightedIndex);
            selectOption(highlightedValue);
            if (selectedValue === highlightedValue) {
                setIsOpen(false);
            }
        }
        else {
            setIsOpen(false);
        }
        event.preventDefault();
    }, [highlightedIndex, filteredOptions, selectedValue]);
    const handleInputKeyDown = useCallback((event) => {
        switch (event.key) {
            case 'Escape':
                handleKeyboardOnEscape();
                break;
            case 'Enter':
                handleKeyboardOnEnter(event);
                break;
            case 'ArrowUp': {
                if (!isOpen) {
                    handleKeyboardNavigation(event, filteredOptions.length);
                }
                else {
                    handleKeyboardNavigation(event, -1);
                }
                setIsOpen(true);
                break;
            }
            case 'ArrowDown': {
                setIsOpen(true);
                handleKeyboardNavigation(event, 1);
                break;
            }
            default:
        }
    }, [handleKeyboardOnEscape, handleKeyboardOnEnter, handleKeyboardNavigation]);
    const handleInputFocus = useCallback(() => {
        setIsElevated(true);
        setIsFocused(true);
    }, []);
    const handleInputBlur = useCallback(() => {
        const matchingLabelOption = findMatchingOptionByLabel(options, inputValue, localization.isoLocale);
        if (matchingLabelOption) {
            // when current input value matches the label of one of the options displayed, we select it automatically
            setSelectedValue(matchingLabelOption.value);
        }
        else if (filteredOptions.length === 1) {
            // if there is only one option displayed, that option is also selected automatically for convenience
            setSelectedValue(filteredOptions[0].value);
        }
        if (!selectedValue) {
            setInputValue('');
        }
        setIsOpen(false);
        setIsFocused(false);
        setIsElevated(Boolean(selectedValue));
    }, [selectedValue, inputValue, filteredOptions]);
    const handleHandlerClick = useCallback(() => {
        setIsOpen(true);
    }, []);
    const handleOutsideContainerMouseDown = useCallback((event) => {
        const elementName = event.target.getAttribute('data-element-name');
        if (elementName === 'handler' ||
            elementName === 'expand-button' ||
            elementName === 'clear-button' ||
            elementName === 'list' ||
            elementName === 'list-option') {
            event.preventDefault();
        }
    }, []);
    const handleOptionClick = useCallback((event) => {
        if (event === null || event.currentTarget === null) {
            return;
        }
        const optionValue = event.currentTarget.getAttribute('data-value');
        selectOption(optionValue !== null && optionValue !== void 0 ? optionValue : undefined);
    }, []);
    const handleExpandClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsOpen((val) => !val);
    }, []);
    const handleClearClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedValue(undefined);
        setInputValue('');
    }, []);
    const contextValue = {
        id,
        inputRef,
        listRef,
        isOpen,
        selectedValue,
        highlightedIndex,
        inputValue,
        placeholder,
        label,
        filteredOptions,
        expandButtonTitle,
        clearButtonTitle,
        descriptionElementId,
        errorElementId,
        hasError,
        isElevated,
        isFocused,
        handleInputFocus,
        handleInputChange,
        handleInputBlur,
        handleInputKeyDown,
        handleOptionClick,
        handleExpandClick,
        handleClearClick,
        handleHandlerClick,
        handleOutsideContainerMouseDown,
    };
    return React.createElement(ComboBoxContext.Provider, { value: contextValue }, children);
};
