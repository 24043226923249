import { useMemo } from 'react';
import { identity } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
export const useCheckedInputAriaProps = ({ checked, description, errorElementId }) => {
    const itemId = useMemo(() => uuidv4().slice(-8), []);
    const titleId = `${itemId}-title`;
    const descriptionId = description ? `${itemId}-description` : undefined;
    const attrs = {
        'aria-labelledby': titleId,
        'aria-checked': checked,
        'aria-invalid': Boolean(errorElementId),
    };
    const describedBy = [errorElementId, descriptionId].filter(identity).join(' ');
    if (describedBy) {
        attrs['aria-describedby'] = describedBy;
    }
    return {
        titleId,
        descriptionId,
        attrs,
    };
};
