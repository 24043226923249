import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { spacingNumeric } from '@izettle/otto';
import { HeaderButton, Header } from 'src/components/Header';
import { ProgressBar } from 'src/components/ProgressBar';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { getCurrentStepObject } from 'src/components/Questionnaire/state/helpers';
import { withHidden } from 'src/hoc/with-hidden';
import { useQuestionnaireEditMode } from 'src/hooks/use-questionnaire-edit-mode';
import { getProgressPercentage, shouldRenderProgressBar } from '../helpers/progress';
// When progress is 0, ProgressBar shouldn't be shown but we need to keep it instantiated,
// so that animation works nicely when we go through the first step
const HidableProgressBar = styled(withHidden(ProgressBar)) `
  max-width: 175px;
  min-width: 100px;
  margin: ${spacingNumeric.none} auto;
`;
const HidableHeader = withHidden(Header);
const BackButton = forwardRef((_props, ref) => {
    const { state, dispatch } = useQuestionnaireState();
    const { cancelEditMode } = useQuestionnaireEditMode();
    if (state.editMode && getCurrentStepObject(state).ID === state.editOrigin) {
        return React.createElement(HeaderButton, { ref: ref, displayStyle: "cancel", onClick: () => cancelEditMode() });
    }
    if (getProgressPercentage(state) === 0) {
        // no back button on the very first step
        return null;
    }
    return (React.createElement(HeaderButton, { ref: ref, displayStyle: "back", onClick: () => dispatch({ type: 'previousStep' }), disabled: state.isSubmissionInProgress }));
});
export const QuestionnaireHeader = () => {
    const { state } = useQuestionnaireState();
    const progressPercentage = shouldRenderProgressBar(state) ? getProgressPercentage(state) : null;
    const isEmptyHeader = !state.editMode && !progressPercentage;
    return (React.createElement(HidableHeader, { hidden: isEmptyHeader, leftButton: React.createElement(BackButton, null) }, progressPercentage !== null ? (React.createElement(HidableProgressBar, { hidden: progressPercentage === 0, value: progressPercentage })) : null));
};
