import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
export const BusinessDetailsSection = ({ renderAddressValue }) => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(Section, { name: "businessDetails", title: text('GAP_OVERVIEW_SECTION_BUSINESS_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: [
                'businessBuilding',
                'businessStreetAddress',
                'businessCity',
                'businessCounty',
                'businessPostalCode',
            ], title: text('GAP_OVERVIEW_FIELD_BUSINESS_ADDRESS_TITLE'), editable: true }, renderAddressValue({
            building: stepData.businessBuilding,
            streetAddress: stepData.businessStreetAddress,
            city: stepData.businessCity,
            county: stepData.businessCounty,
            postalCode: stepData.businessPostalCode,
        }))));
};
