import React from 'react';
import styled from 'styled-components';
import { spacing, typography } from '@izettle/otto';
const Description = styled.div `
  ${typography.regularSecondary};
  padding-bottom: ${spacing.small};
`;
export const DescriptionField = ({ description, id }) => {
    return React.createElement(Description, { id: id }, description);
};
