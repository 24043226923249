import { useCallback, useEffect } from 'react';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { isFieldDisplayable } from 'src/components/Questionnaire/state/helpers';
import { useQuestionnaireEditMode } from 'src/hooks/use-questionnaire-edit-mode';
import { isErrorsList } from 'src/hooks/use-submit-questionnaire';
import { useOverviewPageContextValue } from '../Context';
const getFieldErrorKeys = (errors, fieldNames) => {
    let allErrors = [];
    Object.keys(errors).forEach((fieldName) => {
        if (fieldNames.includes(fieldName)) {
            const fieldErrors = errors[fieldName];
            if (!fieldErrors || !isErrorsList(fieldErrors)) {
                throw new Error(`Rendering '${fieldName}' errors: expected array of strings`);
            }
            allErrors = allErrors.concat(fieldErrors);
        }
    });
    return allErrors;
};
export const useField = ({ fieldNames, onRemove, onEdit, conditionalRendering }) => {
    const { state: questionnaireState } = useQuestionnaireState();
    const { startEditMode } = useQuestionnaireEditMode();
    const { errors, updateFieldOptions } = useOverviewPageContextValue();
    const fieldReference = fieldNames[0];
    const fieldErrorKeys = getFieldErrorKeys(errors, fieldNames);
    const shouldRender = !conditionalRendering || isFieldDisplayable(fieldReference, questionnaireState);
    useEffect(() => {
        if (!shouldRender) {
            return;
        }
        fieldNames.forEach((fieldName) => {
            updateFieldOptions(fieldName, { canHandleErrors: true });
        });
        return () => {
            fieldNames.forEach((fieldName) => {
                updateFieldOptions(fieldName, { canHandleErrors: false });
            });
        };
    }, [fieldNames.join(), shouldRender]);
    const handleEditClick = useCallback(() => {
        if (onEdit) {
            return onEdit();
        }
        startEditMode(fieldReference);
    }, [fieldReference, onEdit, startEditMode]);
    const handleRemoveClick = useCallback(() => {
        if (onRemove) {
            onRemove();
        }
    }, [onRemove]);
    return {
        shouldRender,
        fieldReference,
        fieldErrorKeys,
        handleEditClick,
        handleRemoveClick,
    };
};
