import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
import { Divider } from '@izettle/react-shared';
import { OverviewPageContextProvider, useOverviewPageContextValue } from '../Context';
const Item = styled.div `
  margin-bottom: ${spacing.small};
`;
const getItemErrors = (listErrors, index) => {
    return listErrors[index] || {};
};
export const LegacyOwnerList = ({ fieldName, emptyListMessage, itemTemplate }) => {
    const [listErrors, setListErrors] = useState({});
    const { stepData, errors, isSubmissionInProgress } = useOverviewPageContextValue();
    const items = stepData[fieldName];
    if (!Array.isArray(items)) {
        throw new Error('Items in LegacyOwnerList should be in an array');
    }
    useEffect(() => {
        if (errors) {
            setListErrors(errors[fieldName] || {});
        }
    }, [errors]);
    useEffect(() => {
        if (items) {
            // Since the errors are tied to the indexes of the owners, the formers have to be reset whenever the owners are changed.
            // This prevents error messages from being displayed in wrong positions when owners are removed and the items of the array shift index.
            setListErrors({});
        }
    }, [items]);
    const empty = !Array.isArray(items) || items.length === 0;
    const StyledDivider = styled(Divider) `
    margin-top: ${spacing.none};
    margin-bottom: ${spacing.small};
  `;
    if (!empty) {
        return (React.createElement("div", null, items.map((item, index) => {
            return (React.createElement(OverviewPageContextProvider, { key: index /* eslint-disable-line react/no-array-index-key */, stepData: item, errors: getItemErrors(listErrors, index), stepDataSubPath: `${fieldName}.${index}`, isSubmissionInProgress: isSubmissionInProgress },
                React.createElement(Item, null, itemTemplate(item, index)),
                React.createElement(StyledDivider, null)));
        })));
    }
    return React.createElement(React.Fragment, null, emptyListMessage && React.createElement(Text.Regular, { className: "override-text" }, emptyListMessage));
};
