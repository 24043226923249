import { config } from './config';
import { isNodeOrParentButton, isNodeTextElement, isNodeListItemElement } from './util';
export const verifyRedirectUrl = (url) => Boolean(url === null || url === void 0 ? void 0 : url.startsWith(config.portalUrl));
export const redirectToUrl = (url) => {
    window.location.href = url;
};
export const disableBrowserBackButton = () => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', () => {
        window.history.pushState(null, document.title, window.location.href);
    });
};
export const updateBrowserUrl = (newUrl) => {
    window.history.pushState(null, '', newUrl);
};
export const redirectToBackOffice = () => {
    window.location.replace(config.portalUrl);
};
export const hideMobileKeyboardOnNonInputTouch = () => {
    document.addEventListener('touchstart', (e) => {
        const clickedElement = e.target;
        const activeElement = document.activeElement;
        if (document.activeElement &&
            !isNodeOrParentButton(clickedElement) &&
            !isNodeTextElement(clickedElement) &&
            !isNodeListItemElement(clickedElement) &&
            isNodeTextElement(activeElement)) {
            e.preventDefault();
            activeElement.blur();
        }
    }, { passive: false });
};
