import React from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { spacing, IconEditMedium, IconTrashMedium } from '@izettle/otto';
import { IconButton } from '@izettle/react';
import { useOverviewPageContextValue } from '../Context';
const FieldIconButton = styled(IconButton) `
  margin: -${spacing.xxSmall}; // compensation for extra padding and height from medium icon
  margin-left: ${spacing.xxSmall};
`;
export const Buttons = ({ fieldReference, removable, editable, onRemove, onEdit }) => {
    const { text } = useLocalization();
    const { isSubmissionInProgress } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        removable && (React.createElement(FieldIconButton, { id: `remove_${fieldReference}`, title: text('REMOVE_BUTTON_TITLE'), onClick: onRemove, variant: "destructive", svgIcon: IconTrashMedium, disabled: isSubmissionInProgress })),
        editable && (React.createElement(FieldIconButton, { id: `edit_${fieldReference}`, title: text('EDIT_BUTTON_TITLE'), onClick: onEdit, variant: "primary", svgIcon: IconEditMedium, disabled: isSubmissionInProgress }))));
};
