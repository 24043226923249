import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { Alert } from 'src/components/Alert';
// This is causing extra margins to be added to the error messages, which force us to css override it here
export const ErrorContentContainer = styled.div `
  margin-bottom: ${spacing.small};

  &:last-child {
    margin-bottom: 0;
  }

  /* Markdown adds a p tag around it's output */
  p:last-child {
    margin-bottom: 0;
  }
`;
export const ErrorContentTitle = styled.b ``;
export const ErrorContainer = styled(Alert) `
  overflow: hidden;
`;
