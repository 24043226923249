import { clone, without } from 'ramda';
import { Action } from 'src/hooks/use-tracker';
import { getQuestionnairePayload } from 'src/hooks/use-tracker/helpers';
import { RoleFieldName } from 'src/schema/common/owner-structure';
import { DatadogRumBreadcrumbCategory } from 'src/tools/datadog-rum';
import { isFunctionStepObject } from '../types';
import { getCurrentStepObject, getCustomStepsCompletedStepData, getNextStepIndex, getOverviewPageIndex, getPreviousStepIndex, getStepObject, getUpdatedStepData, isOverviewPage, removeOwnerFromStepData, } from './helpers';
const completeCurrentStep = (state, event, exec) => {
    const stepData = getUpdatedStepData(state, event.payload);
    const stepObject = getCurrentStepObject(state);
    if (!isFunctionStepObject(stepObject)) {
        exec({
            type: `trackEvent`,
            data: {
                action: Action.CompletedStep,
                payload: getQuestionnairePayload(Object.assign(Object.assign({}, state), { stepData })),
            },
        });
    }
    const newState = Object.assign(Object.assign({}, state), { stepData });
    const nextStepIndex = getNextStepIndex(newState);
    const nextStep = getStepObject(newState.stepList, nextStepIndex);
    // If overview page is the next step, it means that the editing is complete.
    if (state.editMode && isOverviewPage(nextStep)) {
        if (state.onEditComplete) {
            state.onEditComplete(newState.stepData);
        }
        // intentionnally returning old state to not trigger any step animations
        // as popup is about to be hidden
        return state;
    }
    exec({
        type: 'triggerEvent',
        data: { type: 'goToStep', payload: { stepIndex: nextStepIndex } },
    });
    return newState;
};
const goToStep = (state, event) => {
    return Object.assign(Object.assign({}, state), { currentStepIndex: event.payload.stepIndex });
};
const previousStep = (state) => {
    return Object.assign(Object.assign({}, state), { currentStepIndex: getPreviousStepIndex(state) });
};
const savePerson = (state, event) => {
    const { payload: { personId, data }, } = event;
    return Object.assign(Object.assign({}, state), { stepData: Object.assign(Object.assign({}, state.stepData), { legalPersons: Object.assign(Object.assign({}, state.stepData.legalPersons), { [personId]: data }) }) });
};
const removePerson = (state, event) => {
    var _a, _b;
    const { payload: { personId }, } = event;
    const stepData = clone(state.stepData);
    if ((_a = stepData.legalPersons) === null || _a === void 0 ? void 0 : _a[personId]) {
        (_b = stepData.legalPersons) === null || _b === void 0 ? true : delete _b[personId];
    }
    // Remove personId from all the role lists
    Object.keys(RoleFieldName).forEach((roleFieldName) => {
        if (!stepData[roleFieldName]) {
            return;
        }
        stepData[roleFieldName] = without([personId], stepData[roleFieldName]);
    });
    return Object.assign(Object.assign({}, state), { stepData });
};
// used in some GAP flows
const removeOwner = (state, event) => {
    const { dependentFieldName, fieldName, index } = event.payload;
    return Object.assign(Object.assign({}, state), { stepData: removeOwnerFromStepData(state.stepData, fieldName, dependentFieldName, index) });
};
const stepLoaded = (state, event, exec) => {
    const stepObject = getCurrentStepObject(state);
    if (!isFunctionStepObject(stepObject)) {
        // no need to track function steps
        exec({
            type: 'trackEvent',
            data: {
                action: Action.ViewedStep,
                payload: getQuestionnairePayload(state),
            },
        });
    }
    exec({ type: 'scrollToTop' });
    exec({
        type: 'addDatadogRumBreadcrumb',
        data: { category: DatadogRumBreadcrumbCategory.StepDisplayed, stepObject: event.payload },
    });
    return state;
};
const prefillForm = (state, event) => (Object.assign(Object.assign({}, state), { currentStepIndex: getOverviewPageIndex(state.stepList), stepData: Object.assign(Object.assign({}, event.payload.data), getCustomStepsCompletedStepData(state.stepList)) }));
const applyEdit = (state, event) => {
    return Object.assign(Object.assign({}, state), event.payload);
};
const submissionStarted = (state) => {
    return Object.assign(Object.assign({}, state), { isSubmissionInProgress: true });
};
const submissionEnded = (state) => {
    return Object.assign(Object.assign({}, state), { isSubmissionInProgress: false });
};
export const questionnaireEventsMap = {
    stepLoaded,
    completeCurrentStep,
    goToStep,
    previousStep,
    savePerson,
    removePerson,
    removeOwner,
    prefillForm,
    applyEdit,
    submissionStarted,
    submissionEnded,
};
