import React from 'react';
import { QuestionnaireStateConsumer, QuestionnaireStateProvider } from '../state';
import { QuestionnaireHeader } from './Header';
import { StepRenderer } from './StepRenderer';
export const Questionnaire = (props) => {
    return (React.createElement(QuestionnaireStateProvider, Object.assign({}, props),
        React.createElement(QuestionnaireStateConsumer, null, ({ state }) => (React.createElement(React.Fragment, null,
            React.createElement(QuestionnaireHeader, null),
            React.createElement(StepRenderer, { key: state.currentStepIndex }),
            props.children)))));
};
