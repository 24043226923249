import React from 'react';
import { reverse } from 'ramda';
import { withHidden } from 'src/hoc/with-hidden';
import { PopupContext } from './PopupContext';
import { AddEditPersonPopupComponent } from './popups/AddEditPerson';
import { ConfirmationPagePopupComponent } from './popups/ConfirmationPage';
import { QuestionnaireEditModePopupComponent } from './popups/QuestionnaireEditMode';
const PopupComponent = ({ popup }) => {
    if (popup.popupName === 'AddEditPerson') {
        return React.createElement(AddEditPersonPopupComponent, { popup: popup });
    }
    if (popup.popupName === 'QuestionnaireEditMode') {
        return React.createElement(QuestionnaireEditModePopupComponent, { popup: popup });
    }
    if (popup.popupName === 'ConfirmationPage') {
        return React.createElement(ConfirmationPagePopupComponent, { popup: popup });
    }
    throw new Error(`Popup '${popup.popupName}' not found`);
};
const HidablePopupComponent = withHidden(PopupComponent);
export const PopupsStack = ({ popups }) => (React.createElement(React.Fragment, null, reverse(popups).map((popup, popupIndex) => (React.createElement(PopupContext.Provider, { key: popup.popupName, value: { popup, index: popups.length - popupIndex - 1 } },
    React.createElement(HidablePopupComponent, { hidden: popupIndex !== 0, popup: popup }))))));
