import React from 'react';
import { map } from 'ramda';
import { datadogRum } from '@datadog/browser-rum';
import { TranslationsProvider } from '@izettle/lingo-client';
import { config, Environment } from 'src/config';
import { useAsyncError } from 'src/hooks/use-async-error';
import { useFormContextValue } from 'src/providers/form';
const getTenkoLocale = (user) => ({
    country: user.countryId,
    language: user.languageId,
});
let translationsFallback;
if (config.appEnv === Environment.Development || config.appEnv === Environment.Staging) {
    void import(
    /* webpackChunkName: "translations.json" */
    '../../../../translations/sources/translations.json').then((translationsJson) => {
        translationsFallback = map(({ text }) => `⚠ ${text}`, translationsJson.textKeys);
    });
}
const onTranslationMissing = (key) => {
    // tenko internally uppers the case, replicating this behavior
    key = key.toUpperCase();
    if (config.appEnv === Environment.Production) {
        datadogRum.addError(`Missing translation for ${key}`);
        return '';
    }
    const fallback = translationsFallback === null || translationsFallback === void 0 ? void 0 : translationsFallback[key];
    return fallback !== null && fallback !== void 0 ? fallback : key;
};
export const AppTranslationsProvider = ({ children, localeOverride }) => {
    const { formContext } = useFormContextValue();
    const throwError = useAsyncError();
    return (React.createElement(TranslationsProvider, { projectName: "kyc-client", locale: localeOverride !== null && localeOverride !== void 0 ? localeOverride : getTenkoLocale(formContext.user), onTranslationMissing: onTranslationMissing, fallbackDisabled: config.appEnv === Environment.Production, onLoadTranslationsError: throwError }, children));
};
