import { useCallback, useContext, useEffect } from 'react';
import { PopupContext } from 'src/components/Popups/components/PopupContext';
import { PageTitleContext } from './context';
export const useSetPageTitle = () => {
    const context = useContext(PageTitleContext);
    const currentPopup = useContext(PopupContext);
    if (!context) {
        throw new Error('useSetPageTitle should be invoked inside PageTitleContextProvider');
    }
    const { setPageTitleForLayer } = context;
    const currentLayer = currentPopup ? currentPopup.index + 1 : 0;
    useEffect(() => {
        // clean up callback
        return () => {
            // clear title when component is unmounted
            setPageTitleForLayer(undefined, currentLayer);
        };
    }, [currentLayer]);
    const setPageTitle = useCallback((title) => {
        setPageTitleForLayer(title, currentLayer);
    }, [setPageTitleForLayer, currentLayer]);
    return {
        setPageTitle,
    };
};
