import { nth } from 'ramda';
import { DatadogRumBreadcrumbCategory } from 'src/tools/datadog-rum';
import { getVerticalScrollPosition } from 'src/util';
const displayPopup = (state, event, exec) => {
    exec({
        type: 'addDatadogRumBreadcrumb',
        data: { category: DatadogRumBreadcrumbCategory.PopupDisplayed, popupName: event.payload.popupName },
    });
    exec({ type: 'scrollToTop' });
    exec({ type: 'focusCancelButton' });
    return Object.assign(Object.assign({}, state), { popups: [
            ...state.popups,
            Object.assign(Object.assign({}, event.payload), { savedActiveElement: window.document.activeElement, savedScrollPosition: getVerticalScrollPosition() }),
        ] });
};
const hidePopup = (state, _event, exec) => {
    if (state.popups.length === 0) {
        throw new Error('Attempting to hide a popup when no popups are shown');
    }
    const hiddenPopup = nth(-1, state.popups);
    const nextPopup = nth(-2, state.popups);
    exec({
        type: 'addDatadogRumBreadcrumb',
        data: {
            category: DatadogRumBreadcrumbCategory.PopupHidden,
            hiddenPopup,
            nextPopup,
        },
    });
    exec({ type: 'scrollToTop', data: { top: hiddenPopup.savedScrollPosition } });
    if (hiddenPopup.savedActiveElement) {
        exec({ type: 'focusElement', data: hiddenPopup.savedActiveElement });
    }
    return Object.assign(Object.assign({}, state), { popups: state.popups.slice(0, -1) });
};
export const popupsEventsMap = {
    displayPopup,
    hidePopup,
};
