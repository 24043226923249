import { createGlobalStyle } from 'styled-components';
import { spacingNumeric } from '@izettle/otto';
import ThemePPUIColors from '@izettle/otto/dist/css/theme-ppui-colors.css';
const ThemeStyle = ThemePPUIColors || {};
export const GlobalStyle = createGlobalStyle `
  .override-text {
    margin: ${spacingNumeric.none} !important;
  }

  body * {
    overflow-wrap: break-word;
  }

  fieldset {
    // fieldsets by default have a min-width: min-content
    // this change allows overflow-wrap: word-break to work correctly
    min-width: auto; 
  }
  ${ThemeStyle}
  `;
