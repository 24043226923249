import { useEffect } from 'react';
import throttle from 'lodash.throttle';
import { datadogRum } from '@datadog/browser-rum';
import { config } from 'src/config';
import { createAppState } from './app-state';
import { handleAndRethrowAuthError } from './helpers';
// Auth SDK doesn't handle concurrent refresh requests well
const tokenRefreshLock = {
    taken: false,
};
const refreshToken = (authClient) => {
    if (tokenRefreshLock.taken) {
        return;
    }
    tokenRefreshLock.taken = true;
    void authClient
        .getTokenSilently({
        redirect_uri: config.applicationUrl,
        ignoreCache: true,
        scope: 'ALL:INTERNAL',
        audience: 'API',
    })
        .catch((error) => {
        if (error.error === 'login_required') {
            void authClient.loginWithRedirect({ appState: createAppState() });
            return;
        }
        handleAndRethrowAuthError('refreshTokenOrLogin')(error);
    })
        .catch((error) => {
        datadogRum.addError(error, {
            fingerprint: ['unhandledRefreshTokenException'],
        });
    })
        .finally(() => {
        tokenRefreshLock.taken = false;
    });
};
// throttling refresh calls, so we don't make network requests too often
const refreshTokenThrottled = throttle(refreshToken, 60000, {
    leading: true,
    trailing: false,
});
/**
 * A hook that automatically refreshes token when user is active
 * @param authClient
 */
export const useTokenAutoRefresh = (authClient) => {
    useEffect(() => {
        if (!authClient) {
            return;
        }
        const onUserAction = () => {
            refreshTokenThrottled(authClient);
        };
        const onVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                refreshTokenThrottled(authClient);
                // flush to immediately refresh token when user switches to the tab
                refreshTokenThrottled.flush();
            }
        };
        document.addEventListener('mousedown', onUserAction);
        document.addEventListener('keydown', onUserAction);
        document.addEventListener('visibilitychange', onVisibilityChange);
        return () => {
            document.removeEventListener('mousedown', onUserAction);
            document.removeEventListener('keydown', onUserAction);
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, [authClient]);
};
