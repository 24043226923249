import React from 'react';
import isObject from 'isobject';
import { isEmpty } from 'ramda';
import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
import { OverviewPageContextProvider, useOverviewPageContextValue } from '../Context';
const TitleContainer = styled.div `
  margin-bottom: ${spacing.small};
`;
const Item = styled.div `
  margin-bottom: ${spacing.large};

  &:last-child {
    margin-bottom: ${spacing.none};
  }
`;
// eslint-disable-next-line complexity
export const ObjectField = ({ title, emptyMessage, itemTemplate, stepDataFieldName }) => {
    var _a;
    const { stepData, errors, isSubmissionInProgress } = useOverviewPageContextValue();
    const objectFieldValue = stepData[stepDataFieldName];
    const objectFieldErrors = ((_a = errors[stepDataFieldName]) !== null && _a !== void 0 ? _a : {});
    if (objectFieldValue && !isObject(objectFieldValue)) {
        throw new Error(`ObjectField used with non-object field`);
    }
    const empty = !objectFieldValue || isEmpty(objectFieldValue);
    if (empty) {
        return React.createElement(React.Fragment, null, emptyMessage && React.createElement(Text.Regular, { className: "override-text" }, emptyMessage));
    }
    return (React.createElement("div", null,
        title ? (React.createElement(TitleContainer, null,
            React.createElement(Text.RegularSecondary, { className: "override-text" }, title))) : null,
        Object.entries(objectFieldValue).map(([key, value]) => {
            var _a;
            return (React.createElement(OverviewPageContextProvider, { key: key, errors: ((_a = objectFieldErrors[key]) !== null && _a !== void 0 ? _a : {}), stepData: value, stepDataSubPath: `${stepDataFieldName}.${key}`, isSubmissionInProgress: isSubmissionInProgress },
                React.createElement(Item, null, itemTemplate({ key }))));
        })));
};
