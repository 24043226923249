import { RoleFieldName } from '../../../schema/common/owner-structure';
import { BusinessType } from './data';
export const createRoleFieldsTitles = (businessType, text) => {
    return {
        roleDirectors: businessType === BusinessType.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL')
            : text('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
        roleAlternativeOwners: businessType === BusinessType.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_LABEL')
            : text('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
        rolePartners: text('OWNER_STRUCTURE_PARTNERS_LABEL'),
        roleBeneficialOwners: text('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
    };
};
export const getLimitedPartnershipRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_LIMITED_COMPANY_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: text('OWNER_STRUCTURE_ROLE_VALUE_PARTNER'),
        [RoleFieldName.roleBeneficialOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_BENEFICIAL_OWNER'),
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const getNonProfitRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: null,
        [RoleFieldName.roleBeneficialOwners]: null,
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_NON_PROFIT_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const getLocalizedBusinessTypeValue = (stepData, text) => {
    if (!stepData.businessType) {
        return '';
    }
    return text(`BUSINESS_TYPE_VALUE_${stepData.businessType}`);
};
