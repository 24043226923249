import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { IconButton } from '@izettle/react';
export const ActionIconButton = styled(IconButton) `
  margin: -${spacing.tiny}; // compensation for extra padding

  &:before {
    content: '';
    position: absolute;
    top: -${spacing.xxSmall};
    bottom: -${spacing.xxSmall};
    left: -${spacing.xxSmall};
    right: -${spacing.xxSmall};
  }
`;
