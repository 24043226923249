import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { spacing, size, IllustrationAccountSettingsSvg, typography } from '@izettle/otto';
import { Text, Button, Illustration } from '@izettle/react';
import { useSetPageTitle } from '../App/components/PageTitle';
import { LogoutQuestion } from '../Logout';
const PageContainer = styled.div `
  height: 100%;
`;
const ContentContainer = styled.div `
  margin-top: ${spacing.large};
  text-align: center;
`;
const ImageContainer = styled(ContentContainer) `
  height: 160px;
`;
const ButtonContainer = styled.div `
  margin-top: ${size.xMedium};
  margin-bottom: ${size.xMedium};
`;
const ResetButton = styled(Button) `
  margin-top: ${spacing.xxSmall};
  span span {
    ${typography.regular}
    color: inherit
  }
`;
const StyledButton = styled(Button) `
  span span {
    ${typography.regular}
    color: inherit
  }
`;
export const ResumeScreen = ({ onResume, onReset, }) => {
    const { text } = useLocalization();
    const { setPageTitle } = useSetPageTitle();
    useEffect(() => {
        setPageTitle(text('RESUME_SCREEN_PAGE_TITLE'));
    }, []);
    return (React.createElement(PageContainer, null,
        React.createElement(ImageContainer, null,
            React.createElement(Illustration, { illustration: IllustrationAccountSettingsSvg, alt: "Alt text", width: "100%", height: "160px" })),
        React.createElement(ContentContainer, null,
            React.createElement(Text.LargeBold, { className: "override-text" }, text('RESUME_SCREEN_HEADER'))),
        React.createElement(ContentContainer, null,
            React.createElement(Text.Regular, { className: "override-text" }, text('RESUME_SCREEN_INSTRUCTION'))),
        React.createElement(ButtonContainer, null,
            React.createElement(StyledButton, { id: "resumeQuestionnaire", variant: "primary", fullWidth: true, onClick: onResume }, text('RESUME_SCREEN_RESUME_BUTTON')),
            React.createElement(ResetButton, { variant: "inline", fullWidth: true, onClick: onReset }, text('RESUME_SCREEN_RESET_BUTTON'))),
        React.createElement(LogoutQuestion, null)));
};
