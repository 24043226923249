import styled from 'styled-components';
import { colors, spacing, typography } from '@izettle/otto';
import { Button, Text } from '@izettle/react';
export const PageTitle = styled.div `
  ${typography.title2};
  margin-bottom: ${spacing.xxSmall};
`;
export const PageSubTitle = styled.div `
  ${typography.regular};
`;
export const SmallMarginBottom = styled.div `
  margin-bottom: ${spacing.small};
`;
export const StyledSubmitText = styled(Text.Regular) `
  > p,
  ul > li {
    ${typography.regular}
  }
  ,
  p a {
    ${typography.regular}
    color : ${colors.action.inline.text.default}
  }
`;
export const LargeMarginBottom = styled.div `
  margin-bottom: ${spacing.large};
`;
export const MediumMarginBottom = styled.div `
  margin-bottom: ${spacing.medium};
`;
export const StyledSubmitButton = styled(Button) `
  span span {
    ${typography.regular}
    color : inherit;
  }
`;
