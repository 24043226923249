export var BusinessType;
(function (BusinessType) {
    BusinessType["SelfEmployed"] = "SelfEmployed";
    BusinessType["LimitedCompany"] = "LimitedCompany";
    BusinessType["Partnership"] = "Partnership";
    BusinessType["NonProfit"] = "NonProfit";
})(BusinessType || (BusinessType = {}));
export var NativeBusinessType;
(function (NativeBusinessType) {
    NativeBusinessType["Tmi"] = "Tmi";
    NativeBusinessType["Oy"] = "Oy";
    NativeBusinessType["Oyj"] = "Oyj";
    NativeBusinessType["Ry"] = "Ry";
    NativeBusinessType["Rs"] = "Rs";
    NativeBusinessType["Kunta"] = "Kunta";
    NativeBusinessType["Ay"] = "Ay";
    NativeBusinessType["Ky"] = "Ky";
    NativeBusinessType["Osk"] = "Osk";
})(NativeBusinessType || (NativeBusinessType = {}));
export const nativeBusinessTypeInfoList = [
    {
        value: NativeBusinessType.Tmi,
        businessType: BusinessType.SelfEmployed,
        title: "T:mi" /* CopyFI.BUSINESS_TYPE_TMI_ACRONYM */,
        descriptionKey: 'NATIVE_BUSINESS_TYPE_FI_TMI_DESCRIPTION',
    },
    {
        value: NativeBusinessType.Oy,
        businessType: BusinessType.LimitedCompany,
        title: "Oy" /* CopyFI.BUSINESS_TYPE_OY_ACRONYM */,
        descriptionKey: 'NATIVE_BUSINESS_TYPE_FI_OY_DESCRIPTION',
    },
    {
        value: NativeBusinessType.Oyj,
        businessType: BusinessType.LimitedCompany,
        title: "Oyj" /* CopyFI.BUSINESS_TYPE_OYJ_ACRONYM */,
        descriptionKey: 'NATIVE_BUSINESS_TYPE_FI_OYJ_DESCRIPTION',
    },
    {
        value: NativeBusinessType.Ry,
        businessType: BusinessType.NonProfit,
        title: "Ry" /* CopyFI.BUSINESS_TYPE_RY_ACRONYM */,
        descriptionKey: 'NATIVE_BUSINESS_TYPE_FI_RY_DESCRIPTION',
    },
    {
        value: NativeBusinessType.Rs,
        businessType: BusinessType.NonProfit,
        title: "Rs" /* CopyFI.BUSINESS_TYPE_RS_ACRONYM */,
        descriptionKey: 'NATIVE_BUSINESS_TYPE_FI_RS_DESCRIPTION',
    },
    {
        value: NativeBusinessType.Kunta,
        businessType: BusinessType.NonProfit,
        titleKey: `NATIVE_BUSINESS_TYPE_FI_KUNTA_TITLE`,
        descriptionKey: undefined,
    },
    {
        value: NativeBusinessType.Ay,
        businessType: BusinessType.Partnership,
        title: "Ay" /* CopyFI.BUSINESS_TYPE_AY_ACRONYM */,
        descriptionKey: 'NATIVE_BUSINESS_TYPE_FI_AY_DESCRIPTION',
    },
    {
        value: NativeBusinessType.Ky,
        businessType: BusinessType.Partnership,
        title: "Ky" /* CopyFI.BUSINESS_TYPE_KY_ACRONYM */,
        descriptionKey: 'NATIVE_BUSINESS_TYPE_FI_KY_DESCRIPTION',
    },
    {
        value: NativeBusinessType.Osk,
        businessType: BusinessType.Partnership,
        title: "Osk" /* CopyFI.BUSINESS_TYPE_OSK_ACRONYM */,
        descriptionKey: 'NATIVE_BUSINESS_TYPE_FI_OSK_DESCRIPTION',
    },
];
