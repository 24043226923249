import { iZettleMarket } from '@izettle/constants';
export const Kyc2Countries = {
    [iZettleMarket.DE]: iZettleMarket.DE,
    [iZettleMarket.DK]: iZettleMarket.DK,
    [iZettleMarket.ES]: iZettleMarket.ES,
    [iZettleMarket.FI]: iZettleMarket.FI,
    [iZettleMarket.FR]: iZettleMarket.FR,
    [iZettleMarket.GB]: iZettleMarket.GB,
    [iZettleMarket.IT]: iZettleMarket.IT,
    [iZettleMarket.MX]: iZettleMarket.MX,
    [iZettleMarket.NL]: iZettleMarket.NL,
    [iZettleMarket.NO]: iZettleMarket.NO,
    [iZettleMarket.SE]: iZettleMarket.SE,
};
export const KycFormKeys = [
    'DE',
    'GB',
    'SE',
    'FR',
    'NL',
    'NO',
    'FI',
    'DK',
    'ES',
    'IT',
    'MX',
    'GB_1099K',
    'DE_1099K',
    'DK_1099K',
    'ES_1099K',
    'FI_1099K',
    'FR_1099K',
    'IT_1099K',
    'NL_1099K',
    'NO_1099K',
    'SE_1099K',
];
export const LUXFormKeys = [
    'GAP_GB_NATIONALITY',
    'GAP_GB_LTD_COMPANY',
    'GAP_SIGNATORY_NATIONALITY',
    'GAP_NPO',
    'GAP_PARTNERSHIP',
    'GAP_DE_LTD_COMPANY',
    'GAP_FR_LTD_COMPANY',
    'GAP_NL_LTD_COMPANY',
    'GAP_FR_ST_COMPANY',
    'GAP_NL_NPO_COMPANY',
    'GAP_NL_PARTNERSHIP',
    'GAP_SE_ST_COMPANY',
    'GAP_SE_NPO_COMPANY',
    'GAP_NO_ST_COMPANY',
    'GAP_SE_LTD_COMPANY',
    'GAP_NO_NPO_COMPANY',
    'GAP_NO_LTD_COMPANY',
    'GAP_SE_PARTNERSHIP',
    'GAP_GB_LTD_COMPANY_NEW',
    'GAP_NO_PARTNERSHIP',
    'GAP_DE_NPO_COMPANY',
    'GAP_FR_NPO_COMPANY',
    'GAP_FI_ST_COMPANY',
    'GAP_FI_LTD_COMPANY',
    'GAP_FI_NPO_COMPANY',
    'GAP_FI_PARTNERSHIP',
    'GAP_DK_ST_COMPANY',
    'GAP_DK_LTD_COMPANY',
    'GAP_DK_PARTNERSHIP',
    'GAP_DK_NPO_COMPANY',
    'GAP_ES_ST_COMPANY',
    'GAP_DE_PARTNERSHIP',
    'GAP_DE_ST_COMPANY',
    'GAP_NL_ST_COMPANY',
    'GAP_GB_ST_COMPANY',
    'GAP_ES_LTD_COMPANY',
    'GAP_IT_ST_COMPANY',
    'GAP_IT_LTD_COMPANY',
];
export const UsaTaxDetailsFormKeys = ['GAP_USA_TAX_DETAILS_SOLE_TRADER'];
export const GapFormKeys = [...LUXFormKeys, ...UsaTaxDetailsFormKeys];
export var FormType;
(function (FormType) {
    FormType["ONBOARDING"] = "ONBOARDING";
    FormType["LUX_GAP"] = "LUX_GAP";
    FormType["USA_TAX_DETAILS_GAP"] = "USA_TAX_DETAILS_GAP";
})(FormType || (FormType = {}));
export var FormStatus;
(function (FormStatus) {
    FormStatus["ONBOARDING"] = "ONBOARDING";
    FormStatus["GAPS"] = "GAPS";
    FormStatus["DOCUMENT_UPLOAD"] = "DOCUMENT_UPLOAD";
    FormStatus["INELIGIBLE"] = "INELIGIBLE";
    FormStatus["PROCESSING"] = "PROCESSING";
})(FormStatus || (FormStatus = {}));
