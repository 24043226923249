import React from 'react';
import styled from 'styled-components';
import { colors, spacing, size, IllustrationFistBumpSvg, IllustrationEasyStaffManagementSvg, typography, } from '@izettle/otto';
import { Text, Icon, Button, Illustration } from '@izettle/react';
import fistBump from 'src/assets/izettle-fist-bump.svg';
import highFive from 'src/assets/izettle-high-five.svg';
import loyalCustomer from 'src/assets/izettle-loyal-customer.svg';
import stopWatch from 'src/assets/zettle-stopwatch.svg';
import { getSvgIcon } from 'src/components/Questionnaire/icons';
import { useThemeInfo } from 'src/hooks/use-theme-info';
const Images = {
    highFive,
    fistBump,
    loyalCustomer,
    stopWatch,
};
const PageContainer = styled.div `
  height: 100%;
`;
const ContentContainer = styled.div `
  margin-top: ${spacing.large};
  text-align: center;
`;
const StyledIcon = styled(Icon) `
  font-size: ${spacing.medium};
  color: ${(props) => (props.paypaltheme ? colors.icon.default : colors.text.highlight)};
`;
const StyledImage = styled.img `
  max-width: 60%;
`;
const IconContainer = styled.div `
  margin: 16px 0;
`;
const ButtonContainer = styled.div `
  margin-top: ${size.xMedium};
`;
const StyledButton = styled(Button) `
  span span {
    ${typography.regular}
    color: inherit
  }
`;
export const SectionIntro = ({ stepObject, nextStep }) => {
    const { header, image, icon, subheader, title, description, button } = stepObject.applicationVariables.content;
    const { paypalTheme } = useThemeInfo();
    if (!image) {
        throw new Error('The image property is required for displaying the SectionIntro component.');
    }
    const renderImage = () => {
        const illustrationImg = { highFive: IllustrationEasyStaffManagementSvg, fistBump: IllustrationFistBumpSvg };
        if (Object.prototype.hasOwnProperty.call(illustrationImg, image)) {
            return React.createElement(Illustration, { illustration: illustrationImg[image], alt: "Alt text", width: "100%", height: "160px" });
        }
        else {
            return React.createElement(StyledImage, { src: Images[image].src, width: Images[image].width, height: Images[image].height, alt: "" });
        }
    };
    return (React.createElement(PageContainer, null,
        React.createElement(ContentContainer, null,
            React.createElement(Text.Title3, { as: "div" }, header)),
        React.createElement(ContentContainer, null, renderImage()),
        React.createElement(ContentContainer, null,
            React.createElement(Text.Title2, { as: "div" }, subheader),
            React.createElement(IconContainer, null,
                React.createElement(StyledIcon, { svgIcon: getSvgIcon(icon), alt: "", paypaltheme: paypalTheme })),
            React.createElement(Text.RegularBold, { className: "override-text" }, title),
            description && React.createElement(Text.RegularSecondary, { className: "override-text" }, description)),
        React.createElement(ButtonContainer, null,
            React.createElement(StyledButton, { id: "continueBtn", variant: "primary", fullWidth: true, onClick: nextStep }, button))));
};
