import { useState, useCallback } from 'react';
export const useAsyncError = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setError] = useState();
    return useCallback((e) => {
        setError(() => {
            throw e;
        });
    }, [setError]);
};
