import { datadogRum } from '@datadog/browser-rum';
export const addValuesForMissingOptionalFields = (formData, JSONSchema, UISchema) => {
    if (JSONSchema.type !== 'object' || !JSONSchema.properties) {
        throw new Error(`JSONSchema of form should be an object with 'properties' defined`); // at least for now
    }
    return Object.keys(JSONSchema.properties).reduce((acc, propName) => {
        var _a, _b, _c;
        if (formData[propName] !== undefined) {
            return acc;
        }
        // ignore type: "null" props which are usually fake ones used for displaying extra labels
        const propSchema = (_a = JSONSchema.properties) === null || _a === void 0 ? void 0 : _a[propName];
        if (typeof propSchema === 'object' && (propSchema === null || propSchema === void 0 ? void 0 : propSchema.type) === 'null') {
            return acc;
        }
        return Object.assign(Object.assign({}, acc), { [propName]: (_c = (_b = UISchema === null || UISchema === void 0 ? void 0 : UISchema[propName]) === null || _b === void 0 ? void 0 : _b['ui:emptyValue']) !== null && _c !== void 0 ? _c : '' });
    }, formData);
};
export const prepareErrorsForLib = (apiErrors) => {
    const libErrors = {};
    Object.keys(apiErrors).forEach((fieldName) => {
        const fieldErrors = apiErrors[fieldName];
        if (Array.isArray(fieldErrors)) {
            libErrors[fieldName] = {
                __errors: fieldErrors,
            };
            return;
        }
        libErrors[fieldName] = prepareErrorsForLib(fieldErrors);
    });
    return libErrors;
};
export const formTransformErrors = (errors) => {
    return errors.map((error) => {
        // TODO: move the content definition the error messages to the schema.
        // For convinience, we are temporarily reusing the same message in both 'required' and 'minItems' cases.
        // Once we start defining the messages from the schema, it will no longer be necessary to make up the error key.
        if (['required', 'minItems'].includes(error.name)) {
            error.message = 'VALIDATION_REQUIRED_ANSWER';
            return error;
        }
        throw new Error(`Unhandled client validation error: ${error.message}`);
    });
};
const findFirstFieldWithError = (formState) => {
    const { errorSchema, schema } = formState;
    if (!errorSchema || !(schema === null || schema === void 0 ? void 0 : schema.properties))
        return;
    return Object.keys(schema.properties).find((property) => Object.keys(errorSchema).includes(property));
};
const focusFieldWithError = (propertyId) => {
    const htmlElement = document.querySelector(`[data-property-name="${propertyId}"]`);
    if (!htmlElement) {
        datadogRum.addError(`Property container ${propertyId} not found when focusing on error`);
        return;
    }
    // Text input, Custom Select and Comboboxes are recognized with 'root_${id}' id attribute
    const innerElementWithId = htmlElement.querySelector(`[id="root_${propertyId}"]`);
    // Checkbox and Radio Groups elements are recognized with 'focusTarget' class name attribute
    const innerElementWithFocusTarget = htmlElement.querySelector('.focusTarget');
    if (innerElementWithId) {
        innerElementWithId.focus();
    }
    else if (innerElementWithFocusTarget) {
        innerElementWithFocusTarget.focus();
    }
    else {
        datadogRum.addError(`Property container ${propertyId} could not found any inner child element that matches our possible tries when focusing on error`);
    }
};
export const focusFirstFieldWithError = (formState) => {
    const firstPropertyId = findFirstFieldWithError(formState);
    if (!firstPropertyId)
        return;
    focusFieldWithError(firstPropertyId);
};
