import { pick } from 'ramda';
import ownerStructureHelpSvg from 'src/assets/owner-structure-help.svg';
import { FunctionStepFuncKey } from 'src/components/Questionnaire/types';
import { getPortalDomain } from 'src/config';
import { BusinessType, nativeBusinessTypeInfoList, statesData } from 'src/utils/country/MX/data';
import { getLimitedPartnershipRoleLabels } from 'src/utils/country/MX/data-helpers';
import { formatCurrencyRange, formatPercentRange, locale } from 'src/utils/country/MX/format';
import { buildCategorySteps } from '../../common/categories';
import { RoleFieldName } from '../../common/owner-structure';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { sequence, step, render, conditional, decision, customStepBuilder } from '../../steps-builder';
import { template } from '../../template';
const businessType = ({ translate }) => ({
    fields: ['nativeBusinessType'],
    JSONSchema: {
        title: translate('BUSINESS_TYPE_HEADER'),
        description: translate('BUSINESS_TYPE_INSTRUCTION'),
        required: ['nativeBusinessType'],
        type: 'object',
        properties: {
            nativeBusinessType: {
                title: translate('BUSINESS_TYPE_LABEL'),
                type: 'string',
                oneOf: nativeBusinessTypeInfoList.map((nativeBusinessTypeValue) => ({
                    enum: [nativeBusinessTypeValue.value],
                    title: nativeBusinessTypeValue.label,
                })),
            },
        },
    },
    UISchema: {
        nativeBusinessType: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessType',
        segment: 'businessDetails',
    },
});
const mapBusinessTypeFunction = () => ({
    functions: [FunctionStepFuncKey.MX_businessType],
    fields: ['businessType'],
    dependsOnFields: ['nativeBusinessType'],
    applicationVariables: {
        stepName: 'businessTypeFunc',
    },
});
const prepScreenGeneral = template(({ translate }) => ({
    componentName: 'InfoBoxScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_CONTENT_HEADER'),
            button: translate('FORM_BUTTON'),
            body: [
                {
                    type: 'list',
                    items: [
                        {
                            icon: 'Home',
                            description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_BODY_LIST_ITEM_DESCRIPTION'),
                        },
                        {
                            icon: 'Person',
                            description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_BODY_LIST_ITEM_DESCRIPTION'),
                        },
                        {
                            icon: 'IdentityCard',
                            description: translate('PREPARATION_SCREEN_OWNER_STRUCTURE_BODY_MX_LIST_ITEM_DESCRIPTION'),
                        },
                        {
                            icon: 'CardReaderOne',
                            description: translate('PREPARATION_SCREEN_USAGE_OF_ZETTLE_BODY_LIST_ITEM_DESCRIPTION'),
                        },
                    ],
                },
                {
                    type: 'text',
                    content: translate('PREPARATION_SCREEN_DOCUMENT_UPLOAD_BODY_TEXT_CONTENT'),
                },
            ],
            helpTexts: [
                {
                    title: translate('HELP_TEXT_WHY_PERSONAL_DETAILS_TITLE'),
                    body: translate('HELP_TEXT_WHY_PERSONAL_DETAILS_MX_BODY', [getPrivacyPolicyLink(locale)]),
                },
            ],
        },
    },
}));
const prepScreenSelfEmployed = prepScreenGeneral.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            body: [
                {
                    type: 'list',
                    items: [
                        {
                            icon: 'Home',
                            description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_BODY_LIST_ITEM_DESCRIPTION'),
                        },
                        {
                            icon: 'Person',
                            description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_BODY_LIST_ITEM_DESCRIPTION'),
                        },
                        {
                            icon: 'CardReaderOne',
                            description: translate('PREPARATION_SCREEN_USAGE_OF_ZETTLE_BODY_LIST_ITEM_DESCRIPTION'),
                        },
                    ],
                },
                {
                    type: 'text',
                    content: translate('PREPARATION_SCREEN_DOCUMENT_UPLOAD_BODY_TEXT_CONTENT'),
                },
            ],
            helpTexts: [
                {
                    title: translate('HELP_TEXT_WHY_PERSONAL_DETAILS_TITLE'),
                    body: translate('HELP_TEXT_WHY_PERSONAL_DETAILS_MX_SELF_EMPLOYED_BODY', [getPrivacyPolicyLink(locale)]),
                },
            ],
        },
    },
}));
const tradingName = template(({ translate }) => ({
    fields: ['tradingName'],
    JSONSchema: {
        type: 'object',
        properties: {
            tradingName: {
                title: translate('TRADING_NAME_MX_LABEL'),
                description: `(${translate('FORM_OPTIONAL_FIELD_LABEL')})`,
                type: 'string',
            },
        },
    },
    UISchema: {
        tradingName: {
            'ui:autofocus': true,
            'ui:placeholder': translate('FORM_EXAMPLE', ["Las flores de rosa" /* CopyMX.BUSINESS_TRADING_NAME_EXAMPLE */]),
        },
    },
    applicationVariables: {
        stepName: 'tradingName',
        segment: 'businessDetails',
    },
}));
const businessName = template(({ translate }) => ({
    fields: ['businessName'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            businessName: {
                title: translate('BUSINESS_NAME_LABEL'),
                description: translate('BUSINESS_NAME_MX_INSTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessName: {
            'ui:autofocus': true,
        },
    },
    applicationVariables: {
        stepName: 'businessName',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_NAME_HELP_TEXT_CAN_I_USE_TRADING_NAME_TITLE'),
                    body: translate('BUSINESS_NAME_HELP_TEXT_CAN_I_USE_TRADING_NAME_BODY', [getPortalDomain()]),
                },
            ],
        },
    },
}));
const businessAddress = template(({ translate }) => ({
    backendValidation: true,
    fields: ['businessStreetAddress', 'businessNeighborhood', 'businessPostalCode', 'businessCity', 'businessState'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_LABEL'),
                description: translate('BUSINESS_ADDRESS_MX_INSTRUCTION'),
                type: 'null',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessNeighborhood: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
            businessState: {
                type: 'string',
                enum: statesData.map((state) => state.value),
                enumNames: statesData.map((state) => state.label),
            },
        },
    },
    UISchema: {
        businessStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        businessNeighborhood: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_MX_NEIGHBORHOOD_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                mask: "99999" /* CopyMX.ADDRESS_POSTAL_CODE_MASK */,
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        businessState: {
            'ui:title': false,
            'ui:widget': 'CustomComboBoxWidget',
            'ui:options': {
                label: translate('ADDRESS_MX_STATE_LABEL'),
                sortByLabels: 'asc',
                descriptions: statesData.filter((state) => state.description !== undefined).map(pick(['value', 'description'])),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_NAME_HELP_TEXT_CAN_I_USE_TRADING_ADDRESS_TITLE'),
                    body: translate('BUSINESS_NAME_HELP_TEXT_CAN_I_USE_TRADING_ADDRESS_BODY', [getPortalDomain()]),
                },
            ],
        },
    },
}));
const businessAddressSelfEmployed = businessAddress.overrideDeep(({ translate }) => ({
    JSONSchema: {
        properties: {
            businessStreetQuestion: {
                title: translate('TRADING_ADDRESS_SELF_EMPLOYED_MX_LABEL'),
                description: '', // Overriding the value in the businessAddress component since for sole traders there should be no description
            },
        },
    },
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('TRADING_ADDRESS_SELF_EMPLOYED_MX_HELP_TEXT_TITLE'),
                    body: translate('TRADING_ADDRESS_SELF_EMPLOYED_MX_HELP_TEXT_BODY'),
                },
            ],
        },
    },
}));
const personalDetailsIntro = template(({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'personalDetails',
        content: {
            icon: 'Person',
            image: 'highFive',
            header: translate('SECTION_INTRO_PERSONAL_DETAILS_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_PERSONAL_DETAILS_TITLE'),
            description: translate('SECTION_INTRO_PERSONAL_DETAILS_NAME_CURP_PHONE_DESCRIPTION'),
            button: translate('FORM_BUTTON'),
        },
    },
}));
const signatoryName = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryFirstName', 'signatoryLastName', 'signatoryMaidenName'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryQuestion: {
                title: translate('SIGNATORY_NAME_LABEL'),
                description: translate('SIGNATORY_NAME_INSTRUCTION'),
                type: 'null',
            },
            signatoryFirstName: {
                type: 'string',
            },
            signatoryLastName: {
                type: 'string',
            },
            signatoryMaidenName: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryFirstName: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_ALL_LABEL'),
            },
        },
        signatoryLastName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_LAST_NAME_LABEL'),
            },
        },
        signatoryMaidenName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('SECOND_LAST_NAME_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryName',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_NAME_HELP_TEXT_ONE_LAST_NAME_MX_TITLE'),
                    body: translate('SIGNATORY_NAME_HELP_TEXT_ONE_LAST_NAME_MX_BODY'),
                },
            ],
        },
    },
});
const businessLegalEntityNumber = template(({ translate }) => ({
    backendValidation: true,
    fields: ['businessLegalEntityNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLegalEntityNumber: {
                title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_MX_RFC_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessLegalEntityNumber: {
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': translate('FORM_EXAMPLE', ["GAZ1703031KA" /* CopyMX.BUSINESS_LEGAL_ENTITY_NUMBER_EXAMPLE */]),
            'ui:options': {
                mask: "aaa999999***" /* CopyMX.BUSINESS_LEGAL_ENTITY_NUMBER_MASK */,
            },
        },
    },
    applicationVariables: {
        stepName: 'businessLegalEntityNumber',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_MX_RFC_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const businessLegalEntityNumberSelfEmployed = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessLegalEntityNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLegalEntityNumber: {
                title: translate('SIGNATORY_CURP_MX_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessLegalEntityNumber: {
            'ui:options': {
                mask: "aaaa999999aaaaaa**" /* CopyMX.CURP_MASK */,
            },
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': translate('FORM_EXAMPLE', ["HEGG860427MVZRRL04" /* CopyMX.CURP_PLACEHOLDER */]),
        },
    },
    applicationVariables: {
        stepName: 'businessLegalEntityNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_CURP_HELP_TEXT_WHY_CURP_MX_TITLE'),
                    body: translate('SIGNATORY_CURP_HELP_TEXT_WHY_CURP_MX_BODY'),
                },
                {
                    title: translate('SIGNATORY_CURP_HELP_TEXT_WHERE_FIND_CURP_MX_TITLE'),
                    body: translate('SIGNATORY_CURP_HELP_TEXT_WHERE_FIND_CURP_MX_BODY', ['https://www.gob.mx/curp/']),
                },
            ],
        },
    },
});
const signatoryLegalEntityNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryLegalEntityNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryLegalEntityNumber: {
                title: translate('SIGNATORY_CURP_MX_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryLegalEntityNumber: {
            'ui:options': {
                mask: "aaaa999999aaaaaa**" /* CopyMX.CURP_MASK */,
            },
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': translate('FORM_EXAMPLE', ["HEGG860427MVZRRL04" /* CopyMX.CURP_PLACEHOLDER */]),
        },
    },
    applicationVariables: {
        stepName: 'signatoryLegalEntityNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_CURP_HELP_TEXT_WHY_CURP_MX_TITLE'),
                    body: translate('SIGNATORY_CURP_HELP_TEXT_WHY_CURP_MX_BODY'),
                },
                {
                    title: translate('SIGNATORY_CURP_HELP_TEXT_WHERE_FIND_CURP_MX_TITLE'),
                    body: translate('SIGNATORY_CURP_HELP_TEXT_WHERE_FIND_CURP_MX_BODY', ['https://www.gob.mx/curp/']),
                },
            ],
        },
    },
});
const signatoryPhoneNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryPhoneNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryPhoneNumber: {
                title: translate('SIGNATORY_PHONE_NUMBER_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryPhoneNumber: {
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:placeholder': translate('FORM_EXAMPLE', ["5578431228" /* CopyMX.SIGNATORY_PHONE_NUMBER_EXAMPLE */]),
            'ui:options': {
                mask: "+99999999999999999999" /* CopyMX.SIGNATORY_PHONE_NUMBER_MASK */,
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryPhoneNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_PHONE_NUMBER_TITLE'),
                    body: translate('SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_PHONE_NUMBER_BODY'),
                },
            ],
        },
    },
});
const ownerStructureIntro = template(({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'ownerStructure',
        content: {
            icon: 'IdentityCard',
            image: 'fistBump',
            header: translate('SECTION_INTRO_END_PERSONAL_DETAILS_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_OWNER_STRUCTURE_MX_TITLE'),
            description: translate('SECTION_INTRO_OWNER_STRUCTURE_MX_NAME_CURP_DESCRIPTION'),
            button: translate('FORM_BUTTON'),
        },
    },
}));
const personStep = ({ translate, substitute }) => {
    return {
        ID: 'personStepId',
        backendValidation: true,
        fields: ['firstName', 'lastName', 'maidenName', 'legalEntityNumber'],
        JSONSchema: {
            type: 'object',
            properties: {
                nameTitle: {
                    title: translate('PERSON_NAME_LABEL'),
                    type: 'null',
                },
                firstName: {
                    type: 'string',
                },
                lastName: {
                    type: 'string',
                },
                maidenName: {
                    type: 'string',
                },
                legalEntityNumber: {
                    title: translate('CURP_MX_LABEL'),
                    type: 'string',
                },
            },
        },
        UISchema: {
            firstName: {
                'ui:title': false,
                'ui:autofocus': true,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('FIRST_NAME_ALL_LABEL'),
                },
            },
            lastName: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('FIRST_LAST_NAME_LABEL'),
                },
            },
            maidenName: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('SECOND_LAST_NAME_LABEL'),
                },
            },
            legalEntityNumber: {
                'ui:options': {
                    mask: "aaaa999999aaaaaa**" /* CopyMX.CURP_MASK */,
                },
                'ui:emptyValue': '',
                'ui:placeholder': translate('FORM_EXAMPLE', ["HEGG860427MVZRRL04" /* CopyMX.CURP_PLACEHOLDER */]),
            },
        },
        applicationVariables: {
            stepName: 'newPerson',
            segment: 'ownerStructure',
            content: {
                helpTexts: [
                    {
                        title: translate('HELP_TEXT_MX_LAST_NAME_TITLE'),
                        body: translate('HELP_TEXT_MX_LAST_NAME_BODY'),
                    },
                    {
                        title: translate('HELP_TEXT_MX_DOES_NOT_HAVE_CURP_TITLE'),
                        body: translate('HELP_TEXT_MX_DOES_NOT_HAVE_CURP_BODY', [substitute('SUPPORT_CONTACT_HUB_URL')]),
                    },
                ],
            },
        },
    };
};
const initLegalPersonsFunction = () => ({
    functions: [FunctionStepFuncKey.initLegalPersons],
    fields: ['legalPersons'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'legalPersonsFunc',
        segment: 'ownerStructure',
    },
});
const hasUBO = ({ translate }) => ({
    fields: ['hasUBO'],
    JSONSchema: {
        required: ['hasUBO'],
        type: 'object',
        title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_MX_LABEL'),
        properties: {
            hasUBO: {
                title: translate('OWNER_STRUCTURE_HAS_UBO_DESCRIPTION'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_YES') },
                    { enum: [false], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        hasUBO: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
        'ui:options': {
            titleStyle: 'LargeBold',
        },
    },
    applicationVariables: {
        stepName: 'hasUBO',
        segment: 'ownerStructure',
        content: {
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
            ],
        },
    },
});
const beneficialOwners = ({ translate, substitute }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleBeneficialOwners'],
    personStep: personStep({ translate, substitute }),
    applicationVariables: {
        stepName: 'beneficialOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleBeneficialOwners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_MX_LABEL'),
            description: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
            ],
        },
    },
});
const usageOfIZettleIntroGeneral = template(({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'usageOfIzettle',
        content: {
            icon: 'CardReaderOne',
            image: 'highFive',
            header: translate('SECTION_INTRO_USAGE_OF_IZETTLE_MX_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_USAGE_OF_IZETTLE_TITLE'),
            button: translate('FORM_BUTTON'),
        },
    },
}));
const usageOfIZettleIntroSelfEmployed = usageOfIZettleIntroGeneral.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            image: 'fistBump',
            header: translate('SECTION_INTRO_USAGE_OF_IZETTLE_SELF_EMPLOYED_HEADER'),
        },
    },
}));
const businessLocationsTypes = ({ translate }) => ({
    fields: ['businessLocationTypes'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLocationTypes: {
                title: translate('BUSINESS_LOCATION_TYPES_LABEL'),
                description: translate('BUSINESS_LOCATION_TYPES_INSTRUCTION'),
                type: 'array',
                minItems: 1,
                items: {
                    type: 'string',
                    anyOf: [
                        { enum: ['PHYSICAL_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_PHYSICAL_STORE') },
                        { enum: ['TEMPORARY_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_TEMPORARY_STORE') },
                        { enum: ['EVENT_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_EVENT_STORE') },
                    ],
                },
                uniqueItems: true,
            },
        },
    },
    UISchema: {
        businessLocationTypes: {
            'ui:widget': 'CustomCheckboxGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessLocationTypes',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
const businessFrequency = ({ translate }) => ({
    fields: ['businessFrequency'],
    JSONSchema: {
        required: ['businessFrequency'],
        type: 'object',
        properties: {
            businessFrequency: {
                title: translate('BUSINESS_FREQUENCY_LABEL'),
                type: 'string',
                oneOf: [
                    { enum: ['EVERY_DAY'], title: translate('BUSINESS_FREQUENCY_VALUE_EVERY_DAY') },
                    {
                        enum: ['FEW_TIMES_A_MONTH'],
                        title: translate('BUSINESS_FREQUENCY_VALUE_FEW_TIMES_A_MONTH'),
                    },
                    {
                        enum: ['FEW_TIMES_A_YEAR'],
                        title: translate('BUSINESS_FREQUENCY_VALUE_FEW_TIMES_A_YEAR'),
                    },
                ],
            },
        },
    },
    UISchema: {
        businessFrequency: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessFrequency',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
const businessAverageTransaction = ({ translate }) => ({
    fields: ['businessAverageTransaction'],
    JSONSchema: {
        required: ['businessAverageTransaction'],
        type: 'object',
        properties: {
            businessAverageTransaction: {
                title: translate('BUSINESS_AVERAGE_TRANSACTION_LABEL'),
                type: 'string',
                oneOf: ['0-1000', '1000-10000', '10000-X'].map((range) => ({
                    enum: [range],
                    title: formatCurrencyRange(range, translate),
                })),
            },
        },
    },
    UISchema: {
        businessAverageTransaction: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessAverageTransaction',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
const businessIzettleShareOfTurnover = ({ translate }) => ({
    fields: ['businessIzettleShareOfTurnover'],
    JSONSchema: {
        required: ['businessIzettleShareOfTurnover'],
        type: 'object',
        properties: {
            businessIzettleShareOfTurnover: {
                title: translate('BUSINESS_IZETTLE_SHARE_OF_TURNOVER_LABEL'),
                type: 'string',
                oneOf: ['0-20%', '20-80%', '80-X%'].map((rangeValue) => ({
                    enum: [rangeValue],
                    title: formatPercentRange(rangeValue, translate),
                })),
            },
        },
    },
    UISchema: {
        businessIzettleShareOfTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessIzettleShareOfTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
const businessCurrentAnnualTurnover = ({ translate }) => ({
    fields: ['businessCurrentAnnualTurnover'],
    JSONSchema: {
        required: ['businessCurrentAnnualTurnover'],
        type: 'object',
        properties: {
            businessCurrentAnnualTurnover: {
                title: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_LABEL'),
                description: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_INSTRUCTION'),
                type: 'string',
                oneOf: [
                    {
                        enum: ['NEW_BUSINESS'],
                        title: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_VALUE_NEW_BUSINESS'),
                    },
                    ...['0-400000', '400000-4000000', '4000000-20000000', '20000000-X'].map((rangeValue) => ({
                        enum: [rangeValue],
                        title: formatCurrencyRange(rangeValue, translate),
                    })),
                ],
            },
        },
    },
    UISchema: {
        businessCurrentAnnualTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessCurrentAnnualTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
const businessExpectedAnnualTurnover = ({ translate }) => ({
    fields: ['businessExpectedAnnualTurnover'],
    JSONSchema: {
        required: ['businessExpectedAnnualTurnover'],
        type: 'object',
        properties: {
            businessExpectedAnnualTurnover: {
                title: translate('BUSINESS_EXPECTED_ANNUAL_TURNOVER_LABEL'),
                type: 'string',
                oneOf: ['0-400000', '400000-4000000', '4000000-20000000', '20000000-X'].map((rangeValue) => ({
                    enum: [rangeValue],
                    title: formatCurrencyRange(rangeValue, translate),
                })),
            },
        },
    },
    UISchema: {
        businessExpectedAnnualTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessExpectedAnnualTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
const overviewPage = ({ translate }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: 'MX',
        content: {
            title: translate('OVERVIEW_TITLE'),
            subtitle: translate('OVERVIEW_SUB_TITLE'),
            submitButton: translate('OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: translate('OVERVIEW_SUBMIT_INSTRUCTION_MX', [getPrivacyPolicyLink(locale)]),
        },
    },
});
const root = sequence([
    // BUSINESS TYPE
    step(businessType),
    step(mapBusinessTypeFunction),
    // intro prep screen
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(prepScreenSelfEmployed),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit],
            branch: step(prepScreenGeneral),
        },
    ]),
    // businessLegalEntityNumber
    conditional('businessType', [
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit],
            branch: step(businessLegalEntityNumber),
        },
    ]),
    // tradingName or businessName
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(tradingName),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit],
            branch: step(businessName),
        },
    ]),
    // businessAddress
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(businessAddressSelfEmployed),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit],
            branch: step(businessAddress),
        },
    ]),
    // personalDetailsIntro
    step(personalDetailsIntro),
    // PERSONAL DETAILS
    step(signatoryName),
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(businessLegalEntityNumberSelfEmployed),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit],
            branch: step(signatoryLegalEntityNumber),
        },
    ]),
    step(signatoryPhoneNumber),
    // OWNER STRUCTURE
    conditional('businessType', [
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit],
            branch: sequence([
                step(initLegalPersonsFunction),
                step(ownerStructureIntro),
                decision('hasUBO', hasUBO, [{ when: true, branch: step(beneficialOwners) }]),
            ]),
        },
    ]),
    // USAGE OF ZETTLE
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(usageOfIZettleIntroSelfEmployed),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit],
            branch: step(usageOfIZettleIntroGeneral),
        },
    ]),
    customStepBuilder(buildCategorySteps),
    step(businessLocationsTypes),
    step(businessFrequency),
    step(businessAverageTransaction),
    step(businessIzettleShareOfTurnover),
    decision('businessCurrentAnnualTurnover', businessCurrentAnnualTurnover, [
        { when: 'NEW_BUSINESS', branch: step(businessExpectedAnnualTurnover) },
    ]),
    // OVERVIEW
    step(overviewPage),
]);
export const MX = (context) => render(root, context);
