import { IsoLocale } from '@izettle/constants';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { nationalityOptions } from '../../common/nationality-options';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { render, sequence, step, decision } from '../../steps-builder';
import { directorsListTemplate, hasUBOTemplate, initLegalPersonsFunction, introScreenTemplate, legalPeoplePreparationScreenTemplate, normalizeSignatoryFieldsFunction, beneficialOwnersListTemplate, alternativeOwnersListTemplate, overviewPageTemplate, } from './common/steps/limited-company/index';
import { signatoryNamePrefilledTemplate, signatoryNationalityTemplate, signatoryPhoneNumberTemplate, signatoryAddressTemplate, signatoryDOBTemplate, signatoryNameTemplate, } from './common/steps/signatory';
const introScreenGB = introScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            items: [
                {
                    icon: 'Person',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                },
                {
                    icon: 'Home',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_DESCRIPTION'),
                },
                {
                    icon: 'People',
                    title: translate('GAP_LTD_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('GAP_AUTO_VERIFICATION_PREPARATION_SCREEN_ITEM_VERIFICATION_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_VERIFICATION_DESCRIPTION'),
                },
            ],
        },
    },
}));
const signatoryPhoneNumberGB = signatoryPhoneNumberTemplate.extendDeep(({ translate }) => ({
    UISchema: {
        signatoryPhoneNumber: {
            'ui:placeholder': translate('SIGNATORY_PHONE_NUMBER_PLACEHOLDER'),
        },
    },
}));
const businessAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessBuilding', 'businessStreetAddress', 'businessCity', 'businessPostalCode', 'businessCounty'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_LABEL'),
                description: translate('BUSINESS_ADDRESS_LIMITED_COMPANY_INSTRUCTION'),
                type: 'null',
            },
            businessBuilding: {
                type: 'string',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
            businessCounty: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessBuilding: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        businessStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        businessCounty: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
    },
});
const legalPeoplePreparationScreenGB = legalPeoplePreparationScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_1_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_1_ACCORDION_CONTENT'),
                },
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                },
                {
                    title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                    body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                },
            ],
        },
    },
}));
const personStep = (translate) => ({
    ID: 'personStepId',
    backendValidation: true,
    fields: [
        'firstName',
        'lastName',
        'dateOfBirth',
        'nationality',
        'building',
        'streetAddress',
        'city',
        'county',
        'postalCode',
    ],
    JSONSchema: {
        type: 'object',
        properties: {
            nameTitle: {
                title: translate('PERSON_NAME_LABEL'),
                type: 'null',
            },
            firstName: {
                type: 'string',
            },
            lastName: {
                type: 'string',
            },
            dateOfBirthTitle: {
                title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                type: 'null',
            },
            dateOfBirth: {
                type: 'string',
            },
            nationalityTitle: {
                title: translate('PERSON_NATIONALITY_LABEL'),
                type: 'null',
            },
            nationality: {
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
            addressTitle: {
                title: translate('PERSON_HOME_ADDRESS_LABEL'),
                type: 'null',
            },
            building: {
                type: 'string',
            },
            streetAddress: {
                type: 'string',
            },
            city: {
                type: 'string',
            },
            county: {
                type: 'string',
            },
            postalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        firstName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_ALL_LABEL'),
            },
        },
        lastName: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('LAST_NAME_ALL_LABEL'),
            },
        },
        dateOfBirth: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('DATE_OF_BIRTH_PLACEHOLDER'),
                mask: '99.99.9999',
            },
        },
        nationality: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:widget': 'CustomComboBoxWidget',
            'ui:emptyValue': '',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
            'ui:options': {
                sortByLabels: 'asc',
            },
        },
        building: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        streetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        city: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        county: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        postalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'newPerson',
    },
});
const overviewPageGB = overviewPageTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        version: 'GAP_GB_LTD_COMPANY_NEW',
        content: {
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(IsoLocale.enGB)]),
        },
    },
}));
const newSignatorySequence = [
    step(signatoryNameTemplate),
    step(signatoryDOBTemplate),
    step(signatoryAddressTemplate),
    step(signatoryPhoneNumberGB),
    step(signatoryNationalityTemplate),
];
const rootSequence = sequence([
    step(introScreenGB),
    decision('signatoryAction', signatoryNamePrefilledTemplate, [
        { when: SIGNATORY_ACTION.CHANGE_SIGNATORY, branch: sequence(newSignatorySequence) },
        { when: SIGNATORY_ACTION.SET_NATIONALITY, branch: step(signatoryNationalityTemplate) },
    ]),
    step(normalizeSignatoryFieldsFunction),
    step(businessAddress),
    step(legalPeoplePreparationScreenGB),
    step(initLegalPersonsFunction),
    step(directorsListTemplate(personStep)),
    decision('hasUBO', hasUBOTemplate, [
        { when: true, branch: step(beneficialOwnersListTemplate(personStep)) },
        { when: false, branch: step(alternativeOwnersListTemplate(personStep)) },
    ]),
    step(overviewPageGB),
]);
export const GAP_GB_LTD_COMPANY_NEW = (context) => render(rootSequence, context);
