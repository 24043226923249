import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
export const GAP_GB_NATIONALITY = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(Section, { name: "personalDetails", title: text('GAP_OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: ['signatoryNationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE'), editable: true, conditionalRendering: true }, stepData.signatoryNationality && React.createElement(Value, null, text(`NATIONALITY_VALUE_${stepData.signatoryNationality}`)))));
};
