import { datadogRum } from '@datadog/browser-rum';
import { IsoLocale } from '@izettle/constants';
export const convertDateToLocalMarketFormat = (originalDate, locale) => {
    const date = new Date(originalDate);
    if (!Number.isFinite(date.getTime())) {
        throw new Error(`Unable to format date. '${originalDate}' is an invalid date value.`);
    }
    if (Intl.DateTimeFormat.supportedLocalesOf(locale).length === 0) {
        datadogRum.addError(`Locale '${locale}' is not supported by Intl in this context. Date will be displayed in en-GB instead.`);
        return new Intl.DateTimeFormat(IsoLocale.enGB).format(date);
    }
    return new Intl.DateTimeFormat(locale).format(date);
};
