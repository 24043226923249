import React from 'react';
import { ObjectPropertyContext } from './context';
import { getPropertyFieldSets } from './helpers';
import { ObjectDescription, ObjectTitle, ObjectPropertyFieldSet, ObjectPropertyContainer, ObjectContainer, } from './styles';
export const ObjectFieldTemplate = (props) => {
    var _a, _b;
    const { uiSchema, title: objectTitle, description: objectDescription, formData: objectFormData } = props;
    const objectTitleStyle = (_b = (_a = uiSchema === null || uiSchema === void 0 ? void 0 : uiSchema['ui:options']) === null || _a === void 0 ? void 0 : _a.titleStyle) !== null && _b !== void 0 ? _b : 'Title1';
    const fieldSets = getPropertyFieldSets(props);
    return (React.createElement(ObjectContainer, null,
        objectTitle && React.createElement(ObjectTitle, { titleStyle: objectTitleStyle }, objectTitle),
        objectDescription && React.createElement(ObjectDescription, null, objectDescription),
        fieldSets.map((fieldSet) => (React.createElement(ObjectPropertyFieldSet, { key: fieldSet.key, as: fieldSet.legend ? 'fieldset' : 'div' },
            fieldSet.legend,
            fieldSet.props.map((prop) => (React.createElement(ObjectPropertyContext.Provider, { key: prop.name, value: { objectFormData, propertyName: prop.name } },
                React.createElement(ObjectPropertyContainer, { "data-property-name": prop.name }, prop.content)))))))));
};
