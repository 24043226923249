import React from 'react';
import styled from 'styled-components';
import { size } from '@izettle/otto';
import { Button, Text } from '@izettle/react';
import fistBump from 'src/assets/izettle-fist-bump.svg';
import highFive from 'src/assets/izettle-high-five.svg';
import loyalCustomer from 'src/assets/izettle-loyal-customer.svg';
import stopWatch from 'src/assets/zettle-stopwatch.svg';
const Images = {
    highFive,
    fistBump,
    loyalCustomer,
    stopWatch,
};
const PageContainer = styled.div `
  height: 100%;
`;
const ContentContainer = styled.div `
  margin-top: ${size.small};
  text-align: center;
`;
const ButtonContainer = styled.div `
  margin-top: ${size.xMedium};
`;
const StyledImage = styled.img `
  max-width: 100%;
`;
export const ConfirmationPage = (props) => {
    const { title, image, subtitle, button, onConfirm } = props;
    return (React.createElement(PageContainer, null,
        React.createElement(ContentContainer, null,
            React.createElement(Text.Title2, null, title)),
        React.createElement(ContentContainer, null,
            React.createElement(StyledImage, { src: Images[image].src, width: Images[image].width, height: Images[image].height, alt: "" })),
        React.createElement(ContentContainer, null,
            React.createElement(Text.Regular, { className: "override-text" }, subtitle)),
        React.createElement(ButtonContainer, null,
            React.createElement(Button, { id: "continueBtn", variant: "primary", fullWidth: true, onClick: onConfirm }, button))));
};
