import { nationalityOptions } from 'src/schema/common/nationality-options';
import { getPrivacyPolicyLink } from 'src/schema/common/privacy-policy-link';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { template } from 'src/schema/template';
export const signatoryNationalityTemplate = template(({ translate }) => ({
    fields: ['signatoryNationality', 'signatoryAction'],
    JSONSchema: {
        required: ['signatoryNationality'],
        type: 'object',
        properties: {
            signatoryNationality: {
                title: translate('GAP_SIGNATORY_NATIONALITY_LABEL'),
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
            signatoryAction: {
                type: 'string',
                default: SIGNATORY_ACTION.SET_NATIONALITY,
            },
        },
    },
    UISchema: {
        signatoryNationality: {
            'ui:options': {
                sortByLabels: 'asc',
            },
            'ui:widget': 'CustomComboBoxWidget',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
        },
        signatoryAction: {
            'ui:title': false,
            'ui:widget': 'hidden',
        },
    },
    applicationVariables: {
        stepName: 'signatoryNationality',
        segment: 'personalDetails',
        content: {
            button: translate('FORM_BUTTON'),
            helpTexts: [
                {
                    title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                    body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                },
            ],
        },
    },
}));
export const overviewPageTemplate = (locale) => template(({ translate }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: '',
        confirmationScreen: {
            title: translate('GAP_OVERVIEW_CONFIRMATION_TITLE'),
            subtitle: translate('GAP_OVERVIEW_CONFIRMATION_SUB_TITLE'),
            button: translate('GAP_OVERVIEW_CLOSE_BUTTON'),
        },
        content: {
            title: translate('GAP_OVERVIEW_TITLE'),
            subtitle: translate('GAP_OVERVIEW_SUB_TITLE'),
            submitButton: translate('GAP_OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(locale)]),
        },
    },
}));
