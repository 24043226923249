import { useContext, useEffect, useState } from 'react';
import { identity } from 'ramda';
import { datadogRum } from '@datadog/browser-rum';
import { useLocalization } from '@izettle/lingo-client';
import { useSaveRedirectTo } from 'src/hooks/use-save-redirect-to';
import { useFormContextValue, FormStatus } from 'src/providers/form';
import { hotJarSendTrigger, hotJarSetUserId, hotJarTagFormKey } from 'src/tools/hotjar';
import { pushDataLayer } from '../../util';
import { PageTitleContext } from './components/PageTitle';
export const useAppSideEffects = () => {
    const { formContext } = useFormContextValue();
    const { trySaveRedirectTo } = useSaveRedirectTo();
    const pageTitle = useContext(PageTitleContext);
    const { text } = useLocalization();
    const [haveAllEffectsRun, setHaveAllEffectsRun] = useState(false);
    useEffect(() => {
        pushDataLayer({
            event: 'KycPageLoaded',
        });
    }, []);
    useEffect(() => {
        window.document.documentElement.lang = formContext.user.languageId;
    }, [formContext.user.languageId]);
    useEffect(() => {
        const titleParts = [pageTitle === null || pageTitle === void 0 ? void 0 : pageTitle.currentTopTitle, text('APP_NAME'), text('COMPANY_NAME')];
        window.document.title = titleParts.filter(identity).join(' – ');
    }, [pageTitle === null || pageTitle === void 0 ? void 0 : pageTitle.currentTopTitle]);
    useEffect(() => {
        pushDataLayer({
            event: 'Demographics',
            visitorDemographicInfo: [formContext.user.languageId, formContext.user.countryId, formContext.user.timeZoneId],
            visitorId: formContext.user.organizationUuid,
        });
        datadogRum.setUser({ id: formContext.user.organizationUuid });
        hotJarSetUserId(formContext.user.organizationUuid);
    }, [formContext.user]);
    useEffect(() => {
        datadogRum.setUserProperty('kyc.countryId', 'formContext.user.countryId');
    }, [formContext.user.countryId]);
    useEffect(() => {
        if (formContext.status === FormStatus.ONBOARDING || formContext.status === FormStatus.GAPS) {
            hotJarTagFormKey(formContext.form);
            hotJarSendTrigger(`form-loaded_key-${formContext.form}`);
            datadogRum.setUserProperty('kyc.formKey', formContext.form);
        }
    }, [formContext.status]);
    useEffect(() => {
        trySaveRedirectTo(formContext.user.organizationUuid);
    }, [formContext.user.organizationUuid]);
    useEffect(() => {
        setHaveAllEffectsRun(true);
    }, []);
    return {
        haveAllEffectsRun,
    };
};
