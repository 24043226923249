import { getUserLocale } from 'src/providers/form/helpers';
import { getPrivacyPolicyLink } from 'src/schema/common/privacy-policy-link';
import { signatorySubjectToUsaBackupWithholding, signatoryUnderUsaTaxLaw, signatoryUsaTaxNumber, } from 'src/schema/common/usaTaxpayerDetails/step-templates';
import { render, sequence, step, customStepBuilder, conditional } from 'src/schema/steps-builder';
const preparationScreen = (isoLocale) => ({ translate }) => {
    return {
        componentName: 'InfoBoxScreen',
        applicationVariables: {
            stepName: 'PreparationScreen',
            content: {
                header: translate('GAP_USA_TAX_DETAILS_PREPARATION_SCREEN_HEADER'),
                description: translate('GAP_USA_TAX_DETAILS_PREPARATION_SCREEN_DESCRIPTION'),
                button: translate('FORM_BUTTON'),
                body: [
                    {
                        type: 'list',
                        header: translate('GAP_USA_TAX_DETAILS_PREPARATION_SCREEN_WHAT_WE_MIGHT_ASK_FOR_HEADER'),
                        items: [
                            {
                                icon: 'Home',
                                description: translate('OVERVIEW_SECTION_BUSINESS_DETAILS_TITLE'),
                            },
                            {
                                icon: 'Person',
                                description: translate('OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE'),
                            },
                        ],
                    },
                ],
                helpTexts: [
                    {
                        title: translate('GAP_USA_TAX_DETAILS_PREPARATION_SCREEN_HELP_TEXT_WHY_NEED_THIS_INFORMATION_TITLE'),
                        body: translate('GAP_USA_TAX_DETAILS_PREPARATION_SCREEN_HELP_TEXT_WHY_NEED_THIS_INFORMATION_CONTENT', [
                            getPrivacyPolicyLink(isoLocale),
                        ]),
                    },
                ],
            },
        },
    };
};
export const buildPreparationScreenStep = ({ formContext }) => {
    return step(preparationScreen(getUserLocale(formContext)));
};
const overviewPage = ({ translate }) => {
    return {
        componentName: 'OverviewPage',
        applicationVariables: {
            stepName: 'overviewPage',
            version: 'GAP_USA_TAX_DETAILS_SOLE_TRADER',
            confirmationScreen: {
                title: translate('GAP_OVERVIEW_CONFIRMATION_TITLE'),
                subtitle: translate('GAP_OVERVIEW_CONFIRMATION_SUB_TITLE'),
                button: translate('GAP_OVERVIEW_CLOSE_BUTTON'),
            },
            content: {
                title: translate('OVERVIEW_TITLE'),
                subtitle: translate('OVERVIEW_SUB_TITLE'),
                submitButton: translate('GAP_OVERVIEW_SUBMIT_BUTTON'),
            },
            displayUsaTaxpayerTerms: true,
        },
    };
};
const root = sequence([
    customStepBuilder(buildPreparationScreenStep),
    step(signatoryUnderUsaTaxLaw),
    conditional('signatoryUnderUsaTaxLaw', [
        {
            when: true,
            branch: sequence([step(signatoryUsaTaxNumber), step(signatorySubjectToUsaBackupWithholding)]),
        },
    ]),
    step(overviewPage),
]);
export const GAP_USA_TAX_DETAILS_SOLE_TRADER = (context) => render(root, context);
