import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { BusinessType } from 'src/utils/country/MX/data';
import { createRoleFieldsTitles } from 'src/utils/country/MX/data-helpers';
import { formatCurrencyRange, formatPercentRange } from 'src/utils/country/MX/format';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Value } from '../../../Value';
import { OwnerStructureSection } from '../../shared/OwnerStructureSection';
import { UsageOfIZettleSection } from '../../shared/UsageOfIZettleSection';
import { BusinessDetailsSection } from './BusinessDetailsSection';
import { PersonalDetailsSection } from './PersonalDetailsSection';
const LegalPersonContent = () => {
    const { text } = useLocalization();
    const { stepData: person } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { fieldNames: ['legalEntityNumber'], title: text('CURP_MX_LABEL') },
            React.createElement(Value, null, person.legalEntityNumber))));
};
export const MX = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(BusinessDetailsSection, null),
        React.createElement(PersonalDetailsSection, null),
        stepData.businessType !== BusinessType.SelfEmployed && (React.createElement(OwnerStructureSection, { legalPersonContentTemplate: LegalPersonContent, roleFieldsTitles: createRoleFieldsTitles(stepData.businessType, text), isMXMarket: true })),
        React.createElement(UsageOfIZettleSection, { formatCurrencyRange: formatCurrencyRange, formatPercentRange: formatPercentRange })));
};
