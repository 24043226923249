import React, { useContext } from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { spacing, typography } from '@izettle/otto';
import { Button } from '@izettle/react';
import { Action, useTracker } from 'src/hooks/use-tracker';
import { getQuestionnairePayload } from 'src/hooks/use-tracker/helpers';
import { useAuthStateValue } from 'src/providers/auth';
import { isRunningInGoAppWebView } from 'src/util';
import { QuestionnaireStateContext } from '../Questionnaire/state';
const DestructiveButton = styled(Button).attrs({
    variant: 'destructive',
    size: 'small',
}) `
  margin-top: ${spacing.xxSmall};
`;
const SecondaryButton = styled(Button).attrs({
    variant: 'secondary',
    size: 'small',
}) `
  margin-top: ${spacing.xxSmall};
`;
const InlineDestructiveButton = styled(Button).attrs({
    variant: 'inlineDestructive',
    noPadding: true,
}) `
  span span {
    ${typography.regular}
    color: inherit
  }
  &&&& {
    display: inline;
    font: unset;
  }
`;
export const LogoutButton = ({ variant }) => {
    const { text } = useLocalization();
    const { trackEvent } = useTracker();
    const { authClient } = useAuthStateValue();
    const context = useContext(QuestionnaireStateContext);
    const onClick = () => {
        trackEvent(Action.ClickedLogoutButton, context ? getQuestionnairePayload(context.state) : {});
        authClient.logout();
    };
    if (isRunningInGoAppWebView()) {
        return null;
    }
    if (variant === 'inlineDestructive') {
        return React.createElement(InlineDestructiveButton, { onClick: onClick }, text('LOGOUT_BUTTON_TITLE'));
    }
    if (variant === 'destructive') {
        return React.createElement(DestructiveButton, { onClick: onClick }, text('LOGOUT_BUTTON_TITLE'));
    }
    if (variant === 'secondary') {
        return React.createElement(SecondaryButton, { onClick: onClick }, text('LOGOUT_BUTTON_TITLE'));
    }
    throw new Error(`Unsupported variant of LogoutButton: '${variant}'`);
};
