/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { IsoLocale } from '@izettle/constants';
import { AppTranslationsProvider } from 'src/components/App/components/TranslationsProvider';
import { useFormContextValue } from 'src/providers/form';
import { TaxpayerCertificationContent } from './components/TaxpayerCertificationContent';
const getLanguageSwitchText = (userLanguageId, shouldDisplayTextInUsaEnglish) => {
    if (userLanguageId === 'en') {
        return undefined;
    }
    return shouldDisplayTextInUsaEnglish ? userLanguageId : 'EN';
};
export const UsaTaxpayerCertification = () => {
    const [shouldDisplayTextInUsaEnglish, setShouldDisplayTextInUsaEnglish] = useState(false);
    const { formContext: { user: { languageId: userLanguageId }, }, } = useFormContextValue();
    const handleLanguageSwitchClick = () => {
        setShouldDisplayTextInUsaEnglish(!shouldDisplayTextInUsaEnglish);
    };
    return (React.createElement(AppTranslationsProvider, { localeOverride: shouldDisplayTextInUsaEnglish || userLanguageId === 'en' ? IsoLocale.enGB : undefined },
        React.createElement("div", { lang: shouldDisplayTextInUsaEnglish ? 'en' : userLanguageId },
            React.createElement(TaxpayerCertificationContent, { languageSwitchText: getLanguageSwitchText(userLanguageId, shouldDisplayTextInUsaEnglish), onLanguageSwitchClick: handleLanguageSwitchClick }))));
};
