export var BusinessType;
(function (BusinessType) {
    BusinessType["SelfEmployed"] = "SelfEmployed";
    BusinessType["LimitedCompany"] = "LimitedCompany";
    BusinessType["Partnership"] = "Partnership";
    BusinessType["NonProfit"] = "NonProfit";
})(BusinessType || (BusinessType = {}));
export var NativeBusinessType;
(function (NativeBusinessType) {
    NativeBusinessType["Enkeltmandsvirksomhed"] = "Enkeltmandsvirksomhed";
    NativeBusinessType["ApS"] = "ApS";
    NativeBusinessType["AS"] = "AS";
    NativeBusinessType["IVS"] = "IVS";
    NativeBusinessType["SMBA"] = "SMBA";
    NativeBusinessType["AMBA"] = "AMBA";
    NativeBusinessType["Forening"] = "Forening";
    NativeBusinessType["FMBA"] = "FMBA";
    NativeBusinessType["ErhvervsdrivendeFond"] = "ErhvervsdrivendeFond";
    NativeBusinessType["IkkeErhvervsdrivendeFond"] = "IkkeErhvervsdrivendeFond";
    NativeBusinessType["IS"] = "IS";
    NativeBusinessType["KS"] = "KS";
    NativeBusinessType["PS"] = "PS";
    NativeBusinessType["Partsrederi"] = "Partsrederi";
})(NativeBusinessType || (NativeBusinessType = {}));
export const nativeBusinessTypeInfoList = [
    {
        value: NativeBusinessType.Enkeltmandsvirksomhed,
        businessType: BusinessType.SelfEmployed,
        title: "Enkeltmandsvirksomhed" /* CopyDK.BUSINESS_TYPE_ENKELTMANDSVIRKSOMHED_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.ApS,
        businessType: BusinessType.LimitedCompany,
        title: "ApS" /* CopyDK.BUSINESS_TYPE_APS_TITLE */,
        description: "Anpartsselskab" /* CopyDK.BUSINESS_TYPE_APS_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.AS,
        businessType: BusinessType.LimitedCompany,
        title: "A/S" /* CopyDK.BUSINESS_TYPE_AS_TITLE */,
        description: "Aktieselskab" /* CopyDK.BUSINESS_TYPE_AS_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.IVS,
        businessType: BusinessType.LimitedCompany,
        title: "IVS" /* CopyDK.BUSINESS_TYPE_IVS_TITLE */,
        description: "Iv\u00E6rks\u00E6tterselskab" /* CopyDK.BUSINESS_TYPE_IVS_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.SMBA,
        businessType: BusinessType.LimitedCompany,
        title: "S.M.B.A." /* CopyDK.BUSINESS_TYPE_SMBA_TITLE */,
        description: "Selskab med begr\u00E6nset ansvar" /* CopyDK.BUSINESS_TYPE_SMBA_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.AMBA,
        businessType: BusinessType.LimitedCompany,
        title: "A.M.B.A." /* CopyDK.BUSINESS_TYPE_AMBA_TITLE */,
        description: "Andelsselskab med begr\u00E6nset ansvar" /* CopyDK.BUSINESS_TYPE_AMBA_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.Forening,
        businessType: BusinessType.NonProfit,
        title: "Forening" /* CopyDK.BUSINESS_TYPE_FORENING_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.FMBA,
        businessType: BusinessType.NonProfit,
        title: "F.M.B.A." /* CopyDK.BUSINESS_TYPE_FMBA_TITLE */,
        description: "Forening med begr\u00E6nset ansvar" /* CopyDK.BUSINESS_TYPE_FMBA_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.ErhvervsdrivendeFond,
        businessType: BusinessType.NonProfit,
        title: "Erhvervsdrivende fond" /* CopyDK.BUSINESS_TYPE_ERHVERVSDRIVENDEFOND_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.IkkeErhvervsdrivendeFond,
        businessType: BusinessType.NonProfit,
        title: "Ikke-erhvervsdrivende fond" /* CopyDK.BUSINESS_TYPE_IKKE_ERHVERVSDRIVENDEFOND_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.IS,
        businessType: BusinessType.Partnership,
        title: "I/S" /* CopyDK.BUSINESS_TYPE_IS_TITLE */,
        description: "Interessentskab" /* CopyDK.BUSINESS_TYPE_IS_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.KS,
        businessType: BusinessType.Partnership,
        title: "K/S" /* CopyDK.BUSINESS_TYPE_KS_TITLE */,
        description: "Kommanditselskab" /* CopyDK.BUSINESS_TYPE_KS_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.PS,
        businessType: BusinessType.Partnership,
        title: "P/S" /* CopyDK.BUSINESS_TYPE_PS_TITLE */,
        description: "Partnerselskab" /* CopyDK.BUSINESS_TYPE_PS_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.Partsrederi,
        businessType: BusinessType.LimitedCompany,
        title: "Partsrederi" /* CopyDK.BUSINESS_TYPE_PARTSREDERI_TITLE */,
        description: undefined,
    },
];
