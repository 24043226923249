import React from 'react';
import { iZettleMarket } from '@izettle/constants';
import { useLocalization } from '@izettle/lingo-client';
import { getCountryUtils } from 'src/utils/country-helpers';
import { AddressValue } from '../../../AddressValue';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
export const BusinessDetailsSection = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const { getLocalizedBusinessTypeValue } = getCountryUtils(iZettleMarket.GB);
    const registeredAtCompaniesHouseLabel = stepData.isRegisteredAtCompaniesHouse ? '_REGISTERED' : '';
    return (React.createElement(Section, { name: "businessDetails", title: text('OVERVIEW_SECTION_BUSINESS_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: ['businessType'], title: text('OVERVIEW_FIELD_BUSINESS_TYPE_TITLE') },
            React.createElement(Value, null, getLocalizedBusinessTypeValue(stepData, text))),
        React.createElement(Field, { fieldNames: ['businessName'], title: text('OVERVIEW_FIELD_BUSINESS_NAME_TITLE'), editable: true },
            React.createElement(Value, null, stepData.businessName)),
        React.createElement(Field, { fieldNames: ['isRegisteredAtCompaniesHouse'], title: text(`OVERVIEW_FIELD_IS_REGISTERED_AT_COMPANIES_HOUSE_TITLE`), conditionalRendering: true },
            React.createElement(Value, null, stepData.isRegisteredAtCompaniesHouse ? text('OVERVIEW_PAGE_VALUE_YES') : text('OVERVIEW_PAGE_VALUE_NO'))),
        React.createElement(Field, { fieldNames: ['businessLegalEntityNumber'], title: () => text(`OVERVIEW_FIELD_BUSINESS_LEGAL_ENTITY_NUMBER_${stepData.businessType}${registeredAtCompaniesHouseLabel}_TITLE`), editable: true, conditionalRendering: true },
            React.createElement(Value, null, stepData.businessLegalEntityNumber)),
        React.createElement(Field, { fieldNames: [
                'businessBuilding',
                'businessStreetAddress',
                'businessCity',
                'businessCounty',
                'businessPostalCode',
            ], title: text('OVERVIEW_FIELD_BUSINESS_ADDRESS_TITLE'), editable: true },
            React.createElement(AddressValue, { addressData: {
                    building: stepData.businessBuilding,
                    streetAddress: stepData.businessStreetAddress,
                    city: stepData.businessCity,
                    county: stepData.businessCounty,
                    postalCode: stepData.businessPostalCode,
                } }))));
};
