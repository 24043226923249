import { IsoLocale } from '@izettle/constants';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { step, sequence, render, decision } from 'src/schema/steps-builder';
import { nationalityOptions } from '../../common/nationality-options';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { introScreenTemplate, legalPeoplePreparationScreenTemplate, alternativeOwnersListTemplate, directorsListTemplate, normalizeSignatoryFieldsFunction, initLegalPersonsFunction, overviewPageTemplate, } from './common/steps/limited-company';
import { signatoryNationalityTemplate, signatoryPhoneNumberTemplate, signatoryNamePrefilledTemplate, signatoryNameTemplate, signatoryDOBTemplate, } from './common/steps/signatory';
import { getNonProfitRoleLabels } from './data-helpers';
const introScreen = introScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            items: [
                {
                    icon: 'Person',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                },
                {
                    icon: 'Home',
                    title: translate('GAP_NPO_PREPARATION_SCREEN_DETAILS_TITLE'),
                    description: translate('GAP_NPO_PREPARATION_SCREEN_DETAILS_DESCRIPTION'),
                },
                {
                    icon: 'People',
                    title: translate('PREPARATION_SCREEN_DECISION_MAKERS_NON_PROFIT_HEADER'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('GAP_AUTO_VERIFICATION_PREPARATION_SCREEN_ITEM_VERIFICATION_TITLE'),
                    description: translate('GAP_NPO_PREPARATION_SCREEN_ITEM_VERIFICATION_DESCRIPTION'),
                },
            ],
        },
    },
}));
const signatoryAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryStreetAddress', 'signatoryStreetAddressLine2', 'signatoryPostalCode', 'signatoryCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryStreetAddressLine2: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        signatoryStreetAddressLine2: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LINE2_FR_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
});
const signatoryDOB = signatoryDOBTemplate.overrideDeep(() => ({
    UISchema: {
        signatoryDateOfBirth: {
            'ui:options': {
                mask: "99/99/9999" /* CopyFR.DATE_OF_BIRTH_MASK */,
            },
            'ui:placeholder': "JJ/MM/AAAA" /* CopyFR.DATE_OF_BIRTH_PLACEHOLDER */,
        },
    },
}));
const signatoryPhoneNumber = signatoryPhoneNumberTemplate.extendDeep(({ translate }) => ({
    UISchema: {
        signatoryPhoneNumber: {
            'ui:placeholder': translate('FORM_EXAMPLE', ["07 12 34 56 78" /* CopyFR.SIGNATORY_PHONE_NUMBER_EXAMPLE */]),
        },
    },
}));
const businessAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessStreetAddress', 'businessPostalCode', 'businessCity', 'businessStreetAddressLine2'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_LABEL'),
                description: translate('BUSINESS_ADDRESS_FR_NON_PROFIT_INSTRUCTION'),
                type: 'null',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessStreetAddressLine2: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        businessStreetAddressLine2: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LINE2_FR_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
    },
});
const legalPeoplePreparationScreen = legalPeoplePreparationScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            items: [
                {
                    icon: 'People',
                    description: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
            ],
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_NON_PROFIT_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const personStep = (translate) => ({
    ID: 'personStepId',
    backendValidation: true,
    fields: [
        'firstName',
        'lastName',
        'dateOfBirth',
        'nationality',
        'streetAddress',
        'streetAddressLine2',
        'postalCode',
        'city',
    ],
    JSONSchema: {
        type: 'object',
        properties: {
            nameTitle: {
                title: translate('PERSON_NAME_LABEL'),
                type: 'null',
            },
            firstName: {
                type: 'string',
            },
            lastName: {
                type: 'string',
            },
            dateOfBirthTitle: {
                title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                type: 'null',
            },
            dateOfBirth: {
                type: 'string',
            },
            nationalityTitle: {
                title: translate('PERSON_NATIONALITY_LABEL'),
                type: 'null',
            },
            nationality: {
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
            addressTitle: {
                title: translate('PERSON_HOME_ADDRESS_LABEL'),
                type: 'null',
            },
            streetAddress: {
                type: 'string',
            },
            streetAddressLine2: {
                type: 'string',
            },
            postalCode: {
                type: 'string',
            },
            city: {
                type: 'string',
            },
        },
    },
    UISchema: {
        firstName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_ALL_LABEL'),
            },
        },
        lastName: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('LAST_NAME_ALL_LABEL'),
            },
        },
        dateOfBirth: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: "JJ/MM/AAAA" /* CopyFR.DATE_OF_BIRTH_PLACEHOLDER */,
                mask: "99/99/9999" /* CopyFR.DATE_OF_BIRTH_MASK */,
            },
        },
        nationality: {
            'ui:marginBottom': 'medium',
            'ui:title': false,
            'ui:widget': 'CustomComboBoxWidget',
            'ui:emptyValue': '',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
            'ui:options': {
                sortByLabels: 'asc',
            },
        },
        streetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        streetAddressLine2: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LINE2_FR_LABEL'),
            },
        },
        postalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        city: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'newPerson',
    },
});
const alternativeOwnersList = alternativeOwnersListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_NON_PROFIT_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
/* eslint-disable sonarjs/no-identical-functions */
const directorsList = directorsListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        personRoleLabels: getNonProfitRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL'),
            description: translate('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_FR_NON_PROFIT_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const overviewPage = overviewPageTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        version: 'GAP_FR_NPO_COMPANY',
        content: {
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(IsoLocale.frFR)]),
        },
    },
}));
const newSignatorySequence = [
    step(signatoryNameTemplate),
    step(signatoryDOB),
    step(signatoryAddress),
    step(signatoryPhoneNumber),
    step(signatoryNationalityTemplate),
];
const rootSequence = sequence([
    step(introScreen),
    decision('signatoryAction', signatoryNamePrefilledTemplate, [
        { when: SIGNATORY_ACTION.CHANGE_SIGNATORY, branch: sequence(newSignatorySequence) },
        { when: SIGNATORY_ACTION.SET_NATIONALITY, branch: step(signatoryNationalityTemplate) },
    ]),
    step(normalizeSignatoryFieldsFunction),
    step(businessAddress),
    step(legalPeoplePreparationScreen),
    step(initLegalPersonsFunction),
    step(directorsList),
    step(alternativeOwnersList),
    step(overviewPage),
]);
export const GAP_FR_NPO_COMPANY = (context) => render(rootSequence, context);
