import { datadogRum } from '@datadog/browser-rum';
import { SupportEmailAddresses, SupportContacts, SupportLevel, defaultSupportEmailAddress, iZettleMarket, } from '@izettle/constants';
const defaultSupportContactHubUrl = 'https://www.zettle.com/help';
const substitutionsMap = {
    SUPPORT_EMAIL: ({ locale }) => { var _a; return (_a = SupportEmailAddresses.get(locale)) !== null && _a !== void 0 ? _a : defaultSupportEmailAddress; },
    SUPPORT_NUMBER: ({ locale }) => {
        var _a, _b;
        const contact = SupportContacts.get(locale);
        return (_b = (_a = contact === null || contact === void 0 ? void 0 : contact.phoneNumbers.get(SupportLevel.Standard)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : '';
    },
    SUPPORT_CONTACT_HUB_URL: ({ countryId }) => {
        if (countryId === iZettleMarket.US) {
            return 'https://www.paypal.com/us/smarthelp/contact-us'; // To be verified
        }
        if (countryId in iZettleMarket) {
            return `https://www.zettle.com/${countryId.toLowerCase()}/help/contact/options`;
        }
        datadogRum.addError(`No contact hub URL found for market '${countryId}.'`);
        return defaultSupportContactHubUrl;
    },
};
export const createSubstitute = (context) => (substitution) => substitutionsMap[substitution](context);
