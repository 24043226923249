export var NativeBusinessType;
(function (NativeBusinessType) {
    // SelfEmployed
    NativeBusinessType["EK"] = "EK";
    NativeBusinessType["SonstigeEinzelunternehmen"] = "Einzelhandler";
    // LimitedCompany
    NativeBusinessType["GmbH"] = "GmbH";
    NativeBusinessType["UG"] = "UG";
    NativeBusinessType["AG"] = "AG";
    NativeBusinessType["KGaA"] = "KGaA";
    // Partnership
    NativeBusinessType["oHG"] = "oHG";
    NativeBusinessType["KG"] = "KG";
    NativeBusinessType["PartGPartGmbB"] = "PartGPartGmbB";
    NativeBusinessType["GbR"] = "GbR";
    // NonProfit
    NativeBusinessType["Stiftung"] = "Stiftung";
    NativeBusinessType["gGmbH"] = "gGmbH";
    NativeBusinessType["gUG"] = "gUG";
    NativeBusinessType["Verein"] = "Verein";
    NativeBusinessType["EingetragenerVerein"] = "EingetragenerVerein";
    NativeBusinessType["Genossenschaft"] = "Genossenschaft";
    NativeBusinessType["EingetrageneGenossenschaft"] = "EingetrageneGenossenschaft";
})(NativeBusinessType || (NativeBusinessType = {}));
export var BusinessType;
(function (BusinessType) {
    BusinessType["SelfEmployed"] = "SelfEmployed";
    BusinessType["LimitedCompany"] = "LimitedCompany";
    BusinessType["Partnership"] = "Partnership";
    BusinessType["NonProfit"] = "NonProfit";
})(BusinessType || (BusinessType = {}));
export var CompanyRegister;
(function (CompanyRegister) {
    CompanyRegister["NonRegistered"] = "NonRegistered";
    CompanyRegister["Handelsregister"] = "Handelsregister";
    CompanyRegister["Partnerschaftsregister"] = "Partnerschaftsregister";
    CompanyRegister["Vereinsregister"] = "Vereinsregister";
    CompanyRegister["Genossenschaftsregister"] = "Genossenschaftsregister";
})(CompanyRegister || (CompanyRegister = {}));
export const nativeBusinessTypeInfoList = [
    {
        value: NativeBusinessType.EK,
        businessType: BusinessType.SelfEmployed,
        companyRegister: CompanyRegister.Handelsregister,
        title: "Eingetragener Kaufmann" /* Copy.BUSINESS_TYPE_EK_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.SonstigeEinzelunternehmen,
        businessType: BusinessType.SelfEmployed,
        companyRegister: CompanyRegister.NonRegistered,
        title: "Sonstige Einzelunternehmen" /* Copy.BUSINESS_TYPE_SONSTIGE_EINZELUNTERNEHMEN_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.GmbH,
        businessType: BusinessType.LimitedCompany,
        companyRegister: CompanyRegister.Handelsregister,
        title: "GmbH" /* Copy.BUSINESS_TYPE_GMBH_TITLE */,
        description: "Gesellschaft mit beschr\u00E4nkter Haftung" /* Copy.BUSINESS_TYPE_GMBH_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.AG,
        businessType: BusinessType.LimitedCompany,
        companyRegister: CompanyRegister.Handelsregister,
        title: "AG" /* Copy.BUSINESS_TYPE_AG_TITLE */,
        description: "Aktiengesellschaft" /* Copy.BUSINESS_TYPE_AG_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.UG,
        businessType: BusinessType.LimitedCompany,
        companyRegister: CompanyRegister.Handelsregister,
        title: "UG" /* Copy.BUSINESS_TYPE_UG_TITLE */,
        description: "Unternehmergesellschaft" /* Copy.BUSINESS_TYPE_UG_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.KGaA,
        businessType: BusinessType.LimitedCompany,
        companyRegister: CompanyRegister.Handelsregister,
        title: "KGaA" /* Copy.BUSINESS_TYPE_KGAA_TITLE */,
        description: "Kommanditgesellschaft auf Aktien" /* Copy.BUSINESS_TYPE_KGAA_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.oHG,
        businessType: BusinessType.Partnership,
        companyRegister: CompanyRegister.Handelsregister,
        title: "oHG" /* Copy.BUSINESS_TYPE_OHG_TITLE */,
        description: "Offene Handelsgesellschaft" /* Copy.BUSINESS_TYPE_OHG_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.KG,
        businessType: BusinessType.Partnership,
        companyRegister: CompanyRegister.Handelsregister,
        title: "KG" /* Copy.BUSINESS_TYPE_KG_TITLE */,
        description: "Kommanditgesellschaft" /* Copy.BUSINESS_TYPE_KG_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.PartGPartGmbB,
        businessType: BusinessType.Partnership,
        companyRegister: CompanyRegister.Partnerschaftsregister,
        title: "PartG / PartG mbB" /* Copy.BUSINESS_TYPE_PARTGPARTGMBB_TITLE */,
        description: "Partnergesellschaft / Partnergesellschaft mit beschr\u00E4nkter Berufshaftung" /* Copy.BUSINESS_TYPE_PARTGPARTGMBB_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.GbR,
        businessType: BusinessType.Partnership,
        companyRegister: CompanyRegister.NonRegistered,
        title: "GbR" /* Copy.BUSINESS_TYPE_GBR_TITLE */,
        description: "Gesellschaft b\u00FCrgerlichen Rechts" /* Copy.BUSINESS_TYPE_GBR_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.Stiftung,
        businessType: BusinessType.NonProfit,
        companyRegister: CompanyRegister.Handelsregister,
        title: "Stiftung" /* Copy.BUSINESS_TYPE_STIFTUNG_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.gGmbH,
        businessType: BusinessType.NonProfit,
        companyRegister: CompanyRegister.Handelsregister,
        title: "gGmbH" /* Copy.BUSINESS_TYPE_GGMBH_TITLE */,
        description: "Gemeinn\u00FCtzige Gesellschaft mit beschr\u00E4nkter Haftung" /* Copy.BUSINESS_TYPE_GGMBH_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.gUG,
        businessType: BusinessType.NonProfit,
        companyRegister: CompanyRegister.Handelsregister,
        title: "gUG" /* Copy.BUSINESS_TYPE_GUG_TITLE */,
        description: "Gemeinn\u00FCtzige Unternehmergesellschaft" /* Copy.BUSINESS_TYPE_GUG_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.Verein,
        businessType: BusinessType.NonProfit,
        companyRegister: CompanyRegister.NonRegistered,
        title: "Verein" /* Copy.BUSINESS_TYPE_VEREIN_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.EingetragenerVerein,
        businessType: BusinessType.NonProfit,
        companyRegister: CompanyRegister.Vereinsregister,
        title: "Eingetragener Verein" /* Copy.BUSINESS_TYPE_EINGETRAGENERVEREIN_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.Genossenschaft,
        businessType: BusinessType.NonProfit,
        companyRegister: CompanyRegister.NonRegistered,
        title: "Genossenschaft" /* Copy.BUSINESS_TYPE_GENOSSENSCHAFT_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.EingetrageneGenossenschaft,
        businessType: BusinessType.NonProfit,
        companyRegister: CompanyRegister.Genossenschaftsregister,
        title: "Eingetragene Genossenschaft" /* Copy.BUSINESS_TYPE_EINGETRAGENEGENOSSENSCHAFT_TITLE */,
        description: undefined,
    },
];
// fetched from https://secure.izettle.com/api/resources/registration/form/provinces/DE/district-courts
export const districtCourtsData = [
    {
        name: 'BW',
        label: 'Baden-Wuerttemberg',
        districtCourts: [
            { name: 'Aalen', label: 'Aalen' },
            { name: 'Achern', label: 'Achern' },
            { name: 'Adelsheim', label: 'Adelsheim' },
            { name: 'Albstadt', label: 'Albstadt' },
            { name: 'Backnang', label: 'Backnang' },
            { name: 'BadMergentheim', label: 'Bad Mergentheim' },
            { name: 'BadSackingen', label: 'Bad Säckingen' },
            { name: 'BadSaulgau', label: 'Bad Saulgau' },
            { name: 'BadUrach', label: 'Bad Urach' },
            { name: 'BadWaldsee', label: 'Bad Waldsee' },
            { name: 'BadenBaden', label: 'Baden-Baden' },
            { name: 'Balingen', label: 'Balingen' },
            { name: 'Besigheim', label: 'Besigheim' },
            { name: 'Biberach', label: 'Biberach' },
            { name: 'Boblingen', label: 'Böblingen' },
            { name: 'Brackenheim', label: 'Brackenheim' },
            { name: 'BreisachamRhein', label: 'Breisach am Rhein' },
            { name: 'Bretten', label: 'Bretten' },
            { name: 'Bruchsal', label: 'Bruchsal' },
            { name: 'Buchen', label: 'Buchen' },
            { name: 'Buhl', label: 'Bühl' },
            { name: 'Calw', label: 'Calw' },
            { name: 'Crailsheim', label: 'Crailsheim' },
            { name: 'Donaueschingen', label: 'Donaueschingen' },
            { name: 'Ehingen', label: 'Ehingen' },
            { name: 'EllwangenJagst', label: 'Ellwangen (Jagst)' },
            { name: 'Emmendingen', label: 'Emmendingen' },
            { name: 'EsslingenamNeckar', label: 'Esslingen am Neckar' },
            { name: 'Ettenheim', label: 'Ettenheim' },
            { name: 'Ettlingen', label: 'Ettlingen' },
            { name: 'Freiburg', label: 'Freiburg' },
            { name: 'Freudenstadt', label: 'Freudenstadt' },
            { name: 'GeislingenSteige', label: 'Geislingen / Steige' },
            { name: 'Gengenbach', label: 'Gengenbach' },
            { name: 'Gernsbach', label: 'Gernsbach' },
            { name: 'Goppingen', label: 'Göppingen' },
            { name: 'Hechingen', label: 'Hechingen' },
            { name: 'Heidelberg', label: 'Heidelberg' },
            { name: 'Heidenheim', label: 'Heidenheim' },
            { name: 'Heilbronn', label: 'Heilbronn' },
            { name: 'Horb', label: 'Horb' },
            { name: 'Karlsruhe', label: 'Karlsruhe' },
            { name: 'KarlsruheDurlach', label: 'Karlsruhe-Durlach' },
            { name: 'Kehl', label: 'Kehl' },
            { name: 'Kenzingen', label: 'Kenzingen' },
            { name: 'KirchheimunterTeck', label: 'Kirchheim unter Teck' },
            { name: 'Konstanz', label: 'Konstanz' },
            { name: 'Kunzelsau', label: 'Künzelsau' },
            { name: 'Lahr', label: 'Lahr' },
            { name: 'Langenburg', label: 'Langenburg' },
            { name: 'Leonberg', label: 'Leonberg' },
            { name: 'Leutkirch', label: 'Leutkirch' },
            { name: 'Lorrach', label: 'Lörrach' },
            { name: 'Ludwigsburg', label: 'Ludwigsburg' },
            { name: 'Mannheim', label: 'Mannheim' },
            { name: 'Marbach', label: 'Marbach' },
            { name: 'Maulbronn', label: 'Maulbronn' },
            { name: 'Mosbach', label: 'Mosbach' },
            { name: 'Mullheim', label: 'Müllheim' },
            { name: 'Munsingen', label: 'Münsingen' },
            { name: 'Nagold', label: 'Nagold' },
            { name: 'Neresheim', label: 'Neresheim' },
            { name: 'Nurtingen', label: 'Nürtingen' },
            { name: 'Oberkirch', label: 'Oberkirch' },
            { name: 'Oberndorf', label: 'Oberndorf' },
            { name: 'Offenburg', label: 'Offenburg' },
            { name: 'Ohringen', label: 'Öhringen' },
            { name: 'Pforzheim', label: 'Pforzheim' },
            { name: 'Philippsburg', label: 'Philippsburg' },
            { name: 'Radolfzell', label: 'Radolfzell' },
            { name: 'Rastatt', label: 'Rastatt' },
            { name: 'Ravensburg', label: 'Ravensburg' },
            { name: 'Reutlingen', label: 'Reutlingen' },
            { name: 'Riedlingen', label: 'Riedlingen' },
            { name: 'RottenburgamNeckar', label: 'Rottenburg am Neckar' },
            { name: 'Rottweil', label: 'Rottweil' },
            { name: 'SanktBlasien', label: 'Sankt Blasien' },
            { name: 'SchonauimSchwarzwald', label: 'Schönau im Schwarzwald' },
            { name: 'Schopfheim', label: 'Schopfheim' },
            { name: 'Schorndorf', label: 'Schorndorf' },
            { name: 'SchwabischGmund', label: 'Schwäbisch Gmünd' },
            { name: 'SchwabischHall', label: 'Schwäbisch Hall' },
            { name: 'Schwetzingen', label: 'Schwetzingen' },
            { name: 'Sigmaringen', label: 'Sigmaringen' },
            { name: 'SingenHohentwiel', label: 'Singen (Hohentwiel)' },
            { name: 'Sinsheim', label: 'Sinsheim' },
            { name: 'Spaichingen', label: 'Spaichingen' },
            { name: 'StaufeniBr', label: 'Staufen i. Br.' },
            { name: 'Stockach', label: 'Stockach' },
            { name: 'Stuttgart', label: 'Stuttgart' },
            { name: 'Tauberbischofsheim', label: 'Tauberbischofsheim' },
            { name: 'Tettnang', label: 'Tettnang' },
            { name: 'TitiseeNeustadt', label: 'Titisee-Neustadt' },
            { name: 'Tubingen', label: 'Tübingen' },
            { name: 'Tuttlingen', label: 'Tuttlingen' },
            { name: 'Uberlingen', label: 'Überlingen' },
            { name: 'Ulm', label: 'Ulm' },
            { name: 'VaihingenEnz', label: 'Vaihingen / Enz' },
            { name: 'VillingenSchwenningen', label: 'Villingen-Schwenningen' },
            { name: 'Waiblingen', label: 'Waiblingen' },
            { name: 'Waldkirch', label: 'Waldkirch' },
            { name: 'WaldshutTiengen', label: 'Waldshut-Tiengen' },
            { name: 'WangenAllgau', label: 'Wangen / Allgäu' },
            { name: 'Weinheim', label: 'Weinheim' },
            { name: 'Wertheim', label: 'Wertheim' },
            { name: 'Wiesloch', label: 'Wiesloch' },
            { name: 'Wolfach', label: 'Wolfach' },
        ],
    },
    {
        name: 'BY',
        label: 'Bayern',
        districtCourts: [
            { name: 'Amberg', label: 'Amberg' },
            { name: 'Ansbach', label: 'Ansbach' },
            { name: 'Aschaffenburg', label: 'Aschaffenburg' },
            { name: 'Augsburg', label: 'Augsburg' },
            { name: 'Bamberg', label: 'Bamberg' },
            { name: 'Bayreuth', label: 'Bayreuth' },
            { name: 'Coburg', label: 'Coburg' },
            { name: 'Deggendorf', label: 'Deggendorf' },
            { name: 'Furth', label: 'Fürth' },
            { name: 'Hof', label: 'Hof' },
            { name: 'Ingolstadt', label: 'Ingolstadt' },
            { name: 'Kempten', label: 'Kempten ' },
            { name: 'Landshut', label: 'Landshut' },
            { name: 'Memmingen', label: 'Memmingen' },
            { name: 'Munchen', label: 'München' },
            { name: 'Nurnberg', label: 'Nürnberg' },
            { name: 'Passau', label: 'Passau' },
            { name: 'Regensburg', label: 'Regensburg' },
            { name: 'Schweinfurt', label: 'Schweinfurt' },
            { name: 'Straubing', label: 'Straubing' },
            { name: 'Traunstein', label: 'Traunstein' },
            { name: 'WeidenidOPfWeiden', label: 'Weiden i. d. OPf (Weiden)' },
            { name: 'Wurzburg', label: 'Würzburg' },
        ],
    },
    { name: 'BE', label: 'Berlin', districtCourts: [{ name: 'CharlottenburgBerlin', label: 'Charlottenburg (Berlin)' }] },
    {
        name: 'BR',
        label: 'Brandenburg',
        districtCourts: [
            { name: 'Cottbus', label: 'Cottbus' },
            { name: 'FrankfurtOder', label: 'Frankfurt/Oder' },
            { name: 'Neuruppin', label: 'Neuruppin' },
            { name: 'Potsdam', label: 'Potsdam' },
        ],
    },
    {
        name: 'HB',
        label: 'Bremen',
        districtCourts: [
            { name: 'Bremen', label: 'Bremen' },
            { name: 'Bremerhaven', label: 'Bremerhaven' },
        ],
    },
    { name: 'HH', label: 'Hamburg', districtCourts: [{ name: 'Hamburg', label: 'Hamburg' }] },
    {
        name: 'HE',
        label: 'Hessen',
        districtCourts: [
            { name: 'BadHersfeld', label: 'Bad Hersfeld' },
            { name: 'BadHomburg', label: 'Bad Homburg' },
            { name: 'Darmstadt', label: 'Darmstadt' },
            { name: 'Eschwege', label: 'Eschwege' },
            { name: 'FrankfurtamMain', label: 'Frankfurt am Main' },
            { name: 'Friedberg', label: 'Friedberg' },
            { name: 'Fritzlar', label: 'Fritzlar' },
            { name: 'Fulda', label: 'Fulda' },
            { name: 'Giessen', label: 'Gießen' },
            { name: 'Hanau', label: 'Hanau' },
            { name: 'Kassel', label: 'Kassel' },
            { name: 'Konigstein', label: 'Königstein' },
            { name: 'Korbach', label: 'Korbach' },
            { name: 'LimburgadLahn', label: 'Limburg a. d. Lahn' },
            { name: 'Marburg', label: 'Marburg' },
            { name: 'OffenbachamMain', label: 'Offenbach am Main' },
            { name: 'Wetzlar', label: 'Wetzlar' },
            { name: 'Wiesbaden', label: 'Wiesbaden' },
        ],
    },
    {
        name: 'MV',
        label: 'Mecklenburg-Vorpommern',
        districtCourts: [
            { name: 'Anklam', label: 'Anklam' },
            { name: 'BadDoberan', label: 'Bad Doberan' },
            { name: 'BergenaufRugen', label: 'Bergen auf Rügen' },
            { name: 'Demmin', label: 'Demmin' },
            { name: 'Greifswald', label: 'Greifswald' },
            { name: 'Grevesmuhlen', label: 'Grevesmühlen' },
            { name: 'Gustrow', label: 'Güstrow' },
            { name: 'Hagenow', label: 'Hagenow' },
            { name: 'Ludwigslust', label: 'Ludwigslust' },
            { name: 'Neubrandenburg', label: 'Neubrandenburg' },
            { name: 'Neustrelitz', label: 'Neustrelitz' },
            { name: 'Parchim', label: 'Parchim' },
            { name: 'Pasewalk', label: 'Pasewalk' },
            { name: 'RibnitzDamgarten', label: 'Ribnitz-Damgarten' },
            { name: 'Rostock', label: 'Rostock' },
            { name: 'Schwerin', label: 'Schwerin' },
            { name: 'Stralsund', label: 'Stralsund' },
            { name: 'Ueckermunde', label: 'Ueckermünde' },
            { name: 'WarenMuritz', label: 'Waren (Müritz)' },
            { name: 'Wismar', label: 'Wismar' },
            { name: 'Wolgast', label: 'Wolgast' },
        ],
    },
    {
        name: 'NI',
        label: 'Niedersachsen',
        districtCourts: [
            { name: 'Aurich', label: 'Aurich' },
            { name: 'Braunschweig', label: 'Braunschweig' },
            { name: 'Gottingen', label: 'Göttingen' },
            { name: 'Hannover', label: 'Hannover' },
            { name: 'Hildesheim', label: 'Hildesheim' },
            { name: 'Luneburg', label: 'Lüneburg' },
            { name: 'Oldenburg', label: 'Oldenburg' },
            { name: 'Osnabruck', label: 'Osnabrück' },
            { name: 'Stadthagen', label: 'Stadthagen' },
            { name: 'Tostedt', label: 'Tostedt' },
            { name: 'Walsrode', label: 'Walsrode' },
        ],
    },
    {
        name: 'NW',
        label: 'Nordrhein-Westfalen',
        districtCourts: [
            { name: 'Aachen', label: 'Aachen' },
            { name: 'Arnsberg', label: 'Arnsberg' },
            { name: 'BadOeynhausen', label: 'Bad Oeynhausen' },
            { name: 'Bielefeld', label: 'Bielefeld' },
            { name: 'Bochum', label: 'Bochum' },
            { name: 'Bonn', label: 'Bonn' },
            { name: 'Coesfeld', label: 'Coesfeld' },
            { name: 'Delbruck', label: 'Delbrück' },
            { name: 'Dortmund', label: 'Dortmund' },
            { name: 'Duisburg', label: 'Duisburg' },
            { name: 'Duren', label: 'Düren' },
            { name: 'Dusseldorf', label: 'Düsseldorf' },
            { name: 'Essen', label: 'Essen' },
            { name: 'Gelsenkirchen', label: 'Gelsenkirchen' },
            { name: 'Gutersloh', label: 'Gütersloh' },
            { name: 'Hagen', label: 'Hagen' },
            { name: 'Hamm', label: 'Hamm' },
            { name: 'Hattingen', label: 'Hattingen' },
            { name: 'Iserlohn', label: 'Iserlohn' },
            { name: 'Kleve', label: 'Kleve' },
            { name: 'Koln', label: 'Köln' },
            { name: 'Krefeld', label: 'Krefeld' },
            { name: 'Lemgo', label: 'Lemgo' },
            { name: 'Marl', label: 'Marl' },
            { name: 'Moenchengladbach', label: 'Moenchengladbach' },
            { name: 'Munster', label: 'Münster' },
            { name: 'Nettetal', label: 'Nettetal' },
            { name: 'Neuss', label: 'Neuss' },
            { name: 'Paderborn', label: 'Paderborn' },
            { name: 'Recklinghausen', label: 'Recklinghausen' },
            { name: 'Rheine', label: 'Rheine' },
            { name: 'Siegburg', label: 'Siegburg' },
            { name: 'Siegen', label: 'Siegen' },
            { name: 'Steinfurt', label: 'Steinfurt' },
            { name: 'Wuppertal', label: 'Wuppertal' },
        ],
    },
    {
        name: 'RP',
        label: 'Rheinland-Pfalz',
        districtCourts: [
            { name: 'BadKreuznach', label: 'Bad Kreuznach' },
            { name: 'Kaiserslautern', label: 'Kaiserslautern' },
            { name: 'Koblenz', label: 'Koblenz' },
            { name: 'Landau', label: 'Landau' },
            { name: 'Ludwigshafen', label: 'Ludwigshafen' },
            { name: 'Mainz', label: 'Mainz' },
            { name: 'Montabaur', label: 'Montabaur' },
            { name: 'Wittlich', label: 'Wittlich' },
            { name: 'Zweibrucken', label: 'Zweibrücken' },
        ],
    },
    {
        name: 'SL',
        label: 'Saarland',
        districtCourts: [
            { name: 'Homburg', label: 'Homburg' },
            { name: 'Lebach', label: 'Lebach' },
            { name: 'Merzig', label: 'Merzig' },
            { name: 'Neunkirchen', label: 'Neunkirchen' },
            { name: 'Ottweiler', label: 'Ottweiler' },
            { name: 'Saarbrucken', label: 'Saarbrücken' },
            { name: 'Saarlouis', label: 'Saarlouis' },
            { name: 'StIngbert', label: 'St. Ingbert' },
            { name: 'StWendel', label: 'St. Wendel' },
            { name: 'Volklingen', label: 'Völklingen' },
        ],
    },
    {
        name: 'SN',
        label: 'Sachsen',
        districtCourts: [
            { name: 'Annaberg', label: 'Annaberg' },
            { name: 'Aue', label: 'Aue' },
            { name: 'Auerbach', label: 'Auerbach' },
            { name: 'Bautzen', label: 'Bautzen' },
            { name: 'Borna', label: 'Borna' },
            { name: 'Chemnitz', label: 'Chemnitz' },
            { name: 'Dippoldiswalde', label: 'Dippoldiswalde' },
            { name: 'Dobeln', label: 'Döbeln' },
            { name: 'Dresden', label: 'Dresden' },
            { name: 'Eilenburg', label: 'Eilenburg' },
            { name: 'Freiberg', label: 'Freiberg' },
            { name: 'Gorlitz', label: 'Görlitz' },
            { name: 'Grimma', label: 'Grimma' },
            { name: 'Hainichen', label: 'Hainichen' },
            { name: 'HohensteinErnstthal', label: 'Hohenstein-Ernstthal' },
            { name: 'Hoyerswerda', label: 'Hoyerswerda' },
            { name: 'Kamenz', label: 'Kamenz' },
            { name: 'Leipzig', label: 'Leipzig' },
            { name: 'Lobau', label: 'Löbau' },
            { name: 'Marienberg', label: 'Marienberg' },
            { name: 'Meissen', label: 'Meißen' },
            { name: 'Oschatz', label: 'Oschatz' },
            { name: 'Pirna', label: 'Pirna' },
            { name: 'Plauen', label: 'Plauen' },
            { name: 'Riesa', label: 'Riesa' },
            { name: 'Stollberg', label: 'Stollberg' },
            { name: 'Torgau', label: 'Torgau' },
            { name: 'Weisswasser', label: 'Weißwasser' },
            { name: 'Zittau', label: 'Zittau' },
            { name: 'Zwickau', label: 'Zwickau' },
        ],
    },
    { name: 'ST', label: 'Sachsen-Anhalt', districtCourts: [{ name: 'Stendal', label: 'Stendal' }] },
    {
        name: 'SH',
        label: 'Schleswig-Holstein',
        districtCourts: [
            { name: 'Flensburg', label: 'Flensburg' },
            { name: 'Kiel', label: 'Kiel' },
            { name: 'Lubeck', label: 'Lübeck' },
            { name: 'Pinneberg', label: 'Pinneberg' },
        ],
    },
    {
        name: 'TH',
        label: 'Thüringen',
        districtCourts: [
            { name: 'Altenburg', label: 'Altenburg' },
            { name: 'Apolda', label: 'Apolda' },
            { name: 'Arnstadt', label: 'Arnstadt' },
            { name: 'BadSalzungen', label: 'Bad Salzungen' },
            { name: 'Eisenach', label: 'Eisenach' },
            { name: 'Erfurt', label: 'Erfurt' },
            { name: 'Gera', label: 'Gera' },
            { name: 'Gotha', label: 'Gotha' },
            { name: 'Greiz', label: 'Greiz' },
            { name: 'HeilbadHeiligenstadt', label: 'Heilbad Heiligenstadt' },
            { name: 'Hildburghausen', label: 'Hildburghausen' },
            { name: 'Jena', label: 'Jena' },
            { name: 'Meiningen', label: 'Meiningen' },
            { name: 'Muhlhausen', label: 'Mühlhausen' },
            { name: 'MuhlhausenZweigstelleBadLangensalza', label: 'Mühlhausen Zweigstelle Bad Langensalza' },
            { name: 'Nordhausen', label: 'Nordhausen' },
            { name: 'Possneck', label: 'Pössneck' },
            { name: 'PossneckZweigstelleBadLobenstein', label: 'Pössneck Zweigstelle Bad Lobenstein' },
            { name: 'Rudolstadt', label: 'Rudolstadt' },
            { name: 'RudolstadtZweigstelleSaalfeld', label: 'Rudolstadt Zweigstelle Saalfeld' },
            { name: 'Sommerda', label: 'Sömmerda' },
            { name: 'Sondershausen', label: 'Sondershausen' },
            { name: 'Sonneberg', label: 'Sonneberg' },
            { name: 'Stadtroda', label: 'Stadtroda' },
            { name: 'Suhl', label: 'Suhl' },
            { name: 'Weimar', label: 'Weimar' },
            { name: 'ZweigstelleIlmenau', label: 'ZweigstelleIlmenau' },
        ],
    },
];
