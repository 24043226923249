import React, { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { PPUI_THEME_CLASSNAME } from 'src/theme';
export const PayPalUI = () => {
    const [enabled, setPpuiThemeValue] = useLocalStorage('ppui-theme', document.body.classList.contains(PPUI_THEME_CLASSNAME) ? 'true' : 'false') || '';
    useEffect(() => {
        if (enabled === 'true') {
            document.body.classList.add(PPUI_THEME_CLASSNAME);
        }
        else {
            document.body.classList.remove(PPUI_THEME_CLASSNAME);
        }
    }, [enabled]);
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { className: "theme-enable-option", type: "checkbox", checked: enabled === 'true', onChange: (e) => {
                setPpuiThemeValue(e.target.checked ? 'true' : 'false');
            } }),
        React.createElement("span", null, "Use PayPal UI")));
};
