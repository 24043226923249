/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { Checkbox } from '@izettle/react';
import { HelpSection } from 'src/components/Form/components/HelpSection';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { SmallMarginBottom, LargeMarginBottom } from '../../styles';
import { useOverviewPageContextValue } from '../Context';
import { Field } from '../Field';
import { UsaTaxpayerCertification } from './components/TaxpayerCertification/TaxpayerCertification';
import { getTermsTextKeys } from './helpers';
export const UsaTaxpayerSection = ({ onTermsPayloadChange }) => {
    const [isTaxLawCertificationSigned, setIsTaxLawCertificationSigned] = useState(false);
    const { state: { stepData }, } = useQuestionnaireState();
    const { text } = useLocalization();
    const termsTextKeys = getTermsTextKeys(stepData);
    const { isSubmissionInProgress } = useOverviewPageContextValue();
    useEffect(() => {
        onTermsPayloadChange(isTaxLawCertificationSigned
            ? {
                signedUsaTaxLawCertificationKeys: termsTextKeys,
            }
            : null);
    }, [stepData, isTaxLawCertificationSigned]);
    // In this first phase of the project, only US-citizen sole traders will be covered
    if (stepData.businessType !== 'SelfEmployed' || stepData.signatoryNationality !== 'US') {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(LargeMarginBottom, null,
            React.createElement(SmallMarginBottom, null,
                React.createElement(UsaTaxpayerCertification, null)),
            React.createElement(HelpSection, { items: [
                    {
                        title: text('OVERVIEW_HELP_USA_PERSON_TITLE'),
                        body: text('OVERVIEW_HELP_USA_PERSON_BODY'),
                    },
                ] })),
        React.createElement(Field, { fieldNames: ['signedUsaTaxLawCertificationKeys'] },
            React.createElement(Checkbox, { label: text('OVERVIEW_ELECTRONIC_SIGNATURE'), marginBottom: "none", onChange: setIsTaxLawCertificationSigned, checked: isTaxLawCertificationSigned, disabled: isSubmissionInProgress }))));
};
