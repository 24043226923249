import { useCallback } from 'react';
import { useMarkdown } from '@izettle/lingo-client';
import { useSubstitute } from './use-substitute';
const errorSubstitutions = {
    VALIDATION_DUPLICATE_BUSINESS_LEGAL_ENTITY_NUMBER: ['SUPPORT_NUMBER', 'SUPPORT_EMAIL'],
    VALIDATION_DUPLICATE_PERSONAL_LEGAL_ENTITY_NUMBER: ['SUPPORT_NUMBER', 'SUPPORT_EMAIL'],
};
// In the step context we are expected to display a logout button for these errors.
const keysWithLogoutButton = [
    'VALIDATION_DUPLICATE_BUSINESS_LEGAL_ENTITY_NUMBER',
    'VALIDATION_DUPLICATE_PERSONAL_LEGAL_ENTITY_NUMBER',
];
const hasLogoutButton = (errorKey) => {
    return keysWithLogoutButton.includes(errorKey);
};
export const useRenderFieldError = () => {
    const { mdElement } = useMarkdown();
    const { substitute } = useSubstitute();
    const renderError = useCallback((errorKey) => {
        var _a;
        const replacements = (_a = errorSubstitutions[errorKey]) === null || _a === void 0 ? void 0 : _a.map(substitute);
        return mdElement(errorKey, { replacements });
    }, [mdElement, substitute]);
    return { renderError, hasLogoutButton };
};
