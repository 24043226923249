var _a, _b, _c, _d, _e, _f, _g;
import { DefaultPrivacyLevel } from '@datadog/browser-rum';
const getReleaseVersion = () => {
    const releaseVersion = process.env.CIRCLE_SHA1;
    if (releaseVersion === undefined) {
        return '';
    }
    return releaseVersion;
};
const ignoredErrors = [
    /Non-Error exception captured/,
    /Non-Error promise rejection captured/,
    // emitted by UJET v1 when it's not finished loading and window resizes
    /[Loader] Channel Port is yet created. Failed to send this message/,
    // Network errors don't need to be captured
    /Network Error/,
];
const envVar = (value, defaultValue) => {
    return (value !== null && value !== void 0 ? value : defaultValue);
};
export var Environment;
(function (Environment) {
    Environment["Production"] = "production";
    Environment["Staging"] = "staging";
    Environment["Test"] = "test";
    Environment["Development"] = "development";
})(Environment || (Environment = {}));
const appEnv = envVar(process.env.APP_ENV, Environment.Development);
const nodeEnv = envVar(process.env.NODE_ENV, Environment.Development);
export const LOCALHOST_APP_URL = 'https://localhost.zettletest.com:3000/';
export const config = {
    appEnv,
    nodeEnv,
    releaseVersion: getReleaseVersion(),
    applicationUrl: (_a = process.env.APP_URL) !== null && _a !== void 0 ? _a : LOCALHOST_APP_URL,
    portalUrl: (_b = process.env.PORTAL_URL) !== null && _b !== void 0 ? _b : 'https://my.zettletest.com',
    setupUrl: (_c = process.env.SETUP_URL) !== null && _c !== void 0 ? _c : 'https://setup.zettletest.com',
    oauth: {
        clientId: (_d = process.env.IZ_APP_OAUTH_CLIENT_ID) !== null && _d !== void 0 ? _d : '92479452-c433-49ee-8407-8940484c9530',
        domain: (_e = process.env.IZ_APP_OAUTH_DOMAIN) !== null && _e !== void 0 ? _e : 'oauth.zettletest.com',
    },
    api: {
        sun: (_f = process.env.SUN_API_URL) !== null && _f !== void 0 ? _f : 'https://www.izettletest.com/api',
        registration: (_g = process.env.REGISTRATION_API_URL) !== null && _g !== void 0 ? _g : 'https://registration.izettletest.com',
    },
    axios: {
        timeout: 10000,
    },
    datadogRum: {
        applicationId: appEnv === Environment.Production
            ? '9804259b-0e40-44ef-9b61-d629e2a3e2d0'
            : '46362552-3358-4dd2-b6aa-d0ef2186a587',
        clientToken: appEnv === Environment.Production ? 'pub6d4487b4acf02a35414c754a70f6e376' : 'pub10505a6ae44d21e97cda40bc3f2725a8',
        site: 'datadoghq.eu',
        service: 'kyc-client',
        version: getReleaseVersion(),
        env: appEnv,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 10,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
        excludedActivityUrls: [
            /extensions\//i,
            /^chrome:\/\//i,
            // Cookie consent banner
            /cdn\.cookielaw\.org/i,
            // Some Jquery scripts which we don't use
            /assets\/js\/jquery\.js/i,
        ],
        beforeSend: (event) => {
            if (event.type === 'error' && ignoredErrors.some((ignoredError) => ignoredError.test(event.error.message))) {
                return false;
            }
            return true;
        },
    },
    ujet: {
        scriptUrl: 'https://websdk.ujet.co/v2/loader.js',
        authUrl: 'https://ldh3gksmxe.execute-api.eu-west-1.amazonaws.com/',
        config: {
            companyId: '151258987453815914aa6053b75b1a57d',
            host: 'https://izettle.api.ujet.co',
        },
    },
};
export const getPortalDomain = () => new URL(config.portalUrl).hostname;
