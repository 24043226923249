import { pick, uniqWith } from 'ramda';
import { isOverviewPage, canExecuteStep, getCurrentStepObject } from 'src/components/Questionnaire/state/helpers';
import { isFunctionStepObject, } from 'src/components/Questionnaire/types';
const isStepPossiblyReachable = (stepObject, stepData) => {
    const { renderIf } = stepObject;
    if (!renderIf) {
        return true;
    }
    return Object.keys(renderIf).every((property) => {
        if (!(property in stepData)) {
            return true;
        }
        return renderIf[property].includes(stepData[property]);
    });
};
const shouldGroupSteps = (step1, step2) => {
    return Boolean(step2.applicationVariables.stepName === step1.applicationVariables.stepName &&
        step2.applicationVariables.segment &&
        step2.applicationVariables.segment === step1.applicationVariables.segment);
};
const getApplicableStepData = ({ currentStepIndex, stepData, stepList }) => stepList
    .slice(0, currentStepIndex)
    .filter((stepObject) => {
    return canExecuteStep(stepObject, stepData);
})
    .reduce((acc, stepObject) => {
    if (!stepObject.fields) {
        return acc;
    }
    return Object.assign(Object.assign({}, acc), pick(stepObject.fields, stepData));
}, {});
const getApplicableSteps = (questionnaire) => questionnaire.stepList
    .filter((stepObject) => !isFunctionStepObject(stepObject))
    .filter((stepObject) => !isOverviewPage(stepObject))
    .filter((stepObject) => isStepPossiblyReachable(stepObject, getApplicableStepData(questionnaire)));
const getClosestApplicableStepIndex = (stepListStepIndex, stepList, applicableSteps) => stepList.slice(0, stepListStepIndex + 1).reduceRight((resultIndex, step) => {
    if (resultIndex >= 0) {
        return resultIndex;
    }
    return applicableSteps.findIndex((applicableStep) => applicableStep.ID === step.ID);
}, -1);
const getUniqueStepsByStepName = uniqWith(shouldGroupSteps);
export const shouldRenderProgressBar = (questionnaire) => {
    if (questionnaire.editMode) {
        return false;
    }
    return getApplicableSteps(questionnaire).length > 0;
};
export const getProgress = (questionnaire) => {
    const currentStep = getCurrentStepObject(questionnaire);
    if (isOverviewPage(currentStep)) {
        return {
            status: 'overview',
        };
    }
    if (questionnaire.editMode) {
        return {
            status: 'editMode',
        };
    }
    const applicableSteps = getApplicableSteps(questionnaire);
    const applicableStepIndex = getClosestApplicableStepIndex(questionnaire.currentStepIndex, questionnaire.stepList, applicableSteps);
    if (applicableStepIndex === -1) {
        // no applicable step found, therefore we haven't yet progressed through them
        return {
            status: 'step',
            completedSteps: 0,
            totalSteps: applicableSteps.length,
        };
    }
    const stepsBeforeCurrent = getUniqueStepsByStepName(applicableSteps.slice(0, applicableStepIndex));
    const nextSteps = getUniqueStepsByStepName(applicableSteps.slice(applicableStepIndex));
    return {
        status: 'step',
        completedSteps: stepsBeforeCurrent.length,
        totalSteps: stepsBeforeCurrent.length + nextSteps.length,
    };
};
export const getProgressPercentage = (questionnaire) => {
    const progress = getProgress(questionnaire);
    if (progress.status === 'overview' || progress.status === 'editMode') {
        return 100;
    }
    return Math.ceil((progress.completedSteps * 100) / progress.totalSteps);
};
