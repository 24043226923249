import React from 'react';
import styled from 'styled-components';
import { size, spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
import { breakpoints } from 'src/theme';
const leftButtonAreaName = 'left-button';
const childrenAreaName = 'children';
const rightSideAreaName = 'right-side'; // not used at the moment
const HeaderContainer = styled.header `
  display: grid;
  grid-template-columns: ${size.small} auto ${size.small};
  grid-template-areas: '${leftButtonAreaName} ${childrenAreaName} ${rightSideAreaName}';
  grid-gap: ${spacing.xxSmall};

  min-height: ${size.xSmall};
  align-items: center;

  margin-bottom: ${spacing.large};
  @media (max-width: ${breakpoints.tiny}) {
    margin-bottom: ${spacing.small};
  }

  &:empty {
    display: none;
  }
`;
const LeftButtonArea = styled.div `
  grid-area: ${leftButtonAreaName};
`;
const ChildrenArea = styled.div `
  grid-area: ${childrenAreaName};
`;
const HeaderText = styled(Text.RegularBold).attrs({
    className: 'override-text',
}) `
  text-align: center;
`;
export const Header = ({ leftButton, children }) => {
    return (React.createElement(HeaderContainer, null,
        leftButton ? React.createElement(LeftButtonArea, null, leftButton) : null,
        children ? (React.createElement(ChildrenArea, null, typeof children === 'string' ? React.createElement(HeaderText, null, children) : children)) : null));
};
