import React, { useEffect, useState } from 'react';
import { getTextDirection } from './helpers';
/**
 * A container that must be a direct child of a scrollable element wrapping actual content.
 * Ensures consistent padding around the content no matter if scrollbar is visible or not.
 */
export const ScrollbarAwarePadding = ({ className, paddingLeft, paddingRight, children, }) => {
    // prefer `useState` over `useRef` to ensure ResizeObserver is disconnected if the element ref changes
    const [paddingRef, setPaddingRef] = useState(null);
    const [childrenRef, setChildrenRef] = useState(null);
    useEffect(() => {
        const scrollableRef = paddingRef === null || paddingRef === void 0 ? void 0 : paddingRef.parentElement;
        if (!paddingRef || !scrollableRef || !childrenRef) {
            return;
        }
        if (typeof ResizeObserver === 'undefined') {
            // browser is too old, fallback for simple behavior
            paddingRef.style.paddingLeft = paddingLeft;
            paddingRef.style.paddingRight = paddingRight;
            return;
        }
        const textDirection = getTextDirection(scrollableRef);
        const resizeObserver = new ResizeObserver(() => {
            const outerWidth = scrollableRef.getBoundingClientRect().width;
            const scrollbarOnRight = textDirection === 'ltr';
            const scrollbarWidth = outerWidth - scrollableRef.clientWidth;
            window.requestAnimationFrame(() => {
                // DOM updates should not happen in observer's callback
                childrenRef.style.width = `calc(${outerWidth}px - ${paddingLeft} - ${paddingRight})`;
                paddingRef.style.paddingLeft = `calc(${paddingLeft} - ${scrollbarOnRight ? 0 : scrollbarWidth}px)`;
                paddingRef.style.paddingRight = `calc(${paddingRight} - ${scrollbarOnRight ? scrollbarWidth : 0}px)`;
            });
        });
        resizeObserver.observe(paddingRef);
        return () => {
            resizeObserver.disconnect();
        };
    }, [paddingLeft, paddingRight, paddingRef, childrenRef]);
    return (React.createElement("div", { ref: setPaddingRef, className: className },
        React.createElement("div", { ref: setChildrenRef }, children)));
};
