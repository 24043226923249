import React from 'react';
import styled from 'styled-components';
import { colors, spacing, typography } from '@izettle/otto';
import { Text, Icon, Button } from '@izettle/react';
import { HelpSection } from 'src/components/Form/components/HelpSection';
import { InfoBox } from 'src/components/InfoBox';
import { getSvgIcon } from 'src/components/Questionnaire/icons';
import { useThemeInfo } from 'src/hooks/use-theme-info';
import { flexItemTextWrapFix } from 'src/styles/mixins';
import { breakpoints } from 'src/theme';
const BodyItemContainer = styled.div `
  margin-bottom: ${spacing.medium};

  &:last-child {
    margin-bottom: ${spacing.none};
  }
`;
const BodyItemUnorderedList = styled.ul `
  margin: ${spacing.none};
  padding: ${spacing.none};
`;
const ListItemWithIconContainer = styled.li `
  display: flex;
  margin-bottom: ${spacing.small};

  &:last-child {
    margin-bottom: ${spacing.none};
  }
`;
const StyledIcon = styled(Icon) `
  font-size: 20px;
  color: ${(props) => (props.paypaltheme ? colors.icon.default : colors.icon.highlight)};
  margin-right: ${spacing.xxSmall};

  @media (max-width: ${breakpoints.tiny}) {
    display: none;
  }
`;
const ListItemWithIconDescription = styled.div `
  ${flexItemTextWrapFix}
  align-self: center;
`;
const HelpSectionContainer = styled.div `
  margin-top: ${spacing.large};
`;
const ButtonContainer = styled.div `
  margin-top: ${spacing.large};
`;
const StyledButton = styled(Button) `
  span span {
    ${typography.regular}
    color: inherit
  }
`;
const ListItemWithIcon = ({ icon, description, paypaltheme = false }) => {
    return (React.createElement(ListItemWithIconContainer, null,
        React.createElement(StyledIcon, { alt: "", svgIcon: getSvgIcon(icon), paypaltheme: paypaltheme }),
        React.createElement(ListItemWithIconDescription, null,
            React.createElement(Text.Regular, { marginTop: "none", marginBottom: "none" }, description))));
};
const BodyItemList = ({ bodyItem: { header, items }, index, paypaltheme, }) => {
    const headerId = `infoBoxList_${index}_header`;
    return (React.createElement(BodyItemContainer, null,
        header && (React.createElement(Text.RegularBold, { id: headerId, marginTop: "none" }, header)),
        React.createElement(BodyItemUnorderedList, Object.assign({}, (header ? { 'aria-labelledby': headerId } : {})), items.map((item) => {
            return React.createElement(ListItemWithIcon, Object.assign({ key: `${item.title}${item.description}` }, item, { paypaltheme: paypaltheme }));
        }))));
};
const BodyItemText = ({ bodyItem: { content } }) => {
    return (React.createElement(BodyItemContainer, null,
        React.createElement(Text.Regular, { marginTop: "none", marginBottom: "none" }, content)));
};
const BodyItem = ({ bodyItem, index, paypaltheme, }) => {
    switch (bodyItem.type) {
        case 'list':
            return React.createElement(BodyItemList, { bodyItem: bodyItem, index: index, paypaltheme: paypaltheme });
        case 'text':
            return React.createElement(BodyItemText, { bodyItem: bodyItem });
        default:
            throw new Error('Unexpected body item type in InfoBoxScreen body');
            break;
    }
};
export const InfoBoxScreen = ({ stepObject, nextStep }) => {
    const { header: contentHeader, description, button, body, helpTexts } = stepObject.applicationVariables.content;
    const { paypalTheme } = useThemeInfo();
    return (React.createElement(React.Fragment, null,
        React.createElement(Text.LargeBold, { id: "infoBoxScreenContentHeader", marginTop: "none" }, contentHeader),
        description && React.createElement(Text.RegularSecondary, { marginTop: "none" }, description),
        React.createElement(InfoBox, { role: "group", "aria-labelledby": "infoBoxScreenContentHeader" }, body.map((bodyItem, index) => {
            return (React.createElement(BodyItem, { key: JSON.stringify(bodyItem), bodyItem: bodyItem, index: index, paypaltheme: paypalTheme }));
        })),
        helpTexts && (React.createElement(HelpSectionContainer, null,
            React.createElement(HelpSection, { items: helpTexts }))),
        React.createElement(ButtonContainer, null,
            React.createElement(StyledButton, { id: "continueBtn", variant: "primary", fullWidth: true, onClick: nextStep }, button))));
};
