import { NativeBusinessType, BusinessType } from 'src/utils/country/FI/data';
import { generateIntFromInterval } from '../../helpers';
const calculateCheckSum = (baseNumber) => {
    const multipliers = [7, 9, 10, 5, 8, 4, 2];
    let sum = 0;
    for (let i = 0; i < baseNumber.length; i++) {
        sum += Number(baseNumber[i]) * multipliers[i];
    }
    const remainder = sum % 11;
    // This case is not expected. According to the references, remainder can never be 1.
    if (remainder === 1) {
        return null;
    }
    if (remainder === 0) {
        return 0;
    }
    return 11 - remainder;
};
export const generateOrganizationNumber = () => {
    const baseNumber = generateIntFromInterval(1, 9999999, 7);
    const checkSum = calculateCheckSum(baseNumber);
    if (checkSum === null) {
        return generateOrganizationNumber();
    }
    return `${baseNumber}-${checkSum}`;
};
export const generateSocialSecurityNumber = () => {
    const dateOfBirth = [
        generateIntFromInterval(1, 28, 2),
        generateIntFromInterval(1, 12, 2),
        generateIntFromInterval(60, 99, 2), // year
    ].join('');
    const centuryIdentifier = '-';
    const individualNumber = generateIntFromInterval(2, 899, 3);
    const checkMarkBase = Number(`${dateOfBirth}${individualNumber}`);
    const remainder = checkMarkBase % 31;
    const checkSum = '0123456789ABCDEFHJKLMNPRSTUVWXY'.charAt(remainder);
    return `${dateOfBirth}${centuryIdentifier}${individualNumber}${checkSum}`;
};
export const generateCommonData = (formContext) => {
    var _a;
    const businessCategories = (_a = formContext.additionalData) === null || _a === void 0 ? void 0 : _a.businessCategories;
    if (!businessCategories) {
        throw new Error('Categories not found in form context');
    }
    const businessCategory = businessCategories[0];
    const businessSubCategory = businessCategory.children[0];
    return {
        businessLegalEntityNumber: generateOrganizationNumber(),
        businessName: 'Theatre Audition OY',
        businessAddressCo: 'c/o Carita Pasanen',
        businessStreetAddress: 'Vanhamaantie 85',
        businessPostalCode: '48300',
        businessCity: 'KOTKA',
        signatoryFirstName: 'Jonna',
        signatoryLastName: 'Johansson',
        signatoryLegalEntityNumber: '260497-734P',
        signatoryNationality: 'US',
        isSignatoryShareAddress: true,
        signatoryPhoneNumber: '416208833',
        businessCategoryId: businessCategory.code.toString(),
        businessSubCategoryId: businessSubCategory.code.toString(),
        businessLocationTypes: ['PHYSICAL_STORE', 'TEMPORARY_STORE'],
        businessFrequency: 'FEW_TIMES_A_MONTH',
        businessAverageTransaction: '0-50',
        businessIzettleShareOfTurnover: '20-80%',
        businessCurrentAnnualTurnover: '200000-1000000',
    };
};
const generatePersonData = () => ({
    firstName: 'Olli',
    lastName: 'Kiprusoff',
    legalEntityNumber: '190650-265J',
    nationality: 'FI',
    streetAddress: 'Mechelininkatu 26',
    postalCode: '41660',
    city: 'Toivakka',
});
const generateLimitedCompanyData = () => ({
    businessType: BusinessType.LimitedCompany,
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generatePartnershipData = () => ({
    businessType: BusinessType.Partnership,
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    },
    rolePartners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateNonProfitData = () => ({
    businessType: BusinessType.NonProfit,
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonData(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    roleAlternativeOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateTmi = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), { nativeBusinessType: NativeBusinessType.Tmi, businessType: BusinessType.SelfEmployed }), (formContext.form.endsWith('1099K') ? { signatoryUnderUsaTaxLaw: false } : false)));
export const generateOy = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), generateLimitedCompanyData()), { nativeBusinessType: NativeBusinessType.Oy }));
export const generateOyj = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), generateLimitedCompanyData()), { nativeBusinessType: NativeBusinessType.Oyj }));
export const generateRy = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), generateNonProfitData()), { nativeBusinessType: NativeBusinessType.Ry }));
export const generateRs = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), generateNonProfitData()), { nativeBusinessType: NativeBusinessType.Rs }));
export const generateKunta = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), generateNonProfitData()), { nativeBusinessType: NativeBusinessType.Kunta }));
export const generateAy = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), generatePartnershipData()), { nativeBusinessType: NativeBusinessType.Ay }));
export const generateKy = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), generatePartnershipData()), { nativeBusinessType: NativeBusinessType.Ky }));
export const generateOsk = (formContext) => (Object.assign(Object.assign(Object.assign({}, generateCommonData(formContext)), generatePartnershipData()), { nativeBusinessType: NativeBusinessType.Osk }));
export const FI = [
    {
        title: NativeBusinessType.Tmi,
        generateFunc: generateTmi,
    },
    {
        title: NativeBusinessType.Oy,
        generateFunc: generateOy,
    },
    {
        title: NativeBusinessType.Oyj,
        generateFunc: generateOyj,
    },
    {
        title: NativeBusinessType.Ry,
        generateFunc: generateRy,
    },
    {
        title: NativeBusinessType.Rs,
        generateFunc: generateRs,
    },
    {
        title: NativeBusinessType.Kunta,
        generateFunc: generateKunta,
    },
    {
        title: NativeBusinessType.Ay,
        generateFunc: generateAy,
    },
    {
        title: NativeBusinessType.Ky,
        generateFunc: generateKy,
    },
    {
        title: NativeBusinessType.Osk,
        generateFunc: generateOsk,
    },
];
