import React from 'react';
import { FieldContext } from './context';
import { FieldErrorList } from './error-list';
import { getDescriptionId, getErrorListId } from './helpers';
import { getFieldTemplateComponent, getFieldTitleAndDescription } from './styles';
export const FieldTemplate = (props) => {
    const { help, rawErrors, children, id } = props;
    const FieldTemplateComponent = getFieldTemplateComponent(props);
    const { title, description } = getFieldTitleAndDescription(props);
    return (React.createElement(FieldTemplateComponent, { title: title, description: description, inputFieldId: id },
        React.createElement(FieldContext.Provider, { value: {
                hasError: rawErrors ? rawErrors.length !== 0 : false,
                errorElementId: (rawErrors === null || rawErrors === void 0 ? void 0 : rawErrors.length) ? getErrorListId(id) : undefined,
                descriptionElementId: description ? getDescriptionId(id) : undefined,
            } }, children),
        React.createElement(FieldErrorList, { id: getErrorListId(id), rawErrors: rawErrors }),
        help));
};
