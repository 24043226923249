export var BusinessTypeValue;
(function (BusinessTypeValue) {
    BusinessTypeValue["SelfEmployed"] = "SelfEmployed";
    BusinessTypeValue["LimitedCompany"] = "LimitedCompany";
    BusinessTypeValue["Partnership"] = "Partnership";
    BusinessTypeValue["NonProfit"] = "NonProfit";
})(BusinessTypeValue || (BusinessTypeValue = {}));
export var NativeBusinessType;
(function (NativeBusinessType) {
    NativeBusinessType["ENK"] = "ENK";
    NativeBusinessType["Aksjeselskap"] = "Aksjeselskap";
    NativeBusinessType["AnsvarligSelskap"] = "AnsvarligSelskap";
    NativeBusinessType["Kommandittselskap"] = "Kommandittselskap";
    NativeBusinessType["Samvirkeforetag"] = "Samvirkeforetag";
    NativeBusinessType["IdeellOrganisasjon"] = "IdeellOrganisasjon";
    NativeBusinessType["Kommune"] = "Kommune";
    NativeBusinessType["NUF"] = "NUF";
})(NativeBusinessType || (NativeBusinessType = {}));
