import styled, { css } from 'styled-components';
import { colors } from '@izettle/otto';
import { breakpoints } from '../../theme';
export const gridDesktop = () => css `
  grid-template-columns: repeat(12, 1fr [col-start]);
`;
export const gridTablet = () => css `
  grid-template-columns: repeat(8, 1fr [col-start]);
`;
export const gridMobile = () => css `
  grid-template-columns: repeat(6, 1fr [col-start]);
`;
export const Grid = styled.div `
  display: grid;
  margin: 0 auto;
  grid-row-gap: 0;
  background: ${colors.background.default};
  ${gridMobile()};
  ${({ gap }) => ((gap === null || gap === void 0 ? void 0 : gap.mobile) ? `grid-column-gap: ${gap.mobile};` : ``)}
  ${({ padding }) => ((padding === null || padding === void 0 ? void 0 : padding.mobile) ? `padding: ${padding.mobile};` : ``)}

  @media (min-width: ${breakpoints.tablet}) {
    ${gridTablet()};
    ${({ gap }) => ((gap === null || gap === void 0 ? void 0 : gap.tablet) ? `grid-column-gap: ${gap.tablet};` : ``)}
    ${({ padding }) => ((padding === null || padding === void 0 ? void 0 : padding.tablet) ? `padding: ${padding.tablet};` : ``)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${gridDesktop()};
    ${({ gap }) => ((gap === null || gap === void 0 ? void 0 : gap.desktop) ? `grid-column-gap: ${gap.desktop};` : ``)}
    ${({ padding }) => ((padding === null || padding === void 0 ? void 0 : padding.desktop) ? `padding: ${padding.desktop};` : ``)}
  }
`;
export const GridColumn = styled.div `
  ${({ columnWidth }) => ((columnWidth === null || columnWidth === void 0 ? void 0 : columnWidth.mobile) ? `grid-column: ${columnWidth.mobile};` : ``)}

  @media (min-width: ${breakpoints.tablet}) {
    ${({ columnWidth }) => ((columnWidth === null || columnWidth === void 0 ? void 0 : columnWidth.tablet) ? `grid-column: ${columnWidth.tablet};` : ``)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${({ columnWidth }) => ((columnWidth === null || columnWidth === void 0 ? void 0 : columnWidth.desktop) ? `grid-column: ${columnWidth.desktop};` : ``)}
  }
`;
