import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
const renderAddressValue = ({ streetAddress, postalCode, city }) => (React.createElement(React.Fragment, null,
    React.createElement(Value, null, streetAddress),
    React.createElement(Value, null, `${postalCode} ${city}`)));
export const GAP_NO_ST_COMPANY = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(Section, { name: "personalDetails", title: text('OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE'), editable: true, conditionalRendering: true }, renderAddressValue({
            streetAddress: stepData.signatoryStreetAddress,
            postalCode: stepData.signatoryPostalCode,
            city: stepData.signatoryCity,
        })),
        React.createElement(Field, { fieldNames: ['signatoryNationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE'), editable: true }, stepData.signatoryNationality && React.createElement(Value, null, text(`NATIONALITY_VALUE_${stepData.signatoryNationality}`)))));
};
