import { FunctionStepFuncKey } from 'src/components/Questionnaire/types';
import { initLegalPersons } from './common/functions';
import { GAP_newLegalPeopleDefaultValueFunction, GAP_omitLegalPeoplePropsFunction, GAP_readonlyLegalPeoplePropsFunction, GAP_normalizeSignatoryFieldsFunction, } from './gaps/LUX/functions';
import { DE_businessTypeFunction } from './onboarding/DE/functions';
import { DK_businessTypeFunction } from './onboarding/DK/functions';
import { ES_businessTypeFunction } from './onboarding/ES/functions';
import { FI_businessTypeFunction } from './onboarding/FI/functions';
import { FR_businessTypeFunction } from './onboarding/FR/functions';
import { IT_businessTypeFunction } from './onboarding/IT/functions';
import { MX_businessTypeFunction } from './onboarding/MX/functions';
import { NO_businessTypeFunction } from './onboarding/NO/functions';
export const functionsMap = {
    [FunctionStepFuncKey.initLegalPersons]: initLegalPersons,
    [FunctionStepFuncKey.FR_businessType]: FR_businessTypeFunction,
    [FunctionStepFuncKey.NO_businessType]: NO_businessTypeFunction,
    [FunctionStepFuncKey.FI_businessType]: FI_businessTypeFunction,
    [FunctionStepFuncKey.DK_businessType]: DK_businessTypeFunction,
    [FunctionStepFuncKey.DE_businessType]: DE_businessTypeFunction,
    [FunctionStepFuncKey.ES_businessType]: ES_businessTypeFunction,
    [FunctionStepFuncKey.IT_businessType]: IT_businessTypeFunction,
    [FunctionStepFuncKey.MX_businessType]: MX_businessTypeFunction,
    [FunctionStepFuncKey.GAP_newLegalPeopleDefaultValue]: GAP_newLegalPeopleDefaultValueFunction,
    [FunctionStepFuncKey.GAP_omitLegalPeopleProps]: GAP_omitLegalPeoplePropsFunction,
    [FunctionStepFuncKey.GAP_readonlyLegalPeopleProps]: GAP_readonlyLegalPeoplePropsFunction,
    [FunctionStepFuncKey.GAP_normalizeSignatoryFields]: GAP_normalizeSignatoryFieldsFunction,
};
