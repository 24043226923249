import React, { useEffect } from 'react';
import { GlobalOttoStyles } from '@izettle/react';
import { disableBrowserBackButton, hideMobileKeyboardOnNonInputTouch } from '../../helpers';
import { AuthProvider } from '../../providers/auth';
import { FormContextProvider } from '../../providers/form';
import { GlobalStyle } from '../../styles/GlobalStyle';
import { ErrorBoundary } from '../ErrorBoundary';
import { AppContent } from './Content';
import { AppLayoutGrid } from './LayoutGrid';
import { ChatSupportWidget } from './components/ChatSupportWidget';
import { PageTitleForScreenReader, PageTitleProvider } from './components/PageTitle';
import { AppTranslationsProvider } from './components/TranslationsProvider';
export const App = () => {
    useEffect(hideMobileKeyboardOnNonInputTouch, []);
    useEffect(disableBrowserBackButton, []);
    return (React.createElement(ErrorBoundary, null,
        React.createElement(React.Fragment, null,
            React.createElement(GlobalOttoStyles, null),
            React.createElement(GlobalStyle, null),
            React.createElement(AuthProvider, null,
                React.createElement(FormContextProvider, null,
                    React.createElement(AppTranslationsProvider, null,
                        React.createElement(PageTitleProvider, null,
                            React.createElement(AppLayoutGrid, null,
                                React.createElement(PageTitleForScreenReader, null),
                                React.createElement(AppContent, null),
                                React.createElement(ChatSupportWidget, null)))))))));
};
