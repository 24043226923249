import { useEffect } from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useSetPageTitle } from 'src/components/App/components/PageTitle';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { getProgress } from '../helpers/progress';
export const usePageTitleForStepEffect = () => {
    const { state } = useQuestionnaireState();
    const { setPageTitle } = useSetPageTitle();
    const { text } = useLocalization();
    useEffect(() => {
        const progress = getProgress(state);
        if (progress.status === 'step' && progress.completedSteps > 0) {
            setPageTitle(text('QUESTIONNAIRE_STEP_PAGE_TITLE', [String(progress.completedSteps + 1), String(progress.totalSteps)]));
        }
        if (progress.status === 'overview') {
            setPageTitle(text('OVERVIEW_TITLE'));
        }
        if (progress.status === 'editMode') {
            setPageTitle(text('QUESTIONNAIRE_EDIT_MODE_PAGE_TITLE'));
        }
    }, [state]);
};
