import { BusinessType, NativeBusinessType } from 'src/utils/country/ES/data';
import { getNativeBusinessTypeInfo } from 'src/utils/country/ES/data-helpers';
import { generateDigitSequence, generateRandomLetter, sumNumberDigits } from '../../helpers';
/**
 * Generates a valid CIF number in Spain.
 * Example: A58818501
 * Implementation based on https://es.wikipedia.org/wiki/C%C3%B3digo_de_identificaci%C3%B3n_fiscal
 */
export const generateCIF = () => {
    const entityKeys = 'ABCDEFGHJNPQRSUVW';
    const entityKey = generateRandomLetter(entityKeys);
    const provinceCode = '08'; // there are many other possible codes, using a hardcoded one for simplicity
    const registrationNumber = generateDigitSequence(5);
    const checkSumBaseNumber = `${provinceCode}${registrationNumber}`;
    let evenSum = 0;
    let oddSum = 0;
    checkSumBaseNumber
        .split('')
        .map(Number)
        .forEach((value, currentIndex) => {
        if ((currentIndex + 1) % 2 !== 0) {
            oddSum += sumNumberDigits(value * 2);
        }
        else {
            evenSum += value;
        }
    }, 0);
    const controlDigitBase = (evenSum + oddSum) % 10;
    const controlDigitNumber = controlDigitBase !== 0 ? 10 - controlDigitBase : 0;
    const checkDigitIsNumeric = ['A', 'B', 'E', 'H'].includes(entityKey);
    const checkDigit = checkDigitIsNumeric ? controlDigitNumber : 'JABCDEFGHI'[controlDigitNumber];
    return `${entityKey}${checkSumBaseNumber}${checkDigit}`;
};
/**
 * Generates a valid NIF number in Spain.
 * Example: 36653712S
 * Implementation based on https://en.wikipedia.org/wiki/Documento_Nacional_de_Identidad_(Spain)
 */
export const generateNIF = () => {
    const securityLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const randomNumber = generateDigitSequence(8);
    const remainder = randomNumber % 23;
    const checkSum = securityLetters[remainder];
    return `${randomNumber}${checkSum}`;
};
export const generateBusinessCategoryFields = (formContext) => {
    var _a;
    const businessCategories = (_a = formContext.additionalData) === null || _a === void 0 ? void 0 : _a.businessCategories;
    if (!businessCategories) {
        throw new Error('Categories not found in form context');
    }
    return {
        businessCategoryId: businessCategories[0].code.toString(),
        businessSubCategoryId: businessCategories[0].children[0].code.toString(),
    };
};
const generatePersonFields = () => ({
    firstName: 'Iair',
    lastName: 'Bernal',
    dateOfBirth: '23/05/2003',
    legalEntityNumber: generateNIF(),
    nationality: 'ES',
    streetAddress: 'C. de Botoneras, 6',
    postalCode: '28012',
    city: 'Madrid',
});
const generateOwnerStructureFieldsLimitedCompany = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateOwnerStructureFieldsPartnership = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    rolePartners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateOwnerStructureFieldsNonProfit = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    roleAlternativeOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const ownerStructureFieldsGenerators = {
    [BusinessType.LimitedCompany]: generateOwnerStructureFieldsLimitedCompany,
    [BusinessType.Partnership]: generateOwnerStructureFieldsPartnership,
    [BusinessType.NonProfit]: generateOwnerStructureFieldsNonProfit,
    [BusinessType.SelfEmployed]: () => null,
};
const generateSoleTraderData = (commonData) => (Object.assign(Object.assign({}, commonData), { signatoryStreetAddress: 'Cercas Bajas 63', signatoryPostalCode: '08181', signatoryCity: 'Barcelona' }));
export const generateNonSoleTraderData = (businessType, commonData) => (Object.assign(Object.assign(Object.assign({}, commonData), { businessLegalEntityNumber: generateCIF(), businessName: 'Mosca Espanola', businessStreetAddress: 'Cercas Bajas 63', businessPostalCode: '08181', businessCity: 'Barcelona', isSignatoryShareAddress: true }), ownerStructureFieldsGenerators[businessType]()));
const createGenerateFunc = (nativeBusinessType) => {
    const { businessType } = getNativeBusinessTypeInfo(nativeBusinessType);
    return (formContext) => {
        const commonData = Object.assign(Object.assign(Object.assign(Object.assign({ businessType,
            nativeBusinessType, signatoryFirstName: 'Yulan', signatoryLastName: 'Regalado', signatoryDateOfBirth: '23/10/1999', signatoryLegalEntityNumber: generateNIF(), signatoryNationality: 'US' }, (businessType === 'SelfEmployed' && formContext.form.endsWith('ES_1099K')
            ? { signatoryUnderUsaTaxLaw: false }
            : false)), { signatoryPhoneNumber: '+34915212900' }), generateBusinessCategoryFields(formContext)), { businessLocationTypes: ['PHYSICAL_STORE', 'TEMPORARY_STORE'], businessFrequency: 'FEW_TIMES_A_MONTH', businessAverageTransaction: '0-50', businessIzettleShareOfTurnover: '20-80%', businessCurrentAnnualTurnover: '20000-200000' });
        return businessType === BusinessType.SelfEmployed
            ? generateSoleTraderData(commonData)
            : generateNonSoleTraderData(businessType, commonData);
    };
};
export const ES = Object.values(NativeBusinessType).map((nativeBusinessType) => ({
    title: `${nativeBusinessType} (${getNativeBusinessTypeInfo(nativeBusinessType).businessType})`,
    generateFunc: createGenerateFunc(nativeBusinessType),
}));
