import React from 'react';
import styled from 'styled-components';
import { colors, spacing, spacingNumeric, typography } from '@izettle/otto';
const SectionContainer = styled.div `
  position: relative;
  margin-bottom: ${spacing.xxSmall};

  &:not(:first-child) {
    margin-top: ${spacing.tiny};
  }

  &:last-child {
    margin-bottom: ${spacing.none};
  }
`;
const SectionHeader = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.xxSmall};
`;
const HeaderText = styled.h5 `
  flex-shrink: ${spacingNumeric.none};
  margin: ${spacing.none};
  padding: ${spacing.none};
  ${typography.mini};
`;
const HeaderSeparator = styled.span `
  margin: ${spacingNumeric.none} ${spacing.xxSmall};
  width: 100%;
  border-bottom: 1px solid ${colors.background.inverted};

  &:last-child {
    margin-right: ${spacingNumeric.none};
  }
`;
const SectionContent = styled.div `
  padding-bottom: ${spacing.xxSmall};

  &:last-child {
    padding-bottom: ${spacing.none};
  }
`;
export const Section = ({ title, children }) => {
    return (React.createElement(SectionContainer, null,
        React.createElement(SectionHeader, null,
            React.createElement(HeaderText, null, title),
            React.createElement(HeaderSeparator, null)),
        React.createElement(SectionContent, null, children)));
};
