import React from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
import { OwnerStructureSection } from '../../shared/OwnerStructureSection';
const IntroContainer = styled.div `
  margin-bottom: ${spacing.small};
`;
const renderAddressValue = ({ streetAddress, postalCode, city }) => (React.createElement(React.Fragment, null,
    React.createElement(Value, null, streetAddress),
    React.createElement(Value, null, `${postalCode} ${city}`)));
const createRoleFieldsTitles = (text) => ({
    roleDirectors: text('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
    roleAlternativeOwners: text('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
    rolePartners: text('OWNER_STRUCTURE_PARTNERS_LABEL'),
    roleBeneficialOwners: text('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
});
const LegalPersonContent = () => {
    const { text } = useLocalization();
    const { stepData: person } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { fieldNames: ['legalEntityNumber'], title: text('OVERVIEW_FIELD_LEGAL_ENTITY_NUMBER_SSN_TITLE') },
            React.createElement(Value, null, person.legalEntityNumber)),
        React.createElement(Field, { fieldNames: ['nationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE') }, person.nationality && React.createElement(Value, null, text(`NATIONALITY_VALUE_${person.nationality}`))),
        React.createElement(Field, { fieldNames: ['streetAddress', 'postalCode', 'city'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE') }, renderAddressValue(person))));
};
const NewSignatoryOverview = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(IntroContainer, null,
            React.createElement(Text.Title2, null, text('GAP_OVERVIEW_TITLE')),
            React.createElement(Text.Regular, { className: "override-text" }, text('GAP_OVERVIEW_SUB_TITLE'))),
        React.createElement(Section, { name: "personalDetails", title: text('GAP_OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
            React.createElement(Field, { fieldNames: ['signatoryFirstName', 'signatoryLastName'], title: text('OVERVIEW_FIELD_SIGNATORY_NAME_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, `${stepData.signatoryFirstName} ${stepData.signatoryLastName}`)),
            React.createElement(Field, { fieldNames: ['signatoryLegalEntityNumber'], title: text('OVERVIEW_FIELD_LEGAL_ENTITY_NUMBER_SSN_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, stepData.signatoryLegalEntityNumber)),
            React.createElement(Field, { fieldNames: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE'), editable: true, conditionalRendering: true }, renderAddressValue({
                streetAddress: stepData.signatoryStreetAddress,
                postalCode: stepData.signatoryPostalCode,
                city: stepData.signatoryCity,
            })),
            React.createElement(Field, { fieldNames: ['signatoryNationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, text(`NATIONALITY_VALUE_${stepData.signatoryNationality}`))),
            React.createElement(Field, { fieldNames: ['signatoryPhoneNumber'], title: text('OVERVIEW_FIELD_SIGNATORY_PHONE_NUMBER_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, stepData.signatoryPhoneNumber))),
        React.createElement(OwnerStructureSection, { legalPersonContentTemplate: LegalPersonContent, roleFieldsTitles: createRoleFieldsTitles(text) })));
};
const SignatoryOverview = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(IntroContainer, null,
            React.createElement(Text.Title2, null, text('GAP_OVERVIEW_TITLE')),
            React.createElement(Text.Regular, { className: "override-text" }, text('GAP_OVERVIEW_SUB_TITLE'))),
        React.createElement(Section, { name: "personalDetails", title: text('GAP_OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
            React.createElement(Field, { fieldNames: ['existingSignatoryFirstName', 'existingSignatoryLastName'], title: text('OVERVIEW_FIELD_SIGNATORY_NAME_TITLE'), editable: true },
                React.createElement(Value, null, `${stepData.existingSignatoryFirstName} ${stepData.existingSignatoryLastName}`)),
            React.createElement(Field, { fieldNames: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE'), editable: true, conditionalRendering: true }, renderAddressValue({
                streetAddress: stepData.signatoryStreetAddress,
                postalCode: stepData.signatoryPostalCode,
                city: stepData.signatoryCity,
            })),
            React.createElement(Field, { fieldNames: ['signatoryNationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, text(`NATIONALITY_VALUE_${stepData.signatoryNationality}`)))),
        React.createElement(OwnerStructureSection, { legalPersonContentTemplate: LegalPersonContent, roleFieldsTitles: createRoleFieldsTitles(text) })));
};
export const GAP_SE_PARTNERSHIP = () => {
    const { stepData } = useOverviewPageContextValue();
    if (stepData.signatoryAction === SIGNATORY_ACTION.SET_NATIONALITY) {
        return React.createElement(SignatoryOverview, null);
    }
    if (stepData.signatoryAction === SIGNATORY_ACTION.CHANGE_SIGNATORY) {
        return React.createElement(NewSignatoryOverview, null);
    }
    throw new Error(`Unsupported signatoryAction "${stepData.signatoryAction}"`);
};
