import { pick, toPairs } from 'ramda';
import ownerStructureHelpSvg from 'src/assets/owner-structure-help.svg';
import { FunctionStepFuncKey } from 'src/components/Questionnaire/types';
import { nationalityOptions } from 'src/schema/common/nationality-options';
import { RoleFieldName } from 'src/schema/common/owner-structure';
import { getPrivacyPolicyLink } from 'src/schema/common/privacy-policy-link';
import { districtCourtsData, formatCurrencyRange, formatPercentRange, getDistrictCourts, getDistrictCourtsWithGroupLabel, getLimitedPartnershipRoleLabels, getNonProfitRoleLabels, locale, nativeBusinessTypeInfoList, nativeBusinessTypesGroupedByBusinessType, } from 'src/utils/country/DE';
import { template } from '../../template';
export const nativeBusinessType = ({ translate, substitute }) => ({
    fields: ['nativeBusinessType'],
    JSONSchema: {
        title: translate('BUSINESS_TYPE_HEADER'),
        description: translate('BUSINESS_TYPE_INSTRUCTION'),
        required: ['nativeBusinessType'],
        type: 'object',
        properties: {
            nativeBusinessTypeTitle: {
                title: translate('BUSINESS_TYPE_LABEL'),
                type: 'null',
            },
            nativeBusinessType: {
                type: 'string',
                oneOf: nativeBusinessTypeInfoList.map((type) => ({
                    enum: [type.value],
                    title: type.title,
                })),
            },
        },
    },
    UISchema: {
        nativeBusinessType: {
            'ui:title': false,
            'ui:widget': 'CustomSelectWidget',
            'ui:placeholder': translate('BUSINESS_TYPE_SELECT_PLACEHOLDER'),
            'ui:options': {
                groups: toPairs(nativeBusinessTypesGroupedByBusinessType).map(([groupKey, groupItems]) => ({
                    label: translate(`BUSINESS_TYPE_GROUP_LABEL_${groupKey}`),
                    values: groupItems.map((type) => type.value),
                })),
                descriptions: nativeBusinessTypeInfoList
                    .filter((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.description !== undefined)
                    .map(pick(['value', 'description'])),
                floatingLabelDisabled: true,
            },
        },
    },
    applicationVariables: {
        stepName: 'businessType',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_TYPE_DE_HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_TYPE_DE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
                {
                    title: translate('BUSINESS_TYPE_DE_HELP_TEXT_NOT_IN_LIST_TITLE'),
                    body: translate('BUSINESS_TYPE_DE_HELP_TEXT_NOT_IN_LIST_BODY', [
                        substitute('SUPPORT_EMAIL'),
                        substitute('SUPPORT_NUMBER'),
                    ]),
                },
            ],
        },
    },
});
export const nonRegSelfEmployedPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_DE_NOT_REGISTERED_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_WITH_GENDER_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
});
export const mapBusinessTypeFunction = () => ({
    functions: [FunctionStepFuncKey.DE_businessType],
    fields: ['businessType'],
    dependsOnFields: ['nativeBusinessType'],
    applicationVariables: {
        stepName: 'businessTypeFunc',
    },
});
export const regSelfEmployedPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_DE_REGISTERED_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_WITH_GENDER_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
});
export const limitedCompanyPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_DE_REGISTERED_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_WITH_GENDER_INSTRUCTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_DECISION_MAKERS_HEADER'),
                    description: translate('PREPARATION_SCREEN_DECISION_MAKERS_WITH_GENDER_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
});
export const regPartnershipPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_DE_REGISTERED_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_WITH_GENDER_INSTRUCTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_DECISION_MAKERS_HEADER'),
                    description: translate('PREPARATION_SCREEN_DECISION_MAKERS_WITH_GENDER_PARTNERSHIP_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
});
export const nonRegPartnershipPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_DE_NOT_REGISTERED_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_WITH_GENDER_INSTRUCTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_DECISION_MAKERS_HEADER'),
                    description: translate('PREPARATION_SCREEN_DECISION_MAKERS_WITH_GENDER_PARTNERSHIP_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
});
export const regNonProfitPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_NON_PROFIT_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_NON_PROFIT_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_DE_REGISTERED_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_WITH_GENDER_INSTRUCTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_DECISION_MAKERS_NON_PROFIT_HEADER'),
                    description: translate('PREPARATION_SCREEN_DECISION_MAKERS_WITH_GENDER_NON_PROFIT_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
});
export const nonRegNonProfitPrepScreen = ({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('PREPARATION_SCREEN_NON_PROFIT_HEADER'),
            button: translate('FORM_BUTTON'),
            items: [
                {
                    icon: 'Home',
                    title: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_NON_PROFIT_HEADER'),
                    description: translate('PREPARATION_SCREEN_BUSINESS_DETAILS_DE_NOT_REGISTERED_INSTRUCTION'),
                },
                {
                    icon: 'Person',
                    title: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_HEADER'),
                    description: translate('PREPARATION_SCREEN_PERSONAL_DETAILS_WITH_GENDER_INSTRUCTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('PREPARATION_SCREEN_DECISION_MAKERS_NON_PROFIT_HEADER'),
                    description: translate('PREPARATION_SCREEN_DECISION_MAKERS_WITH_GENDER_NON_PROFIT_INSTRUCTION'),
                },
                {
                    icon: 'CardReaderOne',
                    title: translate('PREPARATION_SCREEN_USAGE_OF_IZETTLE_HEADER'),
                },
            ],
        },
    },
});
export const generalBusinessName = ({ translate }) => ({
    fields: ['businessName'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            businessName: {
                title: translate('BUSINESS_NAME_LABEL'),
                description: translate('BUSINESS_NAME_INSTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessName: {
            'ui:autofocus': true,
        },
    },
    applicationVariables: {
        stepName: 'businessName',
        segment: 'businessDetails',
    },
});
export const nonProfitBusinessName = ({ translate }) => ({
    fields: ['businessName'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            businessName: {
                title: translate('BUSINESS_NAME_NON_PROFIT_LABEL'),
                description: translate('BUSINESS_NAME_INSTRUCTION'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessName: {
            'ui:autofocus': true,
        },
    },
    applicationVariables: {
        stepName: 'businessName',
        segment: 'businessDetails',
    },
});
export const generalBusinessAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessStreetAddress', 'businessPostalCode', 'businessCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_LABEL'),
                description: translate('BUSINESS_ADDRESS_INSTRUCTION'),
                type: 'null',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
    },
});
export const nonProfitBusinessAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessStreetAddress', 'businessPostalCode', 'businessCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_NON_PROFIT_LABEL'),
                description: translate('BUSINESS_ADDRESS_INSTRUCTION'),
                type: 'null',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
    },
});
export const regBusinessEntityNumber = ({ questionTitle, businessLegalEntityNumberLabel, businessLegalEntityNumberPlaceholder, helpTexts }) => ({ translate }) => ({
    backendValidation: true,
    fields: ['businessLegalEntityNumber', 'businessDistrictCourt'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLegalEntityNumberQuestion: {
                title: questionTitle,
                type: 'null',
            },
            businessLegalEntityNumber: {
                type: 'string',
            },
            businessDistrictCourt: {
                type: 'string',
                enum: getDistrictCourts(districtCourtsData).map((districtCourt) => districtCourt.name),
                enumNames: getDistrictCourts(districtCourtsData).map((districtCourt) => districtCourt.label),
            },
        },
    },
    UISchema: {
        businessLegalEntityNumber: {
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:title': false,
            'ui:placeholder': businessLegalEntityNumberPlaceholder,
            'ui:options': {
                label: businessLegalEntityNumberLabel,
            },
        },
        businessDistrictCourt: {
            'ui:widget': 'CustomSelectWidget',
            'ui:title': false,
            'ui:placeholder': translate('BUSINESS_DISTRICT_COURT_DE_HRG_LABEL'),
            'ui:options': {
                descriptions: getDistrictCourtsWithGroupLabel(districtCourtsData).map((item) => ({
                    value: item.name,
                    description: item.groupLabel,
                })),
                sortByLabels: 'asc',
            },
        },
    },
    applicationVariables: {
        stepName: 'businessLegalEntityNumber',
        segment: 'businessDetails',
        content: {
            helpTexts,
        },
    },
});
export const handelsregisterBusinessEntityNumber = (props) => regBusinessEntityNumber({
    questionTitle: props.translate('BUSINESS_LEGAL_ENTITY_NUMBER_QUESTION_DE_HANDELSREGISTER_LABEL'),
    businessLegalEntityNumberLabel: "Handelsregisternummer" /* CopyDE.HANDELSREGISTERNUMMER_LABEL */,
    businessLegalEntityNumberPlaceholder: "z.B. HRB 123456 A oder HRA 123456" /* CopyDE.HANDELSREGISTERNUMMER_PLACEHOLDER */,
    helpTexts: [
        {
            title: props.translate('HELP_TEXT_INFO_LOCATION_TITLE'),
            body: props.translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_HANDELSREGISTER_HELP_TEXT_INFO_LOCATION_BODY'),
        },
    ],
})(props);
export const partnerschaftsBusinessEntityNumber = (props) => regBusinessEntityNumber({
    questionTitle: props.translate('BUSINESS_LEGAL_ENTITY_NUMBER_QUESTION_DE_PARTNERSCHAFTSREGISTER_LABEL'),
    businessLegalEntityNumberLabel: "Partnerschaftsregisternummer" /* CopyDE.PARTNERSCHAFTSREGISTERNUMMER_LABEL */,
    businessLegalEntityNumberPlaceholder: "z.B. PR 1234" /* CopyDE.PARTNERSCHAFTSREGISTERNUMMER_PLACEHOLDER */,
    helpTexts: [
        {
            title: props.translate('HELP_TEXT_INFO_LOCATION_TITLE'),
            body: props.translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_PARTNERSCHAFTSREGISTER_HELP_TEXT_INFO_LOCATION_BODY'),
        },
    ],
})(props);
export const vereinsregisterBusinessEntityNumber = (props) => regBusinessEntityNumber({
    questionTitle: props.translate('BUSINESS_LEGAL_ENTITY_NUMBER_QUESTION_DE_VEREINSREGISTER_LABEL'),
    businessLegalEntityNumberLabel: "Vereinsregisternummer" /* CopyDE.VEREINSREGISTERNUMMER_LABEL */,
    businessLegalEntityNumberPlaceholder: "z.B. VR 1234 HB oder VR 522" /* CopyDE.VEREINSREGISTERNUMMER_PLACEHOLDER */,
    helpTexts: [
        {
            title: props.translate('HELP_TEXT_INFO_LOCATION_TITLE'),
            body: props.translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_VEREINSREGISTER_HELP_TEXT_INFO_LOCATION_BODY'),
        },
    ],
})(props);
export const genossenschaftsregisterBusinessEntityNumber = (props) => regBusinessEntityNumber({
    questionTitle: props.translate('BUSINESS_LEGAL_ENTITY_NUMBER_QUESTION_DE_GENOSSENSCHAFTSREGISTER_LABEL'),
    businessLegalEntityNumberLabel: "Genossenschaftsregisternummer" /* CopyDE.GENOSSENSCHAFTSREGISTERNUMMER_LABEL */,
    businessLegalEntityNumberPlaceholder: "z.B. GnR 123 B" /* CopyDE.GENOSSENSCHAFTSREGISTERNUMMER_PLACEHOLDER */,
    helpTexts: [
        {
            title: props.translate('HELP_TEXT_INFO_LOCATION_TITLE'),
            body: props.translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_GENOSSENSCHAFTSREGISTER_HELP_TEXT_INFO_LOCATION_BODY'),
        },
    ],
})(props);
export const nonRegBusinessEntityNumberTemplate = template(({ translate }) => ({
    backendValidation: true,
    fields: ['businessLegalEntityNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLegalEntityNumber: {
                title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_TAX_NUMBER_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessLegalEntityNumber: {
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': "z.B. 12 345 67890 oder 28 120 345 67890" /* CopyDE.TAX_NUMBER_PLACEHOLDER */,
        },
    },
    applicationVariables: {
        stepName: 'businessLegalEntityNumber',
        segment: 'businessDetails',
    },
}));
export const nonRegGeneralBusinessEntityNumber = nonRegBusinessEntityNumberTemplate.extendDeep(({ translate }) => ({
    JSONShema: {
        properties: {
            businessLegalEntityNumber: {
                description: translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_TAX_NUMBER_INSTRUCTION'),
            },
        },
    },
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_TAX_NUMBER_HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_TAX_NUMBER_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
export const nonRegNonProfitBusinessEntityNumber = nonRegBusinessEntityNumberTemplate.extendDeep(({ translate }) => ({
    JSONShema: {
        properties: {
            businessLegalEntityNumber: {
                description: translate('BUSINESS_LEGAL_ENTITY_NUMBER_NON_PROFIT_DE_TAX_NUMBER_INSTRUCTION'),
            },
        },
    },
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_TAX_NUMBER_HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_TAX_NUMBER_HELP_TEXT_INFO_LOCATION_BODY'),
                },
                {
                    title: translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_TAX_NUMBER_NON_PROFIT_HELP_TEXT_ABSENT_TITLE'),
                    body: translate('BUSINESS_LEGAL_ENTITY_NUMBER_DE_TAX_NUMBER_NON_PROFIT_HELP_TEXT_ABSENT_BODY'),
                },
            ],
        },
    },
}));
export const businessVatNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessVatNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessVatNumber: {
                title: translate('BUSINESS_DE_VAT_NUMBER_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        businessVatNumber: {
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': "z.B. DE999 999 999" /* CopyDE.VAT_NUMBER_PLACEHOLDER */,
        },
    },
    applicationVariables: {
        stepName: 'businessVatNumber',
        segment: 'businessDetails',
    },
});
export const personalDetailsQuestionsIntro = ({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'personalDetails',
        content: {
            icon: 'IdentityCard',
            image: 'highFive',
            header: translate('SECTION_INTRO_PERSONAL_DETAILS_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_PERSONAL_DETAILS_TITLE'),
            description: translate('SECTION_INTRO_PERSONAL_DETAILS_WITH_GENDER_DESCRIPTION'),
            button: translate('FORM_BUTTON'),
        },
    },
});
export const signatoryName = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryFirstName', 'signatoryLastName'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryQuestion: {
                title: translate('SIGNATORY_NAME_LABEL'),
                type: 'null',
            },
            signatoryFirstName: {
                type: 'string',
            },
            signatoryLastName: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryFirstName: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('FIRST_NAME_LABEL'),
            },
        },
        signatoryLastName: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('LAST_NAME_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryName',
        segment: 'personalDetails',
    },
});
export const signatoryDOB = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryDateOfBirth'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryDateOfBirth: {
                title: translate('SIGNATORY_DATE_OF_BIRTH_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryDateOfBirth: {
            'ui:options': {
                mask: "99.99.9999" /* CopyDE.DATE_OF_BIRTH_MASK */,
            },
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': "TT.MM.JJJJ" /* CopyDE.DATE_OF_BIRTH_PLACEHOLDER */,
        },
    },
    applicationVariables: {
        stepName: 'signatoryDateOfBirth',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_DATE_OF_BIRTH_HELP_TEXT_WHY_DATE_OF_BIRTH_TITLE'),
                    body: translate('SIGNATORY_DATE_OF_BIRTH_HELP_TEXT_WHY_DATE_OF_BIRTH_BODY'),
                },
            ],
        },
    },
});
export const signatoryGender = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryGender'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryGender: {
                title: translate('SIGNATORY_GENDER_LABEL'),
                type: 'string',
                oneOf: [
                    { enum: ['MALE'], title: translate('PERSON_GENDER_LABEL_VALUE_MALE') },
                    { enum: ['FEMALE'], title: translate('PERSON_GENDER_LABEL_VALUE_FEMALE') },
                ],
            },
        },
    },
    UISchema: {
        signatoryGender: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'signatoryGender',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_GENDER_HELP_TEXT_WHY_GENDER_TITLE'),
                    body: translate('SIGNATORY_GENDER_HELP_TEXT_WHY_GENDER_BODY'),
                },
            ],
        },
    },
});
export const signatoryNationality = ({ translate }) => ({
    fields: ['signatoryNationality'],
    backendValidation: true,
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryNationality: {
                title: translate('SIGNATORY_NATIONALITY_LABEL'),
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
        },
    },
    UISchema: {
        signatoryNationality: {
            'ui:widget': 'CustomComboBoxWidget',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
            'ui:options': {
                sortByLabels: 'asc',
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryNationality',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_NATIONALITY_HELP_TEXT_WHY_NATIONALITY_TITLE'),
                    body: translate('SIGNATORY_NATIONALITY_HELP_TEXT_WHY_NATIONALITY_BODY'),
                },
            ],
        },
    },
});
export const isSignatoryShareAddress = ({ translate }) => ({
    fields: ['isSignatoryShareAddress'],
    JSONSchema: {
        required: ['isSignatoryShareAddress'],
        type: 'object',
        properties: {
            isSignatoryShareAddress: {
                title: translate('IS_SIGNATORY_SHARE_ADDRESS_LABEL'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('IS_SIGNATORY_SHARE_ADDRESS_VALUE_YES') },
                    { enum: [false], title: translate('IS_SIGNATORY_SHARE_ADDRESS_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        isSignatoryShareAddress: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'isSignatoryShareAddress',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('IS_SIGNATORY_SHARE_ADDRESS_HELP_TEXT_WHY_ADDRESS_TITLE'),
                    body: translate('IS_SIGNATORY_SHARE_ADDRESS_HELP_TEXT_WHY_ADDRESS_BODY'),
                },
            ],
        },
    },
});
export const signatoryAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
    },
});
export const signatoryPhoneNumber = ({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryPhoneNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryPhoneNumber: {
                title: translate('SIGNATORY_PHONE_NUMBER_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryPhoneNumber: {
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:placeholder': "z. B. 01512 1234 567" /* CopyDE.SIGNATORY_PHONE_NUMBER_PLACEHOLDER */,
        },
    },
    applicationVariables: {
        stepName: 'signatoryPhoneNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_PHONE_NUMBER_TITLE'),
                    body: translate('SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_PHONE_NUMBER_BODY'),
                },
            ],
        },
    },
});
export const usageOfIZettleIntroTemplate = template(({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'usageOfIzettle',
        content: {
            icon: 'CardReaderOne',
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            title: translate('SECTION_INTRO_USAGE_OF_IZETTLE_TITLE'),
            button: translate('FORM_BUTTON'),
        },
    },
}));
export const generalUsageOfIZettleIntro = usageOfIZettleIntroTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            header: translate('SECTION_INTRO_USAGE_OF_IZETTLE_HEADER'),
            image: 'highFive',
        },
    },
}));
export const selfEmployedUsageOfIZettleIntro = usageOfIZettleIntroTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            header: translate('SECTION_INTRO_USAGE_OF_IZETTLE_SELF_EMPLOYED_HEADER'),
            image: 'fistBump',
        },
    },
}));
export const businessLocationsTypes = ({ translate }) => ({
    fields: ['businessLocationTypes'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessLocationTypes: {
                title: translate('BUSINESS_LOCATION_TYPES_LABEL'),
                description: translate('BUSINESS_LOCATION_TYPES_INSTRUCTION'),
                type: 'array',
                minItems: 1,
                items: {
                    type: 'string',
                    anyOf: [
                        { enum: ['PHYSICAL_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_PHYSICAL_STORE') },
                        { enum: ['TEMPORARY_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_TEMPORARY_STORE') },
                        { enum: ['EVENT_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_EVENT_STORE') },
                        { enum: ['ONLINE_STORE'], title: translate('BUSINESS_LOCATION_TYPES_VALUE_ONLINE_STORE') },
                    ],
                },
                uniqueItems: true,
            },
        },
    },
    UISchema: {
        businessLocationTypes: {
            'ui:widget': 'CustomCheckboxGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessLocationTypes',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessFrequency = ({ translate }) => ({
    fields: ['businessFrequency'],
    JSONSchema: {
        required: ['businessFrequency'],
        type: 'object',
        properties: {
            businessFrequency: {
                title: translate('BUSINESS_FREQUENCY_LABEL'),
                type: 'string',
                oneOf: [
                    { enum: ['EVERY_DAY'], title: translate('BUSINESS_FREQUENCY_VALUE_EVERY_DAY') },
                    {
                        enum: ['FEW_TIMES_A_MONTH'],
                        title: translate('BUSINESS_FREQUENCY_VALUE_FEW_TIMES_A_MONTH'),
                    },
                    {
                        enum: ['FEW_TIMES_A_YEAR'],
                        title: translate('BUSINESS_FREQUENCY_VALUE_FEW_TIMES_A_YEAR'),
                    },
                ],
            },
        },
    },
    UISchema: {
        businessFrequency: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessFrequency',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessAverageTransaction = ({ translate }) => ({
    fields: ['businessAverageTransaction'],
    JSONSchema: {
        required: ['businessAverageTransaction'],
        type: 'object',
        properties: {
            businessAverageTransaction: {
                title: translate('BUSINESS_AVERAGE_TRANSACTION_LABEL'),
                type: 'string',
                oneOf: ['0-50', '50-500', '500-X'].map((range) => ({
                    enum: [range],
                    title: formatCurrencyRange(range, translate),
                })),
            },
        },
    },
    UISchema: {
        businessAverageTransaction: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessAverageTransaction',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessIzettleShareOfTurnover = ({ translate }) => ({
    fields: ['businessIzettleShareOfTurnover'],
    JSONSchema: {
        required: ['businessIzettleShareOfTurnover'],
        type: 'object',
        properties: {
            businessIzettleShareOfTurnover: {
                title: translate('BUSINESS_IZETTLE_SHARE_OF_TURNOVER_LABEL'),
                type: 'string',
                oneOf: ['0-20%', '20-80%', '80-X%'].map((rangeValue) => ({
                    enum: [rangeValue],
                    title: formatPercentRange(rangeValue, translate),
                })),
            },
        },
    },
    UISchema: {
        businessIzettleShareOfTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessIzettleShareOfTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessCurrentAnnualTurnover = ({ translate }) => ({
    fields: ['businessCurrentAnnualTurnover'],
    JSONSchema: {
        required: ['businessCurrentAnnualTurnover'],
        type: 'object',
        properties: {
            businessCurrentAnnualTurnover: {
                title: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_LABEL'),
                description: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_INSTRUCTION'),
                type: 'string',
                oneOf: [
                    {
                        enum: ['NEW_BUSINESS'],
                        title: translate('BUSINESS_CURRENT_ANNUAL_TURNOVER_VALUE_NEW_BUSINESS'),
                    },
                    ...['0-20000', '20000-200000', '200000-1000000', '1000000-X'].map((rangeValue) => ({
                        enum: [rangeValue],
                        title: formatCurrencyRange(rangeValue, translate),
                    })),
                ],
            },
        },
    },
    UISchema: {
        businessCurrentAnnualTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessCurrentAnnualTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
export const businessExpectedAnnualTurnover = ({ translate }) => ({
    fields: ['businessExpectedAnnualTurnover'],
    JSONSchema: {
        required: ['businessExpectedAnnualTurnover'],
        type: 'object',
        properties: {
            businessExpectedAnnualTurnover: {
                title: translate('BUSINESS_EXPECTED_ANNUAL_TURNOVER_LABEL'),
                type: 'string',
                oneOf: ['0-20000', '20000-200000', '200000-1000000', '1000000-X'].map((rangeValue) => ({
                    enum: [rangeValue],
                    title: formatCurrencyRange(rangeValue, translate),
                })),
            },
        },
    },
    UISchema: {
        businessExpectedAnnualTurnover: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'businessExpectedAnnualTurnover',
        segment: 'usageOfIzettle',
        content: {
            helpTexts: [
                {
                    title: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_TITLE'),
                    body: translate('FSA_QUESTIONS_HELP_TEXT_WHY_FSA_QUESTIONS_BODY'),
                },
            ],
        },
    },
});
const personStep = (translate) => {
    return {
        ID: 'personStepId',
        backendValidation: true,
        fields: ['firstName', 'lastName', 'dateOfBirth', 'nationality', 'gender', 'streetAddress', 'postalCode', 'city'],
        JSONSchema: {
            type: 'object',
            properties: {
                nameTitle: {
                    title: translate('PERSON_NAME_LABEL'),
                    type: 'null',
                },
                firstName: {
                    type: 'string',
                },
                lastName: {
                    type: 'string',
                },
                dateOfBirthTitle: {
                    title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                    type: 'null',
                },
                dateOfBirth: {
                    type: 'string',
                },
                genderTitle: {
                    title: translate('PERSON_GENDER_LABEL'),
                    type: 'null',
                },
                gender: {
                    type: 'string',
                    oneOf: [
                        { enum: ['MALE'], title: translate('PERSON_GENDER_LABEL_VALUE_MALE') },
                        { enum: ['FEMALE'], title: translate('PERSON_GENDER_LABEL_VALUE_FEMALE') },
                    ],
                },
                nationalityTitle: {
                    title: translate('PERSON_NATIONALITY_LABEL'),
                    type: 'null',
                },
                nationality: {
                    type: 'string',
                    enum: nationalityOptions.map((option) => option.value),
                    enumNames: nationalityOptions.map((option) => translate(option.textKey)),
                },
                addressTitle: {
                    title: translate('PERSON_HOME_ADDRESS_LABEL'),
                    type: 'null',
                },
                streetAddress: {
                    type: 'string',
                },
                postalCode: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
            },
        },
        UISchema: {
            firstName: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('FIRST_NAME_LABEL'),
                },
            },
            lastName: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('LAST_NAME_LABEL'),
                },
            },
            dateOfBirth: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: "TT.MM.JJJJ" /* CopyDE.DATE_OF_BIRTH_PLACEHOLDER */,
                    mask: "99.99.9999" /* CopyDE.DATE_OF_BIRTH_MASK */,
                },
            },
            gender: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:widget': 'CustomRadioGroupWidget',
            },
            nationality: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:widget': 'CustomComboBoxWidget',
                'ui:emptyValue': '',
                'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
                'ui:options': {
                    sortByLabels: 'asc',
                },
            },
            streetAddress: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
                },
            },
            postalCode: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                },
            },
            city: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_CITY_LABEL'),
                },
            },
        },
        applicationVariables: {
            stepName: 'newPerson',
        },
    };
};
export const initLegalPersonsFunction = () => ({
    functions: [FunctionStepFuncKey.initLegalPersons],
    fields: ['legalPersons'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'legalPersonsFunc',
        segment: 'ownerStructure',
    },
});
const ownerStructureIntroTemplate = template(({ translate }) => ({
    componentName: 'SectionIntro',
    applicationVariables: {
        stepName: 'sectionIntro',
        segment: 'ownerStructure',
        content: {
            icon: 'IdentityCard',
            image: 'fistBump',
            header: translate('SECTION_INTRO_OWNER_STRUCTURE_HEADER'),
            subheader: translate('SECTION_INTRO_SUBHEADER'),
            description: translate('SECTION_INTRO_OWNER_STRUCTURE_NAME_DOB_GENDER_NATIONALITY_ADDRESS_DESCRIPTION'),
            button: translate('FORM_BUTTON'),
        },
    },
}));
export const limitedPartnershipOwnerStructureIntro = ownerStructureIntroTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            title: translate('SECTION_INTRO_OWNER_STRUCTURE_TITLE'),
        },
    },
}));
export const nonProfitOwnerStructureIntro = ownerStructureIntroTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            title: translate('SECTION_INTRO_OWNER_STRUCTURE_NON_PROFIT_TITLE'),
        },
    },
}));
export const limitedDirectorsList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleDirectors'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'directorsList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleDirectors,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
            description: translate('OWNER_STRUCTURE_DIRECTORS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_DIRECTORS_DE_LIMITED_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const partnersList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['rolePartners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'partnersList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.rolePartners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_PARTNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_PARTNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_DIRECTORS_DE_LIMITED_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const nonProfitDirectorsList = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleDirectors'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'directorsList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleDirectors,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getNonProfitRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL'),
            description: translate('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_DIRECTORS_DE_NON_PROFIT_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
});
export const hasUBO = ({ translate }) => ({
    fields: ['hasUBO'],
    JSONSchema: {
        required: ['hasUBO'],
        type: 'object',
        title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
        properties: {
            hasUBO: {
                title: translate('OWNER_STRUCTURE_HAS_UBO_DESCRIPTION'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_YES') },
                    { enum: [false], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        hasUBO: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
        'ui:options': {
            titleStyle: 'LargeBold',
        },
    },
    applicationVariables: {
        stepName: 'hasUBO',
        segment: 'ownerStructure',
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_OWNERS_DE_LTD_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
            ],
        },
    },
});
export const beneficialOwners = ({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleBeneficialOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'beneficialOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleBeneficialOwners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_OWNERS_DE_LTD_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY', [
                        ownerStructureHelpSvg.src,
                    ]),
                },
            ],
        },
    },
});
const alternativeOwnersListTemplate = template(({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleAlternativeOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'alternativeOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleAlternativeOwners,
        signatoryRoleLabel: translate('OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        content: {
            title: translate('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
        },
    },
}));
export const limitedPartnershipAlternativeOwnersList = alternativeOwnersListTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        personRoleLabels: getLimitedPartnershipRoleLabels(translate),
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_OWNERS_DE_LTD_PARTNERSHIP_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
export const nonProfitAlternativeOwnersList = alternativeOwnersListTemplate.extendDeep(({ translate }) => ({
    applicationVariables: {
        personRoleLabels: getNonProfitRoleLabels(translate),
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('OWNER_STRUCTURE_OWNERS_DE_NON_PROFIT_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
export const overviewPage = ({ translate, formContext }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: 'DE',
        displayUsaTaxpayerTerms: formContext.form.endsWith('1099K'),
        content: {
            title: translate('OVERVIEW_TITLE'),
            subtitle: translate('OVERVIEW_SUB_TITLE'),
            submitButton: translate('OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: translate('OVERVIEW_SUBMIT_INSTRUCTION', [getPrivacyPolicyLink(locale)]),
            helpTexts: [
                {
                    title: translate('OVERVIEW_HELP_TEXT_PEP_INTRUCTIONS_TITLE'),
                    body: translate('OVERVIEW_HELP_TEXT_PEP_INTRUCTIONS_BODY'),
                },
            ],
        },
    },
});
