import { clone, isEmpty, omit, uniq } from 'ramda';
import { isCustomStepObject, isFunctionStepObject, } from '../types';
export const getStepObject = (stepList, stepIndex) => {
    const stepObject = stepList[stepIndex];
    if (!stepObject) {
        throw new Error('No step object found by given index');
    }
    return stepObject;
};
export const getCurrentStepObject = (questionnaire) => getStepObject(questionnaire.stepList, questionnaire.currentStepIndex);
export const isOverviewPage = (stepObject) => isCustomStepObject(stepObject) && stepObject.componentName === 'OverviewPage';
export const getOverviewPageIndex = (stepList) => {
    return stepList.findIndex((stepObject) => {
        return isCustomStepObject(stepObject) && stepObject.componentName === 'OverviewPage';
    });
};
export const getOverviewPageStepObject = (stepList) => {
    const overviewPageIndex = getOverviewPageIndex(stepList);
    return getStepObject(stepList, overviewPageIndex);
};
export const removeOwnerFromStepData = (stepData, fieldName, dependentFieldName, ownerIndex) => {
    const finalStepData = clone(stepData);
    const currentValue = finalStepData[fieldName];
    if (currentValue && Array.isArray(currentValue)) {
        currentValue.splice(ownerIndex, 1);
        if (currentValue.length === 0) {
            delete finalStepData[fieldName];
            finalStepData[dependentFieldName] = false;
        }
    }
    return finalStepData;
};
export const canExecuteStep = (stepObject, stepData) => {
    const { renderIf } = stepObject;
    if (!renderIf) {
        // If the step does not have the renderIf property, we do not need to check any further.
        return true;
    }
    // Checking if user provided state values are matching with renderIf definition.
    return Object.keys(renderIf).every((key) => {
        return renderIf[key].includes(stepData[key]);
    });
};
export const isStepCompleted = (stepData, stepObject) => {
    if (isCustomStepObject(stepObject) && !stepObject.fields) {
        return Object.keys(stepData).includes(stepObject.ID);
    }
    if (stepObject.fields && !isEmpty(stepObject.fields)) {
        return Object.keys(stepData).includes(stepObject.fields[0]);
    }
    return true;
};
export const areSubsequentStepsCompleted = (stepList, stepData, currentStepIndex) => {
    const subsequentStepObjects = stepList.slice(currentStepIndex + 1, stepList.length - 1);
    const incompleteSteps = subsequentStepObjects.filter((stepObject) => {
        if (!canExecuteStep(stepObject, stepData)) {
            return false;
        }
        return !isStepCompleted(stepData, stepObject);
    });
    return incompleteSteps.length === 0;
};
export const stepExists = (stepList, stepIndex) => {
    return stepList[stepIndex] !== undefined;
};
export const stepCanBeFilled = (stepList, stepData, stepIndex, editMode) => {
    const nextStepObject = getStepObject(stepList, stepIndex);
    if (editMode && isStepCompleted(stepData, nextStepObject)) {
        return false;
    }
    return canExecuteStep(nextStepObject, stepData);
};
export const getNextStepIndex = (questionnaire) => {
    let nextStepIndex = questionnaire.currentStepIndex + 1;
    if (!stepExists(questionnaire.stepList, nextStepIndex)) {
        return questionnaire.currentStepIndex;
    }
    while (!stepCanBeFilled(questionnaire.stepList, questionnaire.stepData, nextStepIndex, questionnaire.editMode)) {
        nextStepIndex += 1;
    }
    return nextStepIndex;
};
export const getPreviousStepIndex = (questionnaire) => {
    let nextStepIndex = questionnaire.currentStepIndex;
    do {
        nextStepIndex -= 1;
        if (!stepExists(questionnaire.stepList, nextStepIndex)) {
            // either already on the first step or somehow reached the end and not found match
            return questionnaire.currentStepIndex;
        }
    } while (!canExecuteStep(getStepObject(questionnaire.stepList, nextStepIndex), questionnaire.stepData) ||
        isFunctionStepObject(getStepObject(questionnaire.stepList, nextStepIndex)));
    return nextStepIndex;
};
export const getNextDisplayableStepIndexByFieldName = (stepList, stepData, fieldName) => {
    const stepIndex = stepList.findIndex((stepObject) => {
        var _a;
        if ((_a = stepObject.fields) === null || _a === void 0 ? void 0 : _a.includes(fieldName)) {
            return canExecuteStep(stepObject, stepData);
        }
        return false;
    });
    return stepIndex >= 0 ? stepIndex : null;
};
export const getStepIndexByFieldName = (stepList, fieldName) => {
    return stepList.findIndex((stepObject) => { var _a; return (_a = stepObject.fields) === null || _a === void 0 ? void 0 : _a.includes(fieldName); });
};
export const getStepObjectByFieldName = (stepList, fieldName) => {
    return stepList.find((stepObject) => { var _a; return (_a = stepObject.fields) === null || _a === void 0 ? void 0 : _a.includes(fieldName); });
};
export const isFieldDisplayable = (fieldName, questionnaire) => {
    const nextDisplayableStepIndex = getNextDisplayableStepIndexByFieldName(questionnaire.stepList, questionnaire.stepData, fieldName);
    return nextDisplayableStepIndex !== null;
};
const getDependentSteps = (stepList, stepObject) => stepList.filter((item) => { var _a; return (_a = item.dependentOn) === null || _a === void 0 ? void 0 : _a.includes(stepObject.ID); });
export const getUpdatedStepData = (questionnaire, updatedStepData) => {
    const currentStepObject = getCurrentStepObject(questionnaire);
    let finalStepData = Object.assign(Object.assign({}, questionnaire.stepData), updatedStepData);
    // ToDo: should check finalStepData !== questionnaire.stepData ?????
    if (JSON.stringify(updatedStepData) !== JSON.stringify(questionnaire.stepData)) {
        const dependentStepObjects = getDependentSteps(questionnaire.stepList, currentStepObject);
        const fieldsToReset = uniq(dependentStepObjects.reduce((acc, { fields }) => acc.concat(fields !== null && fields !== void 0 ? fields : []), []));
        finalStepData = omit(fieldsToReset, finalStepData);
    }
    return finalStepData;
};
export const getCustomStepsCompletedStepData = (stepList) => stepList
    .filter(isCustomStepObject)
    .filter(({ componentName }) => componentName !== 'OverviewPage')
    .map((stepObject) => stepObject.ID)
    .reduce((acc, id) => (Object.assign(Object.assign({}, acc), { [id]: true })), {});
export const getPersonRoles = (personId, stepData, roleFieldNames) => {
    const personRoles = [];
    roleFieldNames.forEach((roleFieldName) => {
        var _a;
        const roleSelectedIds = (_a = stepData[roleFieldName]) !== null && _a !== void 0 ? _a : [];
        if (roleSelectedIds.includes(personId)) {
            personRoles.push(roleFieldName);
        }
    });
    return personRoles;
};
