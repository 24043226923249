import { nationalityOptions } from 'src/schema/common/nationality-options';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { template } from 'src/schema/template';
const DEFAULT_MASK = '99.99.9999';
export const signatoryNamePrefilledTemplate = template(({ translate }) => ({
    fields: ['existingSignatoryFirstName', 'existingSignatoryLastName', 'signatoryAction'],
    JSONSchema: {
        type: 'object',
        required: ['existingSignatoryFirstName', 'existingSignatoryLastName', 'signatoryAction'],
        properties: {
            signatoryQuestion: {
                title: translate('GAP_SIGNATORY_NAME_PREFILLED_LABEL'),
                description: '',
                type: 'null',
            },
            existingSignatoryFirstName: {
                type: 'string',
            },
            existingSignatoryLastName: {
                type: 'string',
            },
            signatoryAction: {
                title: ' ',
                description: translate('GAP_SIGNATORY_NAME_PREFILLED_DESCRIPTION'),
                type: 'string',
                oneOf: [
                    {
                        enum: [SIGNATORY_ACTION.SET_NATIONALITY],
                        title: translate('GAP_IS_CORRECT_PREFILLED_SIGNATORY_NAME_TRUE_RADIO_BUTTON'),
                    },
                    {
                        enum: [SIGNATORY_ACTION.CHANGE_SIGNATORY],
                        title: translate('GAP_IS_CORRECT_PREFILLED_SIGNATORY_NAME_FALSE_RADIO_BUTTON'),
                    },
                ],
            },
        },
    },
    UISchema: {
        existingSignatoryFirstName: {
            'ui:title': false,
            'ui:disabled': true,
        },
        existingSignatoryLastName: {
            'ui:title': false,
            'ui:disabled': true,
        },
        signatoryAction: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
    },
    applicationVariables: {
        stepName: 'signatoryName',
        segment: 'personalDetails',
    },
}));
export const signatoryNameTemplate = template(({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryFirstName', 'signatoryLastName'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryQuestion: {
                title: translate('GAP_SIGNATORY_NAME_LABEL'),
                description: ' ',
                type: 'null',
            },
            signatoryFirstName: {
                type: 'string',
            },
            signatoryLastName: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryFirstName: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:options': {
                label: translate('FIRST_NAME_LABEL'),
            },
        },
        signatoryLastName: {
            'ui:title': false,
            'ui:options': {
                label: translate('LAST_NAME_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'newSignatoryName',
        segment: 'personalDetails',
    },
}));
export const signatoryPhoneNumberTemplate = template(({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryPhoneNumber'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryPhoneNumber: {
                title: translate('GAP_SIGNATORY_PHONE_NUMBER_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryPhoneNumber: {
            'ui:autofocus': true,
            'ui:emptyValue': '',
        },
    },
    applicationVariables: {
        stepName: 'signatoryPhoneNumber',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_PHONE_NUMBER_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
}));
export const signatoryNationalityTemplate = template(({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryNationality'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryNationality: {
                title: translate('GAP_SIGNATORY_NATIONALITY_LABEL'),
                type: 'string',
                enum: nationalityOptions.map((option) => option.value),
                enumNames: nationalityOptions.map((option) => translate(option.textKey)),
            },
        },
    },
    UISchema: {
        signatoryNationality: {
            'ui:options': {
                sortByLabels: 'asc',
            },
            'ui:widget': 'CustomComboBoxWidget',
            'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
        },
    },
    applicationVariables: {
        stepName: 'signatoryNationality',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_NATIONALITY_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_NATIONALITY_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
}));
export const signatoryAddressTemplate = template(({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryBuilding', 'signatoryStreetAddress', 'signatoryCity', 'signatoryCounty', 'signatoryPostalCode'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('GAP_SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryBuilding: {
                type: 'string',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
            signatoryCounty: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryBuilding: {
            'ui:autofocus': true,
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_BUILDING_LABEL'),
            },
        },
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_LABEL'),
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
        signatoryCounty: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_COUNTY_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_ADDRESS_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
}));
export const signatoryDOBTemplate = template(({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryDateOfBirth'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryDateOfBirth: {
                title: translate('GAP_SIGNATORY_DATE_OF_BIRTH_LABEL'),
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryDateOfBirth: {
            'ui:options': {
                mask: DEFAULT_MASK,
            },
            'ui:emptyValue': '',
            'ui:autofocus': true,
            'ui:placeholder': translate('DATE_OF_BIRTH_PLACEHOLDER'),
        },
    },
    applicationVariables: {
        stepName: 'signatoryDateOfBirth',
        segment: 'personalDetails',
        content: {
            helpTexts: [
                {
                    title: translate('GAP_SIGNATORY_DATE_OF_BIRTH_HELP_TEXT_WHY_TITLE'),
                    body: translate('GAP_SIGNATORY_DATE_OF_BIRTH_HELP_TEXT_WHY_DESCRIPTION'),
                },
            ],
        },
    },
}));
