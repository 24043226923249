var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect, useCallback } from 'react';
import urlJoin from 'url-join';
import { datadogRum } from '@datadog/browser-rum';
import { GENERAL_ERROR_FIELD_NAME } from 'src/components/CustomStep/components/OverviewPage/constants';
import { useAuthStateValue } from 'src/providers/auth';
import { makeOAuthRequest } from 'src/utils';
import { config } from '../config';
import { usePollingQuestionnaire, } from './use-polling-questionnaire';
export const isErrorsList = (errors) => {
    return Array.isArray(errors);
};
const isBackendProcessFinished = (pollingResponse) => {
    return pollingResponse.progress === 'complete' || pollingResponse.progress === 'failed';
};
export const useSubmitQuestionnaire = (formContext) => {
    const [result, setResult] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const { authClient } = useAuthStateValue();
    const [{ pollingResponse, isPollingError }, startPollingKYCResult, stopPollingKYCResult] = usePollingQuestionnaire();
    const triggerGenericError = useCallback(() => {
        setErrors({ [GENERAL_ERROR_FIELD_NAME]: ['COMMON_SOMETHING_WENT_WRONG'] });
        setIsLoading(false);
        stopPollingKYCResult();
    }, [stopPollingKYCResult, setErrors, setIsLoading]);
    const handleKYCResult = (responseResult) => {
        setResult(responseResult);
        setIsLoading(false);
        stopPollingKYCResult();
    };
    useEffect(() => {
        if (!isBackendProcessFinished(pollingResponse)) {
            return;
        }
        // TODO: refactor error handling so that statuses are checked in a more specific way
        if (!pollingResponse.result) {
            triggerGenericError();
            return;
        }
        handleKYCResult(pollingResponse.result);
    }, [pollingResponse]);
    useEffect(() => {
        if (isPollingError) {
            triggerGenericError();
        }
    }, [isPollingError]);
    const submitQuestionnaire = (requestPayload) => __awaiter(void 0, void 0, void 0, function* () {
        const requestPayloadJSON = JSON.stringify(requestPayload);
        setErrors({});
        setIsLoading(true);
        try {
            const response = yield makeOAuthRequest({
                method: 'POST',
                url: urlJoin(config.api.registration, formContext.submitPath),
                data: requestPayloadJSON,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            }, authClient, 'submitQuestionnaire');
            const responseData = response.data;
            startPollingKYCResult(responseData.requestId);
        }
        catch (error) {
            triggerGenericError();
            error.message = `Unexpected submission error: ${error.message}`;
            datadogRum.addError(error);
        }
    });
    return [{ result, isLoading, errors, triggerGenericError }, submitQuestionnaire];
};
