export var BusinessTypePartOne;
(function (BusinessTypePartOne) {
    BusinessTypePartOne["SelfEmployed"] = "SelfEmployed";
    BusinessTypePartOne["NonProfit"] = "NonProfit";
    BusinessTypePartOne["CommonLimitedCompany"] = "CommonLimitedCompany";
    BusinessTypePartOne["Other"] = "Other";
})(BusinessTypePartOne || (BusinessTypePartOne = {}));
export var BusinessTypePartTwo;
(function (BusinessTypePartTwo) {
    BusinessTypePartTwo["LimitedCompany"] = "LimitedCompany";
    BusinessTypePartTwo["Partnership"] = "Partnership";
})(BusinessTypePartTwo || (BusinessTypePartTwo = {}));
export var BusinessType;
(function (BusinessType) {
    BusinessType["SelfEmployed"] = "SelfEmployed";
    BusinessType["LimitedCompany"] = "LimitedCompany";
    BusinessType["Partnership"] = "Partnership";
    BusinessType["NonProfit"] = "NonProfit";
})(BusinessType || (BusinessType = {}));
