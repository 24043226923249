import Ajv from 'ajv';
const isPersonValidationErrorResponseValid = new Ajv().compile({
    type: 'object',
    properties: {
        legalPersons: {
            type: 'object',
            properties: {
                TEMPORARY_PERSON_ID: {
                    type: 'object',
                },
            },
            required: ['TEMPORARY_PERSON_ID'],
        },
    },
    required: ['legalPersons'],
});
export const mapDataForRequest = (stepData) => {
    return {
        legalPersons: {
            TEMPORARY_PERSON_ID: stepData,
        },
    };
};
export const mapErrorsFromResponse = (errorsFromBackend) => {
    if (!isPersonValidationErrorResponseValid(errorsFromBackend)) {
        throw new Error('Invalid payload returned by the backend endpoint.');
    }
    return errorsFromBackend.legalPersons.TEMPORARY_PERSON_ID;
};
