/**
 * Takes care of reshaping errors from auth lib to a more friendly and stable format.
 * This helps monitoring and debugging.
 * @param extraInfo Info about context in which the error has happened.
 * @returns A function to be used as an error handler in `catch` section when making calls to auth lib.
 * Returned function always throws an error.
 */
export const handleAndRethrowAuthError = (extraInfo) => (err) => {
    var _a, _b;
    if (/Expiration Time \(exp\) claim error in the access token;/.test(err.message)) {
        err.message = 'Authentication token expired';
        throw err;
    }
    if (/Not Before time \(nbf\) claim in the access token indicates that this token can't be used just yet/.test(err.message)) {
        err.message = 'Authentication token not valid yet';
        throw err;
    }
    throw new Error(`Auth provider failed (${extraInfo}) with error: ${(_b = (_a = err.message) !== null && _a !== void 0 ? _a : err.error) !== null && _b !== void 0 ? _b : JSON.stringify(err)}`);
};
