import { useMemo } from 'react';
import { sort } from 'ramda';
import { useLocalization } from '@izettle/lingo-client';
/**
 * A hook that returns an array of enum options for widgets displaying fields
 * with JSONSchema of type like `oneOf`, `anyOf`
 * @param options `options` property given to a Widget component
 */
export const useEnumOptions = (options) => {
    const localization = useLocalization();
    return useMemo(() => {
        var _a, _b;
        let enumOptions = options.enumOptions;
        if (!enumOptions) {
            throw new Error(`Expected 'options' object to have 'enumOptions' property`);
        }
        // using Map, so key can be any value type, not converted to string
        const descriptionsMap = new Map((_b = (_a = options.descriptions) === null || _a === void 0 ? void 0 : _a.map((op) => [op.value, op.description])) !== null && _b !== void 0 ? _b : []);
        enumOptions = enumOptions.map((item) => (Object.assign(Object.assign({}, item), { description: descriptionsMap.get(item.value) })));
        if (!options.sortByLabels) {
            return enumOptions;
        }
        const collator = new Intl.Collator(localization.isoLocale);
        // treat any truthy value of 'sortByLabels' as ascending direction, except 'desc'
        const sortDirection = options.sortByLabels === 'desc' ? -1 : 1;
        return sort((a, b) => collator.compare(a.label, b.label) * sortDirection, enumOptions);
    }, [localization, options]);
};
