import { isEmpty, pickBy, intersection, complement, propSatisfies, mergeAll, union, concat, mapObjIndexed, } from 'ramda';
import { isFunctionStepObject } from 'src/components/Questionnaire/types';
const isNotEmpty = complement(isEmpty);
const ensureArray = (value) => {
    return Array.isArray(value) ? value : [value];
};
const getFunctionStepDependencies = (stepObject, state) => {
    var _a;
    // function step by default depends on all the fields before them if dependsOnFields not provided
    // this ensures that step's `fields` are updated/removed when `dependsOnFields` are updated
    const fields = (_a = stepObject.dependsOnFields) !== null && _a !== void 0 ? _a : Object.keys(state.fields);
    return fields.reduce((acc, fieldName) => {
        const field = state.fields[fieldName];
        if (!field) {
            throw new Error(`Dependent field '${fieldName}' not found for step '${stepObject.applicationVariables.stepName}'`);
        }
        return [...acc, ...field.stepIds];
    }, []);
};
const renderStep = (template, state, context) => {
    var _a;
    const { predicates, dependencies, getNextStepId } = state;
    const stepFromTemplate = template(context);
    const stepObject = Object.assign(Object.assign({}, stepFromTemplate), { ID: getNextStepId(stepFromTemplate.applicationVariables.stepName), renderIf: isEmpty(predicates) ? undefined : predicates, dependentOn: isEmpty(dependencies) ? undefined : dependencies });
    if (isFunctionStepObject(stepObject)) {
        const funcStepDependentOn = getFunctionStepDependencies(stepObject, state);
        if (!isEmpty(funcStepDependentOn)) {
            stepObject.dependentOn = union((_a = stepObject.dependentOn) !== null && _a !== void 0 ? _a : [], funcStepDependentOn);
        }
    }
    return pickBy((val) => val !== undefined, stepObject);
};
const extractFieldsFromStep = (stepObject) => stepObject.fields
    ? mergeAll(stepObject.fields.map((field) => ({
        [field]: {
            stepIds: [stepObject.ID],
            optional: false,
        },
    })))
    : {};
const mergeFields = (existing, added) => {
    return Object.entries(added).reduce((acc, [addedFieldKey, addedFieldData]) => {
        if (acc[addedFieldKey]) {
            addedFieldData = {
                stepIds: union(acc[addedFieldKey].stepIds, addedFieldData.stepIds),
                optional: acc[addedFieldKey].optional || addedFieldData.optional,
            };
        }
        return Object.assign(Object.assign({}, acc), { [addedFieldKey]: addedFieldData });
    }, Object.assign({}, existing));
};
// Leaf (has no children)
export const step = (template) => (state) => (context) => {
    const renderedStep = renderStep(template, state, context);
    const renderedStepFields = extractFieldsFromStep(renderedStep);
    return { steps: [renderedStep], fields: renderedStepFields };
};
export const conditional = (conditionField, branches) => (state) => (context) => {
    if (!state.fields[conditionField]) {
        throw new Error(`Unknown field for conditional: "${conditionField}"`);
    }
    if (state.fields[conditionField].optional) {
        throw new Error(`Condition field must be defined in all previous branches: "${conditionField}"`);
    }
    return branches
        .map(({ when, branch }) => {
        const fieldPredicates = state.predicates[conditionField];
        const arrayWhen = ensureArray(when);
        return {
            when: fieldPredicates ? intersection(fieldPredicates, arrayWhen) : arrayWhen,
            branch,
        };
    })
        .filter(propSatisfies(isNotEmpty, 'when'))
        .map(({ when, branch }) => {
        const predicates = Object.assign(Object.assign({}, state.predicates), { [conditionField]: when });
        const dependencies = union(state.dependencies, state.fields[conditionField].stepIds);
        return {
            branch,
            branchState: Object.assign(Object.assign({}, state), { predicates, dependencies }),
        };
    })
        .reduce((acc, { branch, branchState }) => {
        const branchRenderResult = branch(branchState)(context);
        // fields coming from the branch should be unavailable for use in `conditional`
        // to the steps "outside" the branch, so setting `optional` to true
        const branchFields = mapObjIndexed((field) => (Object.assign(Object.assign({}, field), { optional: true })), branchRenderResult.fields);
        return {
            steps: [...acc.steps, ...branchRenderResult.steps],
            fields: mergeFields(acc.fields, branchFields),
        };
    }, {
        steps: [],
        fields: {},
    });
};
export const stepIf = (template, renderIf) => (state) => (context) => {
    if (isEmpty(renderIf)) {
        throw new Error(`stepIf has empty 'renderIf'`);
    }
    const [[fieldName, validValues], ...otherFields] = Object.entries(renderIf);
    return conditional(fieldName, [
        {
            when: validValues,
            branch: isEmpty(otherFields) ? step(template) : stepIf(template, Object.fromEntries(otherFields)),
        },
    ])(state)(context);
};
export const sequence = (steps) => (state) => (context) => {
    return steps.reduce((acc, current) => {
        const stepResult = current(Object.assign(Object.assign({}, state), { fields: mergeFields(state.fields, acc.fields) }))(context);
        return {
            steps: concat(acc.steps, stepResult.steps),
            fields: mergeFields(acc.fields, stepResult.fields),
        };
    }, { steps: [], fields: {} });
};
export const customStepBuilder = (f) => (state) => (context) => {
    return f(context)(state)(context);
};
export const decision = (field, template, branches) => sequence([step(template), conditional(field, branches)]);
export const render = (root, context) => {
    let stepId = 0;
    const getNextStepId = (stepName) => {
        stepId += 1;
        return `${stepId}_${stepName}`;
    };
    return root({ predicates: {}, dependencies: [], fields: {}, getNextStepId })(context).steps;
};
export const withExtraDependencies = (fields, stepBuilder) => (state) => {
    const extraDependencies = fields.reduce((acc, fieldName) => {
        const field = state.fields[fieldName];
        if (!field) {
            throw new Error(`Dependent field '${fieldName}' not found`);
        }
        return [...acc, ...field.stepIds];
    }, []);
    return stepBuilder(Object.assign(Object.assign({}, state), { dependencies: union(state.dependencies, extraDependencies) }));
};
