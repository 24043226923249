import React from 'react';
import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { FieldErrorItem } from './error-item';
const ListContainer = styled.ul `
  margin: ${spacing.none};
  margin-top: ${spacing.xxSmall};
  padding: ${spacing.none} ${spacing.none} ${spacing.none} ${spacing.small};
  list-style-type: none;
`;
export const FieldErrorList = ({ rawErrors, id }) => {
    if (!rawErrors || rawErrors.length === 0) {
        return null;
    }
    return (React.createElement(ListContainer, { id: id }, rawErrors.map((errorKey) => (React.createElement(FieldErrorItem, { key: errorKey, errorKey: errorKey })))));
};
