export var BusinessType;
(function (BusinessType) {
    BusinessType["SelfEmployed"] = "SelfEmployed";
    BusinessType["LimitedCompany"] = "LimitedCompany";
    BusinessType["Partnership"] = "Partnership";
    BusinessType["NonProfit"] = "NonProfit";
})(BusinessType || (BusinessType = {}));
export var NativeBusinessType;
(function (NativeBusinessType) {
    NativeBusinessType["ImprenditoreIndividuale"] = "ImprenditoreIndividuale";
    NativeBusinessType["LiberoProfessionista"] = "LiberoProfessionista";
    NativeBusinessType["LavoratoreAutonomo"] = "LavoratoreAutonomo";
    NativeBusinessType["SocietaAResponsabilitaLimitata"] = "SocietaAResponsabilitaLimitata";
    NativeBusinessType["SocietaAResponsabilitaLimitataSemplificata"] = "SocietaAResponsabilitaLimitataSemplificata";
    NativeBusinessType["SocietaAResponsabilitaLimitataUnipersonale"] = "SocietaAResponsabilitaLimitataUnipersonale";
    NativeBusinessType["SocietaInAccomanditaSemplice"] = "SocietaInAccomanditaSemplice";
    NativeBusinessType["SocietaInNomeCollettivo"] = "SocietaInNomeCollettivo";
    NativeBusinessType["SocietaCooperativaAResponsabilitaLimitata"] = "SocietaCooperativaAResponsabilitaLimitata";
    NativeBusinessType["SocietaInAccomanditaPerAzioni"] = "SocietaInAccomanditaPerAzioni";
    NativeBusinessType["SocietaSemplice"] = "SocietaSemplice";
    NativeBusinessType["Associazione"] = "Associazione";
    NativeBusinessType["AgenziaStatale"] = "AgenziaStatale";
})(NativeBusinessType || (NativeBusinessType = {}));
export const nativeBusinessTypeInfoList = [
    {
        value: NativeBusinessType.ImprenditoreIndividuale,
        businessType: BusinessType.SelfEmployed,
        title: "Imprenditore individuale" /* CopyIT.BUSINESS_TYPE_IMPRENDITORE_INDIVIDUALE_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.LiberoProfessionista,
        businessType: BusinessType.SelfEmployed,
        title: "Libero professionista" /* CopyIT.BUSINESS_TYPE_LIBERO_PROFESSIONISTA_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.LavoratoreAutonomo,
        businessType: BusinessType.SelfEmployed,
        title: "Lavoratore autonomo" /* CopyIT.BUSINESS_TYPE_LAVORATORE_AUTONOMO_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.SocietaAResponsabilitaLimitata,
        businessType: BusinessType.LimitedCompany,
        title: "S.r.l." /* CopyIT.BUSINESS_TYPE_SOCIETA_A_RESPONSABILITA_LIMITATA_TITLE */,
        description: "Societ\u00E0 a responsabilit\u00E0 limitata" /* CopyIT.BUSINESS_TYPE_SOCIETA_A_RESPONSABILITA_LIMITATA_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.SocietaAResponsabilitaLimitataSemplificata,
        businessType: BusinessType.LimitedCompany,
        title: "S.r.l.s." /* CopyIT.BUSINESS_TYPE_SOCIETA_A_RESPONSABILITA_LIMITATA_SEMPLIFICATA_TITLE */,
        description: "Societ\u00E0 a responsabilit\u00E0 limitata semplificata" /* CopyIT.BUSINESS_TYPE_SOCIETA_A_RESPONSABILITA_LIMITATA_SEMPLIFICATA_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.SocietaAResponsabilitaLimitataUnipersonale,
        businessType: BusinessType.LimitedCompany,
        title: "S.r.l. Uni" /* CopyIT.BUSINESS_TYPE_SOCIETA_A_RESPONSABILITA_LIMITATA_UNIPERSONALE_TITLE */,
        description: "Societ\u00E0 a responsabilit\u00E0 limitata unipersonale" /* CopyIT.BUSINESS_TYPE_SOCIETA_A_RESPONSABILITA_LIMITATA_UNIPERSONALE_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.SocietaInAccomanditaSemplice,
        businessType: BusinessType.Partnership,
        title: "S.a.s." /* CopyIT.BUSINESS_TYPE_SOCIETA_IN_ACCOMANDITA_SEMPLICE_TITLE */,
        description: "Societ\u00E0 in accomandita semplice" /* CopyIT.BUSINESS_TYPE_SOCIETA_IN_ACCOMANDITA_SEMPLICE_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.SocietaInNomeCollettivo,
        businessType: BusinessType.Partnership,
        title: "S.n.c." /* CopyIT.BUSINESS_TYPE_SOCIETA_IN_NOME_COLLETTIVO_TITLE */,
        description: "Societ\u00E0 in nome collettivo" /* CopyIT.BUSINESS_TYPE_SOCIETA_IN_NOME_COLLETTIVO_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.SocietaCooperativaAResponsabilitaLimitata,
        businessType: BusinessType.Partnership,
        title: "S.c.r.l." /* CopyIT.BUSINESS_TYPE_SOCIETA_COOPERATIVA_A_RESPONSABILITA_LIMITATA_TITLE */,
        description: "Societ\u00E0 cooperativa a responsabilit\u00E0 limitata" /* CopyIT.BUSINESS_TYPE_SOCIETA_COOPERATIVA_A_RESPONSABILITA_LIMITATA_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.SocietaInAccomanditaPerAzioni,
        businessType: BusinessType.Partnership,
        title: "S.a.p.a." /* CopyIT.BUSINESS_TYPE_SOCIETA_IN_ACCOMANDITA_PER_AZIONI_TITLE */,
        description: "Societ\u00E0 in accomandita per azioni" /* CopyIT.BUSINESS_TYPE_SOCIETA_IN_ACCOMANDITA_PER_AZIONI_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.SocietaSemplice,
        businessType: BusinessType.Partnership,
        title: "S.s." /* CopyIT.BUSINESS_TYPE_SOCIETA_SEMPLICE_TITLE */,
        description: "Societ\u00E0 Semplice" /* CopyIT.BUSINESS_TYPE_SOCIETA_SEMPLICE_DESCRIPTION */,
    },
    {
        value: NativeBusinessType.Associazione,
        businessType: BusinessType.NonProfit,
        title: "Associazione" /* CopyIT.BUSINESS_TYPE_ASSOCIAZIONE_TITLE */,
        description: undefined,
    },
    {
        value: NativeBusinessType.AgenziaStatale,
        businessType: BusinessType.NonProfit,
        title: "Agenzia statale" /* CopyIT.BUSINESS_TYPE_AGENZIA_STATALE_TITLE */,
        description: undefined,
    },
];
