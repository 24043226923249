var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css } from 'styled-components';
import { palette } from '@izettle/otto';
import { attachLoadingIconOverlayToLauncher } from './loading-icon-overlay';
// locale code: 1) es-ES 2) es-MX
// language code: 1) es 2) es-MX
const localeToLang = (isoLocale) => {
    // return these language codes as is
    const exceptions = ['es-MX', 'pt-BR'];
    if (exceptions.includes(isoLocale)) {
        return isoLocale;
    }
    const matches = /^([a-z]{2})(-[a-z]{2})?$/i.exec(isoLocale);
    // e.g. return da for da-DK
    if (matches) {
        return matches[1];
    }
    return 'en';
};
const createAuthenticate = (authUrl, onError) => () => {
    return fetch(authUrl, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
    })
        .then((resp) => resp.json())
        .catch(onError);
};
const registerPopupEvents = (instance, props) => {
    if (props.onPopupLoading) {
        instance.registerHook('loading', props.onPopupLoading);
    }
    if (props.onPopupOpen) {
        instance.registerHook('open', props.onPopupOpen);
    }
    if (props.onPopupClose) {
        instance.registerHook('close', props.onPopupClose);
    }
};
export const loadUjetScript = (scriptUrl) => __awaiter(void 0, void 0, void 0, function* () {
    if (typeof UJET !== 'undefined') {
        // script is already loaded
        return;
    }
    return new Promise((resolve, reject) => {
        const headerScript = document.createElement('script');
        headerScript.src = scriptUrl;
        headerScript.async = true;
        headerScript.onload = () => resolve();
        headerScript.onerror = reject;
        document.querySelector('head').appendChild(headerScript);
    });
});
const disableUjetLauncherFocus = (onError) => {
    const iframe = document.querySelector('#ujet-launcher > iframe');
    const launcherDocument = iframe === null || iframe === void 0 ? void 0 : iframe.contentDocument;
    if (!launcherDocument) {
        return onError(new Error('Unable to find UJET launcher iframe document'));
    }
    iframe.tabIndex = -1;
};
export const initializeUjet = (props) => {
    const { locale, authUrl, config, onError, paypalTheme } = props;
    const chatIcon = paypalTheme ? 'ujet-chat-start-blue-500.svg' : 'ujet-chat-start-lilac-80.svg';
    const chatBoxIcon = paypalTheme ? 'ujet-icons/ujet-zettle-logo-blue.png' : 'logos/z-logo-primary-positive.jpg';
    const partialConfig = {
        logo: `https://cdn.izettle.com/${chatBoxIcon}`,
        right: '12px',
        bottom: '12px',
        launcher: {
            right: '20px',
            bottom: '20px',
            cssText: css `
        img#chat,
        .close img#chat,
        .open img#minimize {
          transform: scale(0.66667);
        }
      `.join(''),
            chatIcon: `https://cdn.izettle.com/ujet-icons/${chatIcon}`,
            style: {
                '--background-color': palette.white,
                '--count-color': palette.salmon60,
            },
        },
        style: {
            '--primary-color': palette.magnetic70,
            '--primary-font': 'Zent, sans-serif',
        },
    };
    const ujetInstance = new UJET(Object.assign(Object.assign(Object.assign({}, partialConfig), config), { lang: localeToLang(locale), authenticate: createAuthenticate(authUrl, onError) }));
    attachLoadingIconOverlayToLauncher(ujetInstance, onError, paypalTheme);
    registerPopupEvents(ujetInstance, props);
    disableUjetLauncherFocus(onError);
    return ujetInstance;
};
