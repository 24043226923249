import { FunctionStepFuncKey } from 'src/components/Questionnaire/types';
import { RoleFieldName } from 'src/schema/common/owner-structure';
import { getRoleLabels } from 'src/schema/gaps/LUX/data-helpers';
import { template } from 'src/schema/template';
export const introScreenTemplate = template(({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        stepName: 'PreparationScreen',
        content: {
            header: translate('GAP_PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            helpTexts: [
                {
                    title: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_TITLE'),
                    body: translate('GAP_PREPARATION_SCREEN_HELP_ACCORDION_CONTENT'),
                },
            ],
            items: [
                {
                    icon: 'Person',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                },
                {
                    icon: 'Home',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('GAP_LTD_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_VERIFICATION_TITLE'),
                    description: translate('GAP_LTD_PREPARATION_SCREEN_ITEM_VERIFICATION_DESCRIPTION'),
                },
            ],
        },
    },
}));
export const legalPeoplePreparationScreenTemplate = template(({ translate }) => ({
    componentName: 'PreparationScreen',
    applicationVariables: {
        segment: 'ownerStructure',
        stepName: 'sectionIntro',
        content: {
            header: translate('GAP_LEGAL_PEOPLE_PREPARATION_SCREEN_HEADER'),
            button: translate('FORM_BUTTON'),
            helpTexts: [],
            items: [
                {
                    icon: 'IdentityCard',
                    description: translate('GAP_LTD_LEGAL_PEOPLE_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
            ],
        },
    },
}));
export const beneficialOwnersListTemplate = (personStep) => template(({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleBeneficialOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'beneficialOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleBeneficialOwners,
        signatoryRoleLabel: translate('GAP_OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [],
        },
    },
}));
export const alternativeOwnersListTemplate = (personStep) => template(({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleAlternativeOwners'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'alternativeOwners',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleAlternativeOwners,
        signatoryRoleLabel: translate('GAP_OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_LABEL'),
            description: translate('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [],
        },
    },
}));
export const directorsListTemplate = (personStep) => template(({ translate }) => ({
    componentName: 'OwnerList',
    backendValidation: true,
    fields: ['roleDirectors'],
    personStep: personStep(translate),
    applicationVariables: {
        stepName: 'directorsList',
        segment: 'ownerStructure',
        roleFieldName: RoleFieldName.roleDirectors,
        signatoryRoleLabel: translate('GAP_OWNER_STRUCTURE_ROLE_VALUE_SIGNATORY'),
        personRoleLabels: getRoleLabels(translate),
        content: {
            title: translate('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
            description: translate('OWNER_STRUCTURE_DIRECTORS_DESCRIPTION'),
            instruction: translate('OWNER_STRUCTURE_ROLE_SELECT_ALL_INSTRUCTION'),
            helpTexts: [],
        },
    },
}));
export const hasUBOTemplate = template(({ translate }) => ({
    fields: ['hasUBO'],
    JSONSchema: {
        required: ['hasUBO'],
        type: 'object',
        title: translate('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
        properties: {
            hasUBO: {
                title: translate('OWNER_STRUCTURE_HAS_UBO_DESCRIPTION'),
                type: 'boolean',
                oneOf: [
                    { enum: [true], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_YES') },
                    { enum: [false], title: translate('OWNER_STRUCTURE_HAS_UBO_VALUE_NO') },
                ],
            },
        },
    },
    UISchema: {
        hasUBO: {
            'ui:widget': 'CustomRadioGroupWidget',
        },
        'ui:options': {
            titleStyle: 'LargeBold',
        },
    },
    applicationVariables: {
        stepName: 'hasUBO',
        segment: 'ownerStructure',
        content: {
            helpTexts: [],
        },
    },
}));
export const initLegalPersonsFunction = () => ({
    functions: [FunctionStepFuncKey.initLegalPersons],
    fields: ['legalPersons'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'legalPersonsFunc',
        segment: 'ownerStructure',
    },
});
export const normalizeSignatoryFieldsFunction = () => ({
    functions: [FunctionStepFuncKey.GAP_normalizeSignatoryFields],
    fields: ['signatoryFirstName', 'signatoryLastName'],
    dependsOnFields: [],
    applicationVariables: {
        stepName: 'normalizeSignatoryFieldsFunc',
        segment: 'personalDetails',
    },
});
export const overviewPageTemplate = template(({ translate }) => ({
    componentName: 'OverviewPage',
    applicationVariables: {
        stepName: 'overviewPage',
        version: '',
        confirmationScreen: {
            title: translate('GAP_OVERVIEW_CONFIRMATION_TITLE'),
            subtitle: translate('GAP_OVERVIEW_CONFIRMATION_SUB_TITLE'),
            button: translate('GAP_OVERVIEW_CLOSE_BUTTON'),
        },
        processingScreen: {
            title: translate('PROCESSING_PAGE_TITLE'),
            subtitle: translate('PROCESSING_PAGE_SUBTITLE'),
            button: translate('PROCESSING_PAGE_CLOSE_BUTTON'),
        },
        content: {
            title: '',
            subtitle: '',
            submitButton: translate('GAP_OVERVIEW_SUBMIT_BUTTON'),
            submitInstructions: '',
        },
    },
}));
