var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { useEligibleFormContextValue } from 'src/providers/form';
import { useAuthStateValue } from '../../providers/auth';
import { useTracker } from '../use-tracker';
import { prepareRequestData, prepareValidationErrors, sendRequest, trackValidationError, validateResponseDataFieldNames, isValidationResponseDataValid, } from './helpers';
import { ValidationResponseSuccessValue, } from './types';
export const useBackendStepValidation = (stepObject, mapDataForRequest, mapErrorsFromResponse) => {
    const { state: questionnaire } = useQuestionnaireState();
    const [state, setValidationState] = useState({ current: 'Initial' });
    const { authClient } = useAuthStateValue();
    const { formContext } = useEligibleFormContextValue();
    const { trackEvent } = useTracker();
    useEffect(() => {
        if (state.current === 'ValidationError') {
            trackValidationError(trackEvent, stepObject, questionnaire);
        }
    }, [state, stepObject, trackEvent, questionnaire]);
    const reset = useCallback(() => {
        if (state.current !== 'Initial') {
            setValidationState({ current: 'Initial' });
        }
    }, [setValidationState, state]);
    const validate = useCallback((stepData) => __awaiter(void 0, void 0, void 0, function* () {
        if (!stepObject.backendValidation) {
            setValidationState({ current: 'Successful' });
            return true;
        }
        setValidationState({ current: 'Validating' });
        try {
            const { requestPayload, allowedResponseFieldNames } = prepareRequestData(stepData, stepObject, questionnaire, mapDataForRequest);
            const { data } = yield sendRequest(requestPayload, authClient, formContext);
            if (!isValidationResponseDataValid(data)) {
                throw new Error('Validation response payload is malformed');
            }
            validateResponseDataFieldNames(data, allowedResponseFieldNames);
            if (data.success === ValidationResponseSuccessValue.TRUE) {
                setValidationState({ current: 'Successful' });
                return true;
            }
            setValidationState({
                current: 'ValidationError',
                errors: prepareValidationErrors(data, stepObject, mapErrorsFromResponse),
            });
            return false;
        }
        catch (error) {
            setValidationState({ current: 'GenericError' });
            error.message = `Unexpected step validation error: ${error.message}`;
            datadogRum.addError(error);
            return false;
        }
    }), [setValidationState, questionnaire, mapDataForRequest, mapErrorsFromResponse, stepObject]);
    return useMemo(() => ({ state, reset, validate }), [state, reset, validate]);
};
