import { getCurrentStepObject } from 'src/components/Questionnaire/state/helpers';
const getStepDataPayload = (stepData) => {
    return {
        businessType: stepData.businessType,
        nativeBusinessType: stepData.nativeBusinessType,
        categoryId: stepData.businessCategoryId,
        subCategoryId: stepData.businessSubCategoryId,
    };
};
const getStepInfoPayload = (stepObject) => {
    return {
        stepName: stepObject.applicationVariables.stepName,
        segment: stepObject.applicationVariables.segment,
    };
};
export const getQuestionnairePayload = (questionnaire) => {
    return Object.assign(Object.assign({}, getStepDataPayload(questionnaire.stepData)), getStepInfoPayload(getCurrentStepObject(questionnaire)));
};
