import React, { useCallback, useMemo, useState } from 'react';
import { complement, findLast, isNil } from 'ramda';
import { PageTitleContext } from './context';
export const PageTitleProvider = ({ children }) => {
    const [layers, setLayers] = useState([]);
    const currentTopTitle = useMemo(() => findLast(complement(isNil), layers), [layers]);
    const setPageTitleForLayer = useCallback((title, layerIndex) => setLayers((value) => {
        const valueClone = value.slice();
        valueClone[layerIndex] = title;
        return valueClone;
    }), []);
    return (React.createElement(PageTitleContext.Provider, { value: { currentTopTitle, setPageTitleForLayer } }, children));
};
