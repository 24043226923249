var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { config } from 'src/config';
import { makeTracedRequest } from './make-traced-request';
export const makeOAuthRequest = (axiosConfig, authClient, transactionName) => __awaiter(void 0, void 0, void 0, function* () {
    const token = yield authClient.getToken();
    return makeTracedRequest(Object.assign(Object.assign({ timeout: config.axios.timeout }, axiosConfig), { headers: Object.assign(Object.assign({}, axiosConfig.headers), { Authorization: `Bearer ${token}` }) }), transactionName);
});
