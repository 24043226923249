import { datadogRum } from '@datadog/browser-rum';
const scrollToTop = (_state, effect) => {
    var _a, _b;
    const main = window.document.querySelector('main');
    // ensure content not hidden behind address bar
    // case for Samsung S9, Chrome
    main === null || main === void 0 ? void 0 : main.scrollIntoView();
    (main !== null && main !== void 0 ? main : window).scrollTo({ top: (_b = (_a = effect.data) === null || _a === void 0 ? void 0 : _a.top) !== null && _b !== void 0 ? _b : 0 });
};
const focusElement = (_state, effect) => {
    var _a;
    (_a = effect.data) === null || _a === void 0 ? void 0 : _a.focus();
};
const focusCancelButton = () => {
    var _a;
    (_a = document.querySelector('#cancelButton')) === null || _a === void 0 ? void 0 : _a.focus();
};
// Since there will be several effects that would require data from contexts, make this a hook
export const usePopupsEffectsMap = () => {
    return {
        focusElement,
        focusCancelButton,
        scrollToTop,
        addDatadogRumBreadcrumb: (_state, { data }) => datadogRum.addAction(JSON.stringify(data.category)),
    };
};
