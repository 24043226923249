import React from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
import { SIGNATORY_ACTION } from 'src/schema/gaps/LUX/data';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
import { GapOwnerStructureSection } from './OwnerStructureSection';
const IntroContainer = styled.div `
  margin-bottom: ${spacing.small};
`;
const renderAddressValue = ({ building, streetAddress, city, county, postalCode }) => (React.createElement(React.Fragment, null,
    React.createElement(Value, null, `${building} ${streetAddress}`),
    React.createElement(Value, null, city),
    React.createElement(Value, null, county),
    postalCode && React.createElement(Value, null, postalCode.toString().toUpperCase())));
const NewSignatoryOverview = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(IntroContainer, null,
            React.createElement(Text.Title2, null, text('GAP_OVERVIEW_TITLE')),
            React.createElement(Text.Regular, { className: "override-text" }, text('GAP_OVERVIEW_SUB_TITLE'))),
        React.createElement(Section, { name: "personalDetails", title: text('GAP_OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
            React.createElement(Field, { fieldNames: ['signatoryFirstName', 'signatoryLastName'], title: text('OVERVIEW_FIELD_SIGNATORY_NAME_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, `${stepData.signatoryFirstName} ${stepData.signatoryLastName}`)),
            React.createElement(Field, { fieldNames: ['signatoryDateOfBirth'], title: text('OVERVIEW_FIELD_DATE_OF_BIRTH_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, stepData.signatoryDateOfBirth)),
            React.createElement(Field, { fieldNames: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE'), editable: true, conditionalRendering: true }, renderAddressValue({
                streetAddress: stepData.signatoryStreetAddress,
                postalCode: stepData.signatoryPostalCode,
                city: stepData.signatoryCity,
                building: stepData.signatoryBuilding,
                county: stepData.signatoryCounty,
            })),
            React.createElement(Field, { fieldNames: ['signatoryNationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, text(`NATIONALITY_VALUE_${stepData.signatoryNationality}`))),
            React.createElement(Field, { fieldNames: ['signatoryPhoneNumber'], title: text('OVERVIEW_FIELD_SIGNATORY_PHONE_NUMBER_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, stepData.signatoryPhoneNumber))),
        React.createElement(GapOwnerStructureSection, { renderAddressValue: renderAddressValue, translationKeyGroup: "NONPROFIT" })));
};
const SignatoryOverview = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(IntroContainer, null,
            React.createElement(Text.Title2, null, text('GAP_OVERVIEW_TITLE')),
            React.createElement(Text.Regular, { className: "override-text" }, text('GAP_OVERVIEW_SUB_TITLE'))),
        React.createElement(Section, { name: "personalDetails", title: text('GAP_OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
            React.createElement(Field, { fieldNames: ['existingSignatoryFirstName', 'existingSignatoryLastName'], title: text('OVERVIEW_FIELD_SIGNATORY_NAME_TITLE') },
                React.createElement(Value, null, `${stepData.existingSignatoryFirstName} ${stepData.existingSignatoryLastName}`)),
            React.createElement(Field, { fieldNames: ['signatoryNationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, text(`NATIONALITY_VALUE_${stepData.signatoryNationality}`)))),
        React.createElement(GapOwnerStructureSection, { renderAddressValue: renderAddressValue, translationKeyGroup: "NONPROFIT" })));
};
export const GAP_NPO = () => {
    const { stepData } = useOverviewPageContextValue();
    if (stepData.signatoryAction === SIGNATORY_ACTION.SET_NATIONALITY) {
        return React.createElement(SignatoryOverview, null);
    }
    if (stepData.signatoryAction === SIGNATORY_ACTION.CHANGE_SIGNATORY) {
        return React.createElement(NewSignatoryOverview, null);
    }
    throw new Error(`Unsupported signatoryAction "${stepData.signatoryAction}"`);
};
