import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { BusinessType } from 'src/utils/country/GB/data';
import { createRoleFieldsTitles } from 'src/utils/country/GB/data-helpers';
import { formatCurrencyRange, formatPercentRange } from 'src/utils/country/GB/format';
import { AddressValue } from '../../../AddressValue';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Value } from '../../../Value';
import { OwnerStructureSection } from '../../shared/OwnerStructureSection';
import { UsageOfIZettleSection } from '../../shared/UsageOfIZettleSection';
import { BusinessDetailsSection } from './BusinessDetailsSection';
import { PersonalDetailsSection } from './PersonalDetailsSection';
export const LegalPersonContent = () => {
    const { text } = useLocalization();
    const { stepData: person } = useOverviewPageContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { fieldNames: ['dateOfBirth'], title: text('OVERVIEW_FIELD_DATE_OF_BIRTH_TITLE') },
            React.createElement(Value, null, person.dateOfBirth)),
        React.createElement(Field, { fieldNames: ['nationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE') }, person.nationality && React.createElement(Value, null, text(`NATIONALITY_VALUE_${person.nationality}`))),
        React.createElement(Field, { fieldNames: ['building', 'streetAddress', 'city', 'county', 'postalCode'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE') },
            React.createElement(AddressValue, { addressData: person }))));
};
export const GB = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const businessType = stepData.businessType;
    const showOwnerStructure = stepData.businessType !== BusinessType.SelfEmployed;
    return (React.createElement(React.Fragment, null,
        React.createElement(BusinessDetailsSection, null),
        React.createElement(PersonalDetailsSection, null),
        showOwnerStructure && (React.createElement(OwnerStructureSection, { legalPersonContentTemplate: LegalPersonContent, roleFieldsTitles: createRoleFieldsTitles(businessType, text) })),
        React.createElement(UsageOfIZettleSection, { formatCurrencyRange: formatCurrencyRange, formatPercentRange: formatPercentRange })));
};
