import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { spacing, colors, sizeNumeric } from '@izettle/otto';
import { MouseClickFocusRemover } from '@izettle/react';
const containerSize = sizeNumeric.xSmall;
const outerCircleRadius = 10;
const innerDotRadius = 6;
const OuterCircle = styled.circle.attrs({
    cx: '50%',
    cy: '50%',
    r: outerCircleRadius,
}) ``;
const InnerCircle = styled.circle.attrs({
    cx: '50%',
    cy: '50%',
    r: innerDotRadius,
}) ``;
const SvgContainer = styled.svg `
  background: transparent;
  min-width: 100%;
  width: 100%;
  height: 100%;

  ${OuterCircle} {
    fill: none;
    stroke-width: 1;
    stroke: ${({ checked }) => (checked ? colors.selector.selected.default : colors.selector.initial.default)};
    ${({ disabled }) => disabled && `stroke: ${colors.action.primary.background.disabled}`};
  }

  ${InnerCircle} {
    fill: ${({ disabled }) => !disabled ? colors.selector.selected.default : colors.action.primary.background.disabled};

    opacity: ${({ checked }) => (checked ? '1' : '0')};
    transition: opacity 50ms ease-in-out;
  }
`;
const OuterContainer = styled.div `
  position: relative;

  min-width: ${containerSize}px;
  width: ${containerSize}px;
  height: ${containerSize}px;
  margin: 0;
  margin-right: ${spacing.small};
  font-style: normal;

  :focus {
    outline: none;
  }
`;
export const RadioIcon = forwardRef((props, ref) => (React.createElement(OuterContainer, Object.assign({}, props, { ref: ref }),
    React.createElement(MouseClickFocusRemover, null,
        React.createElement(SvgContainer, { "aria-hidden": true, checked: props.checked, disabled: props.disabled },
            React.createElement(OuterCircle, null),
            React.createElement(InnerCircle, null))))));
