import React, { forwardRef, memo } from 'react';
import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { Button } from '@izettle/react';
const StyledButton = styled(Button) `
  &&&& {
    // ensure that icon and title are aligned when title is wrapped
    align-items: start;

    // provide padding around content instead of original min-height (32px for small and 48px for regular)
    // we assume that main content has line-height of 24px
    min-height: unset;
    padding: ${(p) => (p.size === 'small' ? spacing.tiny : spacing.xSmall)} ${spacing.small};

    // replace text-align: center for content
    & > span > span {
      text-align: start;
    }

    // remove negative outer margin for icon
    & > span > span[role='img'] {
      margin-left: unset;
    }
  }
`;
/**
 * Preferred implementation of inline button that fixes some design issues around it
 */
export const InlineButtonWithIcon = memo(forwardRef((props, ref) => {
    return React.createElement(StyledButton, Object.assign({ iconPosition: "left" }, props, { ref: ref }));
}));
