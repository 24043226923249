import React from 'react';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { colors, IconErrorFilledSmall, spacing, typography } from '@izettle/otto';
import { SvgIcon } from '@izettle/react';
// TODO: Verify if we can suppress rendering the <p> tag while using mdElement
// This is causing extra margins to be added to the error messages, which force us to css override it here
const ErrorContainer = styled.div `
  display: grid;
  grid-template-columns: auto 1fr;

  ${typography.regular};
  color: ${colors.text.error};

  /* Markdown adds a p tag around it's output */
  p {
    margin: ${spacing.none};
  }
`;
const ErrorIcon = styled(SvgIcon).attrs({
    icon: IconErrorFilledSmall,
}) `
  margin-right: ${spacing.xxSmall};
`;
const ErrorContent = styled.div `
  p {
    ${typography.regular};
    color: ${colors.text.error};
  }
`;
export const FieldError = ({ children }) => {
    const { text } = useLocalization();
    const errorIconTitle = text('ERROR_ICON_TITLE');
    return (React.createElement(ErrorContainer, { className: "field-error" },
        React.createElement(ErrorIcon, { title: errorIconTitle }),
        React.createElement(ErrorContent, null, children)));
};
