import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { spacing, borderRadius, colors } from '@izettle/otto';
const Container = styled.div `
  height: ${spacing.xxSmall};
  border-radius: ${borderRadius.small};
  background-color: ${colors.background.groupedSecondary};
`;
const AnimatedFiller = styled(animated.div) `
  height: 100%;
  background-color: ${colors.border.action};
  border-radius: ${borderRadius.small};
  user-select: none;
`;
export const ProgressBar = ({ value, className }) => {
    const fillerStyle = useSpring({
        to: { width: `${value}%` },
        config: {
            friction: 15,
            clamp: true,
        },
    });
    return (React.createElement(Container, { className: className },
        React.createElement(AnimatedFiller, { style: fillerStyle }, "\u00A0")));
};
