import { useCallback } from 'react';
import { config } from '../../config';
import { FormStatus, useFormContextValue } from '../../providers/form';
import { usePageName } from '../use-page-name';
import { trackEventKyc2 } from './tracking';
export const useTracker = () => {
    const { formContext } = useFormContextValue();
    const pageName = usePageName();
    const trackEvent = useCallback((action, payload, options) => {
        var _a;
        if (config.nodeEnv === 'test' || formContext.status === FormStatus.INELIGIBLE || !formContext.tracking.enabled) {
            return;
        }
        const { tracking: { formType }, user: { countryId }, } = formContext;
        const formContextPayload = {
            countryId,
            formType,
        };
        const completePayload = Object.assign(Object.assign({}, formContextPayload), payload); // TS issue requires assertion: https://github.com/microsoft/TypeScript/issues/28884#issuecomment-448356158
        trackEventKyc2((_a = options === null || options === void 0 ? void 0 : options.page) !== null && _a !== void 0 ? _a : pageName, action, completePayload);
    }, [formContext]);
    return { trackEvent };
};
