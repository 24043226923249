import React, { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { datadogRum } from '@datadog/browser-rum';
import { Ujet } from 'src/components/UJET';
import { updateUJETIcons } from 'src/components/UJET/loading-icon-overlay';
import { config } from 'src/config';
import { Action, useTracker } from 'src/hooks/use-tracker';
import { useFormContextValue } from 'src/providers/form';
import { getUserLocale } from 'src/providers/form/helpers';
import { useThemeInfo } from '../../../hooks/use-theme-info';
export const ChatSupportWidget = () => {
    const { formContext } = useFormContextValue();
    const { trackEvent } = useTracker();
    const { paypalTheme } = useThemeInfo();
    const [enabled] = useLocalStorage('ppui-theme', document.body.classList.contains('theme-ppui')) || '';
    useEffect(() => {
        const ujetLauncher = document.getElementById('ujet-launcher');
        if (window.ujetInstance && ujetLauncher) {
            updateUJETIcons(window.ujetInstance, paypalTheme);
        }
    }, [paypalTheme]);
    const updateChatPopupboxIcon = () => {
        const chatPopupLogo = document.getElementsByClassName('head_logo');
        if (chatPopupLogo.length) {
            const findSourceImg = chatPopupLogo[0].getElementsByTagName('img');
            if (findSourceImg.length) {
                const chatBoxIcon = paypalTheme ? 'ujet-icons/ujet-zettle-logo-blue.png' : 'logos/z-logo-primary-positive.jpg';
                findSourceImg[0].setAttribute('src', `https://cdn.izettle.com/${chatBoxIcon}`);
            }
        }
    };
    const popupEvents = useMemo(() => ({
        // 'loading' invoked on the first launcher click, while 'open' isn't
        onPopupLoading: () => trackEvent(Action.OpenedSupportChat, {}),
        onPopupOpen: () => {
            updateChatPopupboxIcon();
            trackEvent(Action.OpenedSupportChat, {});
        },
        onPopupClose: () => trackEvent(Action.ClosedSupportChat, {}),
    }), [trackEvent]);
    return (React.createElement(Ujet, Object.assign({}, config.ujet, { locale: getUserLocale(formContext), onError: datadogRum.addError }, popupEvents, { paypalTheme: enabled === 'true' })));
};
