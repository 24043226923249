import React, { useCallback, useContext } from 'react';
import { includes, filter, flatten, map, reject, identity } from 'ramda';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { colors, typography } from '@izettle/otto';
import { Select, SelectOption, SelectOptionTitle } from '@izettle/react';
import { FieldContext } from '../FieldTemplate/context';
import { useEnumOptions } from '../hooks/use-enum-options';
const StyledSelect = styled(Select) `
  div {
    ${typography.regular}
    label {
      color: ${colors.text.default};
    }
    svg {
      color: ${colors.icon.default};
    }
  }
`;
const groupOptions = (groups, enumOptions) => {
    const matchedGroups = map((group) => ({
        label: group.label,
        enumOptions: filter((option) => group.values.includes(option.value), enumOptions),
    }), groups);
    const matchedOptions = flatten(map((group) => group.enumOptions, matchedGroups));
    const unmatchedOptions = reject((option) => includes(option, matchedOptions), enumOptions);
    const result = [...matchedGroups, { enumOptions: unmatchedOptions }];
    return filter((group) => Boolean(group.enumOptions.length), result);
};
const renderChildren = (enumOptions, options) => {
    const renderOption = ({ value, label, description }) => (React.createElement(SelectOption, { key: value, value: value, text: label, description: description }));
    if (!options.groups) {
        // no groups config, just render a list of options
        return map(renderOption, enumOptions);
    }
    const groups = groupOptions(options.groups, enumOptions);
    if (groups.length === 1 && !groups[0].label) {
        // a single group found with no label
        return map(renderOption, groups[0].enumOptions);
    }
    return flatten(groups.map((group) => {
        var _a;
        return [
            React.createElement(SelectOptionTitle, { key: group.label, text: (_a = group.label) !== null && _a !== void 0 ? _a : ' ' }),
            ...map(renderOption, group.enumOptions),
        ];
    }));
};
/**
 * Renders Select component with groups support
 */
export const CustomSelect = ({ value, options, onChange, placeholder, id }) => {
    const { text } = useLocalization();
    const { hasError, errorElementId, descriptionElementId } = useContext(FieldContext);
    const enumOptions = useEnumOptions(options);
    const handleOnChange = useCallback((newValue) => {
        if (newValue !== value) {
            // The JSONReactSchema library doesn't seem to treat null as a non-existing
            // value and requires it be set to `undefined` in order to consider a
            // required field to not be set.
            onChange(newValue !== null && newValue !== void 0 ? newValue : undefined);
        }
    }, [value, onChange]);
    const labelString = placeholder !== null && placeholder !== void 0 ? placeholder : text('SELECT_PLACEHOLDER');
    const label = options.floatingLabelDisabled && value ? undefined : labelString;
    const ariaDescribedBy = [errorElementId, descriptionElementId].filter(identity).join(' ');
    // Select props:
    // `label` - placeholder
    // `textSubmit` - text for button shown in mobile to dismiss popup
    // `textInputButton` - hint for toggle button
    return (React.createElement(StyledSelect, Object.assign({ id: id, value: value, onChange: handleOnChange, label: label, textSubmit: text('SELECT_DONE_BUTTON'), textInputButton: text('SELECT_TOGGLE_HINT'), ariaInvalid: hasError }, (ariaDescribedBy ? { ariaDescribedBy } : {}), { isErrorStateOverriden: hasError }), renderChildren(enumOptions, options)));
};
