import { CROSSED_OUT_CERTIFICATION_KEY, NON_USA_TAXPAYER_ENTITY_CERTIFICATION_KEYS, NON_USA_TAXPAYER_SIGNATORY_CERTIFICATION_KEYS, USA_TAXPAYER_CERTIFICATION_KEYS, } from './constants';
export const getTermsTextKeys = (stepData) => {
    let termsKeys = [];
    if (stepData.signatoryUnderUsaTaxLaw === true || stepData.businessUnderUsaTaxLaw === true) {
        termsKeys = USA_TAXPAYER_CERTIFICATION_KEYS;
    }
    else if (stepData.signatoryUnderUsaTaxLaw === false) {
        termsKeys = NON_USA_TAXPAYER_SIGNATORY_CERTIFICATION_KEYS;
    }
    else if (!stepData.businessUnderUsaTaxLaw) {
        // TODO: check more specifically if businessUnderUsaTaxLaw === false once the US entities are added to the flow
        termsKeys = NON_USA_TAXPAYER_ENTITY_CERTIFICATION_KEYS;
    }
    else {
        throw new Error('Certification keys unexpected for this specific case.');
    }
    if (stepData.signatorySubjectToUsaBackupWithholding === true ||
        stepData.businessSubjectToUsaBackupWithholding === true) {
        termsKeys = [...termsKeys, CROSSED_OUT_CERTIFICATION_KEY];
    }
    return termsKeys;
};
