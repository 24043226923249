import React from 'react';
import { Questionnaire } from 'src/components/Questionnaire';
import { getNextDisplayableStepIndexByFieldName, getStepObject } from 'src/components/Questionnaire/state/helpers';
export const QuestionnaireEditModePopupComponent = ({ popup }) => {
    const { questionnaireState, fieldName, options, onEditComplete } = popup.params;
    const stepData = Object.assign(Object.assign({}, questionnaireState.stepData), options === null || options === void 0 ? void 0 : options.extraStepData);
    const currentStepIndex = getNextDisplayableStepIndexByFieldName(questionnaireState.stepList, stepData, fieldName);
    if (currentStepIndex === null) {
        throw new Error(`Displayable step not found for field '${fieldName}' when starting edit mode`);
    }
    const initialState = Object.assign(Object.assign({}, questionnaireState), { stepData,
        currentStepIndex, editMode: true, editOrigin: getStepObject(questionnaireState.stepList, currentStepIndex).ID, onEditComplete });
    return React.createElement(Questionnaire, { initialState: initialState });
};
