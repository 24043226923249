import { isSchemaTypeNull } from '../FieldTemplate/helpers';
const getVisibleObjectProperties = (objectFieldTemplateProps) => {
    const { properties, uiSchema, schema, formData } = objectFieldTemplateProps;
    const isPropVisible = ({ name: propName }) => {
        var _a, _b;
        if (formData[`omit_${propName}`] !== true) {
            // keeping prop
            return true;
        }
        if (!uiSchema[propName] || !((_a = uiSchema[propName]['ui:options']) === null || _a === void 0 ? void 0 : _a.omittable)) {
            throw new Error(`Trying to omit not-omittable property '${propName}'`);
        }
        if ((_b = schema.required) === null || _b === void 0 ? void 0 : _b.includes(propName)) {
            throw new Error(`Cannot omit required property '${propName}'`);
        }
        return false;
    };
    return properties.filter(isPropVisible);
};
export const getPropertyFieldSets = (objectFieldTemplateProps) => {
    const visibleProperties = getVisibleObjectProperties(objectFieldTemplateProps);
    return visibleProperties.reduce((fieldSets, currentProp) => {
        var _a, _b;
        if (isSchemaTypeNull((_b = (_a = objectFieldTemplateProps.schema) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b[currentProp.name])) {
            // when we detect a property with schema.type === 'null', we consider it being a legend item
            // and starting a new fieldset
            fieldSets.push({ legend: currentProp.content, props: [], key: currentProp.name });
            return fieldSets;
        }
        if (fieldSets.length === 0) {
            // if we have no fieldset yet, adding one
            fieldSets.push({ legend: null, props: [], key: 'default' });
        }
        // add property to the last fieldset
        fieldSets[fieldSets.length - 1].props.push(currentProp);
        return fieldSets;
    }, []);
};
