import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { useEligibleFormContextValue } from 'src/providers/form';
import { getUserLocale } from 'src/providers/form/helpers';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { PrefilledAddressValue } from '../../../PrefilledAddressValue';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
import { convertDateToLocalMarketFormat } from '../helpers';
import { getBusinessTypeValue } from './helpers';
export const GAP_USA_TAX_DETAILS_SOLE_TRADER = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const { formContext } = useEligibleFormContextValue();
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { name: "businessDetails", title: text('GAP_OVERVIEW_SECTION_BUSINESS_DETAILS_TITLE') },
            React.createElement(Field, { fieldNames: ['businessType'], title: text('OVERVIEW_FIELD_BUSINESS_TYPE_TITLE') },
                React.createElement(Value, null, stepData.businessType && getBusinessTypeValue(stepData.businessType, text))),
            React.createElement(Field, { fieldNames: ['businessLegalName'], title: text('OVERVIEW_FIELD_BUSINESS_NAME_TITLE') },
                React.createElement(Value, null, stepData.businessLegalName)),
            React.createElement(Field, { fieldNames: [
                    'businessLegalAddress',
                    'businessLegalAddressLine2',
                    'businessLegalZipCode',
                    'businessLegalCity',
                    'businessLegalState',
                ], title: text('OVERVIEW_FIELD_ADDRESS_TITLE') },
                React.createElement(PrefilledAddressValue, null))),
        React.createElement(Section, { name: "personalDetails", title: text('OVERVIEW_SECTION_PERSONAL_DETAILS_TITLE') },
            React.createElement(Field, { fieldNames: ['signatoryFirstName', 'signatoryLastName'], title: text('OVERVIEW_FIELD_SIGNATORY_NAME_TITLE') },
                React.createElement(Value, null, `${stepData.signatoryFirstName} ${stepData.signatoryLastName}`)),
            React.createElement(Field, { fieldNames: ['signatoryDateOfBirth'], title: text('OVERVIEW_FIELD_DATE_OF_BIRTH_TITLE') }, stepData.signatoryDateOfBirth && (React.createElement(Value, null, convertDateToLocalMarketFormat(stepData.signatoryDateOfBirth, getUserLocale(formContext))))),
            React.createElement(Field, { fieldNames: ['signatoryNationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE') }, stepData.signatoryNationality && React.createElement(Value, null, text(`NATIONALITY_VALUE_${stepData.signatoryNationality}`))),
            React.createElement(Field, { fieldNames: ['signatoryUnderUsaTaxLaw'], title: text('OVERVIEW_FIELD_SIGNATORY_UNDER_TAX_LAWS_TITLE'), conditionalRendering: true, editable: true },
                React.createElement(Value, null, stepData.signatoryUnderUsaTaxLaw ? text('OVERVIEW_PAGE_VALUE_YES') : text('OVERVIEW_PAGE_VALUE_NO'))),
            React.createElement(Field, { fieldNames: ['signatoryUsaTaxNumber'], title: text('OVERVIEW_FIELD_SIGNATORY_USA_TAX_NUMBER_TITLE'), editable: true, conditionalRendering: true },
                React.createElement(Value, null, stepData.signatoryUsaTaxNumber)),
            React.createElement(Field, { fieldNames: ['signatorySubjectToUsaBackupWithholding'], title: text('OVERVIEW_FIELD_SIGNATORY_SUBJECT_TO_USA_BACKUP_WITHHOLDING_TITLE'), conditionalRendering: true, editable: true },
                React.createElement(Value, null, stepData.signatorySubjectToUsaBackupWithholding
                    ? text('OVERVIEW_PAGE_VALUE_YES')
                    : text('OVERVIEW_PAGE_VALUE_NO'))))));
};
