import React from 'react';
import imageIceCream from '../../assets/izettle-ice-cream.svg';
const CONTACT_SUPPORT_URL = 'https://www.izettle.com/help/contact';
const getGenericErrorContent = () => ({
    imgSrc: imageIceCream.src,
    title: 'Didn’t see this coming',
    body: (React.createElement(React.Fragment, null,
        "Something went wrong. Please try again or come back later. ",
        React.createElement("br", null),
        "Need more help? ",
        React.createElement("a", { href: CONTACT_SUPPORT_URL }, "Contact support"),
        ".")),
});
const getTokenExpiredContent = () => ({
    imgSrc: imageIceCream.src,
    title: 'Timeout',
    body: (React.createElement(React.Fragment, null,
        "Please retry to continue as your session expired. ",
        React.createElement("br", null),
        "Need more help? ",
        React.createElement("a", { href: CONTACT_SUPPORT_URL }, "Contact support"),
        ".")),
});
const getTokenNotValidYetContent = () => ({
    imgSrc: imageIceCream.src,
    title: 'Clock out of sync',
    body: (React.createElement(React.Fragment, null,
        "Please check your time and time zone settings to sync the clock on your device. ",
        React.createElement("br", null),
        "Need more help? ",
        React.createElement("a", { href: CONTACT_SUPPORT_URL }, "Contact support"),
        ".")),
});
export const getErrorContent = (error) => {
    if (error.message === 'Authentication token expired') {
        return getTokenExpiredContent();
    }
    if (error.message === 'Authentication token not valid yet') {
        return getTokenNotValidYetContent();
    }
    return getGenericErrorContent();
};
