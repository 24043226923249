import { IsoLocale } from '@izettle/constants';
import { getPortalDomain } from 'src/config';
import { template } from 'src/schema/template';
import { nationalityOptions } from '../../common/nationality-options';
import { getPrivacyPolicyLink } from '../../common/privacy-policy-link';
import { step, sequence, render, decision } from '../../steps-builder';
import { introScreenTemplate, legalPeoplePreparationScreenTemplate, beneficialOwnersListTemplate, alternativeOwnersListTemplate, directorsListTemplate, hasUBOTemplate, normalizeSignatoryFieldsFunction, initLegalPersonsFunction, overviewPageTemplate, } from './common/steps/limited-company';
import { signatoryNameTemplate, signatoryPhoneNumberTemplate, signatoryNationalityTemplate, signatoryNamePrefilledTemplate, signatoryDOBTemplate, } from './common/steps/signatory';
import { SIGNATORY_ACTION } from './data';
const introScreen = introScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            items: [
                {
                    icon: 'Person',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_SIGNATORY_DETAILS_TITLE'),
                },
                {
                    icon: 'Home',
                    title: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_BUSINESS_DETAILS_DESCRIPTION'),
                },
                {
                    icon: 'People',
                    title: translate('GAP_LTD_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_TITLE'),
                    description: translate('GAP_PREPARATION_SCREEN_ITEM_DECISION_MAKERS_DESCRIPTION'),
                },
                {
                    icon: 'IdentityCard',
                    title: translate('GAP_AUTO_VERIFICATION_PREPARATION_SCREEN_ITEM_VERIFICATION_TITLE'),
                    description: translate('GAP_LTD_PREPARATION_SCREEN_ITEM_VERIFICATION_DESCRIPTION'),
                },
            ],
        },
    },
}));
const businessAddress = ({ translate }) => ({
    backendValidation: true,
    fields: ['businessStreetAddress', 'businessPostalCode', 'businessCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            businessStreetQuestion: {
                title: translate('BUSINESS_ADDRESS_LABEL'),
                description: translate('BUSINESS_ADDRESS_IT_VISURA_CAMERALE_INSTRUCTION'),
                type: 'null',
            },
            businessStreetAddress: {
                type: 'string',
            },
            businessPostalCode: {
                type: 'string',
            },
            businessCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        businessStreetAddress: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        businessPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                mask: "99999" /* CopyIT.ADDRESS_POSTAL_CODE_MASK */,
            },
        },
        businessCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'businessAddress',
        segment: 'businessDetails',
        content: {
            helpTexts: [
                {
                    title: translate('BUSINESS_NAME_HELP_TEXT_CAN_I_USE_TRADING_ADDRESS_TITLE'),
                    body: translate('BUSINESS_NAME_HELP_TEXT_CAN_I_USE_TRADING_ADDRESS_BODY', [getPortalDomain()]),
                },
            ],
        },
    },
});
const legalPeoplePreparationScreen = legalPeoplePreparationScreenTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_TITLE'),
                    body: translate('GAP_LEGAL_PERSONS_PREPARATION_SCREEN_HELP_2_ACCORDION_CONTENT'),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_IT_CERTIFICATE_ATRIBUZIONE_REGISTRO_IMPRESE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const personStep = (translate) => {
    return {
        ID: 'personStepId',
        backendValidation: true,
        fields: ['firstName', 'lastName', 'dateOfBirth', 'nationality', 'streetAddress', 'postalCode', 'city'],
        JSONSchema: {
            type: 'object',
            properties: {
                nameTitle: {
                    title: translate('PERSON_NAME_LABEL'),
                    type: 'null',
                },
                firstName: {
                    type: 'string',
                },
                lastName: {
                    type: 'string',
                },
                dateOfBirthTitle: {
                    title: translate('PERSON_DATE_OF_BIRTH_LABEL'),
                    type: 'null',
                },
                dateOfBirth: {
                    type: 'string',
                },
                nationalityTitle: {
                    title: translate('PERSON_NATIONALITY_LABEL'),
                    type: 'null',
                },
                nationality: {
                    type: 'string',
                    enum: nationalityOptions.map((option) => option.value),
                    enumNames: nationalityOptions.map((option) => translate(option.textKey)),
                },
                addressTitle: {
                    title: translate('PERSON_HOME_ADDRESS_LABEL'),
                    type: 'null',
                },
                streetAddress: {
                    type: 'string',
                },
                postalCode: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
            },
        },
        UISchema: {
            firstName: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('FIRST_NAME_LABEL'),
                },
            },
            lastName: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('LAST_NAME_LABEL'),
                },
            },
            dateOfBirth: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: "GG/MM/AAAA" /* CopyIT.DATE_OF_BIRTH_PLACEHOLDER */,
                    mask: "99/99/9999" /* CopyIT.DATE_OF_BIRTH_MASK */,
                },
            },
            nationality: {
                'ui:marginBottom': 'medium',
                'ui:title': false,
                'ui:widget': 'CustomComboBoxWidget',
                'ui:emptyValue': '',
                'ui:placeholder': translate('NATIONALITY_PLACEHOLDER'),
                'ui:options': {
                    sortByLabels: 'asc',
                },
            },
            streetAddress: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
                },
            },
            postalCode: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                    mask: "99999" /* CopyIT.ADDRESS_POSTAL_CODE_MASK */,
                },
            },
            city: {
                'ui:title': false,
                'ui:emptyValue': '',
                'ui:options': {
                    label: translate('ADDRESS_CITY_LABEL'),
                },
            },
        },
        applicationVariables: {
            stepName: 'newPerson',
        },
    };
};
const beneficialOwnersList = beneficialOwnersListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY'),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_IT_CERTIFICATE_ATRIBUZIONE_REGISTRO_IMPRESE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const alternativeOwnersList = alternativeOwnersListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_IT_CERTIFICATE_ATRIBUZIONE_REGISTRO_IMPRESE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
/* eslint-disable sonarjs/no-identical-functions */
const directorsList = directorsListTemplate(personStep).overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_IT_CERTIFICATE_ATRIBUZIONE_REGISTRO_IMPRESE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
/* eslint-disable sonarjs/no-identical-functions */
const hasUBO = hasUBOTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        content: {
            helpTexts: [
                {
                    title: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_TITLE'),
                    body: translate('HAS_OTHER_LEGAL_PERSONS_LIMITED_COMPANY_HELP_TEXT_HOW_TO_ANSWER_BODY'),
                },
                {
                    title: translate('HELP_TEXT_INFO_LOCATION_TITLE'),
                    body: translate('BUSINESS_DETAILS_IT_CERTIFICATE_ATRIBUZIONE_REGISTRO_IMPRESE_HELP_TEXT_INFO_LOCATION_BODY'),
                },
            ],
        },
    },
}));
const overviewPage = overviewPageTemplate.overrideDeep(({ translate }) => ({
    applicationVariables: {
        version: 'GAP_IT_LTD_COMPANY',
        content: {
            submitInstructions: translate('GAP_OVERVIEW_SUBMIT_INSTRUCTIONS', [getPrivacyPolicyLink(IsoLocale.itIT)]),
        },
    },
}));
const signatoryDOB = signatoryDOBTemplate.overrideDeep(() => ({
    UISchema: {
        signatoryDateOfBirth: {
            'ui:options': {
                mask: "99/99/9999" /* CopyIT.DATE_OF_BIRTH_MASK */,
            },
            'ui:placeholder': "GG/MM/AAAA" /* CopyIT.DATE_OF_BIRTH_PLACEHOLDER */,
        },
    },
}));
const signatoryAddress = template(({ translate }) => ({
    backendValidation: true,
    fields: ['signatoryStreetAddress', 'signatoryPostalCode', 'signatoryCity'],
    JSONSchema: {
        type: 'object',
        properties: {
            signatoryStreetQuestion: {
                title: translate('SIGNATORY_ADDRESS_LABEL'),
                type: 'null',
            },
            signatoryStreetAddress: {
                type: 'string',
            },
            signatoryPostalCode: {
                type: 'string',
            },
            signatoryCity: {
                type: 'string',
            },
        },
    },
    UISchema: {
        signatoryStreetAddress: {
            'ui:title': false,
            'ui:autofocus': true,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_STREET_ADDRESS_WITH_HOUSE_NUMBER_LABEL'),
            },
        },
        signatoryPostalCode: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_POSTAL_CODE_LABEL'),
                mask: "99999" /* CopyIT.ADDRESS_POSTAL_CODE_MASK */,
            },
        },
        signatoryCity: {
            'ui:title': false,
            'ui:emptyValue': '',
            'ui:options': {
                label: translate('ADDRESS_CITY_LABEL'),
            },
        },
    },
    applicationVariables: {
        stepName: 'signatoryAddress',
        segment: 'personalDetails',
    },
}));
const signatoryPhoneNumber = signatoryPhoneNumberTemplate.extendDeep(({ translate }) => ({
    UISchema: {
        signatoryPhoneNumber: {
            'ui:placeholder': translate('FORM_EXAMPLE', ["312 3456789" /* CopyIT.SIGNATORY_PHONE_NUMBER_EXAMPLE */]),
            'ui:options': {
                mask: "+99999999999999999999" /* CopyIT.SIGNATORY_PHONE_NUMBER_MASK */,
            },
        },
    },
}));
const newSignatorySequence = [
    step(signatoryNameTemplate),
    step(signatoryDOB),
    step(signatoryAddress),
    step(signatoryPhoneNumber),
    step(signatoryNationalityTemplate),
];
const rootSequence = sequence([
    step(introScreen),
    decision('signatoryAction', signatoryNamePrefilledTemplate, [
        { when: SIGNATORY_ACTION.CHANGE_SIGNATORY, branch: sequence(newSignatorySequence) },
        { when: SIGNATORY_ACTION.SET_NATIONALITY, branch: step(signatoryNationalityTemplate) },
    ]),
    step(normalizeSignatoryFieldsFunction),
    step(businessAddress),
    step(legalPeoplePreparationScreen),
    step(initLegalPersonsFunction),
    step(directorsList),
    decision('hasUBO', hasUBO, [
        { when: true, branch: step(beneficialOwnersList) },
        { when: false, branch: step(alternativeOwnersList) },
    ]),
    step(overviewPage),
]);
export const GAP_IT_LTD_COMPANY = (context) => render(rootSequence, context);
