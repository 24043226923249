import { IsoLocale } from '@izettle/constants';
import { render, sequence, step } from '../../steps-builder';
import { signatoryNationalityTemplate, overviewPageTemplate } from './common/steps/sole-trader';
const overviewPage = overviewPageTemplate(IsoLocale.esES).overrideDeep(() => ({
    applicationVariables: {
        version: 'GAP_ES_ST_COMPANY',
    },
}));
const rootSequence = sequence([step(signatoryNationalityTemplate), step(overviewPage)]);
export const GAP_ES_ST_COMPANY = (context) => render(rootSequence, context);
