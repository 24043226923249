import { conditional, sequence, step } from 'src/schema/steps-builder';
import { signatorySubjectToUsaBackupWithholding, signatoryUnderUsaTaxLaw, signatoryUsaTaxNumber, } from './step-templates';
export const buildSelfEmployedUsaTaxpayerSequence = (businessTypeFieldName, businessTypeFieldValue) => ({ formContext }) => {
    // TODO: Remove this when temporary flows are no longer in use
    if (!(formContext === null || formContext === void 0 ? void 0 : formContext.form.endsWith('_1099K'))) {
        return sequence([]);
    }
    return conditional(businessTypeFieldName, [
        {
            when: businessTypeFieldValue,
            branch: conditional('signatoryNationality', [
                {
                    when: 'US',
                    branch: sequence([
                        step(signatoryUnderUsaTaxLaw),
                        conditional('signatoryUnderUsaTaxLaw', [
                            {
                                when: true,
                                branch: sequence([step(signatoryUsaTaxNumber), step(signatorySubjectToUsaBackupWithholding)]),
                            },
                        ]),
                    ]),
                },
            ]),
        },
    ]);
};
export const selfEmployedUsaTaxpayerQuestions = [
    conditional('signatoryNationality', [
        {
            when: 'US',
            branch: sequence([
                step(signatoryUnderUsaTaxLaw),
                conditional('signatoryUnderUsaTaxLaw', [
                    {
                        when: true,
                        branch: sequence([step(signatoryUsaTaxNumber), step(signatorySubjectToUsaBackupWithholding)]),
                    },
                ]),
            ]),
        },
    ]),
];
