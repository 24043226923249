import React from 'react';
import { isEmpty } from 'ramda';
import styled from 'styled-components';
import { useLocalization } from '@izettle/lingo-client';
import { IconCirclePlusMedium, spacing } from '@izettle/otto';
import { Text } from '@izettle/react';
import { useQuestionnaireState } from 'src/components/Questionnaire/state';
import { useQuestionnaireEditMode } from 'src/hooks/use-questionnaire-edit-mode';
import { LUX_NEW_LEGAL_PEOPLE_FIELD_NAME } from 'src/schema/common/owner-structure';
import { GAPS } from 'src/schema/gaps/LUX/data';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { FieldInlineButton } from '../../../FieldInlineButton';
import { LegacyOwnerList } from '../../../LegacyOwnerList';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
const ButtonsContainer = styled.div `
  margin-top: ${spacing.small};
`;
const StyledRole = styled.span `
  display: block;
`;
const RoleField = ({ text, roles, translationKeyGroup }) => (React.createElement(Field, { fieldNames: ['role'], title: text('GAP_PERSON_ROLE_LABEL'), buttonPlacement: "title" },
    React.createElement(Value, null, Array.isArray(roles) && roles.length > 0
        ? roles.map((value) => (React.createElement(StyledRole, { key: value }, text(`GAP_OVERVIEW_ROLE_VALUE_${translationKeyGroup}_${value.toUpperCase()}`))))
        : '-')));
const NewLegalPeopleList = ({ renderAddressValue, translationKeyGroup }) => {
    const { startEditMode } = useQuestionnaireEditMode();
    const { dispatch } = useQuestionnaireState();
    const { text } = useLocalization();
    return (React.createElement(LegacyOwnerList, { fieldName: LUX_NEW_LEGAL_PEOPLE_FIELD_NAME, 
        // eslint-disable-next-line react/no-unstable-nested-components
        itemTemplate: (object, index) => {
            return (React.createElement(React.Fragment, null,
                React.createElement(Field, { fieldNames: ['firstName', 'lastName'], title: `${object.firstName} ${object.lastName}`, editable: true, removable: true, titleStyle: "RegularBold", buttonPlacement: "title", onEdit: () => startEditMode('newLegalPeople', { extraStepData: { hasNewLegalPeople: true } }), onRemove: () => {
                        dispatch({
                            type: 'removeOwner',
                            payload: {
                                fieldName: 'newLegalPeople',
                                dependentFieldName: 'hasNewLegalPeople',
                                index,
                            },
                        });
                    } }),
                React.createElement(Field, { fieldNames: ['dateOfBirth'], title: text('OVERVIEW_FIELD_DATE_OF_BIRTH_TITLE') },
                    React.createElement(Value, null, object.dateOfBirth)),
                React.createElement(Field, { fieldNames: ['nationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE') }, object.nationality && React.createElement(Value, null, text(`NATIONALITY_VALUE_${object.nationality}`))),
                React.createElement(Field, { fieldNames: ['streetAddress', 'city', 'postalCode', 'building', 'county'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE') }, renderAddressValue(object)),
                React.createElement(RoleField, { text: text, roles: object.role, translationKeyGroup: translationKeyGroup })));
        } }));
};
const LegalPeopleList = ({ renderAddressValue, translationKeyGroup }) => {
    const { startEditMode } = useQuestionnaireEditMode();
    const { text } = useLocalization();
    return (React.createElement(LegacyOwnerList, { fieldName: "legalPeople", 
        // eslint-disable-next-line react/no-unstable-nested-components
        itemTemplate: (object) => {
            return (React.createElement(React.Fragment, null,
                React.createElement(Field, { fieldNames: ['firstName', 'lastName'], title: `${object.firstName} ${object.lastName}`, editable: true, titleStyle: "RegularBold", buttonPlacement: "title", onEdit: () => startEditMode('legalPeople') }),
                object.dateOfBirth && (React.createElement(Field, { fieldNames: ['dateOfBirth'], title: text('OVERVIEW_FIELD_DATE_OF_BIRTH_TITLE') },
                    React.createElement(Value, null, object.dateOfBirth))),
                object.nationality && (React.createElement(Field, { fieldNames: ['nationality'], title: text('OVERVIEW_FIELD_NATIONALITY_TITLE') },
                    React.createElement(Value, null, text(`NATIONALITY_VALUE_${object.nationality}`)))),
                object.streetAddress && object.city && object.postalCode && (React.createElement(Field, { fieldNames: ['streetAddress', 'city', 'postalCode', 'building', 'county'], title: text('OVERVIEW_FIELD_ADDRESS_TITLE') }, renderAddressValue(object))),
                React.createElement(RoleField, { text: text, roles: object.role, translationKeyGroup: translationKeyGroup })));
        } }));
};
export const GapOwnerStructureSection = ({ renderAddressValue, translationKeyGroup, }) => {
    const { startEditMode } = useQuestionnaireEditMode();
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const gaps = stepData === null || stepData === void 0 ? void 0 : stepData.gaps;
    const newLegalPeople = stepData === null || stepData === void 0 ? void 0 : stepData.newLegalPeople;
    const legalPeople = stepData === null || stepData === void 0 ? void 0 : stepData.legalPeople;
    const hasLegalPeople = legalPeople && !isEmpty(legalPeople);
    if (!(gaps === null || gaps === void 0 ? void 0 : gaps.includes(GAPS.LUX_UBOS)) && !(gaps === null || gaps === void 0 ? void 0 : gaps.includes(GAPS.LUX_DIRECTORS))) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        hasLegalPeople && (React.createElement(Section, { name: "ownerStructure", title: text('GAP_LEGAL_PERSONS_OVERVIEW_OWNER_STRUCTURE_LEGAL_PEOPLE_TITLE') },
            React.createElement(LegalPeopleList, { renderAddressValue: renderAddressValue, translationKeyGroup: translationKeyGroup }))),
        newLegalPeople && !isEmpty(newLegalPeople) && (React.createElement(Section, { name: "ownerStructure", title: text(hasLegalPeople
                ? 'GAP_LEGAL_PERSONS_OVERVIEW_OWNER_STRUCTURE_NEW_LEGAL_PEOPLE_TITLE'
                : 'GAP_LEGAL_PERSONS_OVERVIEW_OWNER_STRUCTURE_LEGAL_PEOPLE_TITLE') },
            React.createElement(NewLegalPeopleList, { renderAddressValue: renderAddressValue, translationKeyGroup: translationKeyGroup }))),
        React.createElement(Section, { name: "ownerStructure", title: "" },
            React.createElement(Text.Small, { className: "override-text" }, text(`GAP_LEGAL_PERSONS_OVERVIEW_NOTICE_DESCRIPTION_${translationKeyGroup}`)),
            React.createElement(ButtonsContainer, null,
                React.createElement(FieldInlineButton, { svgIcon: IconCirclePlusMedium, onClick: () => {
                        startEditMode('newLegalPeople', {
                            extraStepData: {
                                hasNewLegalPeople: true,
                            },
                        });
                    } }, text(`GAP_LEGAL_PERSONS_OVERVIEW_NOTICE_ADD_OWNER_BUTTON_${translationKeyGroup}`))))));
};
