import React from 'react';
import { FieldError } from 'src/components/FieldError';
import { LogoutButton } from 'src/components/Logout';
import { useRenderFieldError } from 'src/hooks/use-render-field-error';
const ErrorTemplateWithLogout = ({ children }) => (React.createElement(FieldError, null,
    children,
    React.createElement(LogoutButton, { variant: "destructive" })));
export const FieldErrorItem = ({ errorKey }) => {
    const { renderError, hasLogoutButton } = useRenderFieldError();
    const ErrorItemTemplate = hasLogoutButton(errorKey) ? ErrorTemplateWithLogout : FieldError;
    return (React.createElement("li", null,
        React.createElement(ErrorItemTemplate, null, renderError(errorKey))));
};
