import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { FieldError } from 'src/components/FieldError';
import { useRenderFieldError } from 'src/hooks/use-render-field-error';
import { findParentWithClassName } from 'src/util';
import { FieldContainer } from './container';
const ErrorContainer = styled.div `
  margin-top: ${spacing.xxSmall};
`;
const FieldContainerClassName = FieldContainer.toString().slice(1);
export const Errors = ({ errorKeys }) => {
    const { renderError } = useRenderFieldError();
    const errorRef = useRef(null);
    useEffect(() => {
        if (errorKeys.length > 0 && errorRef.current) {
            const fieldContainer = findParentWithClassName(errorRef.current, FieldContainerClassName);
            fieldContainer === null || fieldContainer === void 0 ? void 0 : fieldContainer.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [errorKeys.length]);
    if (errorKeys.length === 0) {
        return null;
    }
    return (React.createElement(ErrorContainer, { ref: errorRef, "aria-live": "assertive" }, errorKeys.map((errorKey) => {
        return React.createElement(FieldError, { key: errorKey }, renderError(errorKey));
    })));
};
