import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import { datadogRum } from '@datadog/browser-rum';
import { App } from './components/App';
import { Environment, config } from './config';
if ([Environment.Production, Environment.Staging].includes(config.appEnv)) {
    datadogRum.init(config.datadogRum);
}
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
