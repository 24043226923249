import { isPlainObject } from 'src/util';
const isSameType = (left, right) => {
    if (typeof left !== typeof right) {
        return false;
    }
    if (Array.isArray(left) && !Array.isArray(right)) {
        return false;
    }
    if (left === null && right !== null) {
        return false;
    }
    return true;
};
export const overrideTemplateDeep = (original, override, debugPath = '#root') => {
    if (!isSameType(original, override)) {
        throw new Error(`Types for original and override do not match at path ${debugPath}`);
    }
    if (!isPlainObject(override)) {
        return override;
    }
    return Object.keys(override).reduce((acc, key) => {
        const keyDebugPath = `${debugPath}.${key}`;
        if (!Object.prototype.hasOwnProperty.call(original, key)) {
            throw new Error(`Override has a new key ${keyDebugPath} which is not present in the original template. Did you mean to use '.extendDeep'?`);
        }
        return Object.assign(Object.assign({}, acc), { [key]: overrideTemplateDeep(original[key], override[key], keyDebugPath) });
    }, Object.assign({}, original));
};
export const extendTemplateDeep = (original, extension, debugPath = '#root') => {
    if (!isPlainObject(original) || !isPlainObject(extension)) {
        throw new Error(`Only plain objects can be extended by plain objects at path ${debugPath}`);
    }
    return Object.keys(extension).reduce((acc, propName) => {
        const keyDebugPath = `${debugPath}.${propName}`;
        if (!(propName in original)) {
            return Object.assign(Object.assign({}, acc), { [propName]: extension[propName] });
        }
        if (isPlainObject(original[propName])) {
            return Object.assign(Object.assign({}, acc), { [propName]: extendTemplateDeep(original[propName], extension[propName], keyDebugPath) });
        }
        throw new Error(`Extension sets a key ${keyDebugPath} which is already present in the original template. Did you mean to use '.overrideDeep'?`);
    }, Object.assign({}, original));
};
