import { useContext } from 'react';
import { last } from 'ramda';
import { PopupsStateContext } from 'src/components/Popups/state';
import { QuestionnaireStateContext } from 'src/components/Questionnaire/state';
import { getCurrentStepObject } from 'src/components/Questionnaire/state/helpers';
export const getPageNameForPopups = (popupsState, questionnaireState) => {
    const currentPopup = last(popupsState.popups);
    if (currentPopup) {
        return currentPopup.popupName;
    }
    const currentStep = getCurrentStepObject(questionnaireState);
    return `${currentStep.applicationVariables.stepName}Step`;
};
export const usePageName = () => {
    const popupsContextValue = useContext(PopupsStateContext);
    const questionnaireContextValue = useContext(QuestionnaireStateContext);
    if (!popupsContextValue) {
        return 'Index';
    }
    if (!questionnaireContextValue) {
        const currentPopup = last(popupsContextValue.state.popups);
        if (currentPopup) {
            return currentPopup.popupName;
        }
        return 'Index';
    }
    const currentStep = getCurrentStepObject(questionnaireContextValue.state);
    return `${currentStep.applicationVariables.stepName}Step`;
};
