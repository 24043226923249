import styled from 'styled-components';
import { spacing, typography } from '@izettle/otto';
import { Button } from '@izettle/react';
export const FormContainer = styled.div ``;
export const SubmitButtonContainer = styled.div `
  margin-top: ${spacing.xLarge};
`;
export const InnerContentContainer = styled.div `
  margin-top: ${spacing.xxSmall};
`;
export const HelpSectionContainer = styled.div `
  margin-bottom: ${spacing.small};
`;
export const SubmitButton = styled(Button).attrs({
    variant: 'primary',
    fullWidth: true,
    type: 'submit',
    id: 'continueBtn',
}) `
  > span span {
    ${typography.regular}
    color: inherit;
  }
`;
