import React from 'react';
import { iZettleMarket } from '@izettle/constants';
import { useLocalization } from '@izettle/lingo-client';
import { getCountryUtils } from 'src/utils/country-helpers';
import { isNativeBusinessTypeRegistered, getDistrictCourtLabel, districtCourtsData, } from 'src/utils/country/DE';
import { AddressValue } from '../../../AddressValue';
import { useOverviewPageContextValue } from '../../../Context';
import { Field } from '../../../Field';
import { Section } from '../../../Section';
import { Value } from '../../../Value';
const RegisteredLegalEntityNumber = () => {
    var _a;
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(Field, { fieldNames: ['businessLegalEntityNumber', 'businessDistrictCourt'], title: "Registernummer" /* Copy.REGISTERNUMMER_LABEL */, editable: true, conditionalRendering: true },
        React.createElement(Value, null, stepData.businessLegalEntityNumber),
        React.createElement(Value, null, (_a = getDistrictCourtLabel(districtCourtsData, stepData.businessDistrictCourt)) !== null && _a !== void 0 ? _a : stepData.businessDistrictCourt)));
};
const UnregisteredLegalEntityNumber = () => {
    const { stepData } = useOverviewPageContextValue();
    return (React.createElement(Field, { fieldNames: ['businessLegalEntityNumber'], title: "Steuernummer" /* Copy.TAX_NUMBER_LABEL */, editable: true, conditionalRendering: true },
        React.createElement(Value, null, stepData.businessLegalEntityNumber)));
};
export const BusinessDetailsSection = () => {
    const { text } = useLocalization();
    const { stepData } = useOverviewPageContextValue();
    const isCompanyRegistered = stepData.nativeBusinessType && isNativeBusinessTypeRegistered(stepData.nativeBusinessType);
    const LegalEntityNumber = isCompanyRegistered ? RegisteredLegalEntityNumber : UnregisteredLegalEntityNumber;
    const { getLocalizedBusinessTypeValue } = getCountryUtils(iZettleMarket.DE);
    return (React.createElement(Section, { name: "businessDetails", title: text('OVERVIEW_SECTION_BUSINESS_DETAILS_TITLE') },
        React.createElement(Field, { fieldNames: ['businessType'], title: text('OVERVIEW_FIELD_BUSINESS_TYPE_TITLE') },
            React.createElement(Value, null, getLocalizedBusinessTypeValue(stepData, text))),
        React.createElement(Field, { fieldNames: ['businessName'], title: text('OVERVIEW_FIELD_BUSINESS_NAME_TITLE'), editable: true },
            React.createElement(Value, null, stepData.businessName)),
        React.createElement(LegalEntityNumber, null),
        React.createElement(Field, { fieldNames: ['businessVatNumber'], title: "Umsatzsteuer-ID" /* Copy.VAT_NUMBER_LABEL */, editable: true, conditionalRendering: true },
            React.createElement(Value, null, stepData.businessVatNumber ? stepData.businessVatNumber : '-')),
        React.createElement(Field, { fieldNames: ['businessStreetAddress', 'businessPostalCode', 'businessCity'], title: text('OVERVIEW_FIELD_BUSINESS_ADDRESS_TITLE'), editable: true },
            React.createElement(AddressValue, { addressData: {
                    streetAddress: stepData.businessStreetAddress,
                    postalCode: stepData.businessPostalCode,
                    city: stepData.businessCity,
                } }))));
};
