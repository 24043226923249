import { buildSelfEmployedUsaTaxpayerSequence } from 'src/schema/common/usaTaxpayerDetails/schema';
import { NativeBusinessType, CompanyRegister, BusinessType } from '../../../utils/country/DE/data';
import { nativeBusinessTypesFromBusinessType, nativeBusinessTypesFromCompanyRegister, } from '../../../utils/country/DE/data-helpers';
import { buildCategorySteps } from '../../common/categories';
import { sequence, decision, step, render, conditional, customStepBuilder } from '../../steps-builder';
import * as stepTemplates from './flow-step-templates';
const personalDetails = [
    step(stepTemplates.personalDetailsQuestionsIntro),
    step(stepTemplates.signatoryName),
    step(stepTemplates.signatoryDOB),
    step(stepTemplates.signatoryGender),
    step(stepTemplates.signatoryNationality),
    customStepBuilder(buildSelfEmployedUsaTaxpayerSequence('nativeBusinessType', [
        ...nativeBusinessTypesFromBusinessType(BusinessType.SelfEmployed),
    ])),
    decision('isSignatoryShareAddress', stepTemplates.isSignatoryShareAddress, [
        { when: false, branch: step(stepTemplates.signatoryAddress) },
    ]),
    step(stepTemplates.signatoryPhoneNumber),
];
const root = sequence([
    step(stepTemplates.nativeBusinessType),
    step(stepTemplates.mapBusinessTypeFunction),
    // Preparation screen
    conditional('nativeBusinessType', [
        {
            when: [NativeBusinessType.EK],
            branch: step(stepTemplates.regSelfEmployedPrepScreen),
        },
        {
            when: [NativeBusinessType.SonstigeEinzelunternehmen],
            branch: step(stepTemplates.nonRegSelfEmployedPrepScreen),
        },
        {
            when: [NativeBusinessType.GmbH, NativeBusinessType.AG, NativeBusinessType.UG, NativeBusinessType.KGaA],
            branch: step(stepTemplates.limitedCompanyPrepScreen),
        },
        {
            when: [NativeBusinessType.oHG, NativeBusinessType.KG, NativeBusinessType.PartGPartGmbB],
            branch: step(stepTemplates.regPartnershipPrepScreen),
        },
        {
            when: [NativeBusinessType.GbR],
            branch: step(stepTemplates.nonRegPartnershipPrepScreen),
        },
        {
            when: [
                NativeBusinessType.Stiftung,
                NativeBusinessType.gUG,
                NativeBusinessType.gGmbH,
                NativeBusinessType.EingetragenerVerein,
                NativeBusinessType.EingetrageneGenossenschaft,
            ],
            branch: step(stepTemplates.regNonProfitPrepScreen),
        },
        {
            when: [NativeBusinessType.Verein, NativeBusinessType.Genossenschaft],
            branch: step(stepTemplates.nonRegNonProfitPrepScreen),
        },
    ]),
    conditional('nativeBusinessType', [
        {
            when: [
                ...nativeBusinessTypesFromBusinessType(BusinessType.SelfEmployed),
                ...nativeBusinessTypesFromBusinessType(BusinessType.LimitedCompany),
                ...nativeBusinessTypesFromBusinessType(BusinessType.Partnership),
            ],
            branch: sequence([step(stepTemplates.generalBusinessName), step(stepTemplates.generalBusinessAddress)]),
        },
        {
            when: nativeBusinessTypesFromBusinessType(BusinessType.NonProfit),
            branch: sequence([step(stepTemplates.nonProfitBusinessName), step(stepTemplates.nonProfitBusinessAddress)]),
        },
    ]),
    // businessLegalEntityNumber
    conditional('nativeBusinessType', [
        {
            when: nativeBusinessTypesFromCompanyRegister(CompanyRegister.Handelsregister),
            branch: step(stepTemplates.handelsregisterBusinessEntityNumber),
        },
        {
            when: nativeBusinessTypesFromCompanyRegister(CompanyRegister.Partnerschaftsregister),
            branch: step(stepTemplates.partnerschaftsBusinessEntityNumber),
        },
        {
            when: nativeBusinessTypesFromCompanyRegister(CompanyRegister.Vereinsregister),
            branch: step(stepTemplates.vereinsregisterBusinessEntityNumber),
        },
        {
            when: nativeBusinessTypesFromCompanyRegister(CompanyRegister.Genossenschaftsregister),
            branch: step(stepTemplates.genossenschaftsregisterBusinessEntityNumber),
        },
        {
            when: [NativeBusinessType.SonstigeEinzelunternehmen, NativeBusinessType.GbR],
            branch: step(stepTemplates.nonRegGeneralBusinessEntityNumber),
        },
        {
            when: [NativeBusinessType.Verein, NativeBusinessType.Genossenschaft],
            branch: step(stepTemplates.nonRegNonProfitBusinessEntityNumber),
        },
    ]),
    step(stepTemplates.businessVatNumber),
    sequence(personalDetails),
    // Owner structure
    conditional('nativeBusinessType', [
        {
            when: nativeBusinessTypesFromBusinessType(BusinessType.LimitedCompany),
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.limitedPartnershipOwnerStructureIntro),
                step(stepTemplates.limitedDirectorsList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.beneficialOwners) },
                    { when: false, branch: step(stepTemplates.limitedPartnershipAlternativeOwnersList) },
                ]),
            ]),
        },
        {
            when: nativeBusinessTypesFromBusinessType(BusinessType.Partnership),
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.limitedPartnershipOwnerStructureIntro),
                step(stepTemplates.partnersList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.beneficialOwners) },
                    { when: false, branch: step(stepTemplates.limitedPartnershipAlternativeOwnersList) },
                ]),
            ]),
        },
        {
            when: nativeBusinessTypesFromBusinessType(BusinessType.NonProfit),
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.nonProfitOwnerStructureIntro),
                step(stepTemplates.nonProfitDirectorsList),
                step(stepTemplates.nonProfitAlternativeOwnersList),
            ]),
        },
    ]),
    conditional('nativeBusinessType', [
        {
            when: nativeBusinessTypesFromBusinessType(BusinessType.SelfEmployed),
            branch: step(stepTemplates.selfEmployedUsageOfIZettleIntro),
        },
        {
            when: [
                ...nativeBusinessTypesFromBusinessType(BusinessType.LimitedCompany),
                ...nativeBusinessTypesFromBusinessType(BusinessType.Partnership),
                ...nativeBusinessTypesFromBusinessType(BusinessType.NonProfit),
            ],
            branch: step(stepTemplates.generalUsageOfIZettleIntro),
        },
    ]),
    customStepBuilder(buildCategorySteps),
    step(stepTemplates.businessLocationsTypes),
    step(stepTemplates.businessFrequency),
    step(stepTemplates.businessAverageTransaction),
    step(stepTemplates.businessIzettleShareOfTurnover),
    decision('businessCurrentAnnualTurnover', stepTemplates.businessCurrentAnnualTurnover, [
        { when: 'NEW_BUSINESS', branch: step(stepTemplates.businessExpectedAnnualTurnover) },
    ]),
    step(stepTemplates.overviewPage),
]);
export const DE = (context) => render(root, context);
