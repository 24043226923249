import { useCallback } from 'react';
import { CheckedInputContainer } from '.';
// Key codes can differ across platforms. Fortunately for us, these don't
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
var ActivationKeyCode;
(function (ActivationKeyCode) {
    ActivationKeyCode["Enter"] = "Enter";
    ActivationKeyCode["Space"] = "Space";
})(ActivationKeyCode || (ActivationKeyCode = {}));
const ACTIVATION_KEY_CODES = Object.values(ActivationKeyCode);
var ArrowKeyCode;
(function (ArrowKeyCode) {
    ArrowKeyCode["ArrowUp"] = "ArrowUp";
    ArrowKeyCode["ArrowDown"] = "ArrowDown";
    ArrowKeyCode["ArrowLeft"] = "ArrowLeft";
    ArrowKeyCode["ArrowRight"] = "ArrowRight";
})(ArrowKeyCode || (ArrowKeyCode = {}));
const ARROW_KEY_CODES = Object.values(ArrowKeyCode);
const CheckedInputContainerClassName = CheckedInputContainer.toString().slice(1);
// Adds behavior which activates list items when arrow keys are pressed
// Made specifically for RadioGroup, it recreates functionality like here
// https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/radio/radio.html
const handleArrowKeyDown = (e) => {
    var _a, _b, _c, _d;
    const itemContainer = e.currentTarget;
    let activationTarget;
    if (e.key === ArrowKeyCode.ArrowUp || e.key === ArrowKeyCode.ArrowLeft) {
        const previousItem = ((_a = itemContainer === null || itemContainer === void 0 ? void 0 : itemContainer.previousElementSibling) !== null && _a !== void 0 ? _a : (_b = itemContainer === null || itemContainer === void 0 ? void 0 : itemContainer.parentElement) === null || _b === void 0 ? void 0 : _b.lastElementChild);
        activationTarget = previousItem === null || previousItem === void 0 ? void 0 : previousItem.querySelector('.focusTarget');
    }
    else if (e.key === ArrowKeyCode.ArrowDown || e.key === ArrowKeyCode.ArrowRight) {
        const nextItem = ((_c = itemContainer === null || itemContainer === void 0 ? void 0 : itemContainer.nextElementSibling) !== null && _c !== void 0 ? _c : (_d = itemContainer === null || itemContainer === void 0 ? void 0 : itemContainer.parentElement) === null || _d === void 0 ? void 0 : _d.firstElementChild);
        activationTarget = nextItem === null || nextItem === void 0 ? void 0 : nextItem.querySelector('.focusTarget');
    }
    activationTarget === null || activationTarget === void 0 ? void 0 : activationTarget.click();
    activationTarget === null || activationTarget === void 0 ? void 0 : activationTarget.focus();
};
/**
 * @returns `onKeyDown` handler that should be attached to root CheckedInputContainer
 */
export const useCheckedInputKeyboardListener = ({ handleArrowKeys } = {}) => {
    const onKeyDown = useCallback((e) => {
        if (!e.currentTarget.classList.contains(CheckedInputContainerClassName)) {
            // capturing event only when it bubbles up to the CheckedInputContainer level
            return;
        }
        e.stopPropagation();
        if (ACTIVATION_KEY_CODES.includes(e.code)) {
            e.currentTarget.click();
            return;
        }
        if (handleArrowKeys && ARROW_KEY_CODES.includes(e.code)) {
            handleArrowKeyDown(e);
        }
    }, []);
    return {
        onKeyDown,
    };
};
