import { RoleFieldName } from '../../../schema/common/owner-structure';
import { BusinessType, nativeBusinessTypeInfoList } from './data';
export const getNativeBusinessTypeInfo = (nativeBusinessType) => {
    const result = nativeBusinessTypeInfoList.find((nativeBusinessTypeInfo) => nativeBusinessTypeInfo.value === nativeBusinessType);
    if (!result) {
        throw new Error(`Not possible to fetch native business type info with the value ${nativeBusinessType}`);
    }
    return result;
};
export const getNativeBusinessTypeLabel = (nativeBusinessType) => {
    const { label } = getNativeBusinessTypeInfo(nativeBusinessType);
    return label;
};
export const getLocalizedBusinessTypeValue = (stepData) => {
    if (!stepData.nativeBusinessType) {
        return '';
    }
    return getNativeBusinessTypeLabel(stepData.nativeBusinessType);
};
export const createRoleFieldsTitles = (businessType, text) => {
    return {
        roleDirectors: businessType === BusinessType.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_DIRECTORS_LABEL')
            : text('OWNER_STRUCTURE_LIMITED_COMPANY_DIRECTORS_LABEL'),
        roleAlternativeOwners: businessType === BusinessType.NonProfit
            ? text('OWNER_STRUCTURE_NON_PROFIT_ALTERNATIVE_OWNERS_LABEL')
            : text('OWNER_STRUCTURE_ALTERNATIVE_OWNERS_LABEL'),
        rolePartners: text('OWNER_STRUCTURE_PARTNERS_LABEL'),
        roleBeneficialOwners: text('OWNER_STRUCTURE_BENEFICIAL_OWNERS_LABEL'),
    };
};
export const getLimitedPartnershipRoleLabels = (text) => {
    return {
        [RoleFieldName.roleDirectors]: text('OWNER_STRUCTURE_LIMITED_COMPANY_ROLE_VALUE_DIRECTOR'),
        [RoleFieldName.rolePartners]: text('OWNER_STRUCTURE_ROLE_VALUE_PARTNER'),
        [RoleFieldName.roleBeneficialOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_BENEFICIAL_OWNER'),
        [RoleFieldName.roleAlternativeOwners]: text('OWNER_STRUCTURE_ROLE_VALUE_ALTERNATIVE_OWNER'),
    };
};
export const getStateLabel = (statesData, stateName) => {
    const result = statesData.find((element) => element.value === stateName);
    if (!result) {
        throw new Error(`No state was found with the given name ${stateName}`);
    }
    return result.label;
};
