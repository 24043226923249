import { BusinessType, NativeBusinessType } from 'src/utils/country/IT/data';
import { getNativeBusinessTypeInfo } from 'src/utils/country/IT/data-helpers';
import { addLuhnCheckDigitToNumber, generateDigitSequence } from '../../helpers';
export const generateOrganizationNumber = () => addLuhnCheckDigitToNumber(generateDigitSequence(10)).toString();
export const generateBusinessCategoryFields = (formContext) => {
    var _a;
    const businessCategories = (_a = formContext.additionalData) === null || _a === void 0 ? void 0 : _a.businessCategories;
    if (!businessCategories) {
        throw new Error('Categories not found in form context');
    }
    return {
        businessCategoryId: businessCategories[0].code.toString(),
        businessSubCategoryId: businessCategories[0].children[0].code.toString(),
    };
};
const generatePersonFields = () => ({
    firstName: 'ANDREA',
    lastName: 'PIRLO',
    dateOfBirth: '23/05/1963',
    nationality: 'CH',
    streetAddress: 'Via Alfredo Dino Ferrari, 43',
    postalCode: '41053',
    city: 'Maranello',
});
const generateOwnerStructureFieldsLimitedCompany = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateOwnerStructureFieldsPartnership = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    rolePartners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    hasUBO: true,
    roleBeneficialOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
const generateOwnerStructureFieldsNonProfit = () => ({
    legalPersons: {
        '495953a4-6706-4604-aa87-5373bb9b7bda': generatePersonFields(),
    },
    roleDirectors: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
    roleAlternativeOwners: ['SIGNATORY', '495953a4-6706-4604-aa87-5373bb9b7bda'],
});
export const ownerStructureFieldsGenerators = {
    [BusinessType.LimitedCompany]: generateOwnerStructureFieldsLimitedCompany,
    [BusinessType.Partnership]: generateOwnerStructureFieldsPartnership,
    [BusinessType.NonProfit]: generateOwnerStructureFieldsNonProfit,
    [BusinessType.SelfEmployed]: () => null,
};
const createGenerateFunc = (nativeBusinessType) => {
    const { businessType } = getNativeBusinessTypeInfo(nativeBusinessType);
    return (formContext) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ businessType,
        nativeBusinessType, businessLegalEntityNumber: generateOrganizationNumber(), businessName: businessType === BusinessType.SelfEmployed ? 'ROBERTO MANCHINI' : 'Juventus Coin & C.', businessStreetAddress: 'VIA GRIMANI 67/A', businessPostalCode: '35041', businessCity: 'BATTAGLIA TERME', signatoryFirstName: 'ROBERTO', signatoryLastName: 'MANCHINI', signatoryDateOfBirth: '23/10/1969', signatoryNationality: 'US' }, (businessType === 'SelfEmployed' && formContext.form.endsWith('1099K')
        ? { signatoryUnderUsaTaxLaw: false }
        : false)), { isSignatoryShareAddress: true, signatoryPhoneNumber: '+390536949888' }), ownerStructureFieldsGenerators[businessType]()), generateBusinessCategoryFields(formContext)), { businessLocationTypes: ['PHYSICAL_STORE', 'TEMPORARY_STORE'], businessFrequency: 'FEW_TIMES_A_MONTH', businessAverageTransaction: '500-X', businessIzettleShareOfTurnover: '20-80%', businessCurrentAnnualTurnover: '1000000-X' }));
};
export const IT = Object.values(NativeBusinessType).map((nativeBusinessType) => {
    const info = getNativeBusinessTypeInfo(nativeBusinessType);
    return {
        title: `${info.title} (${info.businessType})`,
        generateFunc: createGenerateFunc(nativeBusinessType),
    };
});
