import React from 'react';
import styled from 'styled-components';
import { colors, spacing, size } from '@izettle/otto';
import { Text, Icon, Button } from '@izettle/react';
import { HelpSection } from 'src/components/Form/components/HelpSection';
import { getSvgIcon } from 'src/components/Questionnaire/icons';
import { useThemeInfo } from 'src/hooks/use-theme-info';
import { flexItemTextWrapFix } from 'src/styles/mixins';
import { breakpoints } from 'src/theme';
const PageContainer = styled.div `
  height: 100%;
  margin: 0 auto;
`;
const ContentContainer = styled.div `
  margin-top: ${spacing.large};
`;
const ButtonContainer = styled.div `
  margin-top: ${size.xMedium};
`;
const InstructionList = styled.ul `
  padding: 0 ${spacing.small};
  margin-top: ${spacing.large};

  @media (max-width: ${breakpoints.tiny}) {
    padding: ${spacing.none};
  }
`;
const InstructionListItem = styled.li `
  display: flex;
  margin-bottom: ${spacing.large};

  &:last-child {
    margin-bottom: 0px;
  }
`;
const StyledIcon = styled(Icon) `
  font-size: 24px;
  color: ${(props) => (props.paypaltheme ? colors.icon.default : colors.text.highlight)};
  margin-right: ${spacing.small};

  @media (max-width: ${breakpoints.tiny}) {
    display: none;
  }
`;
const InstructionListItemContent = styled.div `
  ${flexItemTextWrapFix}
`;
const HelpSectionContainer = styled.div `
  margin-top: ${spacing.large};
`;
export const InstructionItem = ({ icon, title, description, paypaltheme }) => {
    return (React.createElement(InstructionListItem, null,
        React.createElement(StyledIcon, { svgIcon: getSvgIcon(icon), alt: "", paypaltheme: paypaltheme }),
        React.createElement(InstructionListItemContent, null,
            title && React.createElement(Text.RegularBold, { className: "override-text" }, title),
            description && React.createElement(Text.RegularSecondary, { className: "override-text" }, description))));
};
export const PreparationScreen = ({ stepObject, nextStep }) => {
    const { header: contentHeader, button, items, helpTexts } = stepObject.applicationVariables.content;
    const { paypalTheme } = useThemeInfo();
    return (React.createElement(PageContainer, null,
        React.createElement(ContentContainer, { id: "preparationScreenContentHeader" },
            React.createElement(Text.LargeBold, { className: "override-text" }, contentHeader)),
        React.createElement(InstructionList, { "aria-labelledby": "preparationScreenContentHeader" }, items.map((item) => {
            return (React.createElement(InstructionItem, { key: item.title, icon: item.icon, title: item.title, description: item.description, paypaltheme: paypalTheme }));
        })),
        helpTexts && (React.createElement(HelpSectionContainer, null,
            React.createElement(HelpSection, { items: helpTexts, key: stepObject.ID }))),
        React.createElement(ButtonContainer, null,
            React.createElement(Button, { id: "continueBtn", variant: "primary", fullWidth: true, onClick: nextStep }, button))));
};
