import styled from 'styled-components';
import { spacing } from '@izettle/otto';
import { breakpoints } from 'src/theme';
export const ActionsContainer = styled.div `
  display: flex;

  & > * {
    margin-left: ${spacing.xxSmall};
  }

  @media (max-width: ${breakpoints.tiny}) {
    flex-direction: column;

    & > * {
      margin-bottom: ${spacing.xxSmall};
    }
  }
`;
