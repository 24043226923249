import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAsyncError } from '../../hooks/use-async-error';
import { startAuthFlow } from './auth-flow';
import { useTokenAutoRefresh } from './use-token-auto-refresh';
const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
    const [authClient, setAuthClient] = useState(null);
    const throwError = useAsyncError();
    useEffect(() => {
        void startAuthFlow().then(setAuthClient).catch(throwError);
    }, []);
    useTokenAutoRefresh(authClient);
    if (!authClient) {
        return null;
    }
    return React.createElement(AuthContext.Provider, { value: { authClient } }, children);
};
export const useAuthStateValue = () => useContext(AuthContext);
