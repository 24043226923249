import { buildSelfEmployedUsaTaxpayerSequence } from 'src/schema/common/usaTaxpayerDetails/schema';
import { BusinessType } from 'src/utils/country/FI/data';
import { buildCategorySteps } from '../../common/categories';
import { sequence, step, render, conditional, decision, customStepBuilder } from '../../steps-builder';
import * as stepTemplates from './flow-step-templates';
const root = sequence([
    // BUSINESS TYPE
    step(stepTemplates.businessType),
    step(stepTemplates.mapBusinessTypeFunction),
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: sequence([step(stepTemplates.selfEmployedPrepScreen)]),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.Partnership, BusinessType.NonProfit],
            branch: sequence([step(stepTemplates.generalPrepScreen)]),
        },
    ]),
    // BUSINESS DETAILS
    step(stepTemplates.businessLegalEntityNumber),
    step(stepTemplates.businessName),
    step(stepTemplates.businessAddress),
    // PERSONAL DETAILS
    step(stepTemplates.personalDetailsIntro),
    step(stepTemplates.signatoryName),
    step(stepTemplates.signatoryLegalEntityNumber),
    step(stepTemplates.signatoryNationality),
    customStepBuilder(buildSelfEmployedUsaTaxpayerSequence('businessType', 'SelfEmployed')),
    decision('isSignatoryShareAddress', stepTemplates.isSignatoryShareAddress, [
        { when: false, branch: step(stepTemplates.signatoryAddress) },
    ]),
    step(stepTemplates.signatoryPhoneNumber),
    // OWNER STRUCTURE
    conditional('businessType', [
        {
            when: [BusinessType.LimitedCompany],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.ownerStructureIntro),
                step(stepTemplates.limitedDirectorsList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.beneficialOwners) },
                    { when: false, branch: step(stepTemplates.limitedPartnershipAlternativeOwnersList) },
                ]),
            ]),
        },
        {
            when: [BusinessType.Partnership],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.ownerStructureIntro),
                step(stepTemplates.partnersList),
                decision('hasUBO', stepTemplates.hasUBO, [
                    { when: true, branch: step(stepTemplates.beneficialOwners) },
                    { when: false, branch: step(stepTemplates.limitedPartnershipAlternativeOwnersList) },
                ]),
            ]),
        },
        {
            when: [BusinessType.NonProfit],
            branch: sequence([
                step(stepTemplates.initLegalPersonsFunction),
                step(stepTemplates.ownerStructureIntro),
                step(stepTemplates.nonProfitDirectorsList),
                step(stepTemplates.nonProfitAlternativeOwnersList),
            ]),
        },
    ]),
    // USAGE OF ZETTLE
    conditional('businessType', [
        {
            when: [BusinessType.SelfEmployed],
            branch: step(stepTemplates.selfEmployedUsageOfIZettleIntro),
        },
        {
            when: [BusinessType.LimitedCompany, BusinessType.NonProfit, BusinessType.Partnership],
            branch: step(stepTemplates.generalUsageOfIZettleIntro),
        },
    ]),
    customStepBuilder(buildCategorySteps),
    step(stepTemplates.businessLocationsTypes),
    step(stepTemplates.businessFrequency),
    step(stepTemplates.businessAverageTransaction),
    step(stepTemplates.businessIzettleShareOfTurnover),
    decision('businessCurrentAnnualTurnover', stepTemplates.businessCurrentAnnualTurnover, [
        { when: 'NEW_BUSINESS', branch: step(stepTemplates.businessExpectedAnnualTurnover) },
    ]),
    // OVERVIEW
    step(stepTemplates.overviewPage),
]);
export const FI = (context) => render(root, context);
