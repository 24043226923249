import React from 'react';
import { useLocalization } from '@izettle/lingo-client';
import { Section } from '../../../Section';
import { HasUboFieldIfExists, LegalPersonsFieldIfNotEmpty, NoRoleAssignedField, RoleFieldIfExists } from './components';
export const OwnerStructureSection = ({ legalPersonContentTemplate, roleFieldsTitles, isMXMarket = false, }) => {
    const { text } = useLocalization();
    return (React.createElement(Section, { name: "ownerStructure", title: isMXMarket ? text('OVERVIEW_SECTION_OWNER_STRUCTURE_MX_TITLE') : text('OVERVIEW_SECTION_OWNER_STRUCTURE_TITLE') },
        React.createElement(RoleFieldIfExists, { roleFieldName: "roleDirectors", roleFieldsTitles: roleFieldsTitles }),
        React.createElement(RoleFieldIfExists, { roleFieldName: "rolePartners", roleFieldsTitles: roleFieldsTitles }),
        React.createElement(HasUboFieldIfExists, null),
        React.createElement(RoleFieldIfExists, { roleFieldName: "roleBeneficialOwners", roleFieldsTitles: roleFieldsTitles }),
        React.createElement(RoleFieldIfExists, { roleFieldName: "roleAlternativeOwners", roleFieldsTitles: roleFieldsTitles }),
        React.createElement(NoRoleAssignedField, null),
        React.createElement(LegalPersonsFieldIfNotEmpty, { legalPersonContentTemplate: legalPersonContentTemplate, isMXMarket: isMXMarket })));
};
