export const USA_TAXPAYER_CERTIFICATION_KEYS = [
    'OVERVIEW_USA_TAXPAYER_CERTIFICATION_INTRO',
    'OVERVIEW_USA_TAXPAYER_CERTIFICATION_ITEM_2',
    'OVERVIEW_USA_TAXPAYER_CERTIFICATION_OUTRO',
];
export const CROSSED_OUT_CERTIFICATION_KEY = 'OVERVIEW_USA_TAXPAYER_CERTIFICATION_ITEM_2_CROSSED_OUT';
export const NON_USA_TAXPAYER_SIGNATORY_CERTIFICATION_KEYS = [
    'OVERVIEW_NON_USA_TAXPAYER_INDIVIDUAL_CERTIFICATION_TERMS',
];
export const NON_USA_TAXPAYER_ENTITY_CERTIFICATION_KEYS = ['OVERVIEW_NON_USA_TAXPAYER_ENTITY_CERTIFICATION_TERMS'];
