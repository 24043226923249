import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { size, colors, borderRadius, sizeNumeric, spacingNumeric, IconTickExtraSmall, spacing } from '@izettle/otto';
import { MouseClickFocusRemover, SvgIcon } from '@izettle/react';
const TickBoxContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: ${size.xSmall};
  height: ${size.xSmall};
  margin: 0;
  margin-right: ${spacing.small};

  :focus {
    outline: none;
  }
`;
const tickBoxColors = {
    unchecked: {
        background: colors.background.default,
        border: colors.selector.initial.default,
    },
    checked: {
        background: colors.selector.selected.default,
        border: colors.selector.selected.default,
    },
};
const tickBoxStyles = css `
  ${({ tickState }) => `
    background: ${tickBoxColors[tickState].background};
    border-color: ${tickBoxColors[tickState].border};
    `};
`;
const TickBox = styled.div `
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid;
  border-radius: ${borderRadius.small};
  height: ${sizeNumeric.xSmall - spacingNumeric.tiny}px;
  width: ${sizeNumeric.xSmall - spacingNumeric.tiny}px;
  ${tickBoxStyles};
  ${({ disabled }) => disabled &&
    `
      background: ${colors.background.groupedSecondary};
      border-color: ${colors.background.groupedSecondary};
  `};
`;
const TickIcon = styled(SvgIcon).attrs(() => ({
    icon: IconTickExtraSmall,
})) `
  color: ${colors.action.primary.text.default};
`;
export const Tick = forwardRef((props, ref) => {
    const tickState = props.checked ? 'checked' : 'unchecked';
    return (React.createElement(TickBoxContainer, Object.assign({}, props, { ref: ref }),
        React.createElement(MouseClickFocusRemover, null,
            React.createElement(TickBox, { className: "tick_box", "aria-hidden": true, tickState: tickState, disabled: props.disabled }, props.checked && React.createElement(TickIcon, null)))));
});
